import React, {useContext, useState} from 'react';
import { TextInput, Button,Text, View, StyleSheet, Dimensions, TouchableOpacity} from 'react-native';
import * as yup from 'yup' /*Forms validator */
import { Formik } from 'formik' /*Forms Handler */
import { StatusBar } from 'expo-status-bar';
import Svg, {Path} from 'react-native-svg';
import SvgLogo from './svg_components/SvgLogo';
import { useNavigation } from '@react-navigation/native';
import awsconfig from '../aws-exports';
import {Amplify, Auth} from 'aws-amplify';
import { LinearGradient } from 'expo-linear-gradient';
import Context from '../context/Context';


Amplify.configure(awsconfig); 

const {width, height}= Dimensions.get('window')


const Reset_Password =()=>{
const [errorResponse, setErrorResponse] = useState('');
const  {userNameJSON}= useContext(Context);
const Navigation = useNavigation();

// Collect confirmation code and new password, then
async function ChangePasswordForgotten(username, verification_code,new_password){
    try {
      const response = await Auth.forgotPasswordSubmit(username, verification_code, new_password);
      (response) ?
      Navigation.navigate('StackLogin')
      : null
    } catch (error) {
      setErrorResponse('No fue posible realizar el cambio de la contraseña, por favor intente realizar el proceso mas tarde');
    }
  }


    
  function SvgTop(props){
    return(
      <Svg width={width} height="100%" {...props}>
  <Path fill="#111" d="M0 0h1920v1080H0z" />
  <Path
    fill="#183740"
    d="m0 308 53.3-7.8c53.4-7.9 160-23.5 266.7-25.9 106.7-2.3 213.3 8.7 320 4.9 106.7-3.9 213.3-22.5 320-17.5 106.7 5 213.3 33.6 320 34.8 106.7 1.2 213.3-25.2 320-22.8 106.7 2.3 213.3 33.3 266.7 48.8L1920 338V0H0Z"
  />
  <Path
    fill="#0f484d"
    d="m0 163 53.3 4.7c53.4 4.6 160 14 266.7 24.3 106.7 10.3 213.3 21.7 320 33.8C746.7 238 853.3 251 960 246c106.7-5 213.3-28 320-35.3 106.7-7.4 213.3 1 320 12.1 106.7 11.2 213.3 25.2 266.7 32.2l53.3 7V0H0Z"
  />
  <Path
    fill="#0e5a55"
    d="m0 156 53.3-4c53.4-4 160-12 266.7-18.2 106.7-6.1 213.3-10.5 320-5.1 106.7 5.3 213.3 20.3 320 25 106.7 4.6 213.3-1 320 1.8 106.7 2.8 213.3 14.2 320 10.2 106.7-4 213.3-23.4 266.7-33l53.3-9.7V0H0Z"
  />
  <Path
    fill="#236b55"
    d="m0 85 53.3 5.5c53.4 5.5 160 16.5 266.7 14.7 106.7-1.9 213.3-16.5 320-27.4C746.7 67 853.3 60 960 60.5c106.7.5 213.3 8.5 320 12.8 106.7 4.4 213.3 5 320 11.7 106.7 6.7 213.3 19.3 266.7 25.7l53.3 6.3V0H0Z"
  />
</Svg>
    )
  }

    return (
      <View style={styles.mainContainer}>
      <View style={styles.containerSvg}>
      <SvgTop width="100%" height="100%"/> 
      </View>
        <View style={styles.container}>
        {/* <SvgLogo/> */}

       <View style={styles.ContainerTittleLogin}>
        <Text style={styles.FirstTitle}>Check</Text>
        <Text style={styles.SecondTitle}>Out</Text>
        <Text style={styles.FirstTitleBottom}>Business</Text>
        </View>

           
      <View style={styles.ContainerLogin}>
        

       <Formik /*Initial values to evaluate:*/
        initialValues={{ 
          verification_code:'',
          new_password:''
        }}
        onSubmit={values => {
          const username=JSON.stringify(userNameJSON)
          const verification_code=JSON.stringify(values["verification_code"])
          const new_password=JSON.stringify(values["new_password"])
          ChangePasswordForgotten(JSON.parse(username), JSON.parse(verification_code), JSON.parse(new_password))
          }
        }  /*Values for send with JSON Format, this happen when handleSubmit catch the information sent*/
        validationSchema={yup.object().shape({ /*form validations with yup object with shapes:*/
            verification_code: yup
            .number('El código de verificación debe ser numerico')
            .required('El codigo de verificación es completamente obligatorio'),
            new_password: yup
            .string()
            .min(4, 'La contraseña debe tener como minimo 4 caracteres')
            .max(20, 'La contraseña no puede exceder los 20 caracteres')
            .required('El campo Password es requerido'),
        })}
       >
      {/* props to handler the errors, events and submiting data: */}
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <View style={styles.ContainerLogin}>   
          <Text style={styles.subTitle}>Restablecer Contraseña</Text>
          <Text style={styles.forgotPasswordText}>Especifica a continuación el código de verificación recibido en el correo registrado, y una nueva contraseña de ingreso a tu cuenta:</Text>       
            <TextInput
              editable={false}
              selectTextOnFocus={false}
              style={styles.textInputDissabled}
              placeholder={userNameJSON}
            />
            <TextInput
              value={values.verification_code}
              style={styles.textInput}
              onChangeText={handleChange('verification_code')}
              onBlur={() => setFieldTouched('verification_code')}
              placeholder="Código de verificación"
            />
            {touched.verification_code && errors.verification_code &&
              <Text style={{ fontSize: 12, color: '#fa7251' }}>{errors.verification_code}</Text>
            }
            <TextInput
              value={values.new_password}
              style={styles.textInput}
              onChangeText={handleChange('new_password')}
              onBlur={() => setFieldTouched('new_password')}
              placeholder="Nueva Contraseña"
            />
            {touched.new_password && errors.new_password &&
              <Text style={{ fontSize: 12, color: '#fa7251' }}>{errors.new_password}</Text>
            }
            
            {(errorResponse!='') &&
              <Text style={styles.errorResponse}>{errorResponse}</Text>
            } 

         <TouchableOpacity disabled={!isValid} onPress={handleSubmit} style={styles.containerButton}>
          
          <LinearGradient colors={(!isValid===true) ? ['#808080', '#434e64'] : ['#0b3a24', '#27bf78'] }
          start={{x:0, y:0}}
          end={{x:1, y:1}}
          style={styles.button}  
          >
          <Text style={styles.text}>Cambiar Contraseña</Text>
        </LinearGradient>
   
             </TouchableOpacity>
      
             <Text style={styles.dontHaveAccount} onPress={()=>{setErrorResponse('Por favor contacta el área de administración de ConstruEpoxicos para realizar la creación del nuevo usuario, gracias')}}>Aun no tengo una cuenta</Text>
           </View>
        )}
      </Formik>
      
      </View>
      
      </View>
               <StatusBar style="auto" />
      </View>
    );
  


}

export default Reset_Password;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor:'black',
    height:'100%'
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop:80

  },
  containerSvg: {
    width:'100%',
    height:'100%',
    backgroundColor:'black',
    // justifyContent:'flex-start',
    // alignItems:'center'
    position: 'absolute',
     top: 0,
     left: 0,
     right: 0,
     bottom: 0
  },

  MainContainerTittle:{
    width:"50%"
  },
  ContainerTittleLogin:{
   flexDirection:'row',
   flexWrap:'wrap',
   textAlign:'center',
   alignItems:'center',
   marginTop:50,
   justifyContent:'center'
  },
   FirstTitle: {
    fontSize:60, 
    color:'#74d9f9',
    fontWeight:'bold',
    textAlign:'center'
   },
   SecondTitle: {
    fontSize:60, 
    color:'#33d7ff',
    paddingLeft:20,
    textAlign:'left',
    fontWeight:'bold'
   },


   FirstTitleBottom: {
    fontSize:60, 
    color:'#74f9c1',
    fontWeight:'bold',
    textAlign:'center',
    width:'100%'
   },
   ContainerLogin:{
    marginTop:35,
    justifyContent:'center',
    alignItems:'center',
    width:'100%'
   },

   subTitle: {
    fontSize:20,
    color:'gray',
   },
   forgotPassword: {
    fontSize:14,
    color:'gray',
    paddingTop:15
   },
   errorResponse: {
    fontSize:12,
    color:'#ff9494',
    paddingTop:15,
    width:'80%',
    textAlign:'center'
   }
   ,
   dontHaveAccount: {
    fontSize:14,
    color:'#f1f1f1',
    marginTop:80,
   },
   textInput: {
    padding: 10,
    paddingStart:30,
    width:'80%',
    marginTop:10,
    borderRadius:30,
    height:50,
    fontSize:12,
    backgroundColor:'#f1f1f1',
   },
   containerButton:{
   
    alignItems:'center',
    justifyContent:'center',
    width:'60%',
    height:50,
    marginTop:25
  },
  button: {
    width:'100%',
    height:50,
    borderRadius:25,
    alignItems:'center',
    justifyContent:'center',
 },

   text: {
    fontSize:14,
    color:'#f1f1f1'
   },

   forgotPasswordText: {
    width:'80%',
    color:'#636363',
    marginTop:10,
    textAlign:'center'
 }
});
