//import liraries
import React, { Component, useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import awsconfig from '../../aws-exports';
import {Amplify, Auth} from 'aws-amplify';
import { useNavigation } from '@react-navigation/native';
import CryptoJS from "react-native-crypto-js";
import Context from '../../context/Context';
Amplify.configure(awsconfig);  

export const returnScrts= async () => {
    const scrts = await secureStorage('cryptedHash');
    return scrts;
}

const secureStorage = async (keyStored) => {
    const sSSNKey = await userSessionID();
    if (sSSNKey !== undefined && sSSNKey !== null && sSSNKey.length>0) {
        try {
            const HashScrts = await AsyncStorage.getItem(keyStored);
            let hashDcrpt  = CryptoJS.AES.decrypt(HashScrts, sSSNKey);
            let scrtsDcrptd = hashDcrpt.toString(CryptoJS.enc.Utf8);
            const scrtsParsed = JSON.parse(scrtsDcrptd);
            return scrtsParsed; 
            } catch (error) {
                await closeSession()
            }
        } else {
            await closeSession()
        }
}

const dropSecureStorage = async (keyStored) => {
    try {
     await AsyncStorage.removeItem(keyStored);
    } catch (error) {
     console.log('No fue posible eliminar la clave almacenada.')
    }
  };

export const userSessionID = async () => {
    const sessionToken=await Auth.currentAuthenticatedUser();
    if (sessionToken.signInUserSession.accessToken.jwtToken !== undefined && sessionToken.signInUserSession.accessToken.jwtToken !== null &&
        sessionToken.signInUserSession.accessToken.jwtToken.length>0) { return sessionToken.attributes.sub }  else { 
            await closeSession() 
        }
}

export const verifySession = async () => {

    const {setSessionStatus}=useContext(Context);
    const sessionToken=await Auth.currentAuthenticatedUser();
    if (sessionToken.signInUserSession.accessToken.jwtToken !== undefined && sessionToken.signInUserSession.accessToken.jwtToken !== null &&
        sessionToken.signInUserSession.accessToken.jwtToken.length>0) { 
            setSessionStatus(true);
        }  else {
            await closeSession() 
        }
}

export const closeSession = async (Navigation) => {

    try {
        Navigation.reset({
            index: 0,
            routes: [{ name: 'StackLogin' }]
          });
        await Auth.signOut();
        await dropSecureStorage('cryptedHash');
      } catch (error) {
        console.error('Error al cerrar la sesión del usuario.');
      }
}
