import React, {useState, useContext, useEffect} from 'react'
import { StyleSheet,Text, View, TextInput, ScrollView, Button } from 'react-native'
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import Context from '../../../../context/Context';
//For making requests:
import { executeRequest } from "../../../functional_global_components/API_Requests";
  

const Audits_Done = () => {

  const Navigation = useNavigation();

    //Search on Table:
    const [search, setSearch]=useState('0');
    const  {setDataNavigationAuditsSch, updateRequest}= useContext(Context);
    const [arrayAuditsSch, setArrayAuditsSch]= useState([]);

     
    ;
    

    const Requests_Created=(e, index)=>{
      
        return ( 
          <TouchableOpacity style={styles.Container}  key={index}> 
          <View style={styles.titleContainer}>
          <Text style={styles.titleRequest}>{e.auditName}</Text>
          </View>
          <Text style={styles.TextScreenLeft}>Auditor:</Text> 
          <Text style={styles.TextScreenRight}>{e.fullName}</Text>
          <Text style={styles.TextScreenLeft}>Fecha Auditoría:</Text> 
          <Text style={styles.TextScreenRight}>{e.dateAudit}</Text>
          <Text style={styles.TextScreenLeft}>Hora Inicio:</Text> 
          <Text style={styles.TextScreenRight}>{e.startHour}</Text>
          <Text style={styles.TextScreenLeft}>Hora Fin:</Text> 
          <Text style={styles.TextScreenRight}>{e.endHour}</Text>
          <Text style={styles.TextScreenLeft}>Estado:</Text> 
          <Text style={styles.TextScreenRightHighlight}>{e.state}</Text>
          </TouchableOpacity>

        )
       
    }


    const sendRequest=()=>{
    //Get:
    executeRequest('/audits', {
      "Get_Audits_Scheduled": [
        {
          "body": {
            "_Type_Request": "Get_All_Done_Audits_Scheduled",
            "_Audit_Scheduled_ID": null
          }
        }
      ]
    }, 'get', 'post').then(
          getData=>{
            setArrayAuditsSch(getData)
          }
        )
   }

      useEffect(()=>{
        sendRequest();
    },[]);

    useEffect(()=>{
      sendRequest();
  },[updateRequest]);
    
    const showData=()=>{
      if (arrayAuditsSch !== null && (arrayAuditsSch.length)>0) {
      return (
          <>
          {
          (search!=='0') 
          &&
          arrayAuditsSch.filter((e)=>(e.auditName.toLowerCase().includes((search).toLowerCase()))).map((e, index)=>(
            Requests_Created(e, index)
            )).slice(0,10)
          }
          {
          (search==='0' || search==='' || search=== null || search=== undefined) &&
          arrayAuditsSch.map((e, index)=>(
            Requests_Created(e, index)
          )).slice(0,10)
           }
          </>
        )
      } else { null }
    }



    
    return (
      
      <View style={styles.RootContainer}>
      <View style={styles.MainContainer}>
      <Text style={styles.TittleScreen}>Auditorias Realizadas</Text>
    <Text style={styles.TextHigh}>Especifica el nombre de la auditoria para realizar una búsqueda personalizada:</Text>
    <View style={styles.ContainerSearch}>

    {/* <Button title='Press me for Reset' onPress={()=>{setDataNavigation(dataNavigation[0]); console.log(dataNavigation)}}/> */}

    <TextInput title='Search' placeholder='Nombre auditoría' style={styles.SearchInput}
     onChangeText={(e) => { 
    (e==='' || e=== null || e=== undefined)?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    <ScrollView style={styles.Scroller} >
        {showData()}
        </ScrollView>

      </View>
      </View>
     
    )
  }

  const styles = StyleSheet.create({
    Scroller:{
      backgroundColor:'#111111'
    },
    RootContainer: {
      flex:1,
      width:'100%',
      padding:10,
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#111111'
    },
    MainContainer: {
      flex:1
    },
    Container:{
      borderRadius:15,
      flexGrow: 1,
      marginTop:10,
      marginBottom:10,
      marginLeft:5,
      marginRight:5,
      backgroundColor:'#151515',
      elevation: 5,
      shadowColor: '#ffffff',
      paddingLeft:25,
      paddingRight:25,
      paddingTop:25,   
      paddingBottom:25,   
      justifyContent:'space-between',
      flexWrap:'wrap',
      flexDirection: 'row',
      alignItems:'flex-start'
    },
    TextScreenLeft: {
      color: '#78baeb',
      fontWeight: 'bold',
      fontSize: 14,
      width:'35%',

    },
    TextScreenRight: {
      color: '#dbede9',
      fontSize: 14,
      width:'60%',

    },
    TextView: {
      color: '#2f2f2f',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop:10
    },
    InProgress: {
      color:'#04620f',

    },
    Finished: {
      color:'#343434',
    },
    ContainerSearch: {
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    },
    SearchInput: {
      backgroundColor:'#767a7a',
      width:'75%',
      height:25,
      textAlign:'left',
      borderRadius:10,
      marginTop:40,
      paddingLeft:10,
      marginLeft:30
    },
    IconSearch:{
      width:'10%',
      marginTop:40,
      marginLeft:20
    },
    TextHigh: {
      textAlign:'left',
      color:'#EDF0F5',
      paddingTop:'5%',
      paddingLeft:'5%',
      paddingRight:'5%',
      marginBottom:5
    },
    TittleScreen:{
      color:'#61AAE3',
      margin:10,
      fontSize:22,
      fontWeight:'bold',
      textAlign:'center'
    },
    titleRequest:{
      fontSize:16,
      color:'#adfffa',
      fontWeight:'bold',
      textAlign:'center',
      justifyContent:'center',
      alignItems:'center',
      width:'100%',
      margin:5
    },
    titleContainer:{
      width:'100%'
    },
    TextScreenRightHighlight: {
      color: '#b2ff8b',
      fontSize: 14,
      width:'60%'

    }
  });  

export default Audits_Done
