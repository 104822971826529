import React, { useState } from "react";
import Context from "./Context";

const Provider = function ({children}) {
    const  [activityIndicator, setActivityIndicator]= useState(false);
    const [dataSession, setDataSession]=useState({});
    //Performing response Array Clients:
    const [arrayClients, setArrayClients]=useState([]); 
    //Data Navigation Projects:
    const [dataNavigation, setDataNavigation]=useState([{}]);
    //Data Navigation Inventory:
    const [dataNavigationInventory, setDataNavigationInventory]=useState([{}]);
    //Data Navigation for Requests:
    const [dataNavigationRequest, setDataNavigationRequest]=useState([{}]);
    //Data Navigation for Teams:
    const [dataNavigationTeams, setDataNavigationTeams]=useState([{}]);
    //Data Navigation Clients:
    const [dataNavigationClients, setDataNavigationClients]=useState([{}]);
    //Data Navigation Audits Scheduled:
    const [dataNavigationAuditsSch, setDataNavigationAuditsSch]=useState([{}]);
    //Email Confirmation:
    const [emailConfirmation,setEmailConfirmation]=useState('');

    const [userNameJSON, setUserNameJSON]=useState();
    const [disconnectSession, setDisconnectSession]=useState(false);
    const [identificationUser, setIdentificationUser]=useState([]);
    const [typeSignature, setTypeSignature]=useState([{typeDoc:'', typeSign:''}]);
    //ID Novelty Project SubProject:
    const [idNovelty, setIdNovelty]=useState('');
    //Update Requests:
    const [updateRequest, setUpdateRequest]=useState(false);
    //Array Data Project:
    const [arrayProject, setArrayProject]=useState([]);
    //Array Data SubProject:

    //Variables Reports:
    const [showAlertReport,setShowAlertReport]=useState(false);
    const [endPointReport,setEndPointReport]=useState([{endPoint:'', typeRequest:'', rptName:''}]);
    //Axios Requests:
    const [strResponse, setStrResponse]=useState(null);
    const [arrayResponse, setArrayResponse]=useState([]);
    const [sessionStatus, setSessionStatus]=useState(false);
    //MQTT Global Object:
    const [MQTTObject, setMQTTObject]=useState({});
    //Array Tokens FCM:
    const [FCM_Tokens, setFCM_Tokens]=useState([]);
    return(
        <>
        <Context.Provider value={{activityIndicator, setActivityIndicator, dataSession, setDataSession,  userNameJSON, setUserNameJSON, 
            disconnectSession, setDisconnectSession, setDataNavigation, dataNavigation, identificationUser, setIdentificationUser, typeSignature, 
            setTypeSignature, updateRequest, setUpdateRequest, arrayProject, setArrayProject, dataNavigationRequest, setDataNavigationRequest,
            setDataNavigationTeams, dataNavigationTeams, dataNavigationClients, setDataNavigationClients, dataNavigationAuditsSch, setDataNavigationAuditsSch,
            emailConfirmation,setEmailConfirmation, showAlertReport,setShowAlertReport, arrayClients, setArrayClients, dataNavigationInventory, 
            setDataNavigationInventory, idNovelty, setIdNovelty, endPointReport, setEndPointReport, strResponse, setStrResponse,
            arrayResponse, setArrayResponse, sessionStatus, setSessionStatus, MQTTObject, setMQTTObject, FCM_Tokens, setFCM_Tokens}}>
            {children}
        </Context.Provider>
        </>
    )
    
}

export default Provider
 