import React, { useState, useContext, useEffect } from 'react';
import { ScrollView, Text, View, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { FlatList } from 'react-native-gesture-handler'
import Context from '../../../../context/Context';
import AwesomeAlert from 'react-native-awesome-alerts';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
 //Export to Excel And Sharing:
import * as XLSX from 'xlsx';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { FontAwesome } from '@expo/vector-icons';

const Request_Project_SubProject_Quotation =()=>  {

  
  const  {setDataNavigation, dataNavigation, dataSession, setEndPointReport,endPointReport}= useContext(Context);

  const [arrayItems, setArrayItems]=useState([]);
  const [disableSubmit, setDisableSubmit]=useState(false);
      /*Variables for gerating Report Inventory:*/
  const [arrayReport, setArrayReport]=useState([]);
  const [arrayColumns, setArrayColumns]=useState([]);
  const [arrayRows, setArrayRows]=useState([]);
  const [messageReport, setMessageReport]=useState('');
  const [showAlertFinished, setShowAlertFinished]=useState(false);
  
     
    ;
    

    /*Start Process Generate Report Inventory: */

const sendRequestReport=()=>{
        setDisableSubmit(true);
        //Rpt:
        executeRequest(endPointReport[0].endPoint, {
          "Get_Reports": [
            {
              "body": {
                "_Type_Request": (endPointReport[0] !== undefined) ? endPointReport[0].typeRequest : null,
                "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
                "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null 
              }
            }
          ]
        }, 'rpt', 'post').then(
              getData=>{ 
                setArrayReport(getData);
                if (response.data.length===0) {
                  setMessageReport("No existe información en la báse de datos para generar el reporte.");
                  setShowAlertFinished(true);
                }
                setShowAlertFinished(true);
                setDisableSubmit(false);
              }
        )

  }


useEffect(() => {
      if (arrayReport.length>0) {
        setArrayColumns(Object.keys(arrayReport[0])); /*["Tipo", "Nombre_Proyecto", "Descripción", "Cliente", "NIT", "Nombre_Supervisor", "Identificación", "Ciudad", "Dirección", "Ubicación", "Fecha_Estimada_Inicio_Obra", "Fecha_Estimada_Finalización_Obra", "Equipo_Asignado", "Acta_Inicio_Obra", "Acta_Finalización_Obra", "Cotización_Obra", "Estado", "Fecha_Creación", "Fecha_Finalización", "Usuario_Creador"]*/
       }
    }, [arrayReport]);


useEffect(() => {
      if (arrayReport.length>0 && arrayColumns.length>0) {
        setArrayRows([arrayColumns]);  /*Saving headers in a new array, after this step we iterate all results*/
        for (let i=0; i <  arrayReport.length; i++)  {
          setArrayRows(e=>[...e, Object.values(arrayReport[i])]);
          };
       }
    }, [arrayColumns]);
    
useEffect(() => {
      if (arrayReport.length>0 && arrayColumns.length>0 && arrayRows.length>0) {
        generateExcel();
          };
    }, [arrayRows]);
    
    



const generateExcel = () => {

      if (arrayReport.length>0) {
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(
          arrayRows
        );
        XLSX.utils.book_append_sheet(wb, ws, "Reporte", true);

              /*On Android And IOS: */
      if (Platform.OS==='android' || Platform.OS==='ios'){
        const base64 = XLSX.write(wb, { type: "base64" });
        const filename = FileSystem.documentDirectory + endPointReport[0].rptName +".xlsx";
        FileSystem.writeAsStringAsync(filename, base64, {
          encoding: FileSystem.EncodingType.Base64
        }).then(() => {
          Sharing.shareAsync(filename);
              });
    } else if (Platform.OS==='web'){
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, endPointReport[0].rptName.concat(".xlsx"));
        setMessageReport("El reporte " + endPointReport[0].rptName + " fue generado exitosamente.");
        setShowAlertFinished(true);
            }; 
        } 

      };
/*End Process Generate Report Inventory */
   
    const sendRequestProject=()=>{
      if (dataNavigation !== null && dataNavigation.length>0) {
        //Get:
        executeRequest('/psp-data', {
          "Get_Projects": [
              {
                  "body": {
                      "_Type_Request": "Final_Project_Quotation",
                      "_Client_ID": dataNavigation[0].clientChosen,
                      "_Project_ID": dataNavigation[1].projectChosen,
                      "_SubProject_ID": null,
                      "_Group_Quotation": dataNavigation[1].groupQuotation,
                      "_Group_Quotation_SubProject":null,
                      "_User_ID":null
                  }
              }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayItems(getData)
              }
            )

        } else { null }
      }

      const sendRequestSubProject=()=>{
        if (dataNavigation !== null && dataNavigation.length>0) {
          //Get:
          executeRequest('/psp-data', {
            "Get_Projects": [
                {
                    "body": {
                        "_Type_Request": "Final_Quotation_SubProject",
                        "_Client_ID": dataNavigation[0].clientChosen,
                        "_Project_ID": dataNavigation[1].projectChosen,
                        "_SubProject_ID": dataNavigation[2].subProjectChosen,
                        "_Group_Quotation": null,
                        "_Group_Quotation_SubProject":dataNavigation[2].groupQuotationSubProject,
                        "_User_ID":null
                    }
                }
            ]
          }, 'get', 'post').then(
                getData=>{
                  setArrayItems(getData)
                }
              )

          } else { null }
        }
     
    
      useEffect(()=>{
        if (dataNavigation[2]===undefined) {
          sendRequestProject()
        } else {
          sendRequestSubProject()
      }
        setEndPointReport([{endPoint:`/quotations/specific-quotation`, typeRequest:'Get_Report_Specific_Quotation', rptName:'Hoja_de_Salida'}]); 
      },[])
  




const listArrayItems= ({item})=>{


        return (
          <View style={styles.ViewRows}>
            <View style={styles.Row}>
              <Text style={styles.TextRows}>{item.item}</Text>
            </View>
            <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.quantityQuotized}</Text>
            </View>
            <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.quantityInStore}</Text>
            </View>
            <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.measure}</Text>
             </View>
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
              <>
             <View style={styles.Row}>
            <Text style={styles.TextRows}>$ {item.unitValue}</Text>
             </View>
             <View style={styles.Row}>
            <Text style={styles.TextRows}>$ {item.totalValue}</Text>
             </View>
             <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.brand}</Text>
             </View>
             <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.consecutive}</Text>
             </View>
             </>
        }
            </View>
        )
      
      }

if (dataNavigation !== null && arrayItems.length>0) {
    return (

<View style={styles.MainContainer}>
<ScrollView>
   
<View style={styles.formContainer}>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Hoja de Salida {(arrayItems.length===0)&&'0000'}{(arrayItems.length===1)&&'0000'}
 {(arrayItems.length===2)&&'000'}{(arrayItems.length===3)&&'00'}
{(arrayItems.length>0) ? arrayItems[0].counterQuotations
: null 
}
</Text>

</View>

{ (disableSubmit===true) ?  
<TouchableOpacity style={styles.ButtonAuditFinished}>
<FontAwesome style={styles.iconButtons} name="send-o" size={24} color="#f1f1f1" />
<Text style={styles.TextStylesFinished}>Procesando</Text>
</TouchableOpacity>
:
<TouchableOpacity style={styles.ButtonAuditFinished} onPress={()=>{
sendRequestReport()
  }}>
<FontAwesome style={styles.iconButtons} name="send-o" size={24} color="#f1f1f1" />
<Text style={styles.TextStylesFinished}>Descargar Hoja de Salida</Text>
</TouchableOpacity>
}

    </View>
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Fecha Creación:</Text> 
        </View>
        <View  style={styles.TextRight} >
                     <Text>{arrayItems[0].dateCreated}</Text>
        </View>
        </View>
        
        <View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Cliente:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text>{arrayItems[0].clientName}</Text>
        </View>
        </View>
        <ScrollView horizontal={true} nestedScrollEnabled={true}>          
        <View style={styles.TableStyles}> 
        <FlatList 
            data={arrayItems}
            renderItem={listArrayItems} 
            ListHeaderComponent={()=>{
                return(
                  <View style={styles.ViewRows}>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Item</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cantidad Requerida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cantidad Existente Bodega</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>U. Medida</Text>
                        </View>
                        {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
                        <>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Valor Unit.</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Valor Total</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Marca</Text>
                        </View>
                         <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Consecutivo</Text>
                        </View>
                        </>
                        }
                  </View>
                      )}}
                        />  

        </View>
        </ScrollView>

{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
<View style={styles.finalValues}>
        <View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>V.T Insumos & Herramientas:</Text> 
        </View>
        <View  style={styles.TextRight} >
                     <Text>$ {arrayItems[0].totalValueQuotized}</Text>
        </View>
        </View>
        
        <View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>{"Valor Total ".concat((dataNavigation[2] !== undefined) ? "SubProyecto" : "Proyecto",":")}</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text>$ {arrayItems[0].finalValueProject}</Text>
        </View>
        </View>
        
        </View>
}

      </ScrollView>
      </View>
    ); } 
    
    else { 

      return (
<View style={styles.MainContainer}>
  <ScrollView>
   
<View style={styles.formContainer}>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Hoja de Salida 0000</Text>
</View>
    </View>
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Fecha Creación:</Text> 
        </View>
        <View  style={styles.TextRight} >
                     <Text>__________________</Text>
        </View>
        </View>
        
        <View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Cliente:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text>__________________</Text>
        </View>
        </View>
        <ScrollView horizontal={true} nestedScrollEnabled={true}>          
        <View style={styles.TableStyles}> 
 
                  <View style={styles.ViewRows}>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Item</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cantidad Requerida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cantidad Existente Bodega</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>U. Medida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Valor Unit.</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Valor Total</Text>
                        </View>
                  </View>

        </View>
        </ScrollView>

<View style={styles.finalValues}>
        <View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>V.T Insumos & Herramientas:</Text> 
        </View>
        <View  style={styles.TextRight} >
                     <Text>$ __________________</Text>
        </View>
        </View>
        
        <View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Valor Total:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text>$ __________________</Text>
        </View>
        </View>
        </View>


        <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={messageReport}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
          }}
        />


      </ScrollView>
      </View>
        );
     } 
  }

const styles = StyleSheet.create({
  MainContainer:{
    backgroundColor:'#0d0d0d',
    justifyContent:'center',
    flex:1,
    flexDirection:'row'
  },
  formContainer: {
    padding: 20,
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10
  },
  FormStylesText:{
    color:'#f1f1f1'
  },
  formGroup: {
    marginTop:5,
    justifyContent:'center',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'center',
    width:'95%'
  },
  TextRight: {
    alignItems:'center',
    marginTop:10,
    justifyContent:'center',
    borderRadius:10,
    width:'50%',
    padding:10,
    backgroundColor:'#adadad'
  },
  TextLeft: {
    textAlign:'left',
    justifyContent:'center',
    width:'40%',
    marginTop:10,
  },
  DropDownSelection: {
    marginTop:10
  },
  TittleScreen:{
    color:'#bce4ff',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#2c2c2c',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:40
  },
  ButtonCenterFinished: {
    backgroundColor:'#4d83a5',
    marginLeft:40,
    marginRight:40,
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  Row:{
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  
  ContainerSearch: {
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'center',
  },
  SearchInput: {
    backgroundColor:'#767a7a',
    width:'70%',
    textAlign:'left',
    borderRadius:10,
    marginTop:40,
    paddingLeft:10,
    marginLeft:30
  },
  IconSearch:{
    width:'10%',
    marginTop:40,
    marginLeft:20
  },
  TextHigh: {
    textAlign:'left',
    color:'#EDF0F5',
    paddingTop:'5%',
    paddingLeft:'5%',
    paddingRight:'5%',
    marginBottom:5
  },
  PropertiesView:{
    backgroundColor:'#111111',
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:5
  },
  
  Text:{
    width:120,
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
  },
  TextHeader:{
    color:'#B4DCEC',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TextRows:{
    color:'#f1f1f1',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TableStyles: {
    backgroundColor:'#181818',
    marginTop:10,
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center'
  },
  TextInput:{
    width:60,
    backgroundColor:'grey',
    borderRadius:7,
    color:'#f1f1f1',
    textAlign:'center',
    borderRightWidth:1,
    borderRightColor:'#393939',
    alignContent:'center',
    marginLeft:30,
    marginRight:30
  },
  LeftSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    color:'#f1f1f1'
  },
  CenterSelect:{
    width:'18%',
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    marginTop:10,
    color:'#f1f1f1'
  },
  RightSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    color:'#f1f1f1'
  },

  LeftTitleSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  CenterTitleSelect:{
    width:'18%',
    textAlign:'center',
    borderRadius:10,
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  RightTitleSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  QuantitySelected:{
    width:120,
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    color:'#f1f1f1'
  },
  finalValues:{
    marginTop:20
  },
  ContainerButtons:{
    width:'100%',
    alignItems:'center',
    marginTop:10,
    marginBottom:20
  },
  ButtonAuditFinished:{
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#2e2e2e',
    width:'100%'
    },
    iconButtons:{
      marginTop:10
    },
    TextStylesFinished: {
      color:'#D2DDE9',
      fontWeight:'bold',
      margin:10,
      fontSize:14,
      textAlign:'center'
       },
});

export default Request_Project_SubProject_Quotation
