//import liraries
import React, { Component, useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Context from '../../context/Context'
import {returnScrts} from '../functional_global_components/Validate_Session' 
import CryptoJS from "react-native-crypto-js";
import  { API_Key_Encrypted, EndPoint_Encrypted } from "@env"
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

axios.defaults.headers['Content-Type'] ="application/json";
axios.defaults.headers['Access-Control-Allow-Origin'] ="https://check-out-business.com";

export const axiosGetScrts= async (email, keyParams , state, subID)=>{
    // Dcrpt:
    let fnKeyDcrptd  = CryptoJS.AES.decrypt(API_Key_Encrypted, "".concat(keyParams, state));
    let fnEndPointDcrptd  = CryptoJS.AES.decrypt(EndPoint_Encrypted, "".concat(keyParams, state));
    let dtKeyDcrptd = fnKeyDcrptd.toString(CryptoJS.enc.Utf8);
    let dtEndPointDcrptd = fnEndPointDcrptd.toString(CryptoJS.enc.Utf8);
    axios.defaults.headers['x-api-key'] = dtKeyDcrptd;
    let response=[];
    try {
        response = await axios.post("".concat(dtEndPointDcrptd, "/returnSecretsEncrypted"), {
          "get_Secrets": [
            {
              "body": {
                "_userName": email,
                "_Encrypted_Secret_Key": CryptoJS.AES.encrypt(API_Key_Encrypted, subID).toString()
              }
            }
          ]
        })
      }
    
    catch (error) {
      console.error('No fue posible almacenar las credenciales encriptadas.');
    }

   if (response !== undefined && response !== null) {
      await saveSecureStorage('cryptedHash', response.data.Response);
      return true;
      }
}

export const saveSecureStorage= async (keyStorage, scrts)=>{
        try {
          await AsyncStorage.setItem(keyStorage, scrts);
        } catch (error) {
          console.error('No fue posible almacenar el HashID obtenido. Por favor intentalo nuevamente más tarde.');
        }
}

export const executeRequest= async (resource, axiosRequest, typeRequest, method)=>{     
  
    let scrts= await returnScrts();
    switch(typeRequest) {
        case 'get':
            axios.defaults.headers['x-api-key'] = scrts.API_Key_Get;
            break;
        case 'rpt':
            axios.defaults.headers['x-api-key'] = scrts.API_Key_Get;
            break;
        case 'post':
            axios.defaults.headers['x-api-key'] = scrts.API_Key_Post;
            break;
        default:
            axios.defaults.headers['x-api-key'] = scrts.API_Key_Get;
            break;
            }
    try {
            const returnRequest = await axios[method]((typeRequest==='get') ? "".concat(scrts.API_Requests,resource) : (typeRequest==='rpt') ? "".concat(scrts.API_Reports,resource)  : "".concat(scrts.API_Post,resource), 
            (method==='delete') ? { data: axiosRequest } : axiosRequest);
            return returnRequest.data;
        } 
    catch (error) 
            {
                console.error('No fue posible establecer conexion con la base de datos.');
            }

            
    }
