import React, {useState, useContext, useEffect} from 'react'
import { StyleSheet,Text, View, TextInput, ScrollView, Button, Pressable } from 'react-native'
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import Context from '../../../context/Context';
//For making requests:
  import {executeRequest} from "../../functional_global_components/API_Requests";
import AwesomeAlert from 'react-native-awesome-alerts';
  

const Users_Created = () => {


  const  {setUpdateRequest,updateRequest, dataSession}= useContext(Context);
  const Navigation = useNavigation();

    //Search on Table:
    const [search, setSearch]=useState('0');
    const [showOptions, setShowOptions]=useState(false);
    const [userSelected, setUserSelected]=useState([]);
    const [arrayUsers, setArrayUsers]= useState([]);
    const [showAlertDeleteUser, setShowAlertDeleteUser]=useState(false);
    const [disableSubmit, setDisableSubmit]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [response, setResponse]=useState('');

     
    

    const Users_Created=(e, index)=>{
      
        return ( 
          <View style={styles.centerBox} key={index}>
          <TouchableOpacity style={styles.Container} onPress={()=> {
            if (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b")
            {
          
            setUserSelected([{id:e.id, userName:e.userName}]);
            setShowOptions(!showOptions);
          } else {null}
       }} >  
          <Text style={styles.TextScreenLeft}>Nombre:</Text> 
          <Text style={styles.TextScreenRight}>{e.fullName}</Text>
          <Text style={styles.TextScreenLeft}>Fecha Creación:</Text> 
          <Text style={styles.TextScreenRightBold}>{e.Date_Created}</Text>
          <Text style={styles.TextScreenLeft}>Identificación:</Text> 
          <Text style={styles.TextScreenRight}>{e.identification}</Text>
          <Text style={styles.TextScreenLeft}>Estado:</Text> 
          <Text style={styles.TextScreenRightBold}>{e.State}</Text>
          <Text style={styles.TextScreenLeft}>Teléfono:</Text> 
          <Text style={styles.TextScreenRightPhone}>{e.phoneNumber}</Text>
          <Text style={styles.TextScreenLeft}>Rol:</Text> 
          <Text style={styles.TextScreenRightBold}>{e.rol}</Text>
          <Text style={styles.TextScreenLeft}>Usuario:</Text> 
          <Text style={styles.TextScreenRightHight}>{e.userName}</Text>
        
          </TouchableOpacity>

        {(showOptions===true && e.id===userSelected[0].id && e.rol!=="Gerencia")  &&
  
            <Pressable style={styles.ContainerDelete} onPress={()=>{
              setShowAlertDeleteUser(true)
            }}>
          <Text style={styles.deleteUser}>Eliminar</Text>
            </Pressable>
            
        }             
        </View>

        )
       
    }


    const sendRequestUsers=()=>{
      //Get:
      executeRequest('/users', {
        "Get_Users": [
          {
            "body": {
              "_Type_Request": "Get_Users_Created",
              "_IdentificationCode":null,
              "_User_ID": null
            }
          }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayUsers(getData)
            }
          ) 
      }

      useEffect(()=>{
        sendRequestUsers();
      },[])

      useEffect(()=>{
        sendRequestUsers();
      },[updateRequest])
    
      const deleteUser=()=>{
      setDisableSubmit(true);
      setShowAlertDeleteUser(false); 
      //Delete:
      executeRequest('/users', {
        "Delete_User": [
          {
            "body": {
              "_User_ID": (userSelected[0] !== undefined ) ? userSelected[0].id : null,
              "_UserName": (userSelected[0] !== undefined ) ? userSelected[0].userName : null
            }
          }
        ]
      }, 'post', 'delete').then(
            getData=>{
              console.log(getData)
              setResponse(getData[0].Response)
              setShowAlertFinished(true);
              setDisableSubmit(false);
            }
          )

      }
      


      const showData=()=>{
        if (arrayUsers !== null && (arrayUsers.length)>0) {
        return (
            <>
            {
            (search!=='0') 
            &&
            arrayUsers.filter((e)=>(e.fullName.toLowerCase().includes((search).toLowerCase()))).map((e, index)=>(
              Users_Created(e, index)
              )) 
            }
             {
            (search!=='0') 
            &&
            arrayUsers.filter((e)=>(e.identification.toLowerCase().includes((search).toLowerCase()))).map((e, index)=>(
              Users_Created(e, index)
              )) 
            }
            {
            (search==='0' || search==='' || search=== null || search=== undefined) &&
            arrayUsers.map((e, index)=>(
              Users_Created(e, index)
            ))
             }
            </>
          )
        } else { null }
      }

    useEffect(()=>{
      showData()
    },[setSearch])
    


    return (
      
      <View style={styles.RootContainer}>
      <View style={styles.MainContainer}>
      <Text style={styles.TittleScreen}>Usuarios Creados</Text>
    <Text style={styles.TextHigh}>Especifica el nombre o la cédula del usuario para realizar una búsqueda personalizada:</Text>
    <View style={styles.ContainerSearch}>


    <TextInput title='Search' placeholder='Nombre del Equipo' style={styles.SearchInput}
     onChangeText={(e) => { 
      (e==='' || e=== null || e=== undefined) ? setSearch('0') : setSearch(e)
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    <ScrollView style={styles.Scroller} >
        {showData()}



        <AwesomeAlert style={styles.AlertContainer}
                show={showAlertDeleteUser}
                showProgress={false}
                title="Eliminar Usuario"
                message="¿Estas seguro de que deseas eliminar este Usuario?, presiona [Eliminar] para confirmar los cambios."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                titleStyle={{color:'#8DDBFF'}}
                messageStyle={{color:'#f9f9f9'}}
                contentContainerStyle={{backgroundColor:'#272727'}}
                confirmButtonColor='#809280'
                cancelButtonColor='#746E6E'
                onCancelPressed={() => {
                  setShowAlertDeleteUser(false)
                }}
                onConfirmPressed={() => {
                  deleteUser()
                }}
              />

<AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            setUpdateRequest(!updateRequest);
          }}
        />


        </ScrollView>

      </View>
      </View>
     
    )
  }

  const styles = StyleSheet.create({
    Scroller:{
      backgroundColor:'#111111'
    },
    RootContainer: {
      flex:1,
      width:'100%',
      padding:10,
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#111111'
    },
    MainContainer: {
      flex:1
    },
    centerBox:{
      alignItems:'center'
    },
    Container:{
      borderRadius:15,
      flexGrow: 1,
      marginTop:10,
      marginBottom:10,
      marginLeft:5,
      marginRight:5,
      backgroundColor:'#151515',
      elevation: 5,
      shadowColor: '#3cc4ff',
      paddingLeft:25,
      paddingRight:25,
      paddingTop:25,   
      paddingBottom:25,
      // padding:25,
      justifyContent:'center',
      flexWrap:'wrap',
      flexDirection: 'row'
      // alignItems:'flex-start'
    },
    ContainerDelete:{
      borderRadius:10,
      height:50,
      marginTop:10,
      marginBottom:10,
      backgroundColor:'#222222',
      width:'70%',
      alignItems:'center',
      textAlign:'center',
      alignContent:'center',
      padding:15
    },

    deleteUser:{
      textAlign:'center',
      fontSize:16,
      color:'#fa8d8d',
      fontWeight:'bold',
      alignItems:'center'
    },
    TextScreenLeft: {
      color: '#78baeb',
      fontWeight: 'bold',
      fontSize: 14,
      width:'35%',

    },
    TextScreenRight: {
      color: '#dbede9',
      fontSize: 14,
      width:'60%',
    },
    TextScreenRightBold:{
      color: '#ff8f8f',
      fontSize: 14,
      fontWeight:'bold',
      width:'60%'
    },
    TextScreenRightHight:{
      color: '#b3cdd8',
      fontSize: 12,
      fontWeight:'bold',
      width:'60%'
    },
    TextScreenRightPhone:{
      color: '#a1faa4',
      fontSize: 12,
      fontWeight:'bold',
      width:'60%'
    },
    TextView: {
      color: '#2f2f2f',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop:10
    },
    InProgress: {
      color:'#04620f',

    },
    Finished: {
      color:'#343434',
    },
    ContainerSearch: {
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    },
    SearchInput: {
      backgroundColor:'#767a7a',
      width:'75%',
      height:25,
      textAlign:'left',
      borderRadius:10,
      marginTop:40,
      paddingLeft:10,
      marginLeft:30
    },
    IconSearch:{
      width:'10%',
      marginTop:40,
      marginLeft:20
    },
    TextHigh: {
      textAlign:'left',
      color:'#EDF0F5',
      paddingTop:'5%',
      paddingLeft:'5%',
      paddingRight:'5%',
      marginBottom:5
    },
    TittleScreen:{
      color:'#61AAE3',
      margin:10,
      fontSize:22,
      fontWeight:'bold',
      textAlign:'center'
    },
  });  

export default Users_Created;
