import React, {useContext, useEffect, useState} from 'react';
import { StyleSheet,View, Text, ScrollView, Platform } from 'react-native';
import { StatusBar } from 'react-native';
/*Import Componets UI: */
import {Projects_Created_Btn, New_Project_Btn, Teams_Btn, Requests_Btn, Assigned_Projects_Btn, My_Team_Btn, Clients_Btn} from './components_ui/Components_Projects';
//UseContext
import Context from '../context/Context';
import {returnScrts} from './functional_global_components/Validate_Session';
import { executeRequest } from './functional_global_components/API_Requests';
//Only Import for platforms Android & IOS:
//import PushNotificationService from './functional_global_components/PushNotificationService';
import AwesomeAlert from 'react-native-awesome-alerts';
import {useNavigation } from '@react-navigation/native';

const Projects = () => {

  const Navigation= useNavigation();
  const [showAlertPending, setShowAlertPending]= useState(false);
  const  {dataSession, setMQTTObject}= useContext(Context);


  const sendRequestDeliveriesPending=()=>{
    //Get:
    executeRequest('/requests', {
      "Get_Requests": [
        {
          "body": {
            "_Type_Request": "Get_All_Requests_For_Delivering",
            "_Request_ID": null,
            "_User_ID": null
          }
        }
      ]
    }, 'get', 'post').then(
          getData=>{
            (getData.length>0) ? setShowAlertPending(true) : null
          }
        )
    }

  useEffect(()=>{
    //MQTT:  
    returnScrts().then(scrts=>setMQTTObject({MQTT:scrts.Host_MQTT, Port:scrts.Port_MQTT, Usr: scrts.User_MQTT, Pwd:scrts.pwrd_MQTT}));
    sendRequestDeliveriesPending();
  },[]) 

  return (
    <ScrollView style={styles.Root}> 
    <View style={styles.RootContainer}>
    <View style={styles.MainContainer}>
    <Text style={styles.TitleScreen}>Gestión de Proyectos</Text>
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
    <>
    <Projects_Created_Btn/>
    <New_Project_Btn/>
    </>
    }
    {/* Allow access to Técnico y Técnico Lider: */}
    {(dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") &&
    <Assigned_Projects_Btn/>
    }
    
    {/* Allow admin Teams Gerencia and Administrative users: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") &&
    <>
    <Text style={styles.TitleScreenMenu}>Parametrización</Text>
    <Teams_Btn/>
    </>
    }
     {/* Allow admin Teams Gerencia and Administrative users: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") &&
    <Clients_Btn/>
    }
    
    {/* Allow go to request for Gerencia, Lider Técnico And Administrativo: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" ||  dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") &&
    <>
    <Text style={styles.TitleScreenMenu}>Solicitudes</Text>
    <Requests_Btn/>
    </>
    
    }

{(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b") &&
    <>
    <Text style={styles.TitleScreenMenu}>Equipo</Text>
    <My_Team_Btn/>
    </>
    
    }



    </View>
    <StatusBar barStyle="light-content" backgroundColor="black" />

     {/* Only use Push Notifications on Android and Ios: */}
     {/* {(Platform.OS === 'android' || Platform.OS === 'ios') ? <PushNotificationService /> : null} */}
     {(Platform.OS === 'android' || Platform.OS === 'ios') ? null : null}
    

    </View>

    <AwesomeAlert
        show={showAlertPending}
        showProgress={false}
        title="Ítems Pendientes por Entrega"
        message="Tienes ítems pendientes por entregar. Los ítems asociados a las Hojas de Salida no se reflejarán en el inventario actual hasta que se marquen como entregados. Por favor, asegúrate de completar la entrega de estos ítems para que las cantidades existentes en el inventario se actualicen correctamente. Si no realizas estas entregas, no será posible actualizar los cambios en el inventario."
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        confirmText="Ver Entregas"
        cancelText="Más Tarde"
        confirmButtonColor="#495c65"
        cancelButtonColor="#5e5251"
        titleStyle={{ color: '#8DDBFF' }}
        messageStyle={{ color: '#f9f9f9' }}
        contentContainerStyle={{ backgroundColor: '#272727' }}
        onCancelPressed={()=>{setShowAlertPending(false)}}
        onConfirmPressed={()=>{
          setShowAlertPending(false);
          Navigation.navigate('Inventory', {  screen: 'Deliveries'});
        }}
        onDismiss={() => {
          setShowAlertPending(false);
        }}
      />

    </ScrollView>
  
  )
} 

const styles = StyleSheet.create({
  Root:{
    backgroundColor:'#111111',
    flex:1,
  },
  RootContainer: {
    flex:1,
    width:'100%',
    padding:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#111111'
    // borderTopLeftRadius:30,
    // borderTopRightRadius:30
  },
  MainContainer: {
    flex:1,
     width:'80%',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 14
  },
  TitleScreen:{
    margin:10,
    textAlign:'center',
    fontWeight:'bold',
    fontSize:20,
    color:'#cef2ff'
  },
  TitleScreenMenu:{
    margin:10,
    textAlign:'center',
    fontWeight:'bold',
    fontSize:20,
    color:'#56d2ff'
  },
});


export default Projects;
