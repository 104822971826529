import React from 'react'
import Svg, {Path} from 'react-native-svg'


const SvgLogo=(props)=>{
    return (
       <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={115.333}
    height={55.333}
    viewBox="0 0 220 109"
    {...props}
  >
    <Path d="M18.1 18.7c-6.4 2.3-11.7 8-13.7 14.9-2.4 8.2-1.6 25.3 1.4 31C10.1 72.9 17.3 77 27.5 77c4.6 0 6.5.4 6.5 1.3 0 3.1 5.4 9 10.3 11.3 11.2 5.2 25.1.8 21.3-6.7-1.1-2.2-1.7-2.4-8-2.1-4.9.2-7.5-.2-9.2-1.3-4.5-2.9-2.7-3.5 10.5-3.5 10.4 0 13.3-.3 14.5-1.6 5.2-5.1-3.8-19-14.2-22-8.3-2.4-14.1-.5-22.9 7.7-4.5 4.2-5.7 4.9-9.3 4.9-3.4 0-4.8-.6-7.6-3.4L16 58.2v-11c0-10.4.2-11.2 2.6-14.1 2.7-3.2 7.7-4.8 11.3-3.6 1.2.3 4.2 2.4 6.7 4.6 5 4.3 7.4 4.5 10.7.6 2.6-3 1.5-6.5-3.7-11.3-6.8-6.3-16.1-8-25.5-4.7zm41.5 45.4c2.7 2.2 1.3 2.9-6.1 2.9-5.9 0-7.3-.3-6.9-1.4 1.3-3.4 9.6-4.4 13-1.5zM168.7 30.7c-1.2 1.1-.7 13.7.6 16.3 1 1.9 2 2.5 4.7 2.5 4 0 5.5-2.9 2-3.8-3-.8-2.9-6.4.3-6.9 1.2-.2 2.2-1 2.2-1.8s-1-1.6-2.2-1.8c-1.4-.2-2.3-1-2.3-2.2 0-2.3-3.7-4-5.3-2.3zM89.5 34.2C81.8 38.4 84.6 50 93.3 50c1.9 0 3.9-.6 4.5-1.3 1.3-1.5-.1-4.2-1.7-3.3-1.8 1.1-4.9-.3-5.6-2.5-.9-2.7 1.2-4.9 4.6-4.9 2.2 0 2.9-.5 2.9-1.9 0-3-4.6-4.1-8.5-1.9zM108.3 34c-5 2.1-6 10.5-1.7 13.9 2.9 2.2 7.4 2.7 10.4 1.1 4.1-2.2 4.6-10.6.8-13.8-2.1-1.7-6.9-2.3-9.5-1.2zm6.5 7c.3 3.7-1.3 5.8-3.5 4.4-3.6-2.2-2.8-7.8.9-7.2 1.6.2 2.4 1.1 2.6 2.8zM130 34.2c-2.3 1.3-2.5 1.9-2.5 8.3 0 6.5.2 7 2.3 7.3 2.1.3 2.2 0 2.2-5.6 0-4.1.4-6.1 1.3-6.5 2.5-.9 3.6.8 3.9 6.3.3 4.8.6 5.5 2.6 5.8 2.1.3 2.2 0 2.2-6.6 0-4.8-.4-7.3-1.4-8.1-2.2-1.9-7.8-2.3-10.6-.9zM151.3 34.5c-2.5 1.8-3 5.6-1 7.3.6.6 2.4 1.3 4 1.7 4.3 1.1 3.3 2.8-1.3 2.2-3.4-.4-4-.2-4 1.3 0 2.1 1.9 3 6.1 3 4.7 0 6.9-1.6 6.9-5.1 0-2.6-.6-3.3-4-4.6-4.4-1.8-4.4-3.8 0-2.8 1.5.3 3 .2 3.3-.2 2-3.2-6.4-5.5-10-2.8zM187.7 34.2c-2.7 1-2.8 1.1-2.5 8.1.3 6.6.5 7.2 2.6 7.5 2.1.3 2.2 0 2.2-5.7 0-5.1.3-6.1 1.8-6.1.9 0 2.3-.4 3.1-.9 1.2-.8 1.2-1.2.2-2.5-1.5-1.8-3.3-1.9-7.4-.4zM201 39.5c0 7.1 2.8 10.5 8.8 10.5 6.2 0 7.4-1.5 7-9-.3-5.8-.5-6.5-2.5-6.8-2-.3-2.2.1-2.5 5.5-.3 5-.6 5.8-2.3 5.8-1.7 0-2.1-.8-2.5-5.5-.5-5.1-.7-5.5-3.2-5.8-2.8-.3-2.8-.3-2.8 5.3z" fill="#ff6f5f"/>
    <Path d="M130.8 51.6c-4.8 2.6-2.6 5.4 2.6 3.4 1.7-.6 2.6-1.6 2.4-2.7-.4-2.3-1.8-2.5-5-.7zM159.4 51.5c-.4.9-.2 2.1.4 2.7 1.6 1.6 4.7.5 4.7-1.7 0-2.6-4.2-3.4-5.1-1zM87.1 58.9c-3 3-2.8 9.1.3 12 1.9 1.8 3.3 2.2 7.2 1.9 4.1-.2 4.9-.6 4.9-2.3 0-1.7-.6-1.9-4.1-1.7-5.8.5-6.5-1.5-1-2.3 6.1-1 7.5-2.8 4.9-6.3-2.7-3.6-9.2-4.3-12.2-1.3zM95 62c0 .5-1.2 1-2.6 1-1.4 0-2.3-.4-1.9-1 .3-.6 1.5-1 2.6-1 1 0 1.9.4 1.9 1zM104.6 58.6c-1.2 1.1-1.6 3.6-1.6 9.8 0 8.5.8 10.6 4.2 10.6 1.3 0 1.8-.8 1.8-3 0-2.7.3-3 3.4-3 6.8 0 10.1-8.6 5.1-13.5-2.9-3-10.4-3.5-12.9-.9zm8.9 6.4c0 2.9-.4 3.6-2.2 3.8-2.1.3-2.3-.1-2.3-3.8 0-3.7.2-4.1 2.3-3.8 1.8.2 2.2.9 2.2 3.8zM124.1 59.6c-1.2 1.5-2.1 3.9-2.1 5.4 0 1.5.9 3.9 2.1 5.4 2.9 3.7 9.9 3.7 12.8 0 1.2-1.5 2.1-3.9 2.1-5.4 0-1.5-.9-3.9-2.1-5.4-1.6-2.1-2.9-2.6-6.4-2.6-3.5 0-4.8.5-6.4 2.6zM133 63c1.4 2.6-.4 6.3-2.8 5.8-1.8-.3-2.8-3.9-1.8-6.4.8-2 3.4-1.7 4.6.6zM141.5 58c-.3.6.4 2.4 1.6 4.1l2.1 3-2.1 2.3c-1.1 1.2-2.1 2.8-2.1 3.4 0 2.3 3.1 2.5 5.4.3l2.3-2.1 1.8 2c2.3 2.6 5.5 2.6 5.5.1 0-1-.7-2.4-1.5-3.1-.8-.7-1.5-2-1.5-3s.7-2.3 1.5-3c2-1.6 1.9-3.9-.1-4.7-1.1-.4-2.3.3-3.5 1.8l-1.9 2.4-1.9-2.3c-2-2.3-4.6-2.9-5.6-1.2zM159.2 64.7c.3 7.7.3 7.8 3.1 8.1 2.7.3 2.7.3 2.7-6.2 0-8-.6-9.6-3.6-9.6-2.4 0-2.5.1-2.2 7.7zM170.1 58.9c-2.9 2.9-2.8 9.3.1 12.1 2.9 2.6 10.1 2.5 10.6-.2.2-1.2-.5-1.7-2.9-2-1.8-.2-3.7-1.1-4.3-2.1-1.6-2.5.4-5.1 4.3-5.5 2.4-.3 3.1-.8 2.9-2-.5-2.7-8.1-2.9-10.7-.3zM186.1 58.7c-3.3 2.7-3.8 8.2-1.1 11.6 1.8 2.2 2.9 2.7 6.6 2.7 5.7 0 8.4-2.5 8.4-7.8 0-7.1-8.4-11-13.9-6.5zm7.9 3.8c.7.9 1 2.5.6 4.1-.9 3.3-4.9 3.1-5.4-.4-.7-4.8 2.1-6.9 4.8-3.7zM204.8 58.1c-3.9 2.2-.6 8.9 4.4 8.9 1 0 1.8.5 1.8 1.1 0 .6-1.5.9-3.7.7-3.2-.2-3.8 0-3.8 1.7s.8 2 4.8 2.3c5.6.4 7.7-.9 7.7-4.8 0-2.3-.7-3.1-4.2-4.6l-4.3-1.8 3-.3c5.1-.6 5.9-1.1 4.5-2.8-1.4-1.7-7.6-2-10.2-.4z" fill="#ff6f5f"/>
  </Svg>
    );
  }
  
  export default SvgLogo