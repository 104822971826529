import React, { useState, useContext, useEffect } from 'react';
import { ScrollView,TextInput, Text, View, StyleSheet, Pressable, Platform } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Context from '../../../../context/Context';
import { AntDesign } from '@expo/vector-icons';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
import AwesomeAlert from 'react-native-awesome-alerts';
import { storeData, getData } from '../../../functional_global_components/LocalStorageManager'; 

const Update_Project_SubProject_Quotation =()=>  {

    const [showAlert, setShowAlert]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [response, setResponse]=useState('');
    const [disableSubmit, setDisableSubmit]=useState(false);
    //Search on Table:
    const [search, setSearch]=useState('0');
    const [totalQuotationValue, setTotalQuotationValue]= useState(0);
    const [itemSelected, setItemSelected] = useState([{id:'0', name:'', measure:'', unitValue:'', type:'',quantity:'',totalValue:'', brand:'',consecutive:''},]);
    const [showFinalValue, setShowFinalValue]= useState(false);
    const [finalValuePSP, setFinalValuePSP]= useState('');
    const [finalValueSend, setFinalValueSend]= useState('');

    const {dataNavigation, dataSession, identificationUser, setUpdateRequest, updateRequest } = useContext(Context);
    const [arrayItems, setArrayItems]= useState([]);
    const [itemsForUpdate, setItemsForUpdate]= useState([]);
     //Array Data:
    const [arrayBasicParameters, setArrayBasicParameters]=useState([]);
    const [itemTypeSelected, setItemTypeSelected]= useState([{id:'0', description:''}]);
    const Navigation = useNavigation();

/*Formating Values to Country: */
const formatCurrency = (locales, currency, fractionDigits, number) => {

  var formatted = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fractionDigits
  }).format(number);
  return formatted;

}

const sendRequestBasicParameters=()=>{
  //Get:
  executeRequest('/basic_parameters', {
    "Get_Basic_Parameters": [
      {
        "body": {
          "_Type_Request": "Get_Basic_Parameters"
        }
      }
    ]
  }, 'get', 'post').then(
        getData=>{
          setArrayBasicParameters(getData)
        }
    )
}

  const requestBasicDataInventory=()=>{
    
      //Get:
      executeRequest('/basic_data_inventory', {
        "Get_Basic_Data_Inventory": [
          {
            "body": {
              "_Type_Request": "Get_Basic_Data_Inventory"
            }
          }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayItems(getData)
            }
          )
        
      }

  const sendRequest=()=>{
    
    setDisableSubmit(true);
    setShowAlert(false);
    //Put:
    executeRequest('/quotations', {
        "Update_PSPQuotation": [
          {
            "body": {
              "_Final_Value_Project": (finalValueSend.length>0) ? finalValueSend : null,
              "_User_Update_ID":  (identificationUser.length>0) ? identificationUser[0]["id"] : null,
              "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null,
              "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
              "_Client_ID": (dataNavigation[0] !== undefined ) ? dataNavigation[0].clientChosen : null,
              "_Data_Json": (itemSelected.length>0) ? itemSelected : null
            }
          }
        ]
      }, 'post', 'put').then(
            getData=>{
              setResponse(getData[0].Response);
              setUpdateRequest(!updateRequest)
              setShowAlertFinished(true);
              setDisableSubmit(false);
            }
          )

    }
  
  
  const showItemsType=()=>{
      if (arrayBasicParameters.length>0) {
      
        return (
      <View>
    {arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Item_Type".toLowerCase()))).map((x, index)=>(
      <Pressable  key={index} onPress={()=>{setItemTypeSelected([{id:x.id, description:x.description}])}}
    style={{backgroundColor:(itemTypeSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
              flexDirection:'row',
              alignItems:'center',
              textAlign:'center',
              justifyContent:'center',
              borderRadius:10,
              width:'100%',
              padding:10,
              marginTop:5
              }}>
        <View style={styles.Row}>
          <Text style={styles.Text}>{x.description}</Text>
        </View>
      </Pressable>
      ))
    }
      </View>
        );
      } else { null }
  }


  const showDataItems=()=>{

    if (arrayItems.length>0) {
      if (itemTypeSelected[0].id !=='0' ) {
    return (
      <ScrollView horizontal={true}>
      <View style={styles.TableStyles}>
    <FlatList 
      data={
        (search!=='0') ? 
      arrayItems.filter((x)=>(x.itemType.toLowerCase().includes((itemTypeSelected[0].description).toLowerCase())))
      .filter((e)=>(e.itemName.toLowerCase().includes((search).toLowerCase())))
      :
      arrayItems.filter((x)=>(x.itemType.toLowerCase().includes((itemTypeSelected[0].description).toLowerCase())))}
      renderItem={item} 
      updateCellsBatchingPeriod={1}
      removeClippedSubviews={true}
      ListHeaderComponent={()=>{
      return(
        <View style={styles.ViewRows}>
        <View style={styles.Row}>
          <Text style={styles.TextHeader}><AntDesign name="checkcircleo" size={18} color="#96CCFE" /></Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.TextHeader}>Item</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.TextHeader}>Marca</Text>
        </View>
      </View>
    )}}
      />  

    </View>
    </ScrollView>
    ) 
  }  
    }   else { null }

  }


  const updateValueQuantity = (id, quant) => {
    if (isNaN(Number(quant)) || quant === null || quant.trim() === '') {
        // Update itemSelected with New Quantity:
        const updatedItems = itemSelected.map(item =>
          item.id === id
            ? { ...item, quantity: '', totalValue: '' }
            : item
        );
        // Update State With new Data:
        setItemSelected(updatedItems);
        // Calculate New Value of TotalQuotation:
        const sum = updatedItems.reduce((total, item) => total + Number(item.totalValue), 0);
        setTotalQuotationValue(sum);
    } else {
      // Update itemSelected with new Data:
      const updatedItems = itemSelected.map(item =>
        item.id === id
          ? { ...item, quantity: quant, totalValue: quant * item.unitValue }
          : item
      );
      // Update State With New Data:
      setItemSelected(updatedItems);
      // Calculate new value total quotation value:
      const sum = updatedItems.reduce((total, item) => total + Number(item.totalValue), 0);
      setTotalQuotationValue(sum);
    }
  };
  



/************StartTableRows*****************/
const item= ({item, index})=>{

  return (

    <Pressable  onPress={()=>{(itemSelected.find(e=>e.id===item.hashID)) ?
      setItemSelected(itemSelected.filter(e=>e.id!==item.hashID)) 
      :
      setItemSelected((e)=>[...e, {id:item.hashID, name:item.itemName, measure:item.measure, unitValue:item.unitValue, type:item.itemType ,quantity:'',totalValue:'', brand:item.brand, consecutive: item.consecutive}])
    }}
  style={{backgroundColor:(itemSelected.map(e=>e.id).indexOf(item.hashID)!==-1)? '#0b0c0e' : '#1e2023' 
            ,  flexDirection:'row',
            alignItems:'center',}}>
      <View style={styles.Row} >
        <Text style={styles.Text}>{index + 1}</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.itemName}</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.brand}</Text>
      </View>
    </Pressable>
  )
}
/************EndTableRows*****************/
/************StartTable2Rows*****************/
const item_Selected= ({item})=>{
 
    // Find the match item on array:
    const findItem = itemSelected.find((x) => x.id === item.id);
    const quantity = findItem?.quantity?.toString() || '';

    // Update Quantity on State:
    const handleQuantityChange = (newQuantity) => {
      updateValueQuantity(item.id, newQuantity);
    };

  return (
    <View style={styles.ViewRows}>
      <View style={styles.Row}>
        <Text style={styles.TextRows}>{item.name}</Text>
      </View>
      <View style={styles.Row}>
      <TextInput
          style={styles.QuantitySelected}
          value={quantity}
          placeholder='?'
          keyboardType='numeric'
          onChangeText={handleQuantityChange}
        />
      </View>
      <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.measure}</Text>
       </View>
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{formatCurrency("es-CO", "COP", 2, item.unitValue)}</Text>
       </View>
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.type}</Text>
       </View>
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{formatCurrency("es-CO", "COP", 2, item.totalValue)}</Text>
       </View>
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.brand}</Text>
       </View>
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.consecutive}</Text>
       </View>

      </View>
  )

}
/************EndTable2Rows*****************/


/*Start Request Data for Update: */

const sendRequestProject=()=>{
if (dataNavigation !== null && dataNavigation.length>0) {
  //Create:
  executeRequest('/psp-data', {
      "Get_Projects": [
          {
              "body": {
                  "_Type_Request": "Final_Project_Quotation",
                  "_Client_ID": dataNavigation[0].clientChosen,
                  "_Project_ID": dataNavigation[1].projectChosen,
                  "_SubProject_ID": null,
                  "_Group_Quotation": dataNavigation[1].groupQuotation,
                  "_Group_Quotation_SubProject":null,
                  "_User_ID":(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" && identificationUser.length>0) ? identificationUser[0]["id"] : null
  
              }
          }
      ]
    }, 'get', 'post').then(
          getData=>{
            setItemsForUpdate(getData)
          }
        )
    } else { null }
  }

  const sendRequestSubProject=()=>{
    if (dataNavigation !== null && dataNavigation.length>0) {
        //Get:
        executeRequest('/psp-data', {
          "Get_Projects": [
              {
                  "body": {
                      "_Type_Request": "Final_Quotation_SubProject",
                      "_Client_ID": dataNavigation[0].clientChosen,
                      "_Project_ID": dataNavigation[1].projectChosen,
                      "_SubProject_ID": dataNavigation[2].subProjectChosen,
                      "_Group_Quotation": null,
                      "_Group_Quotation_SubProject":dataNavigation[2].groupQuotationSubProject,
                      "_User_ID":(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" && identificationUser.length>0) ? identificationUser[0]["id"] : null
                  }
              }
          ]
        }, 'get', 'post').then(
              getData=>{
                setItemsForUpdate(getData)
              }
            )

      } else { null }

    }
 

useEffect(()=>{
  sendRequestBasicParameters();
  requestBasicDataInventory();
  if (dataNavigation[2]===undefined) {
      sendRequestProject()
    } else {
      sendRequestSubProject()
    }
  },[]);


    useEffect(()=>{
      // Recover and Set Data Local Storage or AsyncStorage:
      const fetchData = async () => {
      const data = await getData((dataNavigation[2] !== undefined) ? "U_ExitSheet_".concat(dataNavigation[2].subProjectChosen) : "U_ExitSheet_".concat(dataNavigation[1].projectChosen));
      if (data.length > 0 && data[1] !== undefined) {
              setItemSelected(data);
            } else {
              if (itemsForUpdate.length>0) {
              itemsForUpdate.map(e=>(setItemSelected(x=>[...x, {id:e.inventoryID, name:e.item, measure:e.measure, unitValue:e.unitValue, type:e.type,quantity:e.quantityQuotized,totalValue:e.totalValue, brand:e.brand, consecutive: e.consecutive}])));
            } else null;
            };
          };
      fetchData();
  },[itemsForUpdate]);

useEffect(()=>{
  const saveLocalData = async () => {
    try {
      if (itemSelected[1] !== undefined) {
        await storeData((dataNavigation[2] !== undefined) ? "U_ExitSheet_".concat(dataNavigation[2].subProjectChosen) : "U_ExitSheet_".concat(dataNavigation[1].projectChosen), itemSelected) 
      } else null;
      } catch (err) {
        console.log(err);
      };
  };
  saveLocalData();
  // Calculate again value about total quotation:
  const sum = itemSelected.reduce((total, item) => total + Number(item.totalValue), 0);
  setTotalQuotationValue(sum);
},[itemSelected]);


    return (
<ScrollView style={styles.ViewRoot}>
<View style={styles.formContainer}>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Actualizar Hoja de Salida</Text>
</View>
     
<View  style={styles.DropDownSelection}>

{/* Start Fields And List */}
<Text style={styles.TextHigh}>Por favor especifica el tipo de item que deseas agregar a la Hoja de Salida:</Text>

{showItemsType()}
<Text style={styles.TextHigh}>Realiza una búsqueda personalizada teniendo presente el item que desea agregar a la  Hoja de Salida:</Text>
    <View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder='Buscar producto específico' style={styles.SearchInput}
     onChangeText={(e) => {
    (e==='')?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    {showDataItems()}


   {/* End Fields And FlatList */}

{(itemSelected[1]) &&
   <>
        <Text style={styles.TextHigh}>Lista de Items seleccionados:</Text>
        
        <ScrollView horizontal={true}>          
        <View style={styles.TableStyles}> 
        
        <FlatList 
                        data={ 
                        itemSelected.filter(e=>e.id!=='0')
                             }
                        renderItem={item_Selected} 
                        ListHeaderComponent={()=>{
                        
                        return(
                        <View style={styles.ViewRows}>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Item</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cantidad</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>U. Medida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Valor Unit.</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Tipo</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Valor Total</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Marca</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Consecutivo</Text>
                        </View>
                        
                      </View>
                      
                      )}}
                        />  
        </View>
        </ScrollView>
          
        <View style={styles.formGroupDates}>
        <View style={styles.DatesPicked}>
        <Text style={styles.FormStylesText}>Valor Total Insumos & Herramientas:</Text>  
        </View>
        <View  style={styles.AutoSumValue} >
                     <Text style={styles.AutoSum}>{formatCurrency("es-CO", "COP", 2, totalQuotationValue)}</Text>
        </View>
        </View>
        
        <View style={styles.formGroupDates}>
        <View style={styles.DatesPicked}>
        <Text style={styles.FormStylesText}>{"Valor Total ".concat((dataNavigation[2] !== undefined) ? "SubProyecto" : "Proyecto",":")}</Text> 
        </View>
        <View  style={styles.DatesInput}>

<TextInput placeholder={(itemsForUpdate.length>0 && showFinalValue===false) ? "".concat(formatCurrency("es-CO", "COP", 2, itemsForUpdate[0].finalValueProject)) : "".concat(finalValuePSP)}
              style={styles.inputStyle}
              onChangeText={(e)=>{setFinalValueSend(e)}} 
              {...Platform.select({
                ios: {
                  onEndEditing: (e) => {
                    setShowFinalValue(true);
                    setFinalValuePSP(formatCurrency("es-CO", "COP", 2, finalValueSend));
                  }
                },
                android: {
                  onEndEditing: (e) => {
                    setShowFinalValue(true);
                    setFinalValuePSP(formatCurrency("es-CO", "COP", 2, finalValueSend));
                  }
                }
              
              })}
              onBlur={ (e) => {
                  setShowFinalValue(true);
                  setFinalValuePSP(formatCurrency("es-CO", "COP", 2, finalValueSend));
                }
              }
            />



      </View>
    
        </View>
        </>
}

{ (disableSubmit===true) ?  
       <TouchableOpacity style={styles.ButtonFinished}>
       <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
         <Text style={styles.TextStylesFinished}>Procesando</Text>
         </TouchableOpacity>
      :
          
            <TouchableOpacity style={styles.ButtonFinished} onPress={()=>{setShowAlert(true)}}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
              <Text style={styles.TextStylesFinished}>Actualizar</Text>
              </TouchableOpacity>
           
        }

          </View>

          <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se llevará a cabo la actualización de la Hoja de Salida y se realizará una solicitud automática con la cantidad de items indicados al área de bodega para su posterior entrega. Si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            sendRequest();
            
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />


     </View>

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            if (dataNavigation[2] !== undefined) {
              Navigation.navigate('SubProject');
            } else {
              Navigation.navigate('Project');
            };
          }}
        />

      </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10
  },
  FormStylesText:{
    color:'#f1f1f1'
  },
  formGroupDates: {
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  inputStyle:{
    paddingLeft:10,
    textAlign:'center'
  },
  AutoSumValue: {
    alignItems:'center',
    marginTop:10,
    justifyContent:'center',
    borderRadius:10,
    width:'50%',
    padding:5,
    backgroundColor:'#323232'
  },
  AutoSum:{
    color:'#8efcd1'
  },
  DatesInput: {
    alignItems:'center',
    marginTop:10,
    justifyContent:'center',
    borderRadius:10,
    width:'50%',
    padding:5,
    backgroundColor:'#a9a9a9'
  },
  DatesPicked: {
    textAlign:'left',
    justifyContent:'center',
    width:'40%',
    marginTop:10,
  },
  DropDownSelection: {
    marginTop:10
  },
  TittleScreen:{
    color:'#bcfaff',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewRoot:{
     backgroundColor:'#111111',
     width:'100%',
     height:'100%'
  },
  ViewTittleScreen:{
    backgroundColor:'#242424',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:40
  },
  ButtonCenterFinished: {
    backgroundColor:'#4d83a5',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  Row:{
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  
  ContainerSearch: {
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'center',
  },
  SearchInput: {
    backgroundColor:'#767a7a',
    width:'70%',
    height:25,
    textAlign:'left',
    borderRadius:10,
    marginTop:40,
    paddingLeft:10,
    marginLeft:30
  },
  IconSearch:{
    width:'10%',
    marginTop:40,
    marginLeft:20
  },
  TextHigh: {
    textAlign:'left',
    color:'#EDF0F5',
    paddingTop:'5%',
    paddingLeft:'5%',
    paddingRight:'5%',
    marginBottom:5
  },
  PropertiesView:{
    backgroundColor:'#111111',
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:5
  },
  
  Text:{
    width:120,
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
  },
  TextHeader:{
    color:'#B4DCEC',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TextRows:{
    color:'#f1f1f1',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TableStyles: {
    backgroundColor:'#181818',
  },
  TextInput:{
    width:60,
    backgroundColor:'grey',
    borderRadius:7,
    color:'#f1f1f1',
    textAlign:'center',
    borderRightWidth:1,
    borderRightColor:'#393939',
    alignContent:'center',
    marginLeft:30,
    marginRight:30
  },
  ListSelectedContainer: {
    flex:1,
    flexDirection:'row',
    flexWrap:'wrap',
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
    marginTop:10
  },
  LeftSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    color:'#f1f1f1'
  },
  CenterSelect:{
    width:'18%',
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    marginTop:10,
    color:'#f1f1f1'
  },
  RightSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    color:'#f1f1f1'
  },

  LeftTitleSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  CenterTitleSelect:{
    width:'18%',
    textAlign:'center',
    borderRadius:10,
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  RightTitleSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  QuantitySelected:{
    width:120,
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    color:'#f1f1f1'
  },
  ButtonFinished: {
    backgroundColor:'#252b2d',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    padding:5,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#898989',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStyleLoading: {
    color:'#fa93b4',
    fontSize:16,
    paddingTop:5,
    fontWeight:'bold',
    textAlign:'center'
  },
});

export default Update_Project_SubProject_Quotation
