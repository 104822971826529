import React, {useState, useContext, useEffect} from 'react'
import { StyleSheet,Text, View, TextInput, ScrollView, Button } from 'react-native'
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import SvgIconLogo from '../../svg_components/SvgIconLogo';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { AntDesign } from '@expo/vector-icons';
import Context from '../../../context/Context';
//For making requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
  

const Teams_Created = () => {

  const Navigation = useNavigation();

    //Search on Table:
    const [search, setSearch]=useState('0');
    const  {setDataNavigationTeams, updateRequest}= useContext(Context);
    const [arrayTeams, setArrayTeams]= useState([]);

     
    ;
    



    const Teams_Created=(e, index)=>{
      
        return ( 
          <TouchableOpacity style={styles.Container} onPress={()=> {
            setDataNavigationTeams([{teamChosen:e.id}]);
            Navigation.navigate('Team');
       }} key={index}>  
          <Text style={styles.TitleContainer}>{e.name}</Text>
          <Text style={styles.TextScreenLeft}>Descripción:</Text> 
          <Text style={styles.TextScreenRight}>{e.description}</Text>
          <Text style={styles.TextScreenLeft}>Fecha Creación:</Text> 
          <Text style={styles.TextScreenRight}>{e.dateCreated}</Text>
          <Text style={styles.TextScreenLeft}>Estado:</Text> 
          <Text style={styles.TextScreenRight}>{e.state}</Text>
          </TouchableOpacity>
        )
       
    }


    const sendRequest=()=>{
      
    //Get:
    executeRequest('/teams', {
          "Get_Teams": [
            {
              "body": {
                "_Type_Request": "Get_Teams",
                "_Team_ID": null,
                "_User_ID": null
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayTeams(getData)
              }
          )
      }
      
      useEffect(()=>{
          sendRequest();
      },[])

      useEffect(()=>{
        sendRequest();
    },[updateRequest])

      const showData=()=>{
        if (arrayTeams !== null && (arrayTeams.length)>0) {
        return (
            <>
            {
            (search!=='0') 
            &&
            arrayTeams.filter((e)=>(e.name.toLowerCase().includes((search).toLowerCase()))).map((e, index)=>(
              Teams_Created(e, index)
              )) 
            }
            {
            (search==='0' || search==='' || search=== null || search=== undefined) &&
            arrayTeams.map((e, index)=>(
              Teams_Created(e, index)
            ))
             }
            </>
          )
        } else { null }
      }

    useEffect(()=>{
      showData()
    },[setSearch])
    


    return (
      
      <View style={styles.RootContainer}>
      <View style={styles.MainContainer}>
      <Text style={styles.TittleScreen}>Equipos Creados</Text>
    <Text style={styles.TextHigh}>Especifica el nombre del equipo para realizar una búsqueda personalizada:</Text>
    <View style={styles.ContainerSearch}>

    {/* <Button title='Press me for Reset' onPress={()=>{setDataNavigation(dataNavigation[0]); console.log(dataNavigation)}}/> */}

    <TextInput title='Search' placeholder='Nombre del Equipo' style={styles.SearchInput}
     onChangeText={(e) => { 
      (e==='' || e=== null || e=== undefined) ? setSearch('0') : setSearch(e)
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    <ScrollView style={styles.Scroller} >
        {showData()}
        </ScrollView>

      </View>
      </View>
     
    )
  }

  const styles = StyleSheet.create({
    Scroller:{
      backgroundColor:'#111111'
    },
    RootContainer: {
      flex:1,
      width:'100%',
      padding:10,
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#111111'
    },
    MainContainer: {
      flex:1
    },
    Container:{
      borderRadius:15,
      flexGrow: 1,
      marginTop:10,
      marginBottom:10,
      marginLeft:5,
      marginRight:5,
      backgroundColor:'#151515',
      elevation: 5,
      shadowColor: '#3dadff',
      paddingLeft:25,
      paddingRight:25,
      paddingTop:20,
      paddingBottom:25,   
      // padding:25,
      justifyContent:'space-between',
      flexWrap:'wrap',
      flexDirection: 'row',
      alignItems:'flex-start'
    },
    TextScreenLeft: {
      color: '#78baeb',
      fontWeight: 'bold',
      fontSize: 14,
      width:'35%',

    },
    TextScreenRight: {
      color: '#d6eae1',
      fontSize: 14,
      width:'60%',

    },
    TitleContainer: {
      color: '#3dadff',
      textAlign:'center',
      marginBottom:7,
      fontSize: 18,
      width:'100%',
      fontWeight:'bold'
    },

    TextView: {
      color: '#2f2f2f',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop:10
    },
    InProgress: {
      color:'#04620f',

    },
    Finished: {
      color:'#343434',
    },
    ContainerSearch: {
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    },
    SearchInput: {
      backgroundColor:'#767a7a',
      width:'75%',
      height:25,
      textAlign:'left',
      borderRadius:10,
      marginTop:40,
      paddingLeft:10,
      marginLeft:30
    },
    IconSearch:{
      width:'10%',
      marginTop:40,
      marginLeft:20
    },
    TextHigh: {
      textAlign:'left',
      color:'#EDF0F5',
      paddingTop:'5%',
      paddingLeft:'5%',
      paddingRight:'5%',
      marginBottom:5
    },
    TittleScreen:{
      color:'#61AAE3',
      margin:10,
      fontSize:22,
      fontWeight:'bold',
      textAlign:'center'
    },
  });  

export default Teams_Created;
