import React, { useState,Component, useEffect, useContext } from 'react';
import { ScrollView,TextInput, Text, Button, Alert, View, StyleSheet, Platform, Pressable } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import AwesomeAlert from 'react-native-awesome-alerts';
import Context from '../../../../context/Context'
import { FontAwesome5 } from '@expo/vector-icons';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
//MQTT Connection:
import Paho from "paho-mqtt";

const Create_New_Product =()=>  {

const Navigation = useNavigation();
const {identificationUser, MQTTObject} = useContext(Context);

//Alerts:
const [showAlert, setShowAlert]=useState(false);
const [showAlertFinished, setShowAlertFinished]=useState(false);
const [response, setResponse]=useState('');
const [disableSubmit, setDisableSubmit]=useState(false);
//Array Data:
const [arrayBasicParameters, setArrayBasicParameters]=useState([]);
const [measureSelected, setMeasureSelected]= useState([{id:'0', description:''}]);
const [itemTypeSelected, setItemTypeSelected]= useState([{id:'0', description:''}]);
const [unlockState,setUnlockState]=useState(false);
const [disableButton, setDisableButton]=useState(false);
const [showAlertLockStateFinished,setShowAlertLockStateFinished]=useState(false);
const [showAlertError, setShowAlertError]=useState(false);


 //Paho client connection:
 const client = new Paho.Client(
  MQTTObject.MQTT,
  Number(MQTTObject.Port), 
  identificationUser[0].id
  );

  client.connect({
    timeout: 15, 
    onSuccess: ()=>{ 
      client.subscribe("esp32/blocker");
     },
    onFailure: ()=>{ 
      console.log("Failed to connect!");
     },
    userName:MQTTObject.Usr,
    password:MQTTObject.Pwd, 
    useSSL: true, 
    keepAliveInterval: 60, 
    reconnect: false});

  const changeValue=(Number)=>{
    try {

    const message = new Paho.Message(String(Number));
    message.destinationName="esp32/blocker";
    client.send(message);
    setShowAlertLockStateFinished(true);
  
  setTimeout(()=>{
    setDisableButton(false);  
  }, 30000)
    } catch (error){
      setDisableButton(false);
      setShowAlertError(true);
    }

    }


    

    const saveLogAccessStore=(typeAccess)=>{
      //Create:
      executeRequest('/products/add', {
        "Log_Access_Store": [
          {
            "body": {
              "_User_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
              "_Type_Access":Number(typeAccess),
              "_Type_Request":"Entry_Material"
            
            }
          }
        ]
      }, 'post', 'post').then(
            getData=>{
              null
            }
          )
      }





const sendRequestBasicParameters=()=>{
    //Get:
    executeRequest('/basic_parameters', {
          "Get_Basic_Parameters": [
            {
              "body": {
                "_Type_Request": "Get_Basic_Parameters"
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayBasicParameters(getData)
              }
            )
      }

    useEffect(()=>{
      sendRequestBasicParameters();
    },[])

        const sendRequestCreateProduct=(itemName, quantity, unitValue, _Brand, _Consecutive, _State_Description)=>{
          setDisableSubmit(true);
          setShowAlert(false);
          //Create:
          executeRequest('/products', {
            "Create_Product": [
              {
                "body": {
                  "_Item_Name": itemName,
                  "_Quantity": quantity,
                  "_Measure_ID": measureSelected[0].id,
                  "_UnitValue": unitValue,
                  "_Item_Type_ID": itemTypeSelected[0].id,
                  "_User_Creator_ID": identificationUser[0]["id"],
                  "_Brand": _Brand,
                  "_Consecutive": _Consecutive,
                  "_State_Description": _State_Description
                }
              }
            ]
          }, 'post', 'post').then(
                getData=>{
                  setResponse(getData[0].Response)
                  setShowAlertFinished(true);
                  setShowAlert(false);
                  setDisableSubmit(false);
                }
              )
      
          }



const showMeasures=()=>{
    
  if (arrayBasicParameters.length>0) {
  
      return (
    <View style={styles.TableStyles}>
 {arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Measures".toLowerCase()))).map((x, index)=>(
    <Pressable  key={index} onPress={()=>{(measureSelected.find(e=>e.id===x.id)) ? setMeasureSelected(measureSelected.filter(e=>e.id!==x.id)) 
     : setMeasureSelected([{id:x.id, description:x.description}])
    }}
  style={{backgroundColor:(measureSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            width:'100%',
            padding:10,
            marginTop:5
            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{x.description}</Text>
      </View>
    </Pressable>
    ))
  }
    </View>
      );
    } else { null }

}


const showItemType=()=>{
    
  if (arrayBasicParameters.length>0) {
  
      return (
    <View style={styles.TableStyles}>
 {arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Item_Type".toLowerCase()))).map((x, index)=>(
    <Pressable  key={index} onPress={()=>{(itemTypeSelected.find(e=>e.id===x.id)) ? setItemTypeSelected(itemTypeSelected.filter(e=>e.id!==x.id)) 
     : setItemTypeSelected([{id:x.id, description:x.description}])
    }}
  style={{backgroundColor:(itemTypeSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            width:'100%',
            padding:10,
            marginTop:5
            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{x.description}</Text>
      </View>
    </Pressable>
    ))
  }
    </View>
      );
    } else { null }

}



    return (

    <ScrollView style={styles.formContainer}>

<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Crear Nuevo Producto</Text>
</View>
      <Formik
        initialValues={{
          itemName: '',
          quantity: '',
          unitValue: '',
          Brand:'', 
          Consecutive:'',
          State_Description:''
        }}
        onSubmit={values => {
          const itemName=JSON.stringify(values["itemName"]);
          const quantity=JSON.stringify(values["quantity"]);
          const unitValue=JSON.stringify(values["unitValue"]);
          const _Brand=JSON.stringify(values["Brand"]);
          const _Consecutive=JSON.stringify(values["Consecutive"]);
          const _State_Description=JSON.stringify(values["State_Description"]);
        
          sendRequestCreateProduct(JSON.parse(itemName), JSON.parse(quantity), JSON.parse(unitValue),JSON.parse(_Brand), JSON.parse(_Consecutive),
          JSON.parse(_State_Description));
        }}
        validationSchema={yup.object().shape({
            itemName: yup
            .string()  
            .min(2, 'El nombre del nuevo item debe tener como minimo 2 caracteres')
            .max(100, 'El nombre del nuevo item debe tener como máximo 100 caracteres')
            .required('Por favor especifique un nombre para el producto'),
            quantity: yup
            .number("Este campo unicamente admite valores numéricos")  
            .min(1, 'La cantidad minima permitida es de 1')
            .max(5000, 'La cantidad máxima permitida es de 5000'),
            unitValue: yup
            .number("Este campo unicamente admite valores numéricos")  
            .min(50, 'La cantidad minima permitida es de $ 50 (cincuenta pesos)')
            .max(80000000, 'La cantidad máxima permitida es de $ 80.000.000 (ochenta millones)'),
            Brand:yup
            .string()  
            .min(2, 'La marca del producto debe tener como minimo 2 caracteres')
            .max(50, 'La marca del producto debe tener como máximo 50 caracteres'),
            Consecutive:yup
            .string()  
            .min(1, 'El consecutivo debe tener como minimo 1 caracter')
            .max(50, 'El consecutivo debe tener como máximo 50 caracteres'),
            State_Description:yup
            .string()  
            .min(4, 'La descripción debe tener como minimo 4 caracteres')
            .max(200, 'La descripción no puede superar los 200 caracteres'),

        })}
       >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <View style={styles.formContainer}>
            <TextInput
              value={values.itemName}
              style={styles.inputStyle}
              onChangeText={handleChange('itemName')}
              onBlur={() => setFieldTouched('itemName')}
              placeholder="Nombre del Item"
            />
            {touched.itemName && errors.itemName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.itemName}</Text>
            }
            
        
<View style={styles.formGroupDates}> 
<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}>Cantidad:</Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={values.quantity} 
             style={styles.inputStyle}
             onChangeText={handleChange('quantity')}
             onBlur={() => setFieldTouched('quantity')}
             placeholder="0"
             />
</View>
{touched.quantity && errors.quantity &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.quantity}</Text>
            }
</View>




           
           <View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>Selecciona la unidad de medida:</Text>
</View>

{showMeasures()}

<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>Selecciona el grupo al cual pertenecerá el nuevo item:</Text>
</View>

{showItemType()}


<View style={styles.formGroupDates}>
<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}>Valor unitario:</Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={values.unitValue} 
             style={styles.inputStyle}
             onChangeText={handleChange('unitValue')}
             onBlur={() => setFieldTouched('unitValue')}
             placeholder="$"
             />
</View>
{touched.unitValue && errors.unitValue &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.unitValue}</Text>
            }

<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}>Marca:</Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={values.Brand} 
             style={styles.inputStyle}
             onChangeText={handleChange('Brand')}
             onBlur={() => setFieldTouched('Brand')}
             placeholder=""
             />
</View>
{touched.Brand && errors.Brand &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Brand}</Text>
            }


<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}>Consecutivo/# Herramienta:</Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={values.Consecutive} 
             style={styles.inputStyle}
             onChangeText={handleChange('Consecutive')}
             onBlur={() => setFieldTouched('Consecutive')}
             placeholder=""
             />
</View>
{touched.Consecutive && errors.Consecutive &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Consecutive}</Text>
            }

<Text style={styles.ProceduresExecution}>Estado/Producto:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {10}
              value={values.State_Description}
              style={styles.inputStyleProceduresExecution}
              onChangeText={handleChange('State_Description')}
              onBlur={() => setFieldTouched('State_Description')}
              placeholder=" "
            />
            {touched.State_Description && errors.State_Description &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.State_Description}</Text>
            }

</View>

<View style={styles.alignButtons}>
<TouchableOpacity style={(disableButton===false) ?  styles.ButtonUnlockStyle : styles.ButtonUnlockDisableStyle } onPress={()=> {
              setDisableButton(true);
              if (disableButton===false) {
              setUnlockState(true)
              changeValue(0);
              saveLogAccessStore(0);
                }
             }}>
            <FontAwesome5 style={styles.iconButtons}  name="unlock-alt" size={18} color="#2c2929" />
            <Text style={styles.TextStylesFinished}>Desbloquear Bodega</Text>
              </TouchableOpacity>


              <TouchableOpacity style={(disableButton===false) ?  styles.ButtonLockStyle : styles.ButtonLockDisableStyle } onPress={()=> {
              setDisableButton(true);
              if (disableButton===false) {
              setUnlockState(false)
              changeValue(1);
              saveLogAccessStore(1);
                }
             }}>
            <FontAwesome5 style={styles.iconButtons}  name="unlock-alt" size={18} color="#2c2929" />
            <Text style={styles.TextStylesFinished}>Bloquear Bodega</Text>
              </TouchableOpacity>

 { (disableSubmit===true) ?  
     
     <TouchableOpacity style={styles.ButtonDisabled}>
     <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
       <Text style={styles.TextStylesFinished}>Procesando</Text>
       </TouchableOpacity>
      :

            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonCreateProject} disabled={!isValid} onPress={()=>{setShowAlert(true);}}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
              <Text style={styles.TextStylesFinished}>Crear Producto</Text>
              </TouchableOpacity>
         
 }
</View>

<View style={styles.ViewSpace}></View>
        
<AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />    
          </View>
        )}
      </Formik>

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.navigate('Products_Created')
          }}
        />
<AwesomeAlert
          show={showAlertLockStateFinished}
          showProgress={false}
          title={(unlockState===true) ? "Desbloqueo Realizado" : "Bloqueo Realizado" }
          message={(unlockState===true) ? 
            "Se desbloqueó exitosamente el acceso a bodega. Por favor no olvides cerrar nuevamente la puerta cuando hayas terminado el proceso de entrega."
          : 
            "Se activó exitosamente el bloqueo magnético de la bodega, por favor verifica que la puerta se encuentre bien ajustada, gracias."
          }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertLockStateFinished(false);
          }}
        />
<AwesomeAlert
          show={showAlertError}
          showProgress={false}
          title="Error Inesperado"
          message="Se ha presentado un error inesperado al intentar establecer comunicación con el servidor, por favor reintenta la petición nuevamente."
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#ffb2b2'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertError(false);
          }}
        />
    </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
    backgroundColor:'#111111',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10,
    backgroundColor:'#c0c0c0',
  },
  formGroupDates: {
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  DatesPicked: {
    textAlign:'left',
    verticalAlign:'middle',
    justifyContent:'center',
    width:'55%',
    height:60,
  },
  DatesInput: {
    alignItems:'center',
    justifyContent:'center',
    borderRadius:30,
    width:'45%',
    height:60,
  },
  DropDownSelection: {
    marginTop:10,
    backgroundColor:'#505050',
    padding:5,
    borderRadius:10,
  },
  TittleScreen:{
    color:'#99f5bc',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#0B0B0B',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10
  },
  ButtonCreateProject: {
    backgroundColor:'#313d3d',
    borderRadius:10,
    marginTop:20,
    marginLeft:5,
    paddingTop:5,
    alignItems:'center',
    justifyContent:'center',
    width:'48%'
  },
  ButtonDisabled: {
    backgroundColor:'#bccdd4',
    borderRadius:10,
    marginTop:20,
    marginLeft:5,
    paddingTop:5,
    alignItems:'center',
    justifyContent:'center',
    width:'48%'
  },
  ButtonCenterTeam: {
    backgroundColor:'#24262c',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:12,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewSpace: {
    marginTop:10,
    width:'100%'
  },
 FormStylesText:{
   color:'#f1f1f1'
 },

 inputStyle : {
  width:'100%',
  height:50,
  borderRadius:10,
  padding:10,
  marginTop:10,
  alignItems:'center',
  justifyContent:'center',
  backgroundColor:'#dbd9d9',
  color:'#515151'
  },
  inputStyleDescription: {
    width:'100%',
    height:80,
    borderRadius:10,
    padding:10,
    marginTop:10,
    textAlignVertical:'top',
    backgroundColor:'#dbd9d9'
    },
    ContainerSearch: {
      marginTop:5,
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    },
    SearchInput: {
      backgroundColor:'#767a7a',
      width:'80%',
      height:35,
      textAlign:'left',
      borderRadius:10,
      marginTop:5,
      paddingLeft:10
    },
    SearchInputSelected: {
      backgroundColor:'#e3e3e3',
      width:'80%',
      height:35,
      textAlign:'left',
      borderRadius:10,
      marginTop:5,
      paddingLeft:10
    },
    IconSearch:{
      width:'10%',
      marginLeft:20
    },
    TableStyles: {
      textAlign:'center',
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center',
      borderRadius:10
    },
    Row:{
      textAlign:'left',
      alignItems:'center',
      justifyContent:'center',
      textAlign:'center'
    },
    
    Text:{
      padding:8,
      color:'#f1f1f1',
      textAlign:'center',
      justifyContent:'center',
      alignItems:'center',
      width:180
    },
    TextStyleLoading: {
      color:'#fa93b4',
      margin:10,
      fontSize:16,
      fontWeight:'bold',
      textAlign:'center'
    },
    ListSelectedContainer: {
      width:'100%',
      marginTop:5,
      textAlign:'center',
      alignItems:'center',
      justifyContent:'center'
    },
    RightTitleSelect:{
      textAlign:'left',
      marginTop:10,
      marginBottom:10,
      fontSize:14,
      fontWeight:'bold',
      color:'#bdecda'
    },
    ButtonUnlockStyle:{
      marginTop:20,
      borderRadius:10,
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#4F4242',
      width:'48%'
    },
    ButtonLockStyle:{
      marginTop:20,
      borderRadius:10,
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#424F44',
      marginLeft:5,
      width:'48%'
    },
    ButtonUnlockDisableStyle:{
      marginTop:20,
      borderRadius:10,
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#bfbfbf',
      width:'48%'
    },
    ButtonLockDisableStyle:{
      marginTop:20,
      borderRadius:10,
      alignItems:'center',
      justifyContent:'center',
      marginLeft:5,
      backgroundColor:'#bfbfbf',
      width:'48%'
    },
    alignButtons:{
      flex:1,
      justifyContent:'center',
      flexWrap:'wrap',
      flexDirection: 'row',
      alignItems:'center',
      width:'100%'
    },
    inputStyleProceduresExecution:  {
      width:'100%',
      height:100,
      borderRadius:10,
      padding:10,
      marginTop:10,
      textAlign:'justify',
      textAlignVertical:'top',
      backgroundColor:'#dbd9d9'
    },
    ProceduresExecution:{
      marginTop:10,
      color:'#f9f9f9',
      width:'100%'
    },

});

export default Create_New_Product
