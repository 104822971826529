import React, { useState,Component, useEffect, useContext } from 'react';
import { ScrollView,TextInput, Text, Button, Alert, View, StyleSheet, Platform, Pressable } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import AwesomeAlert from 'react-native-awesome-alerts';
import Context from '../../../../context/Context'

//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
  

const Update_Product =()=>  {

  const Navigation = useNavigation();
  const {identificationUser, dataNavigationInventory, updateRequest, setUpdateRequest} = useContext(Context);

    //Alerts:
    const [showAlert, setShowAlert]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [response, setResponse]=useState('');
    const [disableSubmit, setDisableSubmit]=useState(false);
    //Array Data:
    const [arrayBasicParameters, setArrayBasicParameters]=useState([]);
    const [measureSelected, setMeasureSelected]= useState([]);
    const [itemTypeSelected, setItemTypeSelected]= useState([]);
    const [arrayProducts, setArrayProducts]=useState([]);

   
    const sendRequest=()=>{
      //Get:
      executeRequest('/inventory', {
        "Get_Products": [
          {
            "body": {
              "_Type_Request": "Get_Specific_Product_Detail",
              "_Inventory_ID": dataNavigationInventory[0].itemchosen
            }
          }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayProducts(getData)
            }
        )
  }


      useEffect(()=>{
        sendRequest()
    },[])



    useEffect(()=>{
      (arrayProducts.length>0) ? 
      arrayProducts.map(e=>{ 
      setMeasureSelected([{id:e.measureID, description:e.measure}]);
      setItemTypeSelected([{id:e.typeID, description:e.type}]);
    })
      : null
  },[arrayProducts]);


    const sendRequestBasicParameters=()=>{
        //Get:
        executeRequest('/basic_parameters', {
          "Get_Basic_Parameters": [
            {
              "body": {
                "_Type_Request": "Get_Basic_Parameters"
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayBasicParameters(getData)
              }
            )
      }

    useEffect(()=>{
      sendRequestBasicParameters();
    },[])

        const updateProduct=(itemName, quantity, unitValue, _Brand, _Consecutive, _State_Description)=>{

          setDisableSubmit(true);
          setShowAlert(false);
          //Put:
          executeRequest('/products', {
            "Update_Product": [
              {
                "body": {
                  "_Item_Name": (itemName.length>0) ? itemName : null,
                  "_Quantity": (quantity.length>0) ? quantity : null,
                  "_Measure_ID": (measureSelected.length>0) ? measureSelected[0].id : null,
                  "_UnitValue": (unitValue.length>0) ? unitValue : null,
                  "_Item_Type_ID": (itemTypeSelected.length>0) ? itemTypeSelected[0].id : null,
                  "_User_Update_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
                  "_Inventory_ID": (dataNavigationInventory.length>0) ? dataNavigationInventory[0].itemchosen : null,
                  "_Brand": _Brand,
                  "_Consecutive": _Consecutive,
                  "_State_Description": _State_Description
                }
              }
            ]
          }, 'post', 'put').then(
                getData=>{
                  setResponse(getData[0].Response);
                  setUpdateRequest(!updateRequest);
                  setShowAlertFinished(true);
                  setDisableSubmit(false);
                }
              )
          }



const showMeasures=()=>{
    
  if (arrayBasicParameters.length>0) {
  
      return (
    <View style={styles.TableStyles}>
 {arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Measures".toLowerCase()))).map((x, index)=>(
    <Pressable  key={index} onPress={()=>{(measureSelected.find(e=>e.id===x.id)) ? setMeasureSelected(measureSelected.filter(e=>e.id!==x.id)) 
     : setMeasureSelected([{id:x.id, description:x.description}])
    }}
  style={{backgroundColor:(measureSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            width:'100%',
            padding:10,
            marginTop:5
            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{x.description}</Text>
      </View>
    </Pressable>
    ))
  }
    </View>
      );
    } else { null }

}


const showItemType=()=>{
    
  if (arrayBasicParameters.length>0) {
  
      return (
    <View style={styles.TableStyles}>
 {arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Item_Type".toLowerCase()))).map((x, index)=>(
    <Pressable  key={index} onPress={()=>{(itemTypeSelected.find(e=>e.id===x.id)) ? setItemTypeSelected(itemTypeSelected.filter(e=>e.id!==x.id)) 
     : setItemTypeSelected([{id:x.id, description:x.description}])
    }}
  style={{backgroundColor:(itemTypeSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            width:'100%',
            padding:10,
            marginTop:5
            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{x.description}</Text>
      </View>
    </Pressable>
    ))
  }
    </View>
      );
    } else { null }

}



    return (

    <ScrollView style={styles.formContainer}>

<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Actualizar Producto</Text>
</View>
      <Formik
        initialValues={{
          itemName: '',
          quantity: '',
          unitValue: '',
          Brand:'', 
          Consecutive:'',
          State_Description:''
        }}
        onSubmit={values => {
          const itemName=JSON.stringify(values["itemName"]);
          const quantity=JSON.stringify(values["quantity"]);
          const unitValue=JSON.stringify(values["unitValue"]);
          const _Brand=JSON.stringify(values["Brand"]);
          const _Consecutive=JSON.stringify(values["Consecutive"]);
          const _State_Description=JSON.stringify(values["State_Description"]);

          updateProduct(JSON.parse(itemName), JSON.parse(quantity), JSON.parse(unitValue), JSON.parse(_Brand), JSON.parse(_Consecutive),
          JSON.parse(_State_Description));
        }}
        validationSchema={yup.object().shape({
            itemName: yup
            .string()  
            .min(2, 'El nombre del item debe tener como minimo 2 caracteres')
            .max(100, 'El nombre del item debe tener como máximo 100 caracteres'),
            quantity: yup
            .number("Este campo unicamente admite valores numéricos")  
            .min(1, 'La cantidad minima permitida es de 1')
            .max(5000, 'La cantidad máxima permitida es de 5000'),
            unitValue: yup
            .number("Este campo unicamente admite valores numéricos")  
            .min(50, 'La cantidad minima permitida es de $ 50 (cincuenta pesos)')
            .max(80000000, 'La cantidad máxima permitida es de $ 80.000.000 (ochenta millones)'),
            Brand:yup
            .string()  
            .min(2, 'La marca del producto debe tener como minimo 2 caracteres')
            .max(50, 'La marca del producto debe tener como máximo 50 caracteres'),
            Consecutive:yup
            .string()  
            .min(1, 'El consecutivo debe tener como minimo 1 caracter')
            .max(50, 'El consecutivo debe tener como máximo 50 caracteres'),
            State_Description:yup
            .string()  
            .min(4, 'La descripción debe tener como minimo 4 caracteres')
            .max(200, 'La descripción no puede superar los 200 caracteres'),

        })}
       >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <View style={styles.formContainer}>
            <TextInput
              value={values.itemName}
              style={styles.inputStyle}
              onChangeText={handleChange('itemName')}
              onBlur={() => setFieldTouched('itemName')}
              placeholder={(arrayProducts.length>0) ? ''.concat(arrayProducts[0].itemName) : "Nombre del Item"}   
            />
            {touched.itemName && errors.itemName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.itemName}</Text>
            }
            
        
<View style={styles.formGroupDates}>
<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}>Cantidad:</Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={values.quantity} 
             style={styles.inputStyle}
             onChangeText={handleChange('quantity')}
             onBlur={() => setFieldTouched('quantity')}
             placeholder={(arrayProducts.length>0) ? ''.concat(arrayProducts[0].quantity) : "0"}    
             />
</View>
{touched.quantity && errors.quantity &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.quantity}</Text>
            }
</View>




           
           <View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>Selecciona la unidad de medida:</Text>
</View>

{showMeasures()}

<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>Selecciona el grupo al cual pertenecerá el nuevo item:</Text>
</View>

{showItemType()}


<View style={styles.formGroupDates}>
<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}>Valor unitario:</Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={values.unitValue} 
             style={styles.inputStyle}
             onChangeText={handleChange('unitValue')}
             onBlur={() => setFieldTouched('unitValue')}
             placeholder={(arrayProducts.length>0) ? '$'.concat(arrayProducts[0].unitValue) : "$"}   
             />
</View>
{touched.unitValue && errors.unitValue &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.unitValue}</Text>
            }

<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}>Marca:</Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={values.Brand} 
             style={styles.inputStyle}
             onChangeText={handleChange('Brand')}
             onBlur={() => setFieldTouched('Brand')}
             placeholder={(arrayProducts.length>0) ? ''.concat(arrayProducts[0].Brand) : ""}
             />
</View>
{touched.Brand && errors.Brand &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Brand}</Text>
            }


<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}>Consecutivo/# Herramienta:</Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={values.Consecutive} 
             style={styles.inputStyle}
             onChangeText={handleChange('Consecutive')}
             onBlur={() => setFieldTouched('Consecutive')}
             placeholder={(arrayProducts.length>0) ? ''.concat(arrayProducts[0].Consecutive) : ""}
             />
</View>
{touched.Consecutive && errors.Consecutive &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Consecutive}</Text>
            }

<Text style={styles.ProceduresExecution}>Estado/Producto:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {10}
              value={values.State_Description}
              style={styles.inputStyleProceduresExecution}
              onChangeText={handleChange('State_Description')}
              onBlur={() => setFieldTouched('State_Description')}
              placeholder={(arrayProducts.length>0) ? ''.concat(arrayProducts[0].State_Description) : ""}
            />
            {touched.State_Description && errors.State_Description &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.State_Description}</Text>
            }

</View>

 { (disableSubmit===true) ?  
        <TouchableOpacity style={styles.ButtonDisabled}>
        <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
          <Text style={styles.TextStylesFinished}>Procesando</Text>
          </TouchableOpacity>
      :
            
            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonCreateProject} disabled={!isValid} onPress={()=>{setShowAlert(true);}}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
              <Text style={styles.TextStylesFinished}>Actualizar</Text>
              </TouchableOpacity>
          
 }


<View style={styles.ViewSpace}></View>
        
<AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />    
          </View>
        )}
      </Formik>

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.navigate('Products_Created')
          }}
        />

    </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
    backgroundColor:'#111111',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10,
    backgroundColor:'#c0c0c0',
  },
  formGroupDates: {
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  DatesPicked: {
    textAlign:'left',
    verticalAlign:'middle',
    justifyContent:'center',
    width:'55%',
    height:60,
  },
  DatesInput: {
    alignItems:'center',
    justifyContent:'center',
    borderRadius:30,
    width:'45%',
    height:60,
  },
  DropDownSelection: {
    marginTop:10,
    backgroundColor:'#505050',
    padding:5,
    borderRadius:10,
  },
  TittleScreen:{
    color:'#99f5bc',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#383838',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10
  },
  ButtonCreateProject: {
    backgroundColor:'#313d3d',
    borderRadius:10,
    marginTop:20,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#bccdd4',
    borderRadius:10,
    marginTop:20,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonCenterTeam: {
    backgroundColor:'#24262c',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewSpace: {
    marginTop:10,
    width:'100%'
  },
 FormStylesText:{
   color:'#f1f1f1'
 },

 inputStyle : {
  width:'100%',
  height:50,
  borderRadius:10,
  padding:10,
  marginTop:10,
  alignItems:'center',
  justifyContent:'center',
  backgroundColor:'#dbd9d9',
  color:'#515151'
  },
  inputStyleDescription: {
    width:'100%',
    height:80,
    borderRadius:10,
    padding:10,
    marginTop:10,
    textAlignVertical:'top',
    backgroundColor:'#dbd9d9'
    },
    ContainerSearch: {
      marginTop:5,
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    },
    SearchInput: {
      backgroundColor:'#767a7a',
      width:'80%',
      height:25,
      textAlign:'left',
      borderRadius:10,
      marginTop:5,
      paddingLeft:10
    },
    SearchInputSelected: {
      backgroundColor:'#e3e3e3',
      width:'80%',
      height:25,
      textAlign:'left',
      borderRadius:10,
      marginTop:5,
      paddingLeft:10
    },
    IconSearch:{
      width:'10%',
      marginLeft:20
    },
    TableStyles: {
      textAlign:'center',
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center',
      borderRadius:10
    },
    Row:{
      textAlign:'left',
      alignItems:'center',
      justifyContent:'center',
      textAlign:'center'
    },
    
    Text:{
      padding:8,
      color:'#f1f1f1',
      textAlign:'center',
      justifyContent:'center',
      alignItems:'center',
      width:180
    },
    TextStyleLoading: {
      color:'#fa93b4',
      margin:10,
      fontSize:16,
      fontWeight:'bold',
      textAlign:'center'
    },
    ListSelectedContainer: {
      width:'100%',
      marginTop:5,
      textAlign:'center',
      alignItems:'center',
      justifyContent:'center'
    },
    RightTitleSelect:{
      textAlign:'left',
      marginTop:10,
      marginBottom:10,
      fontSize:14,
      fontWeight:'bold',
      color:'#bdecda'
    },
    inputStyleProceduresExecution:  {
      width:'100%',
      height:100,
      borderRadius:10,
      padding:10,
      marginTop:10,
      textAlign:'justify',
      textAlignVertical:'top',
      backgroundColor:'#dbd9d9'
    },
    ProceduresExecution:{
      marginTop:10,
      color:'#f9f9f9',
      width:'100%'
    },
 
});

export default Update_Product
