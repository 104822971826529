import React, { useRef, useState, useContext } from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { captureRef } from 'react-native-view-shot';
import * as FileSystem from 'expo-file-system';
import {executeRequest} from '../../../functional_global_components/API_Requests';
import AwesomeAlert from 'react-native-awesome-alerts';
import Context from '../../../../context/Context';
import { useNavigation } from '@react-navigation/native';

const SignatureCapture = () => {
  const Navigation = useNavigation();
  const [path, setPath] = useState('');
  const [isDrawing, setIsDrawing] = useState(false);
  const [pathHistory, setPathHistory] = useState([]);
  const [showAlertFinished, setShowAlertFinished]=useState(false);
  const [response, setResponse]=useState('');
  const {dataNavigation, typeSignature} = useContext(Context);
  
   // Función para enviar el archivo a S3 Bucket
   const uploadFile = (imageBase64) => {
    if (dataNavigation.length>0) {
    // Lógica para subir el archivo a S3 Bucket
    executeRequest('/uploadFilesS3', {
      Upload_File: [
        {
          body: {
            _file: imageBase64,
            _filename: typeSignature[0].typeDoc + ((dataNavigation[2]!== undefined) ? dataNavigation[2].subProjectChosen : dataNavigation[1].projectChosen) + typeSignature[0].typeSign + '.jpeg'
          },
        },
      ],
    }, 'post', 'put').then(getData => {
      setResponse(getData[0].Response);
      setShowAlertFinished(true);
    }); 
  } else {null};
  };

  const svgRef = useRef(null);

  const handleStartDrawing = ({ nativeEvent }) => {
    const { locationX, locationY } = nativeEvent;
    setPath(`M${locationX},${locationY}`);
    setIsDrawing(true);
  };

  const handleDrawing = ({ nativeEvent }) => {
    if (!isDrawing) return;
    const { locationX, locationY } = nativeEvent;
    setPath(path => `${path} L${locationX},${locationY}`);
  };

  const handleEndDrawing = () => {
    setIsDrawing(false);
    setPathHistory([...pathHistory, path]);
  };

  const handleClearPath = () => {
    setPath('');
    setPathHistory([]);
  };

  const handleSaveSignature = async () => {
    console.log('pressed')
    try {
      const uri = await captureRef(svgRef, {
        format: 'png',
        quality: 1,
      });
      const base64Data = await FileSystem.readAsStringAsync(uri, {
        encoding: FileSystem.EncodingType.Base64,
      });
      console.log('Base64 signature:', base64Data);
      uploadFile(base64Data);
    } catch (error) {
      console.error('Error saving signature:', error);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.signatureContainer}>
        <Svg
          ref={svgRef}
          width="100%"
          height="100%"
          onTouchStart={handleStartDrawing}
          onTouchMove={handleDrawing}
          onTouchEnd={handleEndDrawing}
        >
          {pathHistory.map((p, index) => (
            <Path key={index} d={p} fill="none" stroke="#889AB2" strokeWidth={2} />
          ))}
          <Path d={path} fill="none" stroke="#889AB2" strokeWidth={2} />
        </Svg>
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={styles.button}
          onPress={handleClearPath}
        >
          <Text style={styles.buttonText}>Borrar</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={handleSaveSignature}
        >
          <Text style={styles.buttonText}>Confirmar</Text>
        </TouchableOpacity>
      </View>
         <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.goBack();
          }}
        />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor:'#19191C',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  signatureContainer: {
    flex: 1,
    width: '100%'
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  button: {
    backgroundColor: '#212121',
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
  },
});

export default SignatureCapture;