import React, {useState, useContext, useEffect} from 'react';
import { ScrollView, Text, TouchableOpacity,View, StyleSheet, TextInput, Pressable, Platform} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
//Download files:
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import AwesomeAlert from 'react-native-awesome-alerts';
import { MaterialIcons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import * as yup from 'yup';
import { Formik } from 'formik';
import Context from '../../../context/Context';
//For making requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
import { deleteLocalData } from '../../functional_global_components/LocalStorageManager';

const SubProject =()=>  {

const  {setDataNavigation, dataNavigation,  setUpdateRequest, updateRequest, idNovelty, setIdNovelty, dataSession, identificationUser}= useContext(Context);
//Array subProject Information Detailed:
const [arraySubProject, setArraySubProject]=useState([]);
//Array Novelties Project:
const [arrayNovelties, setArrayNovelties]=useState([]);
const [showOptionsNovelty, setShowOptionsNovelty]=useState(false);

const [response, setResponse]=useState('');
const [disableSubmit, setDisableSubmit]=useState(false);
const [showAlert, setShowAlert]=useState(false);
const [alertFinishingSubProject, setAlertFinishingSubProject]=useState(false);
const [showAlertFinished, setShowAlertFinished]=useState(false);
const [updateSubProject, setUpdateSubProject]=useState(false);
const [arrayBasicParameters, setArrayBasicParameters]=useState([]);
const [processStateSelected, setProcessStateSelected]= useState([]);
const [lockUnlockQuotation, setLockUnlockQuotation]=useState('1'); /*Default Locked*/
const [alertUpdateDisabled, setAlertUpdateDisabled]=useState(false);
 
//Deleting Novelty:
const [alertDeletingNovelty, setAlertDeletingNovelty]=useState(false);
//Deleting PSP Quotation:
const [alertDeletePSPQuotation, setAlertDeletePSPQuotation]=useState(false);
//Deleting Entry Sheet:
const [alertDeletePSPEntrySheet, setAlertDeletePSPEntrySheet]=useState(false);
//Deleting IDA:
const [alertDeleteIDA, setAlertDeleteIDA]=useState(false);
//Deleting EDA:
const [alertDeleteEDA, setAlertDeleteEDA]=useState(false);
//Deleting PSP:
const [showAlertDeletePSP, setShowAlertDeletePSP]=useState(false);
const [showAlertPSPDeleted, setShowAlertPSPDeleted]=useState(false);
//Blocking Download:
const [blockDownload, setBlockDownload]=useState(false);
const Navigation = useNavigation(); 
//Start UploadFiles:
const [showAlertUpload, setShowAlertUpload]=useState(false);

const checkPermissions = async () => {
    try {
      const result = await PermissionsAndroid.check(
        PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE
      );

      if (!result) {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
          {
            title:
              'Permiso Lectura de Almacenamiento',
            message: 'Esta aplicación requiere permisos de lectura de almacenamiento externo para lograr cargar y respaldar tus archivos en la nube',
            buttonNeutral: 'Preguntar mas tarde',
            buttonNegative: 'Cancelar',
            buttonPositive: 'Confirmar',
          }
        );
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          return true;
        } else {
          Alert.alert('Esta aplicación requiere permisos para acceder al almacenamiento externo para lograr cargar y respaldar tus archivos en la nube');
          return false;
        }
      } else {
        return true;
      }
    } catch (err) {
      console.log('No fue posible habilitar los permisos requeridos por la aplicación.');
      return false;
    }
  };

const updateNameDocumentation=(documentType, nameFile)=>{
        const Update_Upload_Docs = 
        (documentType==='IDA') ?
        {
          "Update_Upload_Docs": [
          {
            "body": {
              "_Project_ID": null,
              "_SubProject_ID": dataNavigation[2].subProjectChosen,
              "_IDA_File_Uploaded_Name": nameFile,
              "_EDA_File_Uploaded_Name": null
              }
            }
          ]
        }
      : (documentType==='EDA') ?
        {     
      "Update_Upload_Docs": [
            {
              "body": {
                "_Project_ID": null,
                "_SubProject_ID": dataNavigation[2].subProjectChosen,
                "_IDA_File_Uploaded_Name": null,
                "_EDA_File_Uploaded_Name": nameFile
                }
              }
            ]
          }
      : null;
      //Put:
      executeRequest('/uploadDocumentation', Update_Upload_Docs, 'post', 'put').then(
            getData=>{
              setUpdateRequest(!updateRequest)
            }
          )
  }

//Sending docs to S3 Bucket React JS:
const uploadFile=(fileBase64, nameFile, documentType)=>{
  //Put:
  executeRequest('/uploadFilesS3', {
    "Upload_File": [
      {
        "body": {
          "_file": fileBase64,
          "_filename": nameFile
        }
      }
    ]
  }, 'post', 'put').then(
    getData=>{
      setResponse(getData[0].Response);
      setShowAlertUpload(true);
      updateNameDocumentation(documentType, nameFile);
    }
)
}

async function selectFile(documentType) {
  let result;
  switch (Platform.OS) {
    case 'web':
      result= true;
      break;
    case 'android':
      const OSversion=Platform.constants['Release'];
      result= (OSversion>=13) ?  true : await checkPermissions();
      break;
    case 'ios':
      result= true;
      break;
    default:
      result= false;
  };
  try {
   
    if (result===true) {
      setBlockDownload(true);
      const result = await DocumentPicker.getDocumentAsync({
        copyToCacheDirectory: true,
        type: 'application/pdf',
        base64: true
      });
      if (Platform.OS==='android' || Platform.OS==='ios') {
        const fileBase64 = await FileSystem.readAsStringAsync(result.assets[0].uri, { encoding: FileSystem.EncodingType.Base64 });
        const nameFile = documentType + '-' + dataNavigation[2].subProjectChosen + '.pdf'
        uploadFile(fileBase64, nameFile, documentType);
      } else if (Platform.OS==='web') {
        const nameFile = documentType + '-' + dataNavigation[2].subProjectChosen + '.pdf';
        const file = result.assets[0].uri;
        uploadFile(file.substring(28), nameFile, documentType);
      } 
    } else {
        setBlockDownload(false);
        console.log("Se ha presentado un error inesperado al momento de cargar el archivo.");
      }
  } catch (err) {
    console.log("Se presentó un error inesperado al seleccionar el archivo de origen.", err)
    setBlockDownload(false);
    return false;
  }
}

  /*Taking a picture: */
  //Capture Image For Uppload:
  const makePicture = async (documentType) => {
    setBlockDownload(true);
    // Request camera permissions:
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
    // Response if request permission is denied:
    if (permissionResult.granted === false) {
      setResponse("Es neceario que autorices el uso de la camara para tomar registro del documento que pretendes subir a la nube");
      setShowAlertFinished(true);
      return;
    }
    // If permission is granted then launch Camera for making a picture:
    const result = await ImagePicker.launchCameraAsync({quality:0.7, base64:true});
    // If process is not canceled then save image uri on variable:
    if (!result.canceled) {
      const ImageUri=(Platform.OS==='android' || Platform.OS==='ios') ? result.assets[0].uri : result.assets[0].file;
      const nameFile = documentType + '-' + dataNavigation[2].subProjectChosen + '.jpeg'
      const fileBase64 = await FileSystem.readAsStringAsync(ImageUri, { encoding: FileSystem.EncodingType.Base64 });
      (Platform.OS==='android' || Platform.OS==='ios') ?
      uploadFile(fileBase64, nameFile, documentType)
      : null
    } else {
      setBlockDownload(false);
    }
  } 
  //End UploadFiles


/*Start Downloading: */
const download = async (documentType) => {
  //setTypeDocument(documentType);
  setBlockDownload(true);
  if (documentType==='IDA') {
  executeRequest('/s3Object', {
    "Download_File": [ 
      {
        "body": {
          "_object_Key": arraySubProject[0].idaID 
        }
      }
    ]
  }, 'get', 'post').then(
      getData=>{
        let fileName='Acta_Inicio_de_Obra_SubProyecto'.concat((arraySubProject[0].idaID).substring((arraySubProject[0].idaID).indexOf('.'),(arraySubProject[0].idaID).length));
        if (Platform.OS==='web') {
          const link = document.createElement("a");
          link.href = getData[0].Response;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }  else if (Platform.OS==='android' || Platform.OS==='ios'){
          FileSystem.downloadAsync(
            getData[0].Response,
            FileSystem.documentDirectory + fileName
            )
            .then(({ uri }) => {
                share(uri) 
            })
            .catch(error => {
              console.error('Se ha presentado un error inesperado al momento de realizar la ejecución de la descarga');
            });
        }
        setBlockDownload(false);
      } 
  )
  } else if (documentType==='EDA'){
        executeRequest('/s3Object', {
          "Download_File": [
            {
              "body": {
                "_object_Key": arraySubProject[0].edaID
              }
            }
          ]
        }, 'get', 'post').then(
            getData=>{
              let fileName='Acta_Entrega_Final_SubProyecto'.concat((arraySubProject[0].edaID).substring((arraySubProject[0].edaID).indexOf('.'),(arraySubProject[0].edaID).length));
              if (Platform.OS==='web') {
                const link = document.createElement("a");
                link.href = getData[0].Response;
                link.setAttribute('download', fileName);
                link.target= "_blank"; 
                link.click();
              }  else if (Platform.OS==='android' || Platform.OS==='ios'){
                FileSystem.downloadAsync(
                  getData[0].Response,
                  FileSystem.documentDirectory + fileName
                  )
                  .then(({ uri }) => {
                      share(uri)
                  })
                  .catch(error => {
                    console.error('Se ha presentado un error inesperado al momento de realizar la ejecución de la descarga');
                  });
              }
              setBlockDownload(false);
            }
        )
        }
    }

  const share = async (url) => {
      setBlockDownload(false);
      try {
        await Sharing.shareAsync(url);
      } catch (error) {
        alert(error.message);
      }
    }
/*End Downloading*/

const idaPhysical=()=>{
  if (arraySubProject.length>0) {
   if (arraySubProject[0].idaID!==null) {
    return (
      <>
{((dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") && (Platform.OS==='android' || Platform.OS==='ios')) ?

<View style={styles.ButtonLeftAdmin}>
    <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
      makePicture('IDA');
      }}>
    <Ionicons name="camera" size={30} color="#d3d7dc" />
      </TouchableOpacity>
    </View> :
    <View style={styles.ButtonLeftAdmin}>
    <TouchableOpacity style={styles.ButtonStylesUnabled}>
    <Ionicons name="camera" size={30} color="#d3d7dc" />
      </TouchableOpacity>
    </View> 
}
{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
    <View style={styles.ButtonCenterAdmin}>
    <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
      selectFile('IDA');
      }}>
   <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
      </TouchableOpacity>
    </View> :
     <View style={styles.ButtonCenterAdmin}>
     <TouchableOpacity style={styles.ButtonStylesUnabled}>
     <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
       </TouchableOpacity>
     </View>
}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
    <View style={styles.ButtonRightAdmin} >
    <TouchableOpacity style={(blockDownload===false) ? styles.ButtonStylesDelete : styles.ButtonStylesBlockDownload}  onPress={()=>{
      (blockDownload===false) ?
      download('IDA')
      : null
      }}>
    <AntDesign name="clouddownload" size={30} color="#bdffc7" />
      </TouchableOpacity>
    </View> :
    <View style={styles.ButtonRightAdmin} >
    <TouchableOpacity style={styles.ButtonStylesUnabled}>
    <AntDesign name="clouddownload" size={30} color="#bdffc7" />
      </TouchableOpacity>
    </View> 
          }
          </>
      );
  } else {
    return (<>
{((dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") && (Platform.OS==='android' || Platform.OS==='ios')) ?
<View style={styles.ButtonLeftAdmin}>
    <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
      makePicture('IDA');
      }}>
    <Ionicons name="camera" size={30} color="#d3d7dc" />
      </TouchableOpacity>
    </View> :
    <View style={styles.ButtonLeftAdmin}>
    <TouchableOpacity style={styles.ButtonStylesUnabled}>
    <Ionicons name="camera" size={30} color="#d3d7dc" />
      </TouchableOpacity>
    </View> 
}
{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
    <View style={styles.ButtonCenterAdmin}>
    <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
      selectFile('IDA');
      }}>
   <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
      </TouchableOpacity>
    </View> :
     <View style={styles.ButtonCenterAdmin}>
     <TouchableOpacity style={styles.ButtonStylesUnabled}>
     <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
       </TouchableOpacity>
     </View>
}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
    <View style={styles.ButtonRightAdmin} >
    <TouchableOpacity style={(blockDownload===false) ? styles.ButtonStylesDelete : styles.ButtonStylesBlockDownload}  onPress={()=>{
      (blockDownload===false) ?
      download('IDA')
      : null
      }}>
    <AntDesign name="clouddownload" size={30} color="#e9e9e9" />
      </TouchableOpacity>
    </View> :
    <View style={styles.ButtonRightAdmin} >
    <TouchableOpacity style={styles.ButtonStylesUnabled}>
    <AntDesign name="clouddownload" size={30} color="#e9e9e9" />
      </TouchableOpacity>
    </View> 
          }
    </>)
    }
  }
}


const idaDigitalized=()=>{
  if (arraySubProject.length>0) {
   if (arraySubProject[0].id_IDA_Digitalized!==null) {
    return (
      <>
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" ||
     dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b") &&
          <View style={styles.ButtonLeftAdmin}>
              <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
            Navigation.navigate('Initial_Delivery_Agreement');
            }}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Consultar</Text>
                </TouchableOpacity>
              </View> 
              }
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
              <View style={styles.ButtonCenterAdmin}>
              <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=> Navigation.navigate('Update_Initial_Delivery_Agreement')}>
              <MaterialIcons name="update" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Actualizar</Text>
                </TouchableOpacity>
              </View> :
              <View style={styles.ButtonCenterAdmin}>
              <TouchableOpacity style={styles.ButtonStylesUnabled}>
              <MaterialIcons name="update" size={15} color="#7e7d7d" />
                <Text style={styles.TextStylesDisabled}>Actualizar</Text>
                </TouchableOpacity>
              </View>
              } 
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ? 
              <View style={styles.ButtonRightAdmin} >
              <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=> setAlertDeleteIDA(true) }>
              <AntDesign name="delete" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Eliminar</Text>
                </TouchableOpacity>
              </View> : 
              <View style={styles.ButtonRightAdmin} >
              <TouchableOpacity style={styles.ButtonStylesUnabled}>
              <AntDesign name="delete" size={15} color="#7e7d7d" />
                <Text style={styles.TextStylesAdmin}>Eliminar</Text>
                </TouchableOpacity>
              </View>
              }
         </>
      )
    } else { return (       
      <>
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonCreateDocumentation}>
          <TouchableOpacity style={styles.ButtonDocumentsDeliveryInitial} onPress={()=>{
            Navigation.navigate('Create_Initial_Delivery_Agreement');
            }}>
          <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
            <Text style={styles.TextStylesAdmin}>Crear Acta</Text>
            </TouchableOpacity>
          </View> :     
           <View style={styles.ButtonCreateDocumentation}>
          <TouchableOpacity style={styles.ButtonDocumentsDeliveryInitial}>
          <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
            <Text style={styles.TextStylesAdmin}>Crear Acta</Text>
            </TouchableOpacity>
          </View>
          } 
          </>
          )
        }
  }  else { 

    return (
      <View style={styles.ButtonCreateDocumentation}>
              <TouchableOpacity style={styles.ButtonDocumentsDeliveryInitial}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
                <Text style={styles.TextStylesAdmin}>______</Text>
                </TouchableOpacity>
              </View>
       )
   } 
}

const edaPhysical=()=>{
  if (arraySubProject.length>0) {
     if (arraySubProject[0].edaID!==null) {
      return (
        <>
        {((dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") && (Platform.OS==='android' || Platform.OS==='ios')) ?
  <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
        makePicture('EDA');
        }}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> 
  }
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonCenterAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
        selectFile('EDA');
        }}>
     <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
        </TouchableOpacity>
      </View> :
       <View style={styles.ButtonCenterAdmin}>
       <TouchableOpacity style={styles.ButtonStylesUnabled}>
       <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
         </TouchableOpacity>
       </View>
  }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonRightAdmin} >
<TouchableOpacity style={(blockDownload===false) ? styles.ButtonStylesDelete : styles.ButtonStylesBlockDownload}  onPress={()=>{
      (blockDownload===false) ?
      download('EDA')
      : null
      }}>
      <AntDesign name="clouddownload" size={30} color="#bdffc7" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <AntDesign name="clouddownload" size={30} color="#bdffc7" />
        </TouchableOpacity>
      </View> 
            }
            </>
        );
    } else {
      return (<>
 {((dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") && (Platform.OS==='android' || Platform.OS==='ios')) ?
  <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
        makePicture('EDA');
        }}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> 
  }
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonCenterAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
        selectFile('EDA');
        }}>
     <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
        </TouchableOpacity>
      </View> :
       <View style={styles.ButtonCenterAdmin}>
       <TouchableOpacity style={styles.ButtonStylesUnabled}>
       <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
         </TouchableOpacity>
       </View>
  }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={(blockDownload===false) ? styles.ButtonStylesDelete : styles.ButtonStylesBlockDownload}  onPress={()=>{
      (blockDownload===false) ?
      download('EDA')
      : null
      }}>
      <AntDesign name="clouddownload" size={30} color="#e9e9e9" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <AntDesign name="clouddownload" size={30} color="#e9e9e9" />
        </TouchableOpacity>
      </View> 
            }
      </>)
    }
  }
}


const edaDigitalized=()=>{
  if (arraySubProject.length>0) {
     if (arraySubProject[0].id_EDA_Digitalized!==null) {
      return (
          <>
          {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
          <View style={styles.ButtonLeftAdmin}>
              <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
                Navigation.navigate('End_Delivery_Agreement');
            }}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Consultar</Text>
                </TouchableOpacity>
              </View> :
              <View style={styles.ButtonLeftAdmin}>
              <TouchableOpacity style={styles.ButtonStylesUnabled}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="#7e7d7d" />
                <Text style={styles.TextStylesDisabled}>Consultar</Text>
                </TouchableOpacity>
              </View>
    }

{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
              <View style={styles.ButtonCenterAdmin}>
              <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=> Navigation.navigate('Update_End_Delivery_Agreement')}>
              <MaterialIcons name="update" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Actualizar</Text>
                </TouchableOpacity>
              </View> :
               <View style={styles.ButtonCenterAdmin}>
              <TouchableOpacity style={styles.ButtonStylesUnabled}>
              <MaterialIcons name="update" size={15} color="#7e7d7d" />
                <Text style={styles.TextStylesDisabled}>Actualizar</Text>
                </TouchableOpacity>
              </View>
    }

              {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ? 
              <View style={styles.ButtonRightAdmin} >
              <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=> setAlertDeleteEDA(true)}>
              <AntDesign name="delete" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Eliminar</Text>
                </TouchableOpacity>
              </View> :
              <View style={styles.ButtonRightAdmin} >
              <TouchableOpacity style={styles.ButtonStylesUnabled}>
              <AntDesign name="delete" size={15} color="#7e7d7d" />
                <Text style={styles.TextStylesDisabled}>Eliminar</Text>
                </TouchableOpacity>
              </View>
              }

            </> 
        )
    } else { return (      
      <>
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" && arraySubProject[0].id_IDA_Digitalized!==null) ?
      <View style={styles.ButtonCreateDocumentation}>
          <TouchableOpacity style={styles.ButtonDocumentsDelivery} onPress={()=> {
            Navigation.navigate('Create_End_Delivery_Agreement')}}>
          <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
            <Text style={styles.TextStylesAdmin}>Crear Acta</Text>
            </TouchableOpacity>
          </View> :
           <View style={styles.ButtonCreateDocumentation}>
           <TouchableOpacity style={styles.ButtonDocumentsDelivery}>
           <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
             <Text style={styles.TextStylesAdmin}>Crear Acta</Text>
             </TouchableOpacity>
           </View>
        } 
          </>
          )
        }
      } else { return (
    
        <View style={styles.ButtonCreateDocumentation}>
                <TouchableOpacity style={styles.ButtonDocumentsDeliveryInitial}>
                <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
                  <Text style={styles.TextStylesAdmin}>______</Text>
                  </TouchableOpacity>
                </View>
         
           )
       }
}


const sendRequestSubProject=()=>{

if (dataNavigation.length>0) {
//Get:
executeRequest('/psp-data', {
  "Get_Projects": [
      {
          "body": {
              "_Type_Request": "SubProject_Information_Detail",
              "_Client_ID": dataNavigation[0].clientChosen,
              "_Project_ID": dataNavigation[1].projectChosen,
              "_SubProject_ID": dataNavigation[2].subProjectChosen,
              "_Group_Quotation": null,
              "_Group_Quotation_SubProject":null,
              "_User_ID":(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" && identificationUser.length>0) ? identificationUser[0]["id"] : null
          }
      }
  ]
}, 'get', 'post').then(
      getData=>{
        setArraySubProject(getData);
        setBlockDownload(false);
          }
        )
    } else { null }
  }

  const sendRequestNoveltiesSP=()=>{
    if (dataNavigation.length>0) {
    //Get:
    executeRequest('/psp-data', {
      "Get_Projects": [
          {
              "body": {
                  "_Type_Request": "List_Novelties_SubProject",
                  "_Client_ID": dataNavigation[0].clientChosen,
                  "_Project_ID": dataNavigation[1].projectChosen,
                  "_SubProject_ID": dataNavigation[2].subProjectChosen,
                  "_Group_Quotation":null,
                  "_Group_Quotation_SubProject":null,
                  "_User_ID":(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" && identificationUser.length>0) ? identificationUser[0]["id"] : null
              }
          }
      ]
    }, 'get', 'post').then(
          getData=>{
            setArrayNovelties(getData)
          }
        )

      } else { null }
    }


    const requestLockUnlockQuotation=()=>{
      
      if (dataNavigation.length>0) {
      //Get:
      executeRequest('/quotations', {
        "Unlock_PSP_Quotation": [
          {
            "body": {
              "_Project_ID": (dataNavigation[1] !== undefined) ?  dataNavigation[1].projectChosen : null,
              "_SubProject_ID": (dataNavigation[2] !== undefined) ?  dataNavigation[2].subProjectChosen : null
            }
          }
        ]
      }, 'get', 'post').then(
            getData=>{
              setLockUnlockQuotation(getData[0].Response)
            }
          )
        } else { null }
      }


  useEffect(()=>{
    sendRequestSubProject();
    sendRequestNoveltiesSP();
    sendRequestBasicParameters();
    requestLockUnlockQuotation();
  },[])

  useEffect(()=>{
    sendRequestSubProject();
    sendRequestNoveltiesSP();
    requestLockUnlockQuotation();
  },[updateRequest])

/*For refresh Quotation SubProject: */
useEffect(()=>{
  (arraySubProject[0] !== undefined) ?
  arraySubProject.map((e)=>(
  setDataNavigation([dataNavigation[0], dataNavigation[1], {subProjectChosen:e.id, groupQuotationSubProject:e.groupQuotation}])
  ))
  : null
},[arraySubProject])

const requestFinishingSubProject=()=>{
setDisableSubmit(true);
setAlertFinishingSubProject(false);
//Put:
executeRequest('/projects', {
  "Finish_PSP": [
    {
      "body": {
        "_Type_Request": "Finish_SubProject",
        "_Project_ID": null,
        "_SubProject_ID": dataNavigation[2].subProjectChosen,
        "_User_Update_ID": null
      }
    }
  ]
}, 'post', 'put').then(
      getData=>{
        setUpdateRequest(!updateRequest);
        setResponse(getData[0].Response);
        setShowAlertFinished(true);
        setDisableSubmit(false);
      }
    )
  }
 
 /*Start Request about basic parameters for updateSubProject: */

 const sendRequestBasicParameters=()=>{
//Get:
 executeRequest('/basic_parameters', {
  "Get_Basic_Parameters": [
    {
      "body": {
        "_Type_Request": "Get_Basic_Parameters"
      }
    }
  ]
}, 'get', 'post').then(
      getData=>{
        setArrayBasicParameters(getData)
      }
    )
  }

const deleteNovelty=()=>{
setDisableSubmit(true);
setAlertDeletingNovelty(false);
//Delete:
 executeRequest('/novelties', {
  "Delete_Novelty": [
    {
      "body": {
        "_Novelty_ID": idNovelty
      }
    }
  ]
}, 'post', 'delete').then(
      getData=>{
        setResponse(getData[0].Response);
        setShowAlertFinished(true);
        setUpdateRequest(!updateRequest);
        setDisableSubmit(false);
      }
    )
  }

const  deletePSPQuotation=()=>{
setDisableSubmit(true);
setAlertDeletePSPQuotation(false);
//Delete:
 executeRequest('/quotations', {
  "Delete_PSP_Quotation": [
    {
      "body": {
        "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
        "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null,
        "_Group_Quotation": (dataNavigation[2] !== undefined) ? dataNavigation[2].groupQuotationSubProject : null
      }
    }
  ]
}, 'post', 'delete').then(
      getData=>{
        setResponse(getData[0].Response);
        setShowAlertFinished(true);
        setUpdateRequest(!updateRequest);
        setDisableSubmit(false);
      }
    )
    //Clean LocalData Create & Update Exit Sheet:
    deleteLocalData('C_ExitSheet_'.concat((dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null));
    deleteLocalData('timeStamp_C_ExitSheet_'.concat((dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null));
    deleteLocalData('U_ExitSheet_'.concat((dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null));
    deleteLocalData('timeStamp_U_ExitSheet_'.concat((dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null)); 
  }


  const  deletePSPEntrySheet=()=>{
    setDisableSubmit(true);
    setAlertDeletePSPEntrySheet(false);
    //Delete:
     executeRequest('/entry-sheet', {
      "Delete_PSP_Entry_Sheet": [
        {
          "body": {
            "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
            "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null,
            "_Group_Quotation": (dataNavigation[2] !== undefined) ? dataNavigation[2].groupQuotationSubProject : null
          }
        }
      ]
    }, 'post', 'delete').then(
          getData=>{
            setResponse(getData[0].Response);
            setShowAlertFinished(true);
            setUpdateRequest(!updateRequest);
            setDisableSubmit(false);
          }
        );
      //Clean LocalData Create & Update Exit Sheet:
      deleteLocalData('C_EntrySheet_'.concat((dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null));
      deleteLocalData('timeStamp_C_EntrySheet_'.concat((dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null));
      deleteLocalData('U_EntrySheet_'.concat((dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null)); 
      deleteLocalData('timeStamp_U_EntrySheet_'.concat((dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null)); 
}


const deleteIDA=()=>{
setDisableSubmit(true);
setAlertDeleteIDA(false);
//Delete:
 executeRequest('/ida', {
  "Delete_IDA": [
    {
      "body": {
        "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
        "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null
      }
    }
  ]
}, 'post', 'delete').then(
      getData=>{
        setResponse(getData[0].Response);
        setShowAlertFinished(true);
        setUpdateRequest(!updateRequest);
        setDisableSubmit(false);
      }
    )
  }

const deleteEDA=()=>{
    setDisableSubmit(true);
    setAlertDeleteEDA(false);
    //Delete:
    executeRequest('/eda', {
      "Delete_EDA": [
        {
          "body": {
            "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
            "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null
          }
        }
      ]
    }, 'post', 'delete').then(
          getData=>{
            setResponse(getData[0].Response);
            setShowAlertFinished(true);
            setUpdateRequest(!updateRequest);
            setDisableSubmit(false);
          }
        )
  }


const deletePSP=()=>{ 
    setDisableSubmit(true);
    setShowAlertDeletePSP(false);
    //Delete:
    executeRequest('/projects', {
      "Delete_PSP": [
        {
          "body": {
            "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
            "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null
          }
        }
      ]
    }, 'post', 'delete').then(
          getData=>{
            setResponse(getData[0].Response);
            setShowAlertPSPDeleted(true);
            setDisableSubmit(false);
          }
        )
  }

  const showStatesPsP=()=>{
    
    if (arrayBasicParameters.length>0) {
    
        return (
      <View style={styles.TableStyles}>
   {arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Process_State".toLowerCase()))).map((x, index)=>(
      <Pressable  key={index} onPress={()=>{(processStateSelected.find(e=>e.id===x.id)) ? setProcessStateSelected(processStateSelected.filter(e=>e.id!==x.id)) 
       : setProcessStateSelected([{id:x.id, name:x.name}])
      }}
    style={{backgroundColor:(processStateSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
              borderRadius:10,
              width:'100%',
              padding:10,
              marginTop:5,
              marginBottom:5
              }}>
        <View style={styles.Row}>
          <Text style={styles.Text}>{x.name}</Text>
        </View>
      </Pressable>
      ))
    }
      </View>
        );
      } else { null }
  
  }

 /*EndRequest about basic parameters for updateSubProject */

/*Update SubProject With New Data: */

const requestUpdatePSP=(SubProjectName)=>{
 setDisableSubmit(true);
 setShowAlert(false);
 //Put:
 executeRequest('/projects/changes', {
  "Update_PSP": [
    {
      "body": {
        "_Type_Request": "Update_SubProject",
        "_Name_Project": null,
        "_Project_Description": null,
        "_Address": null,
        "_Location":  null,
        "_City_ID":  null,
        "_Client_ID":  null,
        "_Init_Date":  null,
        "_End_Date":  null,
        "_Team_ID":  null,
        "_User_Update_ID":  null,
        "_Process_State":  (processStateSelected.length>0) ? processStateSelected[0].id : null,
        "_Project_ID": null,
        "_Name_SubProject": SubProjectName,
        "_SubProject_ID": dataNavigation[2].subProjectChosen
        
      }
    }
  ]
}, 'post', 'put').then(
      getData=>{
        setResponse(getData[0].Response);
        setUpdateRequest(!updateRequest);
        setShowAlertFinished(true);
        setDisableSubmit(false);
       }
    )
}





  const showNovelties=()=>{
    if (arrayNovelties!== null && arrayNovelties.length>0) {
      
      return (
        <View  style={styles.NoveltyContainer} >
    <View style={styles.MainContainer}>
    <View style={styles.TitleDateNovelty} >
    <Text style={styles.TextTitleNovelty}>Fecha</Text>
    </View>
    <View style={styles.TitleUserNovelty} >
    <Text style={styles.TextTitleNovelty}>Usuario</Text>
    </View>
    <View style={styles.TitleDescriptionNovelty} >
    <Text style={styles.TextTitleNovelty}>Novedad</Text>
    </View>
    </View>
      {arrayNovelties.map((e, index)=>(
    <View key={index}>
    <View style={styles.MainContainer} >
    <TouchableOpacity style={styles.MainContainer} onPress={()=>{ setIdNovelty(e.id); setShowOptionsNovelty(!showOptionsNovelty);}}> 
    <View style={styles.DateNovelty} >
          <Text style={styles.TextStylesAdmin}>{e.dateNovelty}</Text>
        </View>
        <View style={styles.UserNovelty} >
        <Text style={styles.TextStylesAdmin}>{e.fullName}</Text>
        </View>
        <View style={styles.DescriptionNovelty} >
        <Text style={styles.TextStylesAdmin}>{e.description}</Text>
        </View>
    </TouchableOpacity>
    </View>
    
    {(idNovelty===(e.id) && showOptionsNovelty===true) &&
    <View style={styles.MainContainer}>
      <TouchableOpacity style={styles.ViewUpdateNovelty} onPress={()=>{Navigation.navigate('Update_Novelty_Project')}}>
      <Text style={styles.TittleCreateNewSubProject}>Actualizar</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.ViewDeleteNovelty} onPress={()=>{setAlertDeletingNovelty(true)}}>
      <Text style={styles.TittleCreateNewSubProject}>Eliminar</Text>
      </TouchableOpacity>
    </View>
              }
          </View>
        ))}
        </View>
        )
      } else { null }
    }
    
    useEffect(()=>{
      showNovelties();
    },[idNovelty])

  const showBasicDataSubProject=()=>{
    if (arraySubProject !== null && arraySubProject.length>0) {
    return (
      <>
      {arraySubProject.map((e, index)=>(
      <ScrollView style={styles.BackgroundScrollView} key={index}>
      <View style={styles.RootContainer}>
          <View style={styles.ViewTittleScreenSubProjects}>
          <Text style={styles.TittleScreenSP}>Datos Básicos SubProyecto</Text>
          </View>
          <View style={styles.MainContainer}> 
          <Text style={styles.TextScreenLeft}>Nombre:</Text> 
          <Text style={styles.TextScreenRight}>{e.nameSubProject}</Text> 
          </View>
          <View style={styles.MainContainer}> 
          <Text style={styles.TextScreenLeft}>Fecha Creación:</Text> 
          <Text style={styles.TextScreenRight}>{e.dateCreated}</Text> 
          </View>
          <View style={styles.MainContainer}> 
          <Text style={styles.TextScreenLeft}>Estado:</Text> 
          <Text style={styles.TextScreenRight}>{e.processState}</Text> 
          </View>
          <View style={styles.MainContainer}> 
          <Text style={styles.TextScreenLeft}>Fecha Finalización:</Text> 
          <Text style={styles.TextScreenRight}>{e.dateFinishedSubProject}</Text> 
          </View>
          {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
          <>
          <View style={styles.MainContainer}> 
          <Text style={styles.TextScreenLeft}>$ Valor Inicial Hoja de Salida:</Text> 
          <Text style={styles.TextScreenRight}>$ {e.initialSubProjectQuotation}</Text> 
          </View>
          <View style={styles.MainContainer}> 
          <Text style={styles.TextScreenLeft}>$ Valor Final Hoja de Salida:</Text> 
          <Text style={styles.TextScreenRight}>$ {e.finalSubProjectQuotation}</Text> 
          </View>
          <View style={styles.MainContainer}> 
          <Text style={styles.TextScreenLeft}>$ Valor Total Hoja de Ingreso:</Text> 
          <Text style={styles.TextScreenRight}>$ {e.entrySheetValue}</Text> 
          </View>
          <View style={styles.MainContainer}> 
          <Text style={styles.TextScreenLeft}>$ V.T Costos Implementación:</Text> 
          <Text style={styles.TextScreenRight}>$ { e.cost_Implementation}</Text> 
          </View>
          <View style={styles.MainContainer}> 
          <Text style={styles.TextScreenLeft}>$ V.T SubProyecto:</Text> 
          <Text style={styles.TextScreenRight}>$ {e.finalValueSubProject}</Text> 
          </View>
          </>
            }
          <View style={styles.Line_Space}></View>
      
          <View style={styles.ViewTittleScreenSubProjects}>
          <Text style={styles.TittleScreenSP}>Documentación SubProyecto</Text>
          </View>
      
          <View style={styles.MainContainerDocumentation}>
          <View style={styles.MainContainerIDA}> 
          <Text style={styles.TextScreenDocumentation}>{(arraySubProject[0].idaID===null && dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Inicio de Obra - Nube" : (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Inicio de Obra - Nube" : null}</Text>   
          {idaPhysical()}
          <Text style={styles.TextScreenDocumentation}>{(arraySubProject[0].id_IDA_Digitalized===null && dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Inicio de Obra - Digital" : (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Inicio de Obra - Digital" : null}</Text>   
          {idaDigitalized()}
          </View>
          <View style={styles.MainContainerEDA}>
          <Text style={styles.TextScreenDocumentation}>{(arraySubProject[0].edaID===null && dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ? "Acta Entrega Final - Nube" : (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ? "Acta Entrega Final - Nube" : null}</Text>
          {edaPhysical()}
          <Text style={styles.TextScreenDocumentation}>{(arraySubProject[0].id_EDA_Digitalized===null && dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ? "Acta Entrega Final - Digital" : (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ? "Acta Entrega Final - Digital" : null}</Text>
          {edaDigitalized()}
          </View>
          <View style={styles.MainContainerPSPQuotation}>
          <Text style={styles.TextScreenDocumentation}>Hoja de Salida SubProyecto</Text>
          {(arraySubProject[0].fsqID!==null) ?
          <>
          {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" 
          || dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b") ?
          <View style={styles.ButtonLeftAdmin}>
              <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
              Navigation.navigate('Project_SubProject_Quotation');
            }}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Consultar</Text>
                </TouchableOpacity>
              </View> :
              <View style={styles.ButtonLeftAdmin}>
              <TouchableOpacity style={styles.ButtonStylesUnabled}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="#7e7d7d" />
                <Text style={styles.TextStylesDisabled}>Consultar</Text>
                </TouchableOpacity>
              </View> 
    }

              {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ? 
              <View style={styles.ButtonCenterAdmin}>
              {(lockUnlockQuotation==='0')  ?
              <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>Navigation.navigate('Update_Project_SubProject_Quotation')}>
              <MaterialIcons name="update" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Actualizar</Text>
                </TouchableOpacity>
                : 
                <TouchableOpacity style={styles.ButtonStylesUnabled} onPress={()=>{ setResponse("No puedes actualizar una hoja de salida si la misma ya tiene solicitudes asociadas."); setAlertUpdateDisabled(true);}}>
              <MaterialIcons name="update" size={15} color="#7e7d7d" />
                <Text style={styles.TextStylesDisabled}>Actualizar</Text>
                </TouchableOpacity>
                }
              </View> 
                : 
                <View style={styles.ButtonCenterAdmin}>
                <TouchableOpacity style={styles.ButtonStylesUnabled}>
                <MaterialIcons name="update" size={15} color="#7e7d7d" />
                  <Text style={styles.TextStylesDisabled}>Actualizar</Text>
                  </TouchableOpacity>
                  </View> 
                }
              {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ? 
              <View style={styles.ButtonRightAdmin} >
              <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=> {
               if (arraySubProject[0].entrySheetID!==null) {
                setResponse("Antes de eliminar una Hoja de Salida, debes asegurarte de eliminar la Hoja de Ingreso tambien."); setAlertUpdateDisabled(true);
              } else {
                setAlertDeletePSPQuotation(true)
                }
              }
              }>
              <AntDesign name="delete" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Eliminar</Text>
                </TouchableOpacity>
              </View>
              :
              <View style={styles.ButtonRightAdmin} >
              <TouchableOpacity style={styles.ButtonStylesUnabled}>
              <AntDesign name="delete" size={15} color="white" />
                <Text style={styles.TextStylesDisabled}>Eliminar</Text>
                </TouchableOpacity>
              </View>
            }
            </> 
                : 
                <>
                {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
                <View style={styles.ButtonCreateDocumentation}>
                    <TouchableOpacity style={styles.ButtonStylesCreateDocumentation} onPress={()=>{Navigation.navigate('Create_New_Project_SubProject_Quotation')}}>
                    <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                      <Text style={styles.TextStylesAdmin}>Crear</Text>
                      </TouchableOpacity>
                    </View> :
                      <View style={styles.ButtonCreateDocumentation}>
                      <TouchableOpacity style={styles.ButtonStylesCreateDocumentation}>
                      <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                        <Text style={styles.TextStylesAdmin}>Inaccesible</Text>
                        </TouchableOpacity>
                      </View>
                 
                }
                </>  
              }

      
<Text style={styles.TextScreenDocumentation}>Hoja de Ingreso SubProyecto</Text>
          {(arraySubProject[0].entrySheetID!==null) ?
          <>
          {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" 
          || dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b") ?
          <View style={styles.ButtonLeftAdmin}>
              <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
              Navigation.navigate('Request_Entry_Sheet_PSP');
            }}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Consultar</Text>
                </TouchableOpacity>
              </View> :
              <View style={styles.ButtonLeftAdmin}>
              <TouchableOpacity style={styles.ButtonStylesUnabled}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="#7e7d7d" />
                <Text style={styles.TextStylesDisabled}>Consultar</Text>
                </TouchableOpacity>
              </View> 
    }

              {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ? 
              <View style={styles.ButtonCenterAdmin}>
              <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>Navigation.navigate('Update_Entry_Sheet_PSP')}>
              <MaterialIcons name="update" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Actualizar</Text>
                </TouchableOpacity>  
              </View> 
                : 
                <View style={styles.ButtonCenterAdmin}>
                <TouchableOpacity style={styles.ButtonStylesUnabled}>
                <MaterialIcons name="update" size={15} color="#7e7d7d" />
                  <Text style={styles.TextStylesDisabled}>Actualizar</Text>
                  </TouchableOpacity>
                  </View> 
                }
              {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ? 
              <View style={styles.ButtonRightAdmin} >
              <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=> setAlertDeletePSPEntrySheet(true)}>
              <AntDesign name="delete" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Eliminar</Text>
                </TouchableOpacity>
              </View>
              :
              <View style={styles.ButtonRightAdmin} >
              <TouchableOpacity style={styles.ButtonStylesUnabled}>
              <AntDesign name="delete" size={15} color="white" />
                <Text style={styles.TextStylesDisabled}>Eliminar</Text>
                </TouchableOpacity>
              </View>
            }
            </> 
                : 
                <>
                {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
                (arraySubProject[0].fsqID!==null) ? 
                <View style={styles.ButtonCreateDocumentation}>
                    <TouchableOpacity style={styles.ButtonStylesCreateDocumentation} onPress={()=>{Navigation.navigate('Create_Entry_Sheet_PSP')}}>
                    <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                      <Text style={styles.TextStylesAdmin}>Crear</Text>
                      </TouchableOpacity>
                    </View> 
                 : 
                 <View style={styles.ButtonCreateDocumentation}>
                    <TouchableOpacity style={styles.ButtonStylesCreateDocumentation} onPress={()=>{
                      setResponse("Solo podrás crear una Hoja de Ingresos cuando hayas diligenciado una Hoja de Salida."); setAlertUpdateDisabled(true)
                    }}>
                    <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                      <Text style={styles.TextStylesAdmin}>Crear</Text>
                      </TouchableOpacity>
                    </View> 
                    :
                    <View style={styles.ButtonCreateDocumentation}>
                    <TouchableOpacity style={styles.ButtonStylesCreateDocumentation}>
                    <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                      <Text style={styles.TextStylesAdmin}>Inaccesible</Text>
                      </TouchableOpacity>
                    </View> 
                }
                </>  
              }
              </View>
            </View>

    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Novedades SubProyecto</Text>
    </View>

    

    {showNovelties()}

    <TouchableOpacity style={styles.ViewCreateNovelty} onPress={()=>{Navigation.navigate('Create_Novelty_Project')}}>
    <Text style={styles.TittleCreateNewSubProject}>Crear Novedad</Text>
    </TouchableOpacity>


    <View style={styles.Line_Space}></View>

    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
          <>
          <View style={styles.ViewTittleScreenSubProjects}>
          <Text style={styles.TittleScreenSP}>Administración Subproyecto</Text>
          </View>
          <View style={styles.MainContainer}> 
              <View style={styles.ButtonAdminProject}>
              { (disableSubmit===true) ? 
              <TouchableOpacity style={styles.ButtonStylesFinished}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Procesando</Text>
                </TouchableOpacity>
                : 
                <TouchableOpacity style={styles.ButtonStylesFinished} onPress={()=> setAlertFinishingSubProject(true)}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Finalizar</Text>
                </TouchableOpacity>
                }
              </View> 
              <View style={styles.ButtonAdminProject}>
              <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{setUpdateSubProject(!updateSubProject)}}>
              <MaterialIcons name="update" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Actualizar</Text>
                </TouchableOpacity>
              </View> 
              {(updateSubProject===true) ? 
          <>
            <Formik
              initialValues={{
                SubProjectName: ''
     
              }}
              onSubmit={values => {
                const SubProjectName=JSON.stringify(values["SubProjectName"]);
                requestUpdatePSP(JSON.parse(SubProjectName));
              }}
              validationSchema={yup.object().shape({
                SubProjectName: yup
                  .string()  
                  .min(4, 'El nombre del subproyecto debe tener como minimo 4 caracteres')
                  .max(50, 'El nombre del subproyecto no puede exceder 50 caracteres')
              })}
              >
            {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
                <View style={styles.formContainer}>
                  <TextInput
                    value={values.SubProjectName}
                    onChangeText={handleChange('SubProjectName')}
                    onBlur={() => setFieldTouched('SubProjectName')}
                    placeholder="Nuevo nombre del SubProyecto"
                    style={styles.inputStyle}
                  />
                  {touched.SubProjectName && errors.SubProjectName &&
                    <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.SubProjectName}</Text>
                  }
                  <View  style={styles.DropDownSelection}>
      
<Text style={styles.titleState}>Estado SubProyecto:</Text> 
<View style={styles.states}>
    {showStatesPsP()}
    </View>

                  </View>
         

{ (disableSubmit===true) ?  
        <TouchableOpacity style={styles.ButtonCreateProject}>
        <MaterialCommunityIcons name="rocket-launch" size={20} color="black" />
          <Text style={styles.TextStylesFinished}>Procesando</Text>
          </TouchableOpacity>
      :
            
      <TouchableOpacity style={styles.ButtonCreateProject} onPress={()=>{  setShowAlert(true) }}>
      <MaterialCommunityIcons name="rocket-launch" size={20} color="black" />
        <Text style={styles.TextStylesFinished}>Actualizar</Text>
        </TouchableOpacity>
            
 }

     <View style={styles.ViewSpace}></View>

<AwesomeAlert style={styles.AlertContainer}
show={showAlert}
showProgress={false}
title="Confirmar Cambios"
message="Los cambios se realizaran teniendo presente la nueva información proporcionada. Si estas seguro, por favor presiona el botón [Continuar]."
closeOnTouchOutside={false}
closeOnHardwareBackPress={false}
showCancelButton={true}
showConfirmButton={true}
cancelText="No, cancelar"
confirmText="Si, confirmar"
cancelButtonColor='#6c8ead'
confirmButtonColor="#DD6B55"
onCancelPressed={() => {
  setShowAlert(false)
}}
onConfirmPressed={() => {
  handleSubmit();
}}
/>

  </View>

)}
            </Formik>
          </>
          : null}
      
            
              <View style={styles.ButtonAdminProject} >
              <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=> setShowAlertDeletePSP(true)}>
              <AntDesign name="delete" size={15} color="white" />
                <Text style={styles.TextStylesAdmin}>Eliminar</Text>
                </TouchableOpacity>
              </View>
          </View>
          </>
      }
      
      </View>
     
 <AwesomeAlert
                show={alertFinishingSubProject}
                showProgress={false}
                title="Finalizar SubProyecto"
                message="¿Estas seguro de que deseas finalizar el SubProyecto?. Si finalizas el SubProyecto no será posible que tu equipo de trabajo realice mas solicitudes de insumos y herramientas. Presiona el boton [Continuar] para terminar el proceso."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, continuar"
                cancelButtonColor='#6c8ead'
                confirmButtonColor="#6cad7e"
                onCancelPressed={() => {
                  setAlertFinishingSubProject(false)
                }}
                onConfirmPressed={() => {
                  requestFinishingSubProject();
                }}
              />

<AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
          }}
        />
<AwesomeAlert
          show={showAlertPSPDeleted}
          showProgress={false}
          title="Proceso Terminado" 
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertPSPDeleted(false);
            Navigation.navigate('Projects_Created');
          }}
        />


<AwesomeAlert
          show={alertUpdateDisabled}
          showProgress={false}
          title="Advertencia"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#ffb2b2'}}
          messageStyle={{color:'#e2e2e2'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setAlertUpdateDisabled(false);
          }}
        />

<AwesomeAlert
          show={alertDeletingNovelty}
          showProgress={false}
          title="Confirmar cambios"
          message="Se eliminará la novedad seleccionada, presiona [Continuar] para confirmar los cambios."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setAlertDeletingNovelty(false)
          }}
          onConfirmPressed={() => {
            deleteNovelty();
            setAlertDeletingNovelty(false);
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />

<AwesomeAlert style={styles.AlertContainer}
                show={showAlertDeletePSP}
                showProgress={false}
                title="Eliminar SubProyecto"
                message="¿Estas seguro de que deseas eliminar el SubProyecto?. Antes de realizar este proceso asegurate de haber eliminado toda la documentación asociada, si continuas las solicitudes pendientes por entrega que se hayan creado y las novedades asociadas al SubProyecto tambien serán eliminadas."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                cancelButtonColor='#6c8ead'
                confirmButtonColor="#DD6B55"
      
                onCancelPressed={() => {
                  setShowAlertDeletePSP(false)
                }}
                onConfirmPressed={() => {
                  deletePSP()
                }}
              />

<AwesomeAlert style={styles.AlertContainer}
                show={alertDeletePSPQuotation}
                showProgress={false}
                title="Eliminar Hoja de Salida SubProyecto"
                message="¿Estas seguro de que deseas eliminar la Hoja de Salida asociada al SubProyecto?, si lo haces tambien se eliminarán todas las solicitudes que aun no se hayan entregado por parte del área de bodega."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                cancelButtonColor='#6c8ead'
                confirmButtonColor="#DD6B55"
      
                onCancelPressed={() => {
                  setAlertDeletePSPQuotation(false)
                }}
                onConfirmPressed={() => {
                  deletePSPQuotation()
                }}
              />


<AwesomeAlert style={styles.AlertContainer}
                show={alertDeletePSPEntrySheet}
                showProgress={false}
                title="Eliminar Hoja de Ingreso SubProyecto"
                message="¿Estas seguro de que deseas eliminar la Hoja de Ingreso asociada al SubProyecto?."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                cancelButtonColor='#6c8ead'
                confirmButtonColor="#DD6B55"
      
                onCancelPressed={() => {
                  setAlertDeletePSPEntrySheet(false)
                }}
                onConfirmPressed={() => {
                  deletePSPEntrySheet()
                }}
              />


<AwesomeAlert style={styles.AlertContainer}
                show={alertDeleteIDA}
                showProgress={false}
                title="Eliminar Acta de Inicio de Obra"
                message="¿Estas seguro de que deseas eliminar el Acta de Inicio de Obra asociada al SubProyecto?, presiona [Continuar] para confirmar los cambios."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                cancelButtonColor='#6c8ead'
                confirmButtonColor="#DD6B55"
      
                onCancelPressed={() => {
                  setAlertDeleteIDA(false)
                }}
                onConfirmPressed={() => {
                  deleteIDA()
                }}
              />

<AwesomeAlert style={styles.AlertContainer}
                show={alertDeleteEDA}
                showProgress={false}
                title="Eliminar Acta de Entrega Final"
                message="¿Estas seguro de que deseas eliminar el Acta de Entrega Final asociada al SubProyecto?, presiona [Continuar] para confirmar los cambios."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                cancelButtonColor='#6c8ead'
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertDeleteEDA(false)
                }}
                onConfirmPressed={() => {
                  deleteEDA()
                }}
              />

<AwesomeAlert
          show={showAlertUpload}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertUpload(false)
          }}
        />

      </ScrollView>
      
               ))
            }
          </>
        )
    } else { 

        return (
          <ScrollView style={styles.BackgroundScrollView}>
          <View style={styles.RootContainer}>
              <View style={styles.ViewTittleScreenSubProjects}>
              <Text style={styles.TittleScreenSP}>Datos Básicos SubProyecto</Text>
              </View>
              <View style={styles.MainContainer}> 
              <Text style={styles.TextScreenLeft}>Nombre:</Text> 
              <Text style={styles.TextScreenRight}>______________________</Text> 
              </View>
              <View style={styles.MainContainer}> 
              <Text style={styles.TextScreenLeft}>Fecha Creación:</Text> 
              <Text style={styles.TextScreenRight}>______________________</Text> 
              </View>
              <View style={styles.MainContainer}> 
              <Text style={styles.TextScreenLeft}>Estado:</Text> 
              <Text style={styles.TextScreenRight}>______________________</Text> 
              </View>
              <View style={styles.MainContainer}> 
              <Text style={styles.TextScreenLeft}>Fecha Finalización:</Text> 
              <Text style={styles.TextScreenRight}>______________________</Text> 
              </View>
            
              <View style={styles.Line_Space}></View>
          
              <View style={styles.ViewTittleScreenSubProjects}>
              <Text style={styles.TittleScreenSP}>Documentación SubProyecto</Text>
              </View>
          
             
          </View>
          </ScrollView>
               )
          }

      }

      return (
        <>
        {showBasicDataSubProject()}
        </>
      )

  }
   

const styles = StyleSheet.create({
  TextScreenLeft: {
    color: '#DDE7F0',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'left',
    width:'40%',
    paddingTop:5
  },
  TextScreenRight: {
    color: '#f1f1f1',
    fontSize: 14,
    textAlign:'left',
    width:'54%',
    paddingTop:5
  },
  TextScreenLeftSubProjects:{
    color: '#3c588b',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'left',
    width:'40%',
    paddingTop:5
  },
  TextScreenRightSubProjects:{
    color: '#565656',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'left',
    width:'60%',
    paddingTop:5
  },
  TextScreenDocumentation: {
    color: '#DDE7F0',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'center',
    width:'100%',
    marginTop:10
  },
  TextScreenDocumentationSP:{
    color: '#f1f1f1',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'center',
    width:'100%',
    marginTop:10,
    padding:5,
    backgroundColor:'#1f1f1f',
    borderRadius:5
  },
  MainContainerDocumentation:{
    width:'95%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#1d1c1c',
    padding:5,
    borderRadius:10,
    marginTop:5,
    padding:10
  },
  MainContainerIDA:{
    textAlign:'center',
    alignContent:'center',
    justifyContent: 'center',
    alignItems:'center',
    width:'100%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#111112',
    marginTop:5,
    paddingBottom:15,
    borderRadius:10
  },
  MainContainerEDA:{
    textAlign:'center',
    alignContent:'center',
    justifyContent: 'center',
    alignItems:'center',
    width:'100%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#151515',
    marginTop:5,
    paddingBottom:15,
    borderRadius:10
  },
  MainContainerPSPQuotation:{
    textAlign:'center',
    alignContent:'center',
    justifyContent: 'center',
    alignItems:'center',
    width:'100%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#151515',
    marginTop:5,
    paddingBottom:15,
    borderRadius:10
  },
  MainContainerDocumentationSP:{
    width:'95%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#1d1c1c',
    padding:5,
    borderRadius:10,
    marginTop:5,
    padding:10
  },
  IconScreenRight: {
    textAlign:'left',
    width:'30%',
    paddingTop:10
  },
ButtonLeftAdmin: {
    fontWeight: 'bold',
    textAlign:'left',
    width:'30%',
    paddingTop:15,
    marginLeft:5
  },
  ButtonCenterAdmin: {
    textAlign:'center',
    width:'30%',
    paddingTop:15,
    marginLeft:10,
  },
  ButtonRightAdmin: {
    textAlign:'left',
    width:'30%',
    paddingTop:15,
    marginLeft:10,
  },
  TitleDateNovelty: {
    textAlign:'center',
    alignItems:'center',
    width:'25%',
    paddingTop:15,
    paddingBottom:5,
    borderBottomColor: '#A6B9C4', 
    borderBottomWidth: .5,
  },
  TitleUserNovelty: {
    textAlign:'center',
    alignItems:'center',
    width:'25%',
    paddingTop:15,
    paddingBottom:5,
    borderBottomColor: '#A6B9C4', 
    borderBottomWidth: .5,
  },
  TitleDescriptionNovelty: {
    textAlign:'center',
    alignItems:'center',
    width:'50%',
    paddingTop:15,
    paddingBottom:5,
    borderBottomColor: '#A6B9C4', 
    borderBottomWidth: .5,
  },
  DateNovelty: { 
    textAlign:'center',
    alignItems:'center',
    width:'25%'
  },
  UserNovelty: {
    textAlign:'center',
    alignItems:'center',
    width:'25%',
    paddingLeft:5,
    paddingRight:5,
  },
  DescriptionNovelty: {
    textAlign:'justify',
    alignItems:'center',
    width:'50%',
    paddingLeft:5,
    paddingRight:5,
  },
  TextTitleNovelty: {
    fontSize: 14,
    color: '#AFEEEE',
  },
  ButtonAdminProject: {
    fontWeight: 'bold',
    textAlign:'center',
    width:'100%',
    paddingLeft:20,
    paddingRight:20,
    paddingTop:10
  },
  
  ButtonStylesFinished: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#6b8161',
  },

  ButtonStylesUpdate: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#3d3d3d',
  },

  ButtonStylesUnabled: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#e8e8e8',
  },

  ButtonStylesDelete: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#3d3d3d',
  },

  ButtonStylesBlockDownload: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#8a8a8a',
  },

  ButtonStylesRequest:{
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#3d3d3d',
  },
  TextStylesAdmin: {
    fontSize: 12,
    color: '#f1f1f1',
    textAlign:'justify'
  },
  RootContainer: {
    flex:1,
    width:'100%',
    alignItems:'center',
    textAlign:'left',
    justifyContent:'center',
    backgroundColor:'#151515',
  },
  MainContainer: {
     width:'90%',
     flexWrap:'wrap',
     flexDirection: 'row',
     marginBottom:10
  },
  NoveltyContainer: {
    width:'100%',
    textAlign:'center',
    alignItems:'center',
    alignContent:'center'
  },
  MainContainerSubProjects: {
    flex:1,
    width:'95%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#c6ced3',
    padding:20,
    marginTop:10,
    borderRadius:10
  },
  BackgroundScrollView: {
    backgroundColor:'#151515'
  },
  TittleScreen:{
    color:'#9ECDFF',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewCreateNovelty:{
    width:'45%',
    backgroundColor:'#2d2d2d',
    borderRadius:10,
    margin:15,
  },
  ViewUpdateNovelty:{
    width:'45%',
    backgroundColor:'#80909d',
    marginTop:5,
    marginLeft:5,
    marginRight:5,
    borderRadius:10,
    marginBottom:10,
  },
  ViewDeleteNovelty:{
    width:'45%',
    backgroundColor:'#c07979',
    marginTop:5,
    marginLeft:5,
    marginRight:5,
    borderRadius:10,
    marginBottom:10,
  },
  TittleCreateNewSubProject:{
    color:'#cee7ff',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  TittleScreenSP:{
    color:'#9ECDFF',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  }
  ,
  ViewTittleScreen:{
    width:'95%',
    backgroundColor:'#0E0E0E',
    marginTop:10,
    borderRadius:10
  },
  ViewTittleScreenSubProjects:{
    width:'95%',
    backgroundColor:'#0E0E0E',
    marginTop:10,
    borderRadius:10,
    marginBottom:10
  },
  Line_Space:{
    width:'100%',
    borderBottomColor: '#f9f9f9', 
    borderBottomWidth: .5,
    marginTop:10
  },
  DropDownSelection: {
    width:'100%',
    alignItems:'center',
    textAlign:'center',
    justifyContent:'center',
    alignContent:'center'
  },
  selectList:{
    width:'100%',
    
  },
  formContainer: {
    backgroundColor:'#2c2c2c',
    width:'100%',
    borderRadius:15,
    marginBottom:10,
    marginTop:10
  },
  inputStyle: {
    height:40,
    borderRadius:10,
    padding:10,
    marginTop:10,
    marginLeft:20,
    marginRight:20,
    backgroundColor:'#dbd9d9'
  },
  ButtonCreateProject: {
    backgroundColor:'#809280',
    margin:10,
    padding:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
    
  },
  ButtonCreateDocumentation:{
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
    fontWeight: 'bold',
    textAlign:'center',
    width:'100%',
    paddingTop:10
  },
  ButtonDocumentsDeliveryInitial:{
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor:'#3d3d3d',
    width:'33%',
    paddingTop:5,
    paddingBottom:5,
  },
  ButtonDocumentsDelivery:{
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor:'#3d3d3d',
    width:'33%',
    paddingTop:5,
    paddingBottom:5,
  },
  ButtonStylesCreateDocumentation:{
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor:'#3d3d3d',
    width:'33%',
    paddingTop:5,
    paddingBottom:5,
  },
  TableStyles: {
    backgroundColor:'#181818',
    borderRadius:10,
    width:'100%'
  },
  Row:{
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center'
  },
  
  Text:{
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    width:180
  },
  titleState:{
    textAlign:'center',
    fontSize:16,
    color:'#dceeff',
    fontWeight:'bold',
    marginTop:10
  },
  states:{
    marginTop:10,
    width:'85%'
  },

  TextStylesDisabled: {
    fontSize: 14,
    color: '#7e7d7d',
  },
  
});

export default SubProject
