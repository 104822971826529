import React, {useContext, useEffect, useState} from 'react'
import { StyleSheet,View, Text, Dimensions, ScrollView, StatusBar, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import Context from '../context/Context';
import { FontAwesome } from '@expo/vector-icons';
import { Fontisto } from '@expo/vector-icons';
//For making requests:
import { executeRequest } from "../views/functional_global_components/API_Requests";
import {LineChart,PieChart} from 'react-native-chart-kit';

const Dashboard = () => {
  const [arrayPSPCreatedByMonth, setArrayPSPCreatedByMonth]=useState([]);
  const [arrayEarnsByMonth, setArrayEarnsByMonth]=useState([]);
  const [arrayPSPQuantityByProcess, setArrayPSPQuantityByProcess]=useState([]);
  /*Arrays PSP Created By Month:*/
  const [datesPSPCreatedByMonth, setDatesPSPCreatedByMonth]=useState([]);
  const [quantityPSPCreatedByMonth, setQuantityPSPCreatedByMonth]=useState([]);
  /*Arrays Earnings By Month:*/
  const [datesEarnsByMonth, setDatesEarnsByMonth]=useState([]);
  const [quantityEarnsByMonth, setQuantityEarnsByMonth]=useState([]);

  const [dataStatePSP, setDataStatePSP]=useState([]);

   
    

  const {setDisconnectSession, dataSession, updateRequest}= useContext(Context);


  // const screenWidth = (Platform.OS==='web') ? Dimensions.get('window').width/3 : (Dimensions.get('window').width/1.05);
  const screenWidth = (Platform.OS==='web' && (Dimensions.get('window').width>1024)) ? Dimensions.get('window').width/2.5 : (Dimensions.get('window').width/1.05);
   /*Data for Showing: */
   const dataPSPxMonth = {
    labels:  (datesPSPCreatedByMonth.length>0 && quantityPSPCreatedByMonth.length>0) ? datesPSPCreatedByMonth : [0],
    datasets: [{
      data: (datesPSPCreatedByMonth.length>0 && quantityPSPCreatedByMonth.length>0) ? quantityPSPCreatedByMonth : [0],
      color: (opacity = 0.7) => `rgba(200, 255, 255, ${opacity})`, // optional
      strokeWidth: 2 
    }]
  };

  const dataEarnsxMonth = {
    labels:  (datesEarnsByMonth.length>0 && quantityEarnsByMonth.length>0) ? datesEarnsByMonth : [0],
    datasets: [{
      data: (datesEarnsByMonth.length>0 && quantityEarnsByMonth.length>0) ? quantityEarnsByMonth : [0],
      color: (opacity = 0.7) => `rgba(200, 255, 255, ${opacity})`, // optional
      strokeWidth: 2
    }]
  };
  
  const chartConfig = {
    backgroundGradientFrom: '#111111',
    backgroundGradientTo: '#111111',
    color: (opacity = 0.7) => `rgba(0, 180, 255, ${opacity})`,
    strokeWidth: 2, 
    decimalPlaces: 0
  };

  const chartConfigMoney = {
    backgroundGradientFrom: '#111111',
    backgroundGradientTo: '#111111',
    color: (opacity = 0.7) => `rgba(0, 180, 255, ${opacity})`,
    strokeWidth: 2, 
    decimalPlaces: 1
  };

const showDataPSPxMonth = ()=>{
  return (
    <LineChart
    verticalLabelRotation={-90} 
    xLabelsOffset={8}
    data={dataPSPxMonth}
    width={screenWidth}
    height={220}
    chartConfig={chartConfig}
  />
    );
}

const showDataEarnsxMonth = ()=>{
  return (
  <LineChart
      data={dataEarnsxMonth}
      width={screenWidth}
      verticalLabelRotation={-90} 
      xLabelsOffset={8}
      height={220}
      chartConfig={chartConfigMoney}
      bezier
      yAxisLabel="$ "
      yAxisSuffix="m"
      yLabelsOffset={7}
    /> 
    );
}


const showDataPSPStates = ()=>{
  if (dataStatePSP.length>0) {
  return (
    <PieChart
    data={dataStatePSP}
    width={screenWidth}
    height={200}
    chartConfig={chartConfig}
    accessor="population"
    backgroundColor="transparent"
    absolute
  />
    );
  }
}

  useEffect(()=>{
    getPSPCreatedByMonth();
    getEarnsByMonth();
    getPSPQuantityProcessState();
  },[])

  useEffect(()=>{

    if (arrayPSPCreatedByMonth.length>0 && datesPSPCreatedByMonth.length === 0 && quantityPSPCreatedByMonth.length === 0) {
      for (let i=0; i < arrayPSPCreatedByMonth.length; i++) {
          setDatesPSPCreatedByMonth(e=>[...e, arrayPSPCreatedByMonth[i].Current_Month]);
          setQuantityPSPCreatedByMonth(e=>[...e, Number(arrayPSPCreatedByMonth[i].Total_PSP_x_Month)]);
        }
        
     };
  },[arrayPSPCreatedByMonth]) 

  useEffect(()=>{
    if (arrayEarnsByMonth.length>0 && datesEarnsByMonth.length === 0 && quantityEarnsByMonth.length === 0) {
      for (let i=0; i < arrayEarnsByMonth.length; i++) {
          setDatesEarnsByMonth(e=>[...e, arrayEarnsByMonth[i].Current_Month]);
          setQuantityEarnsByMonth(e=>[...e, Number(arrayEarnsByMonth[i].Earns)]);
        }
        
     };
  },[arrayEarnsByMonth]) 


  useEffect(()=>{
    if (arrayPSPQuantityByProcess.length>0) {
      setDataStatePSP([
        { name: 'Proyectos Finalizados', population: Number(arrayPSPQuantityByProcess[0].Projects_Finished), color: '#6EB76D', legendFontColor: '#7F7F7F', legendFontSize: 9 },
        { name: 'Proyectos En Proceso', population: Number(arrayPSPQuantityByProcess[0].Projects_In_Process), color: '#6D94B7', legendFontColor: '#7F7F7F', legendFontSize: 9 },
        { name: 'SubProyectos Finalizados', population: Number(arrayPSPQuantityByProcess[0].SubProjects_Finished), color: '#a1ff8e', legendFontColor: '#7F7F7F', legendFontSize: 9 },
        { name: 'SubProyectos En Proceso', population: Number(arrayPSPQuantityByProcess[0].SubProjects_In_Process), color: '#33C1FF', legendFontColor: '#7F7F7F', legendFontSize: 9 }
      ]);
        }
      
  },[arrayPSPQuantityByProcess]) 
  

  useEffect(()=>{
    getPSPCreatedByMonth();
    getEarnsByMonth();
    getPSPQuantityProcessState();
  },[updateRequest])


  const getPSPCreatedByMonth=()=>{ 
      executeRequest('/dashBoard', {
        "Get_Reports": [
          {
            "body": {
              "_Type_Request": "Get_PSP_Created_By_Month"
            }
          }
        ]
      }, 'rpt', 'post').then(
          getData=>{
            setArrayPSPCreatedByMonth(getData)
          }
        )
    }


  const getEarnsByMonth=()=>{
      executeRequest('/dashBoard', {
      "Get_Reports": [
        {
          "body": {
            "_Type_Request": "Get_Final_Earns_By_Month"
          }
        }
      ]
    }, 'rpt', 'post').then(
          getData=>{
            setArrayEarnsByMonth(getData)
          }
        )
    }


  const getPSPQuantityProcessState=()=>{

      executeRequest('/dashBoard', {
        "Get_Reports": [
          {
            "body": {
              "_Type_Request": "Get_PSP_Quantity_Process_State"
            }
          }
        ]
      }, 'rpt', 'post').then(
            getData=>{
              setArrayPSPQuantityByProcess(getData)
            }
          )
    }


  if (dataSession["user"] != undefined && dataSession["IdentificationCode"] != undefined) {
  if (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") {
  return (
    <ScrollView style={styles.Root}>
    <View style={styles.RootContainer}>
    <Text style={styles.TitleScreen}>Estado Proyectos / SubProyectos</Text>
    {showDataPSPStates()}
    <Text style={styles.TitleScreen}>Proyectos / SubProyectos Creados / Mes</Text>

      {showDataPSPxMonth()}
   
     <Text style={styles.TitleScreen}>Ganancias Generadas / Mes</Text>
    {showDataEarnsxMonth()}
   
    <StatusBar barStyle="light-content" backgroundColor="black" />
    
    </View>
    </ScrollView>
  )} else {
    setDisconnectSession(true)
    }
  } else {
    setDisconnectSession(true)
    }
}

const styles = StyleSheet.create({
  Root:{
    backgroundColor:'#111111'
  },
  RootContainer: {
    flex:1,
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#111111'
  },
  MainContainer: {
     width:'70%',
     alignItems:'center',
     justifyContent:'center',
     textAlign:'center',
     alignContent:'center'
  },
  Container:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#555555',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerNewProject:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#262726',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerTeams:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#555555',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 14
  },
 
  TitleScreen:{
    margin:20,
    textAlign:'center',
    fontWeight:'bold',
    fontSize:16,
    color:'#ecfcff'
  },

  TextView: {
    color: '#e0e0f6',
    fontWeight: 'bold',
    fontSize: 14,
    marginTop:5,
    fontWeight: 'bold'
  },
});


export default Dashboard