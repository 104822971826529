import React, {useState, useContext, useEffect} from 'react';
import { ScrollView, Text, TouchableOpacity,View, StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { MaterialIcons } from '@expo/vector-icons';
import Context from '../../../../context/Context'
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
  

const Client =()=>  {

const  {dataNavigationClients, setUpdateRequest,updateRequest}= useContext(Context);
const Navigation = useNavigation();
const [arrayClients, setArrayClients]=useState([]);
const [showAlertDeleteClient, setShowAlertDeleteClient]=useState(false);
const [disableSubmit, setDisableSubmit]=useState(false);
const [showAlertFinished, setShowAlertFinished]=useState(false);
const [response, setResponse]=useState('');

 
    

const sendRequest=()=>{
  if (dataNavigationClients.length>0) {
    //Get:
 executeRequest('/clients', {
  "Get_Clients": [
    {
      "body": {
        "_Type_Request": "Get_Clients_Detail",
        "_Client_ID":dataNavigationClients[0].clientChosen
      }
    }
  ]
}, 'get', 'post').then(
      getData=>{
        setArrayClients(getData)
      }
    )
    } else { null }
}
  
  useEffect(()=>{
      sendRequest();
  },[])

  useEffect(()=>{
    sendRequest();
},[updateRequest])
  

const deleteClient=()=>{
    setDisableSubmit(true);
    setShowAlertDeleteClient(false);
      //Delete:
    executeRequest('/clients', {
      "Delete_Client": [
        {
          "body": {
            "_Client_ID": dataNavigationClients[0].clientChosen
          }
        }
      ]
    }, 'post', 'delete').then(
          getData=>{
            setResponse(getData[0].Response);
            setShowAlertFinished(true);
            setDisableSubmit(false);
          }
      )
}

    return (
<ScrollView style={styles.BackgroundScrollView}>
<View style={styles.RootContainer}>
    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Información Detallada Cliente</Text>
    </View>
    {(arrayClients.length>0) ?
    <>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Nombre:</Text> 
    <Text style={styles.TextScreenRight}>{arrayClients[0].name}</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>NIT:</Text> 
    <Text style={styles.TextScreenRight}>{arrayClients[0].nit}</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Email:</Text> 
    <Text style={styles.TextScreenRight}>{arrayClients[0].email}</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Teléfono:</Text> 
    <Text style={styles.TextScreenRight}>{arrayClients[0].phoneNumber}</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Nombre Supervisor:</Text> 
    <Text style={styles.TextScreenRight}>{arrayClients[0].supervisorName}</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Identificación:</Text> 
    <Text style={styles.TextScreenRight}>{arrayClients[0].supervisorIdentification}</Text> 
    </View>
    </> :
    <>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Nombre:</Text> 
    <Text style={styles.TextScreenRight}>__________________________</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>NIT:</Text> 
    <Text style={styles.TextScreenRight}>__________________________</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Email:</Text> 
    <Text style={styles.TextScreenRight}>__________________________</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Teléfono:</Text> 
    <Text style={styles.TextScreenRight}>__________________________</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Nombre Supervisor:</Text> 
    <Text style={styles.TextScreenRight}>__________________________</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Número Identificación:</Text> 
    <Text style={styles.TextScreenRight}>__________________________</Text> 
    </View>
    </>
    }

 
    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Administración</Text>
    </View>
    <View style={styles.MainContainer}> 
        <View style={styles.ButtonCenterAdmin}>
        <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=> Navigation.navigate('Update_Client')}>
        <MaterialIcons name="update" size={20} color="white" />
          <Text style={styles.TextStylesAdmin}>Actualizar</Text>
          </TouchableOpacity>
        </View> 
        <View style={styles.ButtonRightAdmin} >
        <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=> setShowAlertDeleteClient(true)}>
        <AntDesign name="delete" size={20} color="white" />
          <Text style={styles.TextStylesAdmin}>Eliminar</Text>
          </TouchableOpacity>
        </View>
    </View>

   


</View>
<AwesomeAlert style={styles.AlertContainer}
                show={showAlertDeleteClient}
                showProgress={false}
                title="Eliminar Cliente"
                message="¿Estas seguro de que deseas eliminar este Cliente?, presiona [Eliminar] para confirmar los cambios."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                titleStyle={{color:'#8DDBFF'}}
                messageStyle={{color:'#f9f9f9'}}
                contentContainerStyle={{backgroundColor:'#272727'}}
                confirmButtonColor='#809280'
                cancelButtonColor='#746E6E'
                onCancelPressed={() => {
                  setShowAlertDeleteClient(false)
                }}
                onConfirmPressed={() => {
                  deleteClient()
                }}
              />

<AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            setUpdateRequest(!updateRequest);
            Navigation.goBack();
          }}
        />
</ScrollView>
    );
  }

const styles = StyleSheet.create({
  TextScreenLeft: {
    color: '#DDE7F0',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'left',
    width:'40%',
    paddingTop:5
  },
  TextScreenRight: {
    color: '#f1f1f1',
    fontSize: 14,
    textAlign:'left',
    width:'54%',
    paddingTop:5
  },
  ButtonCenterAdmin: {
    textAlign:'center',
    width:'48%',
    paddingTop:15,
    marginLeft:5,
  },
  ButtonRightAdmin: {
    textAlign:'left',
    width:'48%',
    paddingTop:15,
    marginLeft:5,
  },
  ButtonStylesUpdate: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#293933',
  },
  ButtonStylesDelete: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#632c2c',
  },
  TextStylesAdmin: {
    fontSize: 14,
    color: '#f1f1f1',
  },
  RootContainer: {
    flex:1,
    width:'100%',
    alignItems:'center',
    textAlign:'left',
    justifyContent:'center',
    backgroundColor:'#131313',
  },
  MainContainer: {
    flex:1,
     width:'90%',
     flexWrap:'wrap',
     flexDirection: 'row',
  },
  BackgroundScrollView: {
    backgroundColor:'#131313'
  },
  TittleScreen:{
    color:'#B4DCEC',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    width:'95%',
    backgroundColor:'#0a0a0a',
    marginTop:10,
    borderRadius:10
  },
  SectionLine:{
    width: '100%',
    borderBottomWidth :1,
    borderBottomColor: '#3d3d3d',
  },
  TableStyles: {
    backgroundColor:'#181818',
    marginTop:10
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:5
  },
  Row:{
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  TextRows:{
    color:'#f1f1f1',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TextHeader:{
    color:'#B4DCEC',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
});

export default Client
