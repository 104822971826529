import React, { useContext, useState, useEffect } from 'react'
import {StyleSheet,Text, View, TextInput, Pressable, TouchableOpacity, ScrollView, Platform} from 'react-native'
import { SafeAreaView,FlatList } from 'react-native-gesture-handler'
import { useNavigation } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import Context from '../../../../context/Context';
import { FontAwesome5 } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import { AntDesign } from '@expo/vector-icons';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
  
//Export to Excel And Sharing:
import * as XLSX from 'xlsx';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
//MQTT Connection:
import Paho from "paho-mqtt";
import {returnScrts} from '../../../functional_global_components/Validate_Session';

const Start_Audit = () => {

  const [showAlert, setShowAlert]=useState(false);
  const [showAlertFinished, setShowAlertFinished]=useState(false);
  const [showAlertReportDownloaded, setShowAlertReportDownloaded]=useState(false);
  const [response, setResponse]=useState('');
  const [disableSubmit, setDisableSubmit]=useState(false);

  // const  {activityIndicator,setActivityIndicator}= useContext(Context);
  const {identificationUser, dataNavigationAuditsSch, setEndPointReport,endPointReport, MQTTObject} = useContext(Context);
  const Navigation = useNavigation();
  const [itemChecked, setItemChecked] = useState([{id:'0', quantity:'0', observation:''},]);
  const [showAlertChecked, setShowAlertChecked]=useState(false);
  const [arrayProducts, setArrayProducts]=useState([]);
  /*Variables for gerating Report Inventory:*/
  const [arrayReport, setArrayReport]=useState([]);
  const [arrayColumns, setArrayColumns]=useState([]);
  const [arrayRows, setArrayRows]=useState([]);
  const [unlockState,setUnlockState]=useState(false);
  const [disableButton, setDisableButton]=useState(false);
  const [showAlertLockStateFinished,setShowAlertLockStateFinished]=useState(false);
  const [showAlertError, setShowAlertError]=useState(false);
  const [search, setSearch]=useState('0');
  
 
  //Paho client connection:
  const client = new Paho.Client(
      MQTTObject.MQTT,
      Number(MQTTObject.Port), 
      identificationUser[0].id
  ); 


client.connect({
  timeout: 15, 
  onSuccess: ()=>{ 
    client.subscribe("esp32/blocker");
   },
  onFailure: ()=>{ 
    console.log("Failed to connect!");
   },
  userName:MQTTObject.Usr,
  password:MQTTObject.Pwd, 
  useSSL: true, 
  keepAliveInterval: 60, 
  reconnect: false});

  const changeValue=(Number)=>{
          try {
          const message = new Paho.Message(String(Number));
          message.destinationName="esp32/blocker";
          client.send(message);
          setShowAlertLockStateFinished(true);
          setTimeout(()=>{
          setDisableButton(false);  
        }, 30000)
          } catch (error) {
            setDisableButton(false);
            setShowAlertError(true);
        }
    }

  const saveLogAccessStore=(typeAccess)=>{
//Create:
      executeRequest('/access_store', {
        "Log_Access_Store": [
          {
            "body": {
              "_User_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
              "_Type_Access":Number(typeAccess),
              "_Type_Request":"Making_Audit"
            }
          }
        ]
      }, 'post', 'post').then(
            getData=>{
              null
            }
          )
    }



  const sendRequestGetInventory=()=>{
      //Get:
    executeRequest('/inventory', {
      "Get_Products": [
        {
          "body": {
            "_Type_Request": "Get_All_Products",
            "_Inventory_ID": null
          }
        }
      ]
    }, 'get', 'post').then(
          getData=>{
            setArrayProducts(getData)
          }
        )
    }

    useEffect(()=>{
      sendRequestGetInventory();
      setEndPointReport([{endPoint:`/inventory`, typeRequest:'Get_Inventory', rptName:'Inventario_Actual'}]);
  },[])
  

const sendRequestCreateAudit=()=>{   

  setDisableSubmit(true);
  setShowAlert(false);
  if (dataNavigationAuditsSch.length>0 && identificationUser.length>0) {
    //Create:
 executeRequest('/audits', {
  "Create_Audit": [
    {
      "body": {
        "_Data_Json": (itemChecked.length>0) ? itemChecked : null,
        "_User_Auditor_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
        "_Audits_Scheduled_ID": (dataNavigationAuditsSch.length>0) ? dataNavigationAuditsSch[0].auditSchChosen : null
      }
    }
  ]
}, 'post', 'post').then(
      getData=>{
        setResponse(getData[0].Response);
        setShowAlertFinished(true);
        setDisableSubmit(false);
      }
    )
  } else {null}
}


/*Start Process Generate Report Inventory: */
const sendRequestReport=()=>{
    setDisableSubmit(true);
    //Rpt:
    executeRequest(endPointReport[0].endPoint, {
      "Get_Reports": [
        {
          "body": {
            "_Type_Request": endPointReport[0].typeRequest
          }
        }
      ]
    }, 'rpt', 'post').then(
            getData=>{
              setArrayReport(getData);
              if (getData.length===0) {
                setResponse("No existe información en la báse de datos para generar el reporte.");
                setShowAlertReportDownloaded(true);
              }
              setDisableSubmit(false);
            }
        )
  }


useEffect(() => {
      if (arrayReport.length>0) {
        setArrayColumns(Object.keys(arrayReport[0])); /*["Tipo", "Nombre_Proyecto", "Descripción", "Cliente", "NIT", "Nombre_Supervisor", "Identificación", "Ciudad", "Dirección", "Ubicación", "Fecha_Estimada_Inicio_Obra", "Fecha_Estimada_Finalización_Obra", "Equipo_Asignado", "Acta_Inicio_Obra", "Acta_Finalización_Obra", "Cotización_Obra", "Estado", "Fecha_Creación", "Fecha_Finalización", "Usuario_Creador"]*/
       }
    }, [arrayReport]);


useEffect(() => {
      if (arrayReport.length>0 && arrayColumns.length>0) {
        setArrayRows([arrayColumns]);  /*Saving headers in a new array, after this step we iterate all results*/
        for (let i=0; i <  arrayReport.length; i++)  {
          setArrayRows(e=>[...e, Object.values(arrayReport[i])]);
          };
       }
    }, [arrayColumns]);
    
useEffect(() => {
      if (arrayReport.length>0 && arrayColumns.length>0 && arrayRows.length>0) {
        generateExcel();
          };
    }, [arrayRows]);
    
    



const generateExcel = () => {

      if (arrayReport.length>0) {
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(
          arrayRows
        );
        XLSX.utils.book_append_sheet(wb, ws, "Reporte", true);
       
          if (Platform.OS==='android' || Platform.OS==='ios'){
            const base64 = XLSX.write(wb, { type: "base64" });
            const filename = FileSystem.documentDirectory + endPointReport[0].rptName +".xlsx";
            FileSystem.writeAsStringAsync(filename, base64, {
              encoding: FileSystem.EncodingType.Base64
            }).then(() => {
              Sharing.shareAsync(filename);
                  });
        } else if (Platform.OS==='web'){
            /* generate XLSX file and send to client */
            XLSX.writeFile(wb, endPointReport[0].rptName.concat(".xlsx"));
            setResponse("El reporte " + endPointReport[0].rptName + " fue generado exitosamente.");
            setShowAlertReportDownloaded(true);
                }; 
            } 

      };

/*End Process Generate Report Inventory */


  const updateObservation=(id, observation)=>{
    if (observation=== null || observation === '' || observation ===undefined || observation === ' ') { null }
    else {
      
      const newValueReplace=itemChecked;
      newValueReplace.map(e=>((e.id)===id) ? (e.observation=observation) : null  );
      setItemChecked(newValueReplace)
    }
  };

  const item= ({item})=>{
    
    return (
      <Pressable 
     style={{backgroundColor:(itemChecked.map(e=>e.id).indexOf(item.hashID)!==-1)? '#2f3335' : '#232427' 
               ,  flexDirection:'row',
               alignItems:'center',
               borderBottomWidth:0.8,
               borderColor:'#1d1d1d'
               }}>
        <View style={styles.Row}>
        {(itemChecked.map(e=>e.id).indexOf(item.hashID)!==-1)? <Feather name="check-circle" size={18} color="white" style={styles.Text}/>  : <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={18} color="grey" style={styles.Text}/> }
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.itemName}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.entryDate}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.quantity}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.measure} </Text>
        </View>
        <View style={styles.Row}>
        <TextInput style={styles.TextInput} placeholder={(arrayProducts.length>0) ? ''.concat(item.quantity) : ''} onChangeText={(e)=>{

        if  (isNaN(e) === true && e.toString().length > 0 || e !== null || e !== '' || e !== undefined || e !== ' '  && itemChecked.find(y=>y.id===item.hashID)) {
          setItemChecked(itemChecked.filter(x=>x.id!==item.hashID));
         
        } 
        
        if (isNaN(e) === false && e.toString().length > 0 && e !== null && e !== '' && e !== undefined && e !== ' ' && itemChecked.find(y=>y.id!==item.hashID)) { 
          setItemChecked((x)=>[...x, {id:item.hashID, quantity:e, observation:''}]);
        
        }

      }}

      />
          </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.type} </Text>
        </View>
        
        <View style={styles.Row}>
        <TextInput  multiline={true} numberOfLines={4} style={styles.TextInputObservation} placeholder=''
        onChangeText={(e)=>{
          (itemChecked.find(y=>y.id===item.hashID)) ? updateObservation(item.hashID, e) 
          : null  
        }}
       
        />
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.Brand}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.Consecutive}</Text>
        </View>
      </Pressable>

    )

  }

  
const showData=()=>{
  if (arrayProducts.length>0) {
  return (
<FlatList 
      data={(search!=='0') ?
      arrayProducts.filter((e)=>(e.itemName.toLowerCase().includes((search).toLowerCase()))).slice(0,5)
      :
      arrayProducts.filter((e)=>(e.itemName.toLowerCase().includes((search).toLowerCase()))).slice(0,5)}
      renderItem={item} 
      removeClippedSubviews={true}
      ListHeaderComponent={()=>{return(<View style={styles.ViewRows}
      >
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Revisado</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Item</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Fecha Ingreso</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Cant. Base de Datos</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>U. Medida</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Cant. Existente Bodega</Text>
        </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Tipo</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeaderObservation}>Observación</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Marca</Text>
      </View>
       <View style={styles.Row}>
        <Text style={styles.TextHeader}>Consecutivo</Text>
      </View>
    </View>)}}
      />
      );
    } else {
      return (
        <View style={styles.ViewRows}
              >
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Revisado</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Item</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Fecha Ingreso</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Cant. Base de Datos</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>U. Medida</Text>
                </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Cant. Existente Bodega</Text>
              </View>
                <View style={styles.Row}>
                <Text style={styles.TextHeader}>Tipo</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeaderObservation}>Observación</Text> 
              </View>
              <View style={styles.Row}>
        <Text style={styles.TextHeader}>Marca</Text>
      </View>
       <View style={styles.Row}>
        <Text style={styles.TextHeader}>Consecutivo</Text>
      </View>
            </View>
              );
    }
  }

  return(

   <View style={styles.MainContainer}>
    <ScrollView>

    <View style={styles.TitleView}>
      <Text style={styles.TitleScreen}>Proceso de Auditoría</Text>
      </View>
      <Text style={styles.TextHigh}>Para realizar el proceso de auditoria es necesario tener presente los siguientes lineamientos:</Text>
<Text style={styles.TextHigh}>1. Descarga e imprime el reporte completo de Inventario antes de comenzar a realizar el proceso de Auditoría:</Text>      

<View style={styles.containerButton}>
{ (disableSubmit===true) ?  
<TouchableOpacity style={styles.ButtonAuditFinished}>
<FontAwesome style={styles.iconButtons} name="send-o" size={24} color="#f1f1f1" />
<Text style={styles.TextStylesFinished}>Procesando</Text>
</TouchableOpacity>
:
<TouchableOpacity style={styles.ButtonAuditFinished} onPress={()=>{
sendRequestReport()
  }}>
<FontAwesome style={styles.iconButtons} name="send-o" size={24} color="#f1f1f1" />
<Text style={styles.TextStylesFinished}>Generar Reporte Inventario</Text>
</TouchableOpacity>
}
</View>

      <Text style={styles.TextHigh}>2. Acontinuación podrás visualizar 2 botones de acceso a bodega, los cuales habilitaran o deshabilitarán el ingreso según sea el caso para realizar el proceso de auditoría:</Text>
             <View style={styles.ContainerButtons}>
             <TouchableOpacity style={(disableButton===false) ?  styles.ButtonUnlockStyle : styles.ButtonUnlockDisableStyle } onPress={()=> {
              setDisableButton(true);
              if (disableButton===false) {
              setUnlockState(true)
              changeValue(0);
              saveLogAccessStore(0);
                }
             }}>
            <FontAwesome5 style={styles.iconButtons}  name="unlock-alt" size={18} color="#2c2929" />
            <Text style={styles.TextStylesFinished}>Desbloquear Bodega</Text>
              </TouchableOpacity>


              <TouchableOpacity style={(disableButton===false) ?  styles.ButtonLockStyle : styles.ButtonLockDisableStyle } onPress={()=> {
              setDisableButton(true);
              if (disableButton===false) {
              setUnlockState(false)
              changeValue(1);
              saveLogAccessStore(1);
                }
             }}>
            <FontAwesome5 style={styles.iconButtons}  name="unlock-alt" size={18} color="#2c2929" />
            <Text style={styles.TextStylesFinished}>Bloquear Bodega</Text>
              </TouchableOpacity>
            </View> 

  <Text style={styles.TextHigh}>3. Utiliza el buscador para encontrar productos específicos y agregar las diferencias que hayas evidenciado en bodega respecto al reporte de Inventario generado anteriormente:</Text>

  <View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder='Nombre Item' style={styles.SearchInput}
     onChangeText={(e) => {
    (e==='' || e=== null || e=== undefined)?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    <ScrollView horizontal={true} nestedScrollEnabled={true}>
      
      <View style={styles.TableStyles}>

{showData()}
      
    </View>
    </ScrollView>

    {/* <Text style={styles.TextHigh}>3. Una vez diligenciados los datos correpondientes será posible finalizar el proceso de auditoria, posteriormente se creará un reporte que será consultado por gerencia para efectuar los ajustes correspondientes y tomar las medidas pertinentes junto con el personal administrador de bodega.</Text> */}

<View style={styles.containerButton}>

{ (disableSubmit===true) ?  
<TouchableOpacity style={styles.ButtonAuditFinished}>
<FontAwesome style={styles.iconButtons} name="send-o" size={24} color="#f1f1f1" />
<Text style={styles.TextStylesFinished}>Procesando</Text>
</TouchableOpacity>
:
<TouchableOpacity style={styles.ButtonAuditFinished} onPress={()=>{
setShowAlert(true)
  }}>
<FontAwesome style={styles.iconButtons} name="send-o" size={24} color="#f1f1f1" />
<Text style={styles.TextStylesFinished}>Finalizar Proceso Auditoria</Text>
</TouchableOpacity>

}

        </View>

        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="¿Estas seguro de finalizar el proceso de auditoria?, una vez terminado el proceso estos datos serán almacenados como constancia para tomar las medidas pertinentes en acompañamiento de Gerencia."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            sendRequestCreateAudit();
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />


<AwesomeAlert
          show={showAlertChecked}
          showProgress={false}
          title="Verifica la Auditoría"
          message="Aun no has terminado de realizar la verificación de todos los productos existentes en bodega, existen productos que un no se han validado."
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertChecked(false);
          }}
        />

<AwesomeAlert
          show={showAlertLockStateFinished}
          showProgress={false}
          title={(unlockState===true) ? "Desbloqueo Realizado" : "Bloqueo Realizado" }
          message={(unlockState===true) ? 
            "Se desbloqueó exitosamente el acceso a bodega. Por favor no olvides cerrar nuevamente la puerta cuando hayas terminado el proceso de entrega."
          : 
            "Se activó exitosamente el bloqueo magnético de la bodega, por favor verifica que la puerta se encuentre bien ajustada, gracias."
          }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertLockStateFinished(false);
          }}
        />
<AwesomeAlert
          show={showAlertError}
          showProgress={false}
          title="Error Inesperado"
          message="Se ha presentado un error inesperado al intentar establecer comunicación con el servidor, por favor reintenta la petición nuevamente."
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#ffb2b2'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertError(false);
          }}
        />

<AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.navigate('Audits_Done');
          }}
        />


      <AwesomeAlert
          show={showAlertReportDownloaded}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertReportDownloaded(false);
          }}
        />

    </ScrollView>

    </View>

  )

}

const styles=StyleSheet.create({
  MainContainer:{
    backgroundColor:'#0d0d0d',
    justifyContent:'center',
    flex:1,
    flexDirection:'row'
  },

  PropertiesView:{
    backgroundColor:'#111111',
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
  },
  Row:{
    textAlign:'left',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center'
  },
  
  Text:{
    width:120,
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
  },
  TextHeader:{
    color:'#B4DCEC',
    width:120,
    padding:5,
    textAlign:'center',
    borderRightWidth:1,
    fontWeight:'bold',
    borderRightColor:'#393939',
  },
  TableStyles: {
    backgroundColor:'#181818',
  },
  ContainerSearch: {
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'center',
  },
  SearchInput: {
    backgroundColor:'#767a7a',
    width:'70%',
    height:25,
    textAlign:'left',
    borderRadius:10,
    marginTop:40,
    marginBottom:40,
    paddingLeft:10,
    marginLeft:30
  },
  IconSearch:{
    width:'10%',
    marginTop:40,
    marginBottom:40,
    marginLeft:20
  },
  TextHigh: {
    textAlign:'left',
    color:'#EDF0F5',
    paddingTop:'5%',
    paddingLeft:'5%',
    paddingRight:'5%',
    marginBottom:10
  },
  containerButton: {
    alignItems:'center',
    margin:10
  },

  ButtonLeft: {
    fontWeight: 'bold',
    textAlign:'center',
    width:'100%',
    paddingTop:10,
    alignItems:'center',
   

  },
  ButtonCenter: {
    textAlign:'center',
    width:'45%',
    paddingTop:10,
  },
  ButtonRight: {
    textAlign:'center',
    width:'45%',
    paddingTop:10,
  },

  ButtonStylesFinished: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#37548a',
    width:180
  },

  ButtonStylesUpdate: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#688a37',
    width:180
  },

  ButtonStylesDelete: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#ae4545',
    width:180
  },
  TextStyles: {
    fontSize: 14,
    color: '#f1f1f1',
    
  },
    ContainerButtons:{
    width:'100%',
    alignItems:'center',
    marginTop:10,
    marginBottom:20
  },
 
  ButtonUnlockStyle:{
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#4F4242',
    width:'48%'
  },
  ButtonLockStyle:{
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#424F44',
    marginLeft:5,
    width:'48%'
  },
  ButtonUnlockDisableStyle:{
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#bfbfbf',
    width:'48%'
  },
  ButtonLockDisableStyle:{
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginLeft:5,
    backgroundColor:'#bfbfbf',
    width:'48%'
  },
  ButtonDisableStyle:{
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#bfbfbf',
    width:'60%'
  },
  iconButtons:{
    marginTop:10
  },
  TextStylesFinished: {
  color:'#2e2e2e',
  fontWeight:'bold',
  margin:10,
  fontSize:14,
  textAlign:'center'
   },
   TitleScreen:{
    color:'#61AAE3',
    marginTop:10,
    fontSize:22,
    fontWeight:'bold',
    textAlign:'center'
  },
  TitleView:{
    justifyContent:'center',
    textAlign:'center',
    width:'100%'
  },
    TextInputObservation:{
    width:200,
    backgroundColor:'#1c1c1c',
    borderRadius:7,
    color:'#f1f1f1',
    textAlign:'left',
    alignContent:'center',
    marginTop:10,
    marginBottom:10,
    textAlignVertical: 'top',
    paddingTop:4,
    paddingLeft:4,
    paddingRight:4,
  },
    TextInput:{
    width:60,
    backgroundColor:'#6c6c6c',
    borderRadius:7,
    color:'#f1f1f1',
    textAlign:'center',
    alignContent:'center',
    marginLeft:30,
    marginRight:30
  },
  TextHeaderObservation:{
    color:'#B4DCEC',
    width:200,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  ButtonAuditFinished:{
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#a8c0ad',
    width:'70%',
    marginBottom:10
    },
    ContainerSearch: {
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    }
});

export default Start_Audit