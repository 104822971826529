import React, { useState, useEffect, useContext } from 'react';
import { ScrollView,TextInput, Text, Button, Alert, View, StyleSheet, Platform, Image } from 'react-native';
import * as yup from 'yup';
import { Formik } from 'formik';
import  DateTimePicker  from '@react-native-community/datetimepicker';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import Context from '../../../../context/Context'
import axios from 'axios';
//For Making Requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
import AwesomeAlert from 'react-native-awesome-alerts';
import {returnScrts} from '../../../functional_global_components/Validate_Session';

const Create_New_Novelty =()=>  {

  const Navigation = useNavigation();
  const {dataNavigation, setUpdateRequest, updateRequest, FCM_Tokens, arrayProject, dataSession, identificationUser} = useContext(Context);
  const [showAlert, setShowAlert]=useState(false);
  const [showAlertFinished, setShowAlertFinished]=useState(false);
  const [response, setResponse]=useState('');
  const [disableSubmit, setDisableSubmit]=useState(false);
  const [serverKey, setServerKey]=useState('');
  const actualDate= new Date().toLocaleString();
  
   
  useEffect(()=>{
     returnScrts().then(scrts => setServerKey(scrts.FCM_PK_Firebase));
  },[]);

  const sendPushNotification = async (deviceTokens, title, body) => {
    try {
      const url = 'https://fcm.googleapis.com/fcm/send';
      const notifications = deviceTokens.map(token => ({
        to: token,
        notification: {
          title: title,
          body: body,
          priority: 'high',
          visibility: 'public'
        },
        data: {
          Cliente: arrayProject[0].clientName,
          Ciudad: arrayProject[0].city,
          Lugar: arrayProject[0].location,
          Equipo: arrayProject[0].teamName,
          Usuario: identificationUser[0]["nameUser"],
          Id: dataSession.IdentificationCode
        },
      }));
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `key=${serverKey}`,
      };
      
      await Promise.all(
        notifications.map(notification => axios.post(url, notification, { headers }))
      );
      
    } catch (error) {
      console.error('Se ha presentado un error al realizar el envio de las notificaciones Push.', error);
    }
  };

  const sendRequest=(Observation)=>{
      setDisableSubmit(true);
      setShowAlert(false);
      //Create:
      executeRequest('/novelties', {
        "Create_Novelty": [
          {
            "body": {
              "_User_Creator_ID": (identificationUser[0] !== undefined) ? identificationUser[0]["id"] : null,
              "_Observation": Observation,
              "_Project_ID": (dataNavigation[1] !== undefined) ?  dataNavigation[1].projectChosen : null,
              "_Client_ID": (dataNavigation[0] !== undefined) ? dataNavigation[0].clientChosen : null,
              "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null
            }
          }
        ]
      }, 'post', 'post').then(
            getData=>{
              setResponse(getData[0].Response);
              setUpdateRequest(!updateRequest);
              setShowAlertFinished(true);
              setDisableSubmit(false);
              sendPushNotification(FCM_Tokens, `Novedad ${arrayProject[0].nameProject}`, Observation);
            }
        )
    }



    return (

    <ScrollView style={styles.formContainer}>

<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Nueva Novedad</Text>
</View>
      <Formik
        initialValues={{
          Observation: '',
        }}
        onSubmit={values => {
          const Observation=JSON.stringify(values["Observation"]);
          sendRequest(JSON.parse(Observation));
        }}
        validationSchema={yup.object().shape({
            Observation: yup
            .string() 
            .max(500, 'La observación no puede superar los 500 caracteres')
            .required('Este campo es completamente obligatorio')
        })}
       >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
<View style={styles.formContainer}>
          
<View style={styles. formGroup}>
<View style={styles. ContainerText}>
<Text style={styles.FormStylesText}>Fecha Reporte:</Text> 
</View>
<View  style={styles. RightTextDate}>
           <Text  style={styles. RightTextColor}>{actualDate}</Text>
</View>
</View>



<Text style={styles.ProceduresExecution}>Observación:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {10}
              value={values.Observation}
              style={styles.Observation}
              onChangeText={handleChange('Observation')}
              onBlur={() => setFieldTouched('Observation')}
              placeholder=" "
            />
            {touched.Observation && errors.Observation &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Observation}</Text>
            }
        
            
    { (disableSubmit===true) ?  
       <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished}>
       <Ionicons name="create" size={24} color="#ABEDFF" />
         <Text style={styles.TextStylesSave}>Procesando</Text>
         </TouchableOpacity>
      :  
            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished}
            disabled={!isValid} onPress={()=>{setShowAlert(true)}}>
           <Ionicons name="create" size={24} color="#ABEDFF" />
              <Text style={styles.TextStylesSave}>Guardar</Text>
              </TouchableOpacity>
        }
          
<View style={styles.ViewSpace}></View>

<AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
            
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />


          </View>
        )}
      </Formik>

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            if (dataNavigation[2] !== undefined) {
              Navigation.navigate('SubProject');
            } else {
              Navigation.navigate('Project');
            }
            
          }}
        />

    </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
    backgroundColor:'#111111',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10,
    backgroundColor:'#c0c0c0',
  },
   formGroup: {
    flex:1,
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
   ContainerText: {
    textAlign:'left',
    justifyContent:'center',
    width:'40%'
  },
   ContainerTextMeasure: {
    textAlign:'left',
    justifyContent:'center',
    width:'48%',
    verticalAlign:'bottom',
    paddingTop:18
  },
   RightText: {
    alignItems:'center',
    marginTop:10,
    justifyContent:'center',
    borderRadius:10,
    width:'50%',
    backgroundColor:'white'
  },
  RightTextDate: {
    alignItems:'center',
    marginTop:10,
    justifyContent:'center',
    borderRadius:10,
    width:'60%',
    
  },
  RightTextColor:{
    color:'#F0FBFF',
    fontWeight:'bold'
  },
  DropDownSelection: {
    marginTop:10,
    marginBottom:10,
    backgroundColor:'white',
    borderRadius:15,
    padding:10,
  },
  MeasureSelection: {
    margin:5,
    marginBottom:10,
    backgroundColor:'white',
    borderRadius:10,
    width:'48%'
  },
  TittleScreen:{
    color:'#ABEDFF',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#2A2A2A',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10
  },
  ButtonCreateProject: {
    backgroundColor:'#4d83a5',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonFinished: {
    backgroundColor:'#41484B',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#bccdd4',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonCenterTeam: {
    backgroundColor:'#373737',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  TextStylesSave: {
    color:'#ABEDFF',
    margin:10,
    fontSize:14,
    textAlign:'center'
  },
  ViewSpace: {
    marginTop:10,
    width:'100%'
  },
 FormStylesText:{
   color:'#f1f1f1',
   marginTop:12
 },
  inputStyle:  {
      width:'100%',
      height:80,
      borderRadius:10,
      padding:10,
      marginTop:10,
      textAlign:'justify',
      textAlignVertical:'top',
      backgroundColor:'#dbd9d9'
    },
    Observation:  {
      width:'100%',
      height:150,
      borderRadius:10,
      padding:10,
      marginTop:10,
      textAlign:'justify',
      textAlignVertical:'top',
      backgroundColor:'#dbd9d9'
    },
    ProceduresExecution:{
      marginTop:10,
      color:'#f9f9f9',
      width:'100%'
    },
    Signature:{
      marginTop:10,
      marginLeft:20,
      marginRight:20,
      backgroundColor:'white',
      borderRadius:10,
      height:40,
      paddingLeft:10,
      textAlign:'center'
    },
    TextStyleLoading: {
      color:'#fa93b4',
      fontSize:16,
      paddingTop:5,
      fontWeight:'bold',
      textAlign:'center'
    },

});

export default Create_New_Novelty

