import React, { useState, useContext,Component, useEffect } from 'react';
import { ScrollView,TextInput, Text, Button, Alert, View, StyleSheet, Platform, Pressable } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import { SafeAreaView,FlatList } from 'react-native-gesture-handler'
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Context from '../../../context/Context';
import { AntDesign } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
//For Making Requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
import AwesomeAlert from 'react-native-awesome-alerts';
  

const Create_New_Request =()=>  {

    const [showAlert, setShowAlert]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [response, setResponse]=useState('');
    const [disableSubmit, setDisableSubmit]=useState(false);
    

    //Search on Table:
    const [search, setSearch]=useState('0') 
    //Columns for table:
    const [projectSelected, setProjectSelected]= useState([{id:'0', name:''}]);
    const [subProjectSelected, setSubProjectSelected]= useState([]);
    const [itemSelected, setItemSelected] = useState([{id:'0', name:'', measure:'', type:'', quantity:''},]);
    const [arrayItems, setArrayItems]= useState([]);
    const [arrayPSP, setArrayPSP]= useState([]);
    const {dataNavigation, identificationUser, setUpdateRequest, updateRequest } = useContext(Context);
    
    
    const Navigation = useNavigation();

const showProjects=()=>{
      if (arrayPSP.length>0) {
      return (
        <ScrollView horizontal={true}>
        <View style={styles.TableStyles}>
          
        <FlatList 
          data={
            (search!=='0') ?
            arrayPSP.filter((x)=>(x.type==="Projects")).filter((e)=>(e.projectName.toLowerCase().includes((search).toLowerCase()))).slice(0,4)
            :
            arrayPSP.filter((x)=>(x.type==="Projects")).slice(0,4)
          }
          renderItem={itemsProjects} 
          /> 
          
        </View>
          </ScrollView>
        )
      } else { null }
    }

const itemsProjects= ({item})=>{
  
      return (
        <Pressable  onPress={()=>{ if (projectSelected.find(e=>e.id===item.idProject)) {
          setProjectSelected([{id:'0', name:''}]);
          setSearch('0');
        } else { 
          setProjectSelected([{id:item.idProject, name:item.projectName}]);
          setSearch('0');
        }
        }}
      style={{backgroundColor:(projectSelected.map(e=>e.id).indexOf(item.idProject)!==-1)? '#0071e0' : '#1e1f20',
                flexDirection:'row',
                alignItems:'center',
                textAlign:'center',
                justifyContent:'center',
                borderRadius:10,
                margin:5,
    
                }}>
          <View style={styles.RowSlice}>
            <Text style={styles.TextSlice}>{item.projectName}</Text>
          </View>
        </Pressable>
    
      )
      
    }


const showSubProjects=()=>{
      if (arrayPSP.length>0) {
      return (
        <ScrollView horizontal={true}>
        <View style={styles.TableStyles}>
          
        <FlatList 
          data={
            (search!=='0') ?
            arrayPSP.filter((x)=>(x.type==="SubProjects")).filter((e)=>(e.subProjectName.toLowerCase().includes((search).toLowerCase()))).slice(0,4)
            :
            arrayPSP.filter((x)=>(x.type==="SubProjects")).filter((y)=>(y.idProject===projectSelected[0].id)).slice(0,4)
          }
          renderItem={itemsSubProjects} 
          /> 
          
        </View>
          </ScrollView>
        )
      } else { null }
    }

const itemsSubProjects= ({item})=>{
  
      return (
        <Pressable  onPress={()=>{ if (subProjectSelected.find(e=>e.id===item.idSubProject)) {
          setSubProjectSelected(subProjectSelected.filter(e=>e.id!==item.idSubProject));
          setSearch('0');
        } else { 
          setSubProjectSelected([{id:item.idSubProject, name:item.subProjectName}]);
          setSearch('0');
        }
        }}
      style={{backgroundColor:(subProjectSelected.map(e=>e.id).indexOf(item.idSubProject)!==-1)? '#0071e0' : '#1e1f20',
                flexDirection:'row',
                alignItems:'center',
                textAlign:'center',
                justifyContent:'center',
                borderRadius:10,
                margin:5,
    
                }}>
          <View style={styles.RowSlice}>
            <Text style={styles.TextSlice}>{item.subProjectName}</Text>
          </View>
        </Pressable>
    
      )
      
    }




    const requestBasicDataPSP=()=>{
        //Get:
        executeRequest('/basic_data_psp', {
          "Basic_Data_PSP": [
            {
              "body": {
                "_User_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayPSP(getData)
              }
            )
      }

    const requestBasicDataInventory=()=>{
        //Get:
        executeRequest('/basic_data_inventory', {
          "Get_Basic_Data_Inventory": [
            {
              "body": {
                "_Type_Request": "Get_Basic_Data_Inventory"
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayItems(getData)
              }
          )
      }

      useEffect(()=>{
        requestBasicDataPSP();
        requestBasicDataInventory();
      },[])

      const sendRequest=(requestName, justification)=>{
    
        setDisableSubmit(true);
        setShowAlert(false);
        //Create:
        executeRequest('/requests', {
          "Create_Request": [
            {
              "body": {
                "_Request_Title": (requestName.length>0) ? requestName : null,
                "_Justification": (justification.length>0) ? justification : null,
                "_User_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
                "_Project_ID":(projectSelected.length>0) ? projectSelected[0].id : null,
                "_SubProject_ID":(subProjectSelected.length>0) ? subProjectSelected[0].id : null,
                "_Data_Json":(itemSelected.length) ? itemSelected : null
              }
            }
          ]
        }, 'post', 'post').then(
              getData=>{
                setResponse(getData[0].Response);
                setUpdateRequest(!updateRequest);
                setShowAlertFinished(true);
                setDisableSubmit(false);
              }
            )
    
        }
      


      const showDataItems=()=>{

    
        if (arrayItems.length>0) {
        if (search!=='0') {
        return (
          <ScrollView horizontal={true}>
          <View style={styles.TableStyles}>
        <FlatList 
          data={arrayItems.filter((e)=>(e.itemName.toLowerCase().includes((search).toLowerCase()))).slice(0,10)}
          renderItem={item} 
          ListHeaderComponent={()=>{
          
          return(
          <View style={styles.ViewRows}>
          <View style={styles.Row}>
            <Text style={styles.TextHeader}>#</Text>
          </View>
          <View style={styles.Row}>
            <Text style={styles.TextHeader}>Item</Text>
          </View>
          <View style={styles.Row}>
            <Text style={styles.TextHeader}>Check</Text>
          </View>
        </View>
        
        )}}
          />  
    
        </View>
        </ScrollView>
        )
        } else {
          return (
            <ScrollView horizontal={true}>
            <View style={styles.TableStyles}>
          <FlatList 
            data={arrayItems.slice(0,10)}
            renderItem={item} 
            ListHeaderComponent={()=>{
            
            return(
            <View style={styles.ViewRows}>
            <View style={styles.Row}>
              <Text style={styles.TextHeader}>#</Text>
            </View>
            <View style={styles.Row}>
              <Text style={styles.TextHeader}>Item</Text>
            </View>
            <View style={styles.Row}>
              <Text style={styles.TextHeader}>Check</Text>
            </View>
          </View>
          
          )}}
            />  
      
          </View>
          </ScrollView>
          )
        }
      }  else { null }
    
      }




/************StartTableRows*****************/
const item= ({item})=>{

  return (

    <Pressable  onPress={()=>{ if (itemSelected.find(e=>e.id===item.hashID)) {
      setItemSelected(itemSelected.filter(e=>e.id!==item.hashID)) 
    } else {
     setItemSelected((e)=>[...e, {id:item.hashID, name:item.itemName, measure:item.measure, type:item.itemType ,quantity:''}])
    }
    }}
  style={{backgroundColor:(itemSelected.map(e=>e.id).indexOf(item.hashID)!==-1)? '#222426' : '#111315' 
            ,  flexDirection:'row',
            alignItems:'center',}}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.id}</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.itemName}</Text>
      </View>
      <View style={styles.Row}>
        {(itemSelected.map(e=>e.id).indexOf(item.hashID)!==-1)? <Feather name="check-circle" size={18} color="white" style={styles.Text}/>  : <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={18} color="grey" style={styles.Text}/> }
      </View>
    </Pressable>

  )
  
}


const updateValueQuantity=(id, quant)=>{
  if (isNaN(Number(quant))===true || quant=== null || quant === '' || quant ===undefined || quant == ' ') { null }
  else {
    const newValueReplace=itemSelected;
    newValueReplace.map(e=>((e.id)===id) ? (e.quantity=quant) : null  );
    setItemSelected(newValueReplace);

    }
};

/************EndTableRows*****************/
/************StartTable2Rows*****************/
const item_Selected= ({item})=>{


  return (
    <View style={styles.ViewRows}>
      <View style={styles.Row}>
        <Text style={styles.TextRows}>{item.name}</Text>
      </View>
      <View style={styles.Row}>
       <TextInput style={styles.QuantitySelected} placeholder='?' onChangeText={(e)=>{
      updateValueQuantity(item.id, e);
 }}/> 
      </View>
      <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.measure}</Text>
       </View>
       
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.type}</Text>
       </View>
      </View>
  )

}
/************EndTable2Rows*****************/
    
 if (arrayPSP.length>0) { 
    return (
<ScrollView style={styles.ViewRoot}>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Crear Nueva Solicitud</Text>
</View>
      <Formik
        initialValues={{
          requestName: '',
          justification: ''
        }}
        onSubmit={values => {
          const requestName=JSON.stringify(values["requestName"]);
          const justification=JSON.stringify(values["justification"]);
          sendRequest(JSON.parse(requestName), JSON.parse(justification));
        }}
        validationSchema={yup.object().shape({
            requestName: yup
            .string() 
            .min(3, 'El asunto debe tener como minimo 3 caracteres')
            .max(50, 'El asunto no puede exceder 50 caracteres')
            .required('Por favor especifique brevemente un nombre para la solicitud'),
            justification: yup
            .string()  
            .min(10, 'La cantidad minima de carateres necesarios para justificar la solicitud es de 10')
            .max(500, 'La cantidad máxima de carateres para justificar la solicitud es de 500')
            .required('Por favor describa la razón por la cual se requiere material adicional para continuar la ejecución del proyecto')
        })}
       >

        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <View style={styles.formContainer}>
            <TextInput
              value={values.requestName}
              style={styles.inputStyle}
              onChangeText={handleChange('requestName')}
              onBlur={() => setFieldTouched('requestName')} 
              placeholder="Asunto Solicitud"
            />
            {touched.requestName && errors.requestName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.requestName}</Text>
            }
            <TextInput
              multiline = {true}
              numberOfLines = {10}
              value={values.justification}
              style={styles.inputStyleProceduresExecution}
              onChangeText={handleChange('justification')}
              onBlur={() => setFieldTouched('justification')}
              placeholder="Justificación"
            />
            {touched.justification && errors.justification &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.justification}</Text>
            }


{(arrayPSP.filter((x)=>(x.type==="Projects")).length===1)
? 
<>
<Text style={styles.TextHigh}>Ten presente que tu solicitud se realizará para el proyecto que te fue asignado:</Text>
{showProjects()}
</>
:
<>
<Text style={styles.TextHighCheck}>Especifíca o selecciona el nombre del proyecto que deseas asociar a la solicitud:</Text>

<View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder={(projectSelected.length>0 && projectSelected[0].id !=='0') ?  projectSelected[0].name : 'Proyecto solicitud'} style={(projectSelected.length>0 && projectSelected[0].id !=='0') ? 
    styles.SearchInputSelected  : styles.SearchInput}
     onChangeText={(e) => {
    (e==='')?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

{showProjects()}
</>
}




{((arrayPSP.filter((x)=>(x.type==="SubProjects")).filter((y)=>(y.idProject===projectSelected[0].id)).length>0))
? 
<>
<Text style={styles.TextHighCheck}>Escribe o selecciona el nombre del SubProyecto para el cual deseas realizar la solicitud:</Text>

<View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder={(subProjectSelected.length>0) ?  subProjectSelected[0].name : 'SubProyecto asociado'} style={(subProjectSelected.length>0) ? 
    styles.SearchInputSelected  : styles.SearchInput}
     onChangeText={(e) => {
    (e==='')?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>
{showSubProjects()}
</> : null
}

            
<View  style={styles.DropDownSelection}>

{/* Start Fields And List */}

    <Text style={styles.TextHigh}>Especifíca el nombre de los item que deseas agregar a la solicitud:</Text>
    <View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder='Búscar producto específico' style={styles.SearchInput}
     onChangeText={(e) => {
    (e==='')?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    {showDataItems()}

   {/* End Fields And FlatList */}

{(itemSelected[1]) &&
   <>
        <Text style={styles.TextHigh}>Lista de Items seleccionados:</Text>
        
        <ScrollView horizontal={true}>          
        <View style={styles.TableStyles}> 
        
        <FlatList 
                        data={ 
                        itemSelected.filter(e=>e.id!=='0')
                             }
                        renderItem={item_Selected} 
                        ListHeaderComponent={()=>{
                        
                        return(
                        <View style={styles.ViewRows}>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Item</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cantidad</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>U. Medida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Tipo</Text>
                        </View>
                       </View>
                      
                      )}}
                        />  
        </View>
        </ScrollView>
        </>
}

{ (disableSubmit===true) ?  
       <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished}>
       <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
         <Text style={styles.TextStylesFinished}>Procesando</Text>
         </TouchableOpacity>
      :     
            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished}
            disabled={!isValid} onPress={()=>{setShowAlert(true)}}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
              <Text style={styles.TextStylesFinished}>Crear Solicitud</Text>
              </TouchableOpacity>
      }


                </View>

                <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
            
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />



            </View>
        
      
        )}



      </Formik>
   
      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.navigate('Requests_Created');
          }}
        />

      </ScrollView>
    ) }  else { 
      return (
<ScrollView style={styles.ViewRoot}>
<View style={styles.formContainer}>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Crear Nueva Solicitud</Text>
</View>
            <TextInput
              style={styles.inputStyle}
              placeholder="Asunto Solicitud"
            />
      
            <TextInput
              multiline = {true}
              numberOfLines = {10}
              style={styles.inputStyleProceduresExecution}
              placeholder="Justificación"
            />
</View> 
</ScrollView>      
      );

     }
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10
  },
  formGroupDates: {
    flex:1,
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  DatesInput: {
    alignItems:'center',
    justifyContent:'center',
    borderRadius:30,
    width:'35%',
    height:60,
  },
  DropDownSelection: {
    marginTop:10
  },
  TittleScreen:{
    color:'#7be8bc',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewRoot:{
     backgroundColor:'#111111',
     width:'100%',
     height:'100%'
  },
  ViewTittleScreen:{
    backgroundColor:'#2c2c2c',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:40
  },
  ButtonCenterFinished: {
    backgroundColor:'#4d83a5',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  Row:{
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center'
  },
  
  Text:{
    width:120,
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
    borderRightWidth:1,
    borderRightColor:'#393939',
  },
  ContainerSearch: {
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'center',
  },
  
  IconSearch:{
    width:'10%',
    marginTop:40,
    marginLeft:20
  },
  TextHigh: {
    textAlign:'left',
    color:'#dbe7f6',
    paddingTop:'5%',
    paddingLeft:5
  },
  TextHighCheck: {
    textAlign:'left',
    color:'#dbe7f6',
    paddingTop:'5%',
    paddingLeft:5
  },
  PropertiesView:{
    backgroundColor:'#111111',
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:5
  },
  Row:{
    textAlign:'left',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center'
  },
  
  Text:{
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    width:120
  },

  RowSlice:{
    textAlign:'left',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    width:300
  },

  TextSlice:{
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    width:300
  },

  TextHeader:{
    color:'#B4DCEC',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TableStyles: {
    backgroundColor:'#181818',
    borderRadius:10
  },
  TextInput:{
    width:60,
    backgroundColor:'grey',
    borderRadius:7,
    color:'#f1f1f1',
    textAlign:'center',
    borderRightWidth:1,
    borderRightColor:'#393939',
    alignContent:'center',
    marginLeft:30,
    marginRight:30
  },
  ListSelectedContainer: {
    flex:1,
    flexDirection:'row',
    flexWrap:'wrap',
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
    marginTop:10
  },
  LeftSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    color:'#f1f1f1'
  },
  CenterSelect:{
    width:'18%',
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    marginTop:10,
    color:'#f1f1f1'
  },
  RightSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    color:'#f1f1f1'
  },

  LeftTitleSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  CenterTitleSelect:{
    width:'18%',
    textAlign:'center',
    borderRadius:10,
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  RightTitleSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },

  TextRows:{
    color:'#f1f1f1',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  QuantitySelected:{
    width:120,
    textAlign:'center',
    backgroundColor:'#51555c',
    borderRadius:10,
    height:35,
    color:'#f1f1f1'
  },
  inputStyleProceduresExecution:  {
    width:'100%',
    height:150,
    borderRadius:10,
    padding:10,
    marginTop:10,
    textAlign:'justify',
    textAlignVertical:'top',
    backgroundColor:'#939393'
  },
  inputStyle:{
      width:'100%',
      height:50,
      borderRadius:10,
      padding:10,
      marginTop:10,
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#939393'
  },
  ButtonFinished: {
    backgroundColor:'#232b43',
    borderRadius:10,
    marginTop:10,
    paddingTop:10,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#989898',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  SearchInputSelected: {
    backgroundColor:'#939393',
    width:'80%',
    height:35,
    textAlign:'left',
    borderRadius:10,
    marginTop:5,
    paddingLeft:10
  },
  SearchInput: {
    backgroundColor:'#939393',
    width:'80%',
    height:35,
    textAlign:'left',
    borderRadius:10,
    marginTop:5,
    paddingLeft:10
  }

});

export default Create_New_Request
