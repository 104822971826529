import React, { useState, useContext, useEffect } from 'react';
import { ScrollView,TextInput, Text, View, StyleSheet, Button } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
import AwesomeAlert from 'react-native-awesome-alerts';
import Context from '../../../../context/Context'
  

const Update_Client =()=>  {

  const  {dataNavigationClients, updateRequest, setUpdateRequest}= useContext(Context);
  const Navigation = useNavigation();
  const [showAlert, setShowAlert]=useState(false);
  const [showAlertFinished, setShowAlertFinished]=useState(false);
  const [response, setResponse]=useState('');
  const [confirmRequest, setConfirmRequest]=useState(false);
  const [disableSubmit, setDisableSubmit]=useState(false);
  const [arrayClients, setArrayClients]=useState([]);

   
    
 
const sendRequest=()=>{
  if (dataNavigationClients.length>0) {
    //Get:
    executeRequest('/clients', {
      "Get_Clients": [
        {
          "body": {
            "_Type_Request": "Get_Clients_Detail",
            "_Client_ID":dataNavigationClients[0].clientChosen
          }
        }
      ]
    }, 'get', 'post').then(
          getData=>{
            setArrayClients(getData)
          }
        )
    } else { null }
  }
  
  useEffect(()=>{
      sendRequest();
  },[])  
    
  const sendRequestUpdateClient=(Name,NIT,Email,PhoneNumber,SupervisorName,SupervisorIdentification)=>{
      setDisableSubmit(true);
      //Put:
      executeRequest('/clients', {
        "Update_Client": [
          {
            "body": {
              "_NIT": NIT,
              "_Name": Name,
              "_Email": Email,
              "_PhoneNumber": PhoneNumber,
              "_Project_Supervisor_Name": SupervisorName,
              "_Supervisor_Identification": SupervisorIdentification,
              "_Client_ID": dataNavigationClients[0].clientChosen
            }
          }
        ]
      }, 'post', 'put').then(
            getData=>{
              setResponse(getData[0].Response);
              setShowAlertFinished(true);
              setUpdateRequest(!updateRequest); 
              setDisableSubmit(false);
            }
          )
    }

    return (

    <ScrollView style={styles.formContainer}>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Actualizar Cliente</Text>
</View>

      <Formik
        initialValues={{
          Name: '',
          NIT: '',
          Email: '',
          PhoneNumber: '',
          SupervisorName: '',
          SupervisorIdentification: ''
        }}
        onSubmit={values => {
          const Name=JSON.stringify(values["Name"])
          const NIT=JSON.stringify(values["NIT"])
          const Email=JSON.stringify(values["Email"])
          const PhoneNumber=JSON.stringify(values["PhoneNumber"])
          const SupervisorName=JSON.stringify(values["SupervisorName"])
          const SupervisorIdentification=JSON.stringify(values["SupervisorIdentification"])
          
          sendRequestUpdateClient(JSON.parse(Name), JSON.parse(NIT), JSON.parse(Email), JSON.parse(PhoneNumber), JSON.parse(SupervisorName), 
          JSON.parse(SupervisorIdentification))
        
        }}
        validationSchema={yup.object().shape({
            Name: yup
            .string()  
            .min(4, 'El nombre del cliente debe tener como minimo 4 caracteres')
            .max(40, 'El nombre del cliente no puede exceder los 40 caracteres'),
            
            NIT: yup
            .number('Este campo unicamente permite datos numericos')
            .min(100000000, 'El NIT debe contener mínimo 9 caracteres')
            .max(9999999999, 'El NIT debe contener máximo 10 caracteres'),
            Email: yup
            .string()
            .email('El campo E-mail debe ser un correo valido'),
            PhoneNumber: yup
            .number('Este campo unicamente permite datos numericos')
            .min(1000000, 'El número de teléfono debe contener minimo 7 caracteres')
            .max(9999999999, 'El número de teléfono debe contener máxico 10 caracteres'),
            SupervisorName: yup
            .string()
            .min(4, 'El nombre debe contener como minimo 4 caracteres')
            .max(30, 'El nombre debe contener máximo 30 caracteres'),
            SupervisorIdentification: yup
            .number('Este campo unicamente permite datos numericos')
            .min(1000000, 'El número de identificación debe contener como minimo 7 caracteres')
            .max(99999999999, 'El número de identificación debe contener como máximo 11 caracteres'),
        })}
       >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
<View style={styles.formContainer}>
          
<TextInput
              value={values.Name}
              style={styles.inputStyle}
              onChangeText={handleChange('Name')}
              onBlur={() => setFieldTouched('Name')}
              placeholder={(arrayClients[0] !== undefined) ? "".concat(arrayClients[0].name) : "Razón Social"}    
            />
            {touched.Name && errors.Name &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Name}</Text>
            }
 <TextInput
              value={values.NIT}
              style={styles.inputStyle}
              onChangeText={handleChange('NIT')}
              onBlur={() => setFieldTouched('NIT')}
              placeholder={(arrayClients[0] !== undefined) ? "NIT:  ".concat(arrayClients[0].nit) : "NIT"}      
            />
            {touched.NIT && errors.NIT &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.NIT}</Text>
            }
 <TextInput
              value={values.Email}
              style={styles.inputStyle}
              onChangeText={handleChange('Email')}
              onBlur={() => setFieldTouched('Email')}
              placeholder={(arrayClients[0] !== undefined) ? "".concat(arrayClients[0].email) : "Email"}  
            />
            {touched.Email && errors.Email &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Email}</Text>
            }
<TextInput
              value={values.PhoneNumber}
              style={styles.inputStyle}
              onChangeText={handleChange('PhoneNumber')}
              onBlur={() => setFieldTouched('PhoneNumber')}
              placeholder={(arrayClients[0] !== undefined) ? "Teléfono: ".concat(arrayClients[0].phoneNumber) : "Teléfono"}  
            />
            {touched.PhoneNumber && errors.PhoneNumber &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.PhoneNumber}</Text>
            }
<TextInput
              value={values.SupervisorName}
              style={styles.inputStyle}
              onChangeText={handleChange('SupervisorName')}
              onBlur={() => setFieldTouched('SupervisorName')}
              placeholder={(arrayClients[0] !== undefined) ? "Nombre Supervisor: ".concat(arrayClients[0].supervisorName) : "Nombre Supervisor"}       
            />
            {touched.SupervisorName && errors.SupervisorName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.SupervisorName}</Text>
            }
<TextInput
              value={values.SupervisorIdentification}
              style={styles.inputStyle}
              onChangeText={handleChange('SupervisorIdentification')}
              onBlur={() => setFieldTouched('SupervisorIdentification')}
              placeholder={(arrayClients[0] !== undefined) ? "Identificación: ".concat(arrayClients[0].supervisorIdentification) : "Número de identificación"}      
            />
            {touched.SupervisorIdentification && errors.SupervisorIdentification &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.SupervisorIdentification}</Text>
            }
{ (disableSubmit===true) ?  

<TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished  }>
    <Ionicons name="save" size={20} color="#f0f0f0" />
      <Text style={styles.TextStylesSave}>Procesando</Text>
      </TouchableOpacity>
            
          :
            
            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished  } disabled={!isValid} onPress={()=>{
          setShowAlert(true); 
              }
            }>
            <Ionicons name="save" size={20} color="#f0f0f0" />
              <Text style={styles.TextStylesSave}>Actualizar</Text>
              </TouchableOpacity>
          
          }

<View style={styles.ViewSpace}></View>
<AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
            setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
            setShowAlert(false); 
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />
            
          </View>

        )}

</Formik>

<AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
          }}
        />

              

</ScrollView>

    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
    backgroundColor:'#111111',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10,
    backgroundColor:'#c0c0c0',
  },
  formGroupDates: {
    flex:1,
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  DatesPicked: {
    textAlign:'left',
    justifyContent:'center',
    width:'50%'
  },
  DatesPickedMeasure: {
    textAlign:'left',
    justifyContent:'center',
    width:'48%',
    verticalAlign:'bottom',
    paddingTop:18
  },
  DatesInput: {
    alignItems:'center',
    marginTop:10,
    justifyContent:'center',
    borderRadius:10,
    width:'50%',
    backgroundColor:'white'
  },
  DropDownSelection: {
    marginTop:10,
    marginBottom:10,
    backgroundColor:'white',
    borderRadius:15,
    padding:10,
  },
  MeasureSelection: {
    margin:5,
    marginBottom:10,
    backgroundColor:'white',
    borderRadius:10,
    width:'48%'
  },
  TittleScreen:{
    color:'#fff',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#616161',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10
  },
  ButtonCreateProject: {
    backgroundColor:'#4d83a5',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonFinished: {
    backgroundColor:'#1F1F20',
    borderRadius:10,
    marginTop:10,
    paddingTop:15,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#bccdd4',
    borderRadius:10,
    marginTop:10,
    paddingTop:15,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonCenterTeam: {
    backgroundColor:'#373737',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  TextStylesSave: {
    color:'#f0f0f0',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  TextStyleLoading: {
    color:'#fa93b4',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewSpace: {
    marginTop:10,
    width:'100%'
  },
 FormStylesText:{
   color:'#f1f1f1',
   marginTop:12
 },
  inputStyle:  {
      width:'100%',
      height:50,
      borderRadius:10,
      padding:10,
      marginTop:10,
      textAlign:'justify',
      textAlignVertical:'center',
      backgroundColor:'#dbd9d9'
    },
    inputStyleProceduresExecution:  {
      width:'100%',
      height:150,
      borderRadius:10,
      padding:10,
      marginTop:10,
      textAlign:'justify',
      textAlignVertical:'top',
      backgroundColor:'#dbd9d9'
    },
    ProceduresExecution:{
      marginTop:10,
      color:'#f9f9f9',
      width:'100%'
    },
    Signature:{
      marginTop:10,
      marginLeft:20,
      marginRight:20,
      backgroundColor:'white',
      borderRadius:10,
      height:40,
      paddingLeft:10,
      textAlign:'center'
    },
    response:{
      color:'#f9f9f9'
    }
});

export default Update_Client;



