import React, {useState, useEffect, useContext} from 'react'
import { StyleSheet,Text, View, TextInput, ScrollView } from 'react-native'
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import SvgIconLogo from '../../../svg_components/SvgIconLogo';
import { AntDesign } from '@expo/vector-icons';
import Context from '../../../../context/Context';

//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
  

const Clients_Created = () => {

  const  {setDataNavigationClients, updateRequest}= useContext(Context);
  const [arrayClients, setArrayClients]=useState([]);
  const Navigation = useNavigation();
  //Search on Table:
  const [search, setSearch]=useState('0');


 
;


const Projects_Created_By_Client=(e, index)=>{
    return (
      <TouchableOpacity style={styles.Container} key={index} onPress={()=>{
        setDataNavigationClients([{clientChosen:e.id}]);
        Navigation.navigate('Client');
      }}> 
      <SvgIconLogo />
      <Text style={styles.TextScreen}>{e.name}</Text>
      <Text style={styles.TextScreenLow}>{e.nit}</Text>
      </TouchableOpacity>
    )
}

  const sendRequest=()=>{
      //Get:
      executeRequest('/clients', {
        "Get_Clients": [
          {
            "body": {
              "_Type_Request": "Get_Clients",
              "_Client_ID":null
            }
          }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayClients(getData)
            }
        )
    }
     
    useEffect(()=>{
        sendRequest()
    },[])
    
    useEffect(()=>{
      sendRequest()
  },[updateRequest])
    

    const showData=()=>{
      if (arrayClients !== null && (arrayClients.length)>0) {
      return (
          <>
          {
          (search!=='0') 
          &&
          arrayClients.filter((e)=>(e.name.toLowerCase().includes((search).toLowerCase()))).map((e, index)=>(
            Projects_Created_By_Client(e, index)
            )) 
          }
          { 
          (search!=='0') 
          &&
          arrayClients.filter((e)=>(e.nit.toLowerCase().includes((search).toLowerCase()))).map((e, index)=>(
            Projects_Created_By_Client(e, index)
            )) 
          }
          {
          (search==='0' || search==='' || search=== null || search=== undefined) &&
           arrayClients.map((e, index)=>(
            Projects_Created_By_Client(e, index)
          ))
           }
          </>
        )
      } else { null }
    }



    return (
      <View style={styles.RootContainer}>
      <View style={styles.MainContainer}>
      <View style={styles.TitleView}>
      <Text style={styles.TitleScreen}>Clientes</Text>
      </View>
      <Text style={styles.TextHigh}>Especifica el NIT o el nombre del cliente para efectuar una búsqueda personalizada:</Text>
    <View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder='NIT o Razón Social' style={styles.SearchInput}
     onChangeText={(e) => {
    (e==='' || e=== null || e=== undefined)?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    <ScrollView style={styles.Scroller}>
      <View style={styles.boxContainer}>
      {showData()}
      </View>
    </ScrollView>

      </View>
      </View>
    )


  }

  const styles = StyleSheet.create({
  
    RootContainer: {
      flex:1,
      backgroundColor:'#111111',
    },
    MainContainer: {
      flex:1, 
    },
    boxContainer:{
      flex:1,
      justifyContent:'space-between',
      flexWrap:'wrap',
      flexDirection: 'row',
      alignItems:'center',
      marginLeft:10,
      marginRight:10
    },
    Container:{
      alignItems:'center',
      justifyContent:'center',
      borderRadius:15,
      width:'49%',
      height:100,
      marginTop:10,
      backgroundColor:'#151515',
      elevation: 10,
      paddingLeft:10,
      paddingRight:10,
      paddingBottom:10,
      shadowColor: '#ffffff',
    },
    TextScreen: {
      color: '#E6EFF3',
      fontWeight: 'bold',
      fontSize: 10
    },
    TextScreenLow: {
      color: '#CBEEFF',
      fontWeight: 'bold',
      fontSize: 12
    },
    TextView: {
      color: '#2f2f2f',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop:10
    },
    InProgress: {
      color:'#04620f',

    },
    Finished: {
      color:'#343434',
    },
    ContainerSearch: {
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    },
    SearchInput: {
      backgroundColor:'#767a7a',
      width:'75%',
      height:25,
      textAlign:'left',
      borderRadius:10,
      marginTop:40,
      paddingLeft:10,
      marginLeft:30
    },
    IconSearch:{
      width:'10%',
      marginTop:40,
      marginLeft:20
    },
    TextHigh: {
      textAlign:'left',
      color:'#EDF0F5',
      paddingTop:'5%',
      paddingLeft:'5%',
      paddingRight:'5%',
      marginBottom:5
    },
    TitleScreen:{
      color:'#61AAE3',
      margin:10,
      fontSize:22,
      fontWeight:'bold',
      textAlign:'center'
    },
    TitleView:{
      justifyContent:'center',
      textAlign:'center',
      width:'100%'
    }
  });  

export default Clients_Created
