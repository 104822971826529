import 'react-native-gesture-handler';
import { NavigationContainer } from '@react-navigation/native';
import HomeNavigation from './src/navegation/HomeNavigation';
import React, {useEffect} from 'react'; 
//Provider Context
import Provider from './src/context/Provider';
import { debugData } from './src/views/functional_global_components/LocalStorageManager';


 const App =() => {

  useEffect(()=>{
    const wipeData = async () => {
      await debugData();
    }
    wipeData();
  },[]);

  return (
    <Provider>
    <NavigationContainer> 
     <HomeNavigation/>
     </NavigationContainer>
     </Provider>
  
  );
}

export default App;
