import React, {useContext} from 'react'
import { StyleSheet,View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import { StatusBar } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import Context from '../../../context/Context'

const Requests = () => {
  
  const Navigation = useNavigation();
  const  {dataSession}= useContext(Context);



{/* 37ec1a36-f457-11ed-b65a-0697dd88345b	7ì 6ôW í¶Z  Ý 4[	Administrativo
69fa5f62-f453-11ed-b65a-0697dd88345b	iú_bôS í¶Z  Ý 4[	Gerencia
69fb4a85-f453-11ed-b65a-0697dd88345b	iûJ ôS í¶Z  Ý 4[	Auditor
69fb4d26-f453-11ed-b65a-0697dd88345b	iûM&ôS í¶Z  Ý 4[	Bodega
69fb4dac-f453-11ed-b65a-0697dd88345b	iûM¬ôS í¶Z  Ý 4[	Técnico Lider
69fb83e1-f453-11ed-b65a-0697dd88345b	iû áôS í¶Z  Ý 4[	Técnico
 */}


  return (
    <View style={styles.Root}>
    <View style={styles.RootContainer}>
    <Text style={styles.TitleScreen}>{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") ? "Administrar Solicitudes" : "Solicitudes"}</Text>
    <View style={styles.MainContainer}>
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") &&
    <TouchableOpacity style={styles.Container} onPress={()=> Navigation.navigate('Requests_Created')}> 
    <MaterialCommunityIcons name="wall" size={30} color="#ffe3ab" />
      <Text style={styles.TextView}>Consultar Solicitudes</Text>
    </TouchableOpacity>
}
{(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") &&
    <TouchableOpacity style={styles.Container} onPress={()=> Navigation.navigate('Requests_Created')}> 
    <MaterialCommunityIcons name="wall" size={30} color="#ffe3ab" />
      <Text style={styles.TextView}>Ver Mis Solicitudes</Text>
    </TouchableOpacity>
}

{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") &&
    <TouchableOpacity style={styles.ContainerNewProject} onPress={()=> Navigation.navigate('Create_New_Request')} >
    <Ionicons name="ios-construct" size={24} color="#dfdfdf" />
    <Text style={styles.TextView}>Nueva Solicitud</Text>
    </TouchableOpacity>
}
    </View>
    <StatusBar barStyle="light-content" backgroundColor="black" />
    
    </View>
    </View>
  )
}

const styles = StyleSheet.create({
  Root:{
    backgroundColor:'black',
    flex:1,
  },
  RootContainer: {
    flex:1,
    width:'100%',
    padding:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#111111',
    borderTopLeftRadius:30,
    borderTopRightRadius:30
  },
  MainContainer: {
    flex:1,
     width:'70%',
  },
  Container:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#555555',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerNewProject:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#252937',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerTeams:{
    alignItems:'center',
    justifyContent:'center',
    borderRadius:10,
    width:'100%',
    height:100,
    marginTop:20,
    elevation: 20,
    backgroundColor:'#aaaaa8',
    shadowColor: '#aaaaa8',
  },
  ContainerRequest:{
    alignItems:'center',
    justifyContent:'center',
    borderRadius:10,
    width:'100%',
    height:100,
    marginTop:80,
    elevation: 20,
    backgroundColor:'#4aa9a9',
    shadowColor: '#4aa9a9',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 14
  },

  TextView: {
    color: '#e0e0f6',
    fontWeight: 'bold',
    fontSize: 16,
    marginTop:10,
    fontWeight: 'bold'
  },
  TitleScreen:{
    color:'#b4f1ff',
    margin:25,
    fontSize:20,
    fontWeight:'bold',
    textAlign:'center'
  },
});


export default Requests


