import React, { useState,Component, useEffect, useContext } from 'react';
import { ScrollView,TextInput, Text, Button, Alert, View, StyleSheet, Platform } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import { DatePickerModal, TimePickerModal, es, registerTranslation } from 'react-native-paper-dates'
registerTranslation('es', es);
import { AntDesign } from '@expo/vector-icons'; 
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import Context from '../../../../context/Context'
import AwesomeAlert from 'react-native-awesome-alerts';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";

const Schedule_New_Audit =()=>  {

  const Navigation = useNavigation();
  const {identificationUser} = useContext(Context);

    //Alerts:
    const [showAlert, setShowAlert]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [response, setResponse]=useState('');
    const [disableSubmit, setDisableSubmit]=useState(false);


    const [dateAudit, setDateAudit]= useState('');
    const [startHour, setStartHour]= useState('');
    const [endHour, setEndHour]= useState('');
    const [openModalDateAudit, setOpenModalDateAudit]=useState(false);
    const [openModalStartHour, setOpenModalStartHour]=useState(false);
    const [openModalEndtHour, setOpenModalEndHour]=useState(false);


  const onDismissSingle = () => {
    setOpenModalDateAudit(false);
    setOpenModalStartHour(false);
    setOpenModalEndHour(false);
  }

  const onConfirmDateAudit = (params) => {
      setOpenModalDateAudit(false);
      const date = params.date;
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      setDateAudit(formattedDate);
    }

    const onConfirmStartHour = ({ hours, minutes }) => {
      setOpenModalStartHour(false);
      setStartHour(''.concat(hours,':',minutes));
    }

    const onConfirmEndHour = ({ hours, minutes }) => {
      setOpenModalEndHour(false);
      setEndHour(''.concat(hours,':',minutes));
    }


     

const sendRequestScheduleAudit=(auditName, observations)=>{
          setDisableSubmit(true);
          setShowAlert(false);  
          //Create:
          executeRequest('/audits/schedule', {
            "Schedule_Audit": [
               {
                "body": {
                  "_Audit_Name": (auditName.length>0) ? auditName : null,
                  "_Date_Audit": (dateAudit.length>0) ? dateAudit : null,
                  "_Start_Hour": (startHour.length>0) ? startHour : null,
                  "_End_Hour":   (endHour.length>0) ? endHour : null,
                  "_Initial_Observations": (observations.length>0) ? observations : null,
                  "_User_Creator_ID": (identificationUser[0] !== undefined) ? identificationUser[0]["id"] : null
                }
              }
            ]
          }, 'post', 'post').then(
                getData=>{
                  setResponse(getData[0].Response)
                  setShowAlertFinished(true);
                  setDisableSubmit(false);
                }
              )
        }

    return (

    <ScrollView style={styles.formContainer}>

<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Programar Auditoría</Text>
</View>
      <Formik
        initialValues={{
          auditName: '',
          observations: '',
        }}
        onSubmit={values => {
          const auditName=JSON.stringify(values["auditName"]);
          const observations=JSON.stringify(values["observations"]);
          sendRequestScheduleAudit(JSON.parse(auditName), JSON.parse(observations));
        }}
        validationSchema={yup.object().shape({
            auditName: yup
            .string() 
            .min(4, 'El nombre de la auditoría debe tener como minimo 4 caracteres')
            .max(50, 'El nombre de la auditoría debe tener como máximo 50 caracteres')
            .required('Por favor especifique un nombre para la auditoría'),
            observations: yup
            .string()
            .min(4, 'Las observaciones iniciales deben tener como minimo 4 caracteres')
            .max(300, 'Las observaciones iniciales no pueden superar los 300 caracteres')
        })}
       >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <View style={styles.formContainer}>
         
{/*Implement Date Picker: */}
<View style={styles.formGroupDates}>
<Text style={styles.TextStylesWords}>Por favor indique un nombre para la auditoría:</Text>
<TextInput style={styles.InputAuditoryName} placeholder='Ejemplo: Auditoria 001 Marzo 2023'
value={values.auditName}
onChangeText={handleChange('auditName')}
onBlur={() => setFieldTouched('auditName')}
/>
{touched.auditName && errors.auditName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.auditName}</Text>
            }

<Text style={styles.TextStylesWords}>Especifique una fecha para programar la auditoría:</Text>
<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}  title='Select_Date' onPress={() => setOpenModalDateAudit(true)}>Fecha Auditoría   <AntDesign name="calendar" size={24} color="#829dd3" /></Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={dateAudit}   
             style={styles.inputStyle}
             editable={false}
             onChangeText={handleChange('Team')}
             selectTextOnFocus={false}
             placeholder="mm/dd/aaaa"
             />
</View>
</View>
<View style={styles.formGroupDates}>
<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}  title='Select_Date' onPress={() => setOpenModalStartHour(true)}>Hora Inicio   <AntDesign name="calendar" size={24} color="#829dd3" /></Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={startHour}   
             style={styles.inputStyle}
             editable={false}
             onChangeText={handleChange('Team')}
             selectTextOnFocus={false}
             placeholder="00:00"
             />
</View>
</View>
<View style={styles.formGroupDates}>
<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}  title='Select_Date' onPress={() => setOpenModalEndHour(true)}>Hora Finalización   <AntDesign name="calendar" size={24} color="#829dd3" /></Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={endHour}   
             style={styles.inputStyle}
             editable={false}
             onChangeText={handleChange('Team')}
             selectTextOnFocus={false}
             placeholder="00:00"
             />
</View>
</View>
{/*End Implement Date Picker */}

     
<Text style={styles.TextStylesWords}>Especifica acontinuación las observaciones iniciales correspondientes a la auditoría que se realizará en la fecha estipulada:</Text>
<TextInput  multiline={true} numberOfLines={4} style={styles.TextStylesInput} placeholder='Ejemplo:(Validación interna a nivel de productos existentes en acompañamiento de gerencia.)'
value={values.observations}
onChangeText={handleChange('observations')}
onBlur={() => setFieldTouched('observations')}
/>
{touched.observations && errors.observations &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.observations}</Text>
            }

{ (disableSubmit===true) ?  
       <TouchableOpacity style={styles.ButtonDisabled}>
       <View style={styles.ButtonDisabled}>
       <FontAwesome name="group" size={24} color="white" />
         <Text style={styles.TextStylesFinished}>Procesando</Text>
         </View> 
         </TouchableOpacity>
      :           
            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonCenterTeam} disabled={!isValid} onPress={()=>{setShowAlert(true);}}>
           
            <FontAwesome name="group" size={24} color="white" />
              <Text style={styles.TextStylesFinished}>Programar Auditoría</Text>
               
              </TouchableOpacity>
}

    
<View style={styles.ViewSpace}></View>

<AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />   


          </View>
        )}
      </Formik>

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.navigate('Main_Audit');
          }}
        />

<DatePickerModal
          locale="es"
          mode="single"
          visible={openModalDateAudit}
          onDismiss={onDismissSingle}
          date={dateAudit}
          onConfirm={onConfirmDateAudit}
        />
      <TimePickerModal
          confirmLabel='Aceptar'
          cancelLabel='Cancelar'
          label='Seleccionar'
          visible={openModalStartHour}
          onDismiss={onDismissSingle}
          onConfirm={onConfirmStartHour}
          use24HourClock={false}
        />
      <TimePickerModal
          confirmLabel='Aceptar'
          cancelLabel='Cancelar'
          label='Seleccionar'
          visible={openModalEndtHour}
          onDismiss={onDismissSingle}
          onConfirm={onConfirmEndHour}
          use24HourClock={false}
          
        />
    </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
    backgroundColor:'#111111',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10,
    backgroundColor:'#c0c0c0',
  },
  formGroupDates: {
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  DatesPicked: {
    textAlign:'left',
    justifyContent:'center',
    width:'65%',
    height:60,
  },
  DatesInput: {
    alignItems:'center',
    justifyContent:'center',
    borderRadius:30,
    width:'35%',
    height:60,
  },
  DropDownSelection: {
    marginTop:10
  },
  TittleScreen:{
    color:'#fff',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#616161',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10
  },
  ButtonCreateProject: {
    backgroundColor:'#4d83a5',
    marginTop:10,
    paddingTop:5,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonCenterTeam: {
    backgroundColor:'#373737',
    marginTop:10,
    borderRadius:10,
    padding:5,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewSpace: {
    marginTop:10,
    width:'100%'
  },
 FormStylesText:{
   color:'#f1f1f1'
 },
 TextStylesWords:{
    marginTop:5,
    color:'#f1f1f1',
    width:'100%'
  },
 TextStylesInput:{
  backgroundColor:'#bcc0c8',
  width:'100%',
  paddingLeft:10,
  paddingRight:10,
  height:200, 
  textAlignVertical: 'top',
  height:100,
  marginTop:10,
  marginBottom:10,
  paddingTop:10,
  textAlign:'justify'
 },
 InputAuditoryName:{
  width:'100%',
  height:35,
  borderRadius:10,
  padding:10,
  marginTop:10,
  alignItems:'center',
  justifyContent:'center',
  backgroundColor:'#dbd9d9'
 },
  inputStyle: {
  width:'100%',
  height:50,
  borderRadius:10,
  padding:10,
  marginTop:10,
  alignItems:'center',
  justifyContent:'center',
  color:'#e1efff',
  backgroundColor:'#363636'
},
ButtonDisabled: {
  backgroundColor:'#bccdd4',
  marginTop:10,
  borderRadius:10,
  padding:5,
  alignItems:'center',
  justifyContent:'center'
},
TextStyleLoading: {
  color:'#fa93b4',
  margin:10,
  fontSize:16,
  fontWeight:'bold',
  textAlign:'center'
},
});

export default Schedule_New_Audit
