import React, { useState, useContext, useEffect } from 'react';
import { ScrollView,TextInput, Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { FlatList } from 'react-native-gesture-handler'
import { MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Context from '../../../context/Context';
//For making requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
import AwesomeAlert from 'react-native-awesome-alerts';
  

const Request =()=>  {

  
    const [showAlert, setShowAlert]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const  {dataNavigationRequest, identificationUser, updateRequest, setUpdateRequest, dataSession}= useContext(Context);
    const [arrayRequests, setArrayRequests]=useState([]);
    const [response, setResponse]=useState('');
    const [disableSubmit, setDisableSubmit]=useState(false);

    const [alertDeleteRequest, setAlertDeleteRequest]=useState(false);
    const [showAlertDeleteFinished, setShowAlertDeleteFinished]=useState(false);



    const Navigation = useNavigation();

     
    ;
    


    const sendRequest=()=>{
        //Get:
        executeRequest('/requests', {
          "Get_Requests": [
            {
              "body": {
                "_Type_Request": "Get_Requests_Detailed",
                "_Request_ID": dataNavigationRequest[0].requestChosen,
                "_User_ID": null
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayRequests(getData)
              }
            )
      }

      useEffect(()=>{
        sendRequest();
    },[]);
  
    useEffect(()=>{
      sendRequest();
  },[updateRequest]);

    const approveRequest=()=>{
      
        setDisableSubmit(true);
        setShowAlert(false);
        //Put:
        executeRequest('/requests/approve', {
          "Approve_Request": [
            {
              "body": {
                "_User_Responsable_Checker_ID": identificationUser[0]["id"],
                "_Request_ID": dataNavigationRequest[0].requestChosen
              }
            }
          ]
        }, 'post', 'put').then(
              getData=>{
                setResponse(getData[0].Response);
                setDisableSubmit(false);
                setShowAlertFinished(true);
                setUpdateRequest(!updateRequest);
              }
          )
      }


  const deleteRequest=()=>{
            setDisableSubmit(true);
            setAlertDeleteRequest(false);
            //Delete:
            executeRequest('/requests', {
              "Delete_Request": [
                {
                  "body": {
                    "_Request_ID": dataNavigationRequest[0].requestChosen
                  }
                }
              ]
            }, 'post', 'delete').then(
                  getData=>{
                    setResponse(getData[0].Response);
                    setShowAlertDeleteFinished(true);
                    setDisableSubmit(false);
                  }
            )
      }


const listArrayItems= ({item})=>{


        return (
          <View style={styles.ViewRows}>
            <View style={styles.Row}>
              <Text style={styles.TextRows}>{item.itemName}</Text>
            </View>
            <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.quantity}</Text>
            </View>
            <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.measure}</Text>
            </View>
            <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.itemType}</Text>
             </View>
             <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.existsOnStore}</Text>
             </View>
             <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.quantityExisting}</Text>
             </View>
            </View>

        )
      
      }

if (dataNavigationRequest !== null && arrayRequests.length>0) {
    return (
    
<View style={styles.MainContainer}>    
<ScrollView>
<View style={styles.formContainer}>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>
{arrayRequests[0].requestTitle}
</Text>
</View>
    
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Nombre solicitante:</Text> 
        </View>
        <View  style={styles.TextRight} >
                     <Text style={styles.textRightStyle}>{arrayRequests[0].fullName}</Text>
        </View>
        </View>
        
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Rol asociado:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].rol}</Text>
        </View>
</View>
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Fecha solicitud:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].dateRequest}</Text>
        </View>
</View>
<View style={styles.formGroup}>
<Text style={styles.titleJustification}>Justificación</Text> 
</View>
<View style={styles.formGroup}>
  <Text style={styles.justification}>{arrayRequests[0].justification}</Text>
  </View>  
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Proyecto Asociado:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].nameProject}</Text>
        </View>
</View>
{(arrayRequests[0].nameSubProject !== null) ? 
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>SubProyecto Solicitud:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyleSubProject}>{arrayRequests[0].nameSubProject}</Text>
        </View>
</View>
: null}

<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Cliente:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].clientName}</Text>
        </View>
</View>
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>NIT:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].NIT}</Text>
        </View>
</View>
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Estado solicitud:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].state}</Text>
        </View>
</View>

</View>
                
        <View style={styles.TableStyles}> 
        <ScrollView horizontal={true}  nestedScrollEnabled={true}>  
        <FlatList 
            data={arrayRequests}
            renderItem={listArrayItems} 
            ListHeaderComponent={()=>{
                return(
                  <View style={styles.ViewRows}>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Item</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cantidad Requerida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>U. Medida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Tipo</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>¿Existe en Bodega?</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cant. Existente</Text>
                        </View>
                  </View>
                      )}}
                        />  
          </ScrollView>
        </View>
        


{/* 37ec1a36-f457-11ed-b65a-0697dd88345b	7ì 6ôW í¶Z  Ý 4[	Administrativo
69fa5f62-f453-11ed-b65a-0697dd88345b	iú_bôS í¶Z  Ý 4[	Gerencia
69fb4a85-f453-11ed-b65a-0697dd88345b	iûJ ôS í¶Z  Ý 4[	Auditor
69fb4d26-f453-11ed-b65a-0697dd88345b	iûM&ôS í¶Z  Ý 4[	Bodega
69fb4dac-f453-11ed-b65a-0697dd88345b	iûM¬ôS í¶Z  Ý 4[	Técnico Lider
69fb83e1-f453-11ed-b65a-0697dd88345b	iû áôS í¶Z  Ý 4[	Técnico
 */}


{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b"
|| dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") &&
<View style={styles.alignButtons}>
{(disableSubmit === true) ? 
 <View style={styles.ButtonLeftAdmin}>
         <TouchableOpacity style={styles.ButtonStylesApproval}>
        <MaterialIcons name="update" size={20} color="#8bd6c2" />
          <Text style={styles.TextStylesAdmin}>Procesando</Text>
          </TouchableOpacity>
       </View> 
       : 
       <View style={styles.ButtonLeftAdmin}>
         <TouchableOpacity style={styles.ButtonStylesApproval} onPress={()=>{
          setShowAlert(true)
         }}>
        <MaterialIcons name="update" size={20} color="#8bd6c2" />
          <Text style={styles.TextStylesAdmin}>Aprobar</Text>
          </TouchableOpacity>
       </View> 
       }
        <View style={styles.ButtonCenterAdmin}>
        <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=> {
          Navigation.navigate('Update_Request')}}>
       <MaterialIcons name="update" size={20} color="#8bd6c2" />
         <Text style={styles.TextStylesAdmin}>Actualizar</Text>
          </TouchableOpacity>
        </View> 
         <View style={styles.ButtonRightAdmin} >
       <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=> {
          setAlertDeleteRequest(true)
        }}>
        <AntDesign name="delete" size={20} color="#8bd6c2" />
          <Text style={styles.TextStylesAdmin}>Eliminar</Text>
          </TouchableOpacity>
  </View>
  </View>
}
  <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="=¿Estas seguro de aprobar la solicitud?"
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            approveRequest();
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        /> 

<AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
          }}
        />


<AwesomeAlert
          show={alertDeleteRequest}
          showProgress={false}
          title="Confirma cambios"
          message="=¿Estas seguro de que deseas eliminar la Solicitud?, para confirmar los cambios presiona el botón [Continuar]"
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setAlertDeleteRequest(false); 
          }}
          onConfirmPressed={() => {
            deleteRequest();
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        /> 

<AwesomeAlert
          show={showAlertDeleteFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertDeleteFinished(false);
            Navigation.navigate('Requests');
          }}
        />




      </ScrollView>
      </View>
    ); } 
    
    else { 

      return (
<View style={styles.MainContainer}>
        <ScrollView>
           
        <View style={styles.formContainer}>
        <View style={styles.ViewTittleScreen}>
        <Text style={styles.TittleScreen}>
        ___________________
        </Text>
        </View>
            </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Nombre solicitante:</Text> 
                </View>
                <View  style={styles.TextRight} >
                             <Text style={styles.textRightStyle}>___________________</Text>
                </View>
                </View>
                
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Rol asociado:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Fecha solicitud:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Justificación:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Proyecto:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Cliente:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>NIT:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Estado solicitud:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>

        
        
              </ScrollView>
              </View>
            );
     } 
  }

const styles = StyleSheet.create({
  MainContainer:{
    backgroundColor:'#0d0d0d',
    justifyContent:'center',
    flex:1,
    flexDirection:'row'
  },

  formContainer: {
    padding: 10
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10
  },
  FormStylesText:{
    color:'#f1f1f1'
  },
  formGroup: {
    justifyContent:'center',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'center',
    width:'100%'
  },
  TextRight: {
    alignItems:'flex-end',
    justifyContent:'flex-end',
    width:'50%',
    color:'#f9f9f9'
  },
  TextLeft: {
    textAlign:'left',
    justifyContent:'center',
    width:'40%'
  },
  DropDownSelection: {
    marginTop:10
  },
  TittleScreen:{
    color:'#7be8d4',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },

  ViewTittleScreen:{
    backgroundColor:'#202020',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:40
  },
  ButtonCenterFinished: {
    backgroundColor:'#4d83a5',
    marginLeft:40,
    marginRight:40,
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  Row:{
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  
  ContainerSearch: {
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'center',
  },
  SearchInput: {
    backgroundColor:'#767a7a',
    width:'70%',
    textAlign:'left',
    borderRadius:10,
    marginTop:40,
    paddingLeft:10,
    marginLeft:30
  },
  IconSearch:{
    width:'10%',
    marginTop:40,
    marginLeft:20
  },
  TextHigh: {
    textAlign:'left',
    color:'#EDF0F5',
    paddingTop:'5%',
    paddingLeft:'5%',
    paddingRight:'5%',
    marginBottom:5
  },
  PropertiesView:{
    backgroundColor:'#111111',
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:5
  },
  
  Text:{
    width:120,
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
  },
  TextHeader:{
    color:'#B4DCEC',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TextRows:{
    color:'#f1f1f1',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TableStyles: {
    backgroundColor:'#181818',
    alignItems:'center',
    textAlign:'center',
    justifyContent:'center',
    marginTop:10,
    flexDirection:'row'
  },
  TextInput:{
    width:60,
    backgroundColor:'grey',
    borderRadius:7,
    color:'#f1f1f1',
    textAlign:'center',
    borderRightWidth:1,
    borderRightColor:'#393939',
    alignContent:'center',
    marginLeft:30,
    marginRight:30
  },
  ListSelectedContainer: {
    flex:1,
    flexDirection:'row',
    flexWrap:'wrap',
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
    marginTop:10
  },
  LeftSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    color:'#f1f1f1'
  },
  CenterSelect:{
    width:'18%',
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    marginTop:10,
    color:'#f1f1f1'
  },
  RightSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    color:'#f1f1f1'
  },

  LeftTitleSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  CenterTitleSelect:{
    width:'18%',
    textAlign:'center',
    borderRadius:10,
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  RightTitleSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  QuantitySelected:{
    width:120,
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    color:'#f1f1f1'
  },
  finalValues:{
    marginTop:20
  },
  textRightStyle:{
    color:'#89cff8'
  },
  textRightStyleSubProject:{
    color:'#ff6868'
  },
  titleJustification:{
    color:'#ffa9a9',
    marginTop:25,
    fontSize:16,
    fontWeight:'bold'
  },
  justification:{
    color:'#eaf4ff',
    margin:10,
    textAlign:'left'
  },
    ButtonLeftAdmin: {
    textAlign:'center',
    width:'30%',
    paddingTop:15,
    marginBottom:10
    
  },
  ButtonCenterAdmin: {
    textAlign:'center',
    width:'30%',
    paddingTop:15,
    marginLeft:5,
    marginBottom:10
  },
  ButtonRightAdmin: {
    textAlign:'left',
    width:'30%',
    paddingTop:15,
    marginLeft:5,
    marginBottom:10
  },
  ButtonStylesApproval: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#2a2e32',
  },
  ButtonStylesUpdate: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#3c473a',
  },
  ButtonStylesDelete: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#473a3a',
  },
  TextStylesAdmin: {
    fontSize: 14,
    color: '#cdcdcd',
  },
  alignButtons:{
    flex:1,
    justifyContent:'center',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'center',
    width:'100%'
  }
});

export default Request

