import React, {useContext, useState, useEffect} from 'react'
import { StyleSheet,View, Text, ScrollView, Platform } from 'react-native';
import { StatusBar } from 'react-native';
import {Projects_Management_Btn, Projects_In_Progress_Btn,Projects_Novelties_Btn,Teams_Management_Btn, Reports_Inventory_Btn, Report_Access_Store_Btn, Reports_Auditory_Btn, Reports_Management_Btn} from './components_ui/Components_Reports';
import Context from '../context/Context';
import AwesomeAlert from 'react-native-awesome-alerts';
//Export to Excel And Sharing:
import * as XLSX from 'xlsx';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';

//For making requests:
import { executeRequest } from "../views/functional_global_components/API_Requests";
  

const Reportes = () => {
  const [arrayReport, setArrayReport]=useState([]);
  const [arrayColumns, setArrayColumns]=useState([]); 
  const [arrayRows, setArrayRows]=useState([]);
  const [messageReport, setMessageReport]=useState('');
   
    

  const [showAlertFinished, setShowAlertFinished]=useState(false);
  const {setDisconnectSession, dataSession, setShowAlertReport ,showAlertReport, endPointReport}= useContext(Context);

  const sendRequest=()=>{
    setShowAlertReport(false);  
    //Rpt:
    executeRequest(endPointReport[0].endPoint, {
      "Get_Reports": [
        {
          "body": {
            "_Type_Request": endPointReport[0].typeRequest
          }
        }
      ]
    }, 'rpt', 'post').then(
          getData=>{
            setArrayReport(getData);
            if (getData.length===0) {
              setMessageReport("No existe información en la báse de datos para generar el reporte.");
              setShowAlertFinished(true);
            }
          }
        )
    }


useEffect(() => {
        if (arrayReport.length>0) {
          setArrayColumns(Object.keys(arrayReport[0])); /*["Tipo", "Nombre_Proyecto", "Descripción", "Cliente", "NIT", "Nombre_Supervisor", "Identificación", "Ciudad", "Dirección", "Ubicación", "Fecha_Estimada_Inicio_Obra", "Fecha_Estimada_Finalización_Obra", "Equipo_Asignado", "Acta_Inicio_Obra", "Acta_Finalización_Obra", "Cotización_Obra", "Estado", "Fecha_Creación", "Fecha_Finalización", "Usuario_Creador"]*/
         } 
      }, [arrayReport]);


useEffect(() => {
        if (arrayReport.length>0 && arrayColumns.length>0) {
          setArrayRows([arrayColumns]);  /*Saving headers in a new array, after this step we iterate all results*/
          for (let i=0; i <  arrayReport.length; i++)  {
            setArrayRows(e=>[...e, Object.values(arrayReport[i])]);
            };
         }
      }, [arrayColumns]);
      
useEffect(() => {
        if (arrayReport.length>0 && arrayColumns.length>0 && arrayRows.length>0) {
          generateExcel();
            };
      }, [arrayRows]);
      
       



const generateExcel = () => {

        if (arrayReport.length>0) {
          let wb = XLSX.utils.book_new();
          let ws = XLSX.utils.aoa_to_sheet(
            arrayRows
          );
          XLSX.utils.book_append_sheet(wb, ws, "Reporte", true);

  /*On Android And IOS: */
  if (Platform.OS==='android' || Platform.OS==='ios'){
          const base64 = XLSX.write(wb, { type: "base64" });
          const filename = FileSystem.documentDirectory + endPointReport[0].rptName +".xlsx";
          FileSystem.writeAsStringAsync(filename, base64, {
            encoding: FileSystem.EncodingType.Base64
          }).then(() => {
            Sharing.shareAsync(filename);
                });
      } else if (Platform.OS==='web'){
          /* generate XLSX file and send to client */
          XLSX.writeFile(wb, endPointReport[0].rptName.concat(".xlsx"));
          setMessageReport("El reporte " + endPointReport[0].rptName + " fue generado exitosamente.");
          setShowAlertFinished(true);
              }; 
          } 

      }

  if (dataSession["user"] != undefined && dataSession["IdentificationCode"] != undefined) {
  return (
    <ScrollView style={styles.Root}>
       {/* Show Title to Gerencia, Administrativo: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") &&
    <Text style={styles.TitleScreen}>Gestión de Proyectos</Text>
    }
    <View style={styles.RootContainer}>
    <View style={styles.MainContainer}>
    {/* Allow access to Gerencia: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
    <Projects_Management_Btn/>
    }
    {/* Allow access to Gerencia && Administrativo: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") &&
    <>
    <Projects_In_Progress_Btn/>
    <Projects_Novelties_Btn/>
    </>
    }
    {/* Allow access to Gerencia && Administrativo: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") &&
    <>
    <Text style={styles.TitleScreen}>Equipos</Text>
    <Teams_Management_Btn/>
    </>
    }
    {/* Allow access to Gerencia, Administrativo, Bodega And Auditor: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b" 
    || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4a85-f453-11ed-b65a-0697dd88345b") &&
    <>
    <Text style={styles.TitleScreen}>Inventario</Text>
    <Reports_Inventory_Btn/>
    <Report_Access_Store_Btn/>
    </>
    }

    {/* Allow access to Gerencia, Administrativo and Auditor: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4a85-f453-11ed-b65a-0697dd88345b") &&
    <>
    <Text style={styles.TitleScreen}>Auditoria</Text>
    <Reports_Auditory_Btn/>
    </>
    }
    {/* Allow access to Gerencia, Administrativo And Auditor: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4a85-f453-11ed-b65a-0697dd88345b") &&
    <>
    <Text style={styles.TitleScreen}>Administración</Text>
    <Reports_Management_Btn/>
    </>
    }

    </View>

    <StatusBar barStyle="light-content" backgroundColor="black" />
    
    </View>


    <AwesomeAlert
        show={showAlertReport}
        showProgress={false}
        title="Generar Reporte"
        message={"Se realizará la descarga del reporte " + endPointReport[0].rptName +  ", si estas seguro, por favor confirma la solicitud."}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancelar"
        confirmText="Si, continuar"
        onCancelPressed={() => {
          setShowAlertReport(false); 
        }}
        onConfirmPressed={() => {
          sendRequest(); 
        }}
        titleStyle={{color:'#8DDBFF'}}
        messageStyle={{color:'#f9f9f9'}}
        contentContainerStyle={{backgroundColor:'#272727'}}
        confirmButtonColor='#809280'
        cancelButtonColor='#746E6E'
      />


      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={messageReport}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
          }}
        />



    </ScrollView>
  )} else {
  setDisconnectSession(true)
  }
}

const styles = StyleSheet.create({
  Root:{
    backgroundColor:'#111111',
    flex:1,
  },
  RootContainer: {
    flex:1,
    width:'100%',
    padding:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#111111',

  },
  MainContainer: {
    flex:1,
     width:'80%',
  },
  Container:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#555555',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerNewProject:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#252937',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 14
  },
  TextView: {
    color: '#666699',
    fontWeight: 'bold',
    fontSize: 16,
    marginTop:10,
    fontWeight: 'bold',
  },
  TitleScreen:{
    margin:20,
    textAlign:'center',
    fontWeight:'bold',
    fontSize:20,
    color:'#87e0ff'
  },

});


export default Reportes