import React, {useContext} from 'react';
import {StyleSheet,Text,TouchableOpacity} from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Fontisto } from '@expo/vector-icons';
import Context from '../../context/Context'

export const Projects_Created_Btn = () => {
    const Navigation = useNavigation();

    return (
        <>
        <TouchableOpacity style={styles.Container} onPress={()=> Navigation.navigate('Search_Projects_By_NIT')}> 
        <FontAwesome5 name="project-diagram" size={20} color="#8fe9f1" />
        <Text style={styles.TextView}>Proyectos Creados</Text>
        </TouchableOpacity>
        </>
        )
    }
export const Assigned_Projects_Btn = () => {
    const Navigation = useNavigation();

    return (
        <>
        <TouchableOpacity style={styles.Container} onPress={()=> Navigation.navigate('Search_Projects_By_NIT')}> 
        <FontAwesome5 name="project-diagram" size={20} color="#8fe9f1" />
        <Text style={styles.TextView}>Proyectos Asignados</Text>
        </TouchableOpacity>
        </>
        )
    }

export  const New_Project_Btn = () => {
    const Navigation = useNavigation();

        return (
            <>
            <TouchableOpacity style={styles.ContainerNewProject} onPress={()=> Navigation.navigate('Create_New_Project')} >
            <FontAwesome5 name="rocket" size={20} color="#8ff1bc" />
            <Text style={styles.TextView}>Nuevo Proyecto</Text>
            </TouchableOpacity>
            </>
            )
        }

export  const Teams_Btn = () => {
    const Navigation = useNavigation();
        
         return (
            <>
    <TouchableOpacity style={styles.ContainerTeams} onPress={()=> Navigation.navigate('Teams')} >
    <Fontisto name="persons" size={20} color="#76b7f1" />
    <Text style={styles.TextView}>Equipos</Text>
    </TouchableOpacity>
            </>
        )
    }

export  const My_Team_Btn = () => {
    const Navigation = useNavigation();
        
         return (
            <>
    <TouchableOpacity style={styles.ContainerTeams} onPress={()=> Navigation.navigate('Team')} >
    <Fontisto name="persons" size={20} color="#76b7f1" />
    <Text style={styles.TextView}>Mi Equipo de Trabajo</Text>
    </TouchableOpacity>
            </>
        )
    }

export  const Clients_Btn = () => {
        const Navigation = useNavigation();
             return (
                <>
        <TouchableOpacity style={styles.ContainerClients} onPress={()=> Navigation.navigate('Clients')} >
        <FontAwesome5 name="building" size={20} color="#f26e6e" />
        <Text style={styles.TextView}>Clientes</Text>
        </TouchableOpacity>
                </>
            )
        }

export  const Requests_Btn = () => {
    const Navigation = useNavigation();
    const  {setDisconnectSession,dataSession}= useContext(Context);

         return (
            <>
    <TouchableOpacity style={styles.ContainerRequest} onPress={()=> Navigation.navigate('Requests')} >
    <FontAwesome5 name="tools" size={20} color="#bff176" />
    <Text style={styles.TextView}>

    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" ||  dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
        "Crear / Consultar / Solicitudes" :
        (dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") ?
        "Consultar / Solicitudes"
        : null
         }</Text>
    </TouchableOpacity>
            </>
        )
    }

const styles = StyleSheet.create({
    Container:{
        alignItems:'center',
        justifyContent:'center',
        width:'100%',
        height:80,
        marginTop:10,
        backgroundColor:'#555555',
        borderTopLeftRadius:  30,
        borderBottomRightRadius:  30
      },
      ContainerNewProject:{
        alignItems:'center',
        justifyContent:'center',
        width:'100%',
        height:80,
        marginTop:10,
        backgroundColor:'#1e2128',
        borderTopLeftRadius:  30,
        borderBottomRightRadius:  30
      },
      ContainerTeams:{
        alignItems:'center',
        justifyContent:'center',
        width:'100%',
        height:80,
        marginTop:10,
        backgroundColor:'#555555',
        borderTopLeftRadius:  30,
        borderBottomRightRadius:  30
      },
      ContainerClients:{
        alignItems:'center',
        justifyContent:'center',
        width:'100%',
        height:80,
        marginTop:10,
        backgroundColor:'#1e2128',
        borderTopLeftRadius:  30,
        borderBottomRightRadius:  30
      },
      TextView: {
        color: '#e0e0f6',
        fontWeight: 'bold',
        fontSize: 16,
        marginTop:10,
        fontWeight: 'bold'
      },
      ContainerRequest:{
        alignItems:'center',
        justifyContent:'center',
        width:'100%',
        height:80,
        marginTop:10,
        backgroundColor:'#555555',
        borderTopLeftRadius:  30,
        borderBottomRightRadius:  30
      },
});