import React, { useContext, useState, useEffect } from 'react';
import {StyleSheet,Text,TouchableOpacity} from 'react-native'
import { MaterialIcons } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Context from '../../context/Context';

export const Projects_Management_Btn = () => { 
   
  const  {setShowAlertReport, setEndPointReport, dataSession}= useContext(Context);

    return (
        <>
   <TouchableOpacity style={styles.Container} onPress={()=> {
    setEndPointReport([{endPoint:`/projects/projectsdetail`, typeRequest:'Get_PSP_Detail', rptName:'Detalle_Proyectos_SubProyectos'}])
    setShowAlertReport(true)}}> 
   <MaterialCommunityIcons name="file-document" size={24} color="#88ffd4" />
      <Text style={styles.TextView}>Detalle / Proyectos / SubP</Text>
    </TouchableOpacity>
    <TouchableOpacity style={styles.SecondContainer} onPress={()=> {
    setEndPointReport([{endPoint:`/quotations`, typeRequest:'Get_Report_Quotations', rptName:'Reporte_Insumos_&_Materiales'}])
    setShowAlertReport(true)}}> 
    <Entypo name="text-document-inverted" size={24} color="#88d9ff" />
      <Text style={styles.TextView}>Reporte Insumos y Materiales</Text>
    </TouchableOpacity>
    <TouchableOpacity style={styles.thirdContainer} onPress={()=> {
    setEndPointReport([{endPoint:`/requestMaterial`, typeRequest:'Get_Requests_Material', rptName:'Reporte_Seguimiento_Solicitudes'}])
    setShowAlertReport(true)}}> 
    <MaterialCommunityIcons name="file-document-multiple" size={24} color="#a888ff" />
      <Text style={styles.TextView}>Seguimiento Solicitudes</Text>
    </TouchableOpacity>
        </>
        )
    }

    export const Projects_In_Progress_Btn = () => {
      
      const  {setShowAlertReport, setEndPointReport}= useContext(Context);

      
      return (
          <>
      <TouchableOpacity style={styles.Container} onPress={()=> {
    setEndPointReport([{endPoint:`/projects/pspexecution`, typeRequest:'Get_PSP_In_Execution', rptName:'Proyectos_SubProyectos_En_Gestión'}])
    setShowAlertReport(true)}}> 
      <MaterialCommunityIcons name="file-document" size={24} color="#88ffd4" />
        <Text style={styles.TextView}>Proyectos / SubP / En Gestión</Text>
      </TouchableOpacity>
          </>
          )
      }



    export const Projects_Novelties_Btn = () => {
     
      const  {setShowAlertReport, setEndPointReport}= useContext(Context);

      
  
      return (
          <>
     <TouchableOpacity style={styles.SecondContainer} onPress={()=> {
    setEndPointReport([{endPoint:`/novelties`, typeRequest:'Get_Novelties_PSP', rptName:'Reporte_Novedades'}])
    setShowAlertReport(true)}}> 
     <Entypo name="text-document-inverted" size={24} color="#88d9ff" />
      <Text style={styles.TextView}>Reporte Novedades</Text>
    </TouchableOpacity>
          </>
          )
      }



export  const Teams_Management_Btn = () => {
 
  const  {setShowAlertReport, setEndPointReport}= useContext(Context);

  

        return (
            <>
            <TouchableOpacity style={styles.thirdContainer} onPress={()=> {
    setEndPointReport([{endPoint:`/teams/created`, typeRequest:'Get_Teams', rptName:'Equipos_Creados'}])
    setShowAlertReport(true)}} >
            <MaterialCommunityIcons name="file-document" size={24} color="#88ffd4" />
            <Text style={styles.TextView}>Equipos Creados</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.SecondContainer} onPress={()=> {
    setEndPointReport([{endPoint:`/teams/available`, typeRequest:'Get_Teams_Available', rptName:'Equipos_Disponibles'}])
    setShowAlertReport(true)}} >    
            <Entypo name="text-document-inverted" size={24} color="#88d9ff" />
            <Text style={styles.TextView}>Equipos Disponibles</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.Container} onPress={()=> {
    setEndPointReport([{endPoint:`/teams/pendings`, typeRequest:'Get_Pending_Users_Asignament', rptName:'Reporte_Asignaciones_Pendientes'}])
    setShowAlertReport(true)}} >           
            <MaterialCommunityIcons name="file-document-multiple" size={24} color="#a888ff" />
            <Text style={styles.TextView}>Asignaciones Pendientes</Text>
            </TouchableOpacity>
            </>
            )
        }

export  const Reports_Inventory_Btn = () => {
  
         const  {setShowAlertReport, setEndPointReport, dataSession}= useContext(Context);


         return (
            <>
{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b" || 
dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4a85-f453-11ed-b65a-0697dd88345b") && 
      <>
         <TouchableOpacity style={styles.Container} onPress={()=> {
    setEndPointReport([{endPoint:`/inventory`, typeRequest:'Get_Inventory', rptName:'Inventario_Actual'}])
    setShowAlertReport(true)}}>  
         <MaterialCommunityIcons name="file-document" size={24} color="#88ffd4" />
        <Text style={styles.TextView}>Inventario Actual</Text>
        </TouchableOpacity>
        </>
}
<>
{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b") && 
<>
<TouchableOpacity style={styles.SecondContainer} onPress={()=> {
    setEndPointReport([{endPoint:`/requestMaterial`, typeRequest:'Get_Requests_Material', rptName:'Entregas_Pendientes'}])
    setShowAlertReport(true)}}>
        <Entypo name="text-document-inverted" size={24} color="#88d9ff" />
        <Text style={styles.TextView}>Entregas Pendientes</Text>    
        </TouchableOpacity>
        <TouchableOpacity style={styles.thirdContainer} onPress={()=> {
    setEndPointReport([{endPoint:`/quotations/deliveries-done`, typeRequest:'Get_Deliveries_Done', rptName:'Entregas_Realizadas'}])
    setShowAlertReport(true)}}>
        <MaterialCommunityIcons name="file-document-multiple" size={24} color="#a888ff" />
        <Text style={styles.TextView}>Entregas Realizadas</Text>     
        </TouchableOpacity>
        </>
}
        </>
  </>
        )
    }

    export  const Report_Access_Store_Btn = () => {
   
             const  {setShowAlertReport, setEndPointReport}= useContext(Context);


             return (
                <>
            <TouchableOpacity style={styles.Container} onPress={()=> {
    setEndPointReport([{endPoint:`/access-store`, typeRequest:'Get_Rpt_Access_Store', rptName:'Reporte_Acceso_Bodega'}])
    setShowAlertReport(true)}}>
            <MaterialCommunityIcons name="file-document" size={24} color="#88ffd4" />
            <Text style={styles.TextView}>Reporte Accesos a Bodega</Text>    
            </TouchableOpacity>
                </>
            )
        }
    

export  const Reports_Auditory_Btn = () => {
  
         const  {setShowAlertReport, setEndPointReport}= useContext(Context);


         return (
            <>
            <TouchableOpacity style={styles.SecondContainer} onPress={()=> {
    setEndPointReport([{endPoint:`/audits/scheduled`, typeRequest:'Audits_Scheduled', rptName:'Auditorias_Programadas'}])
    setShowAlertReport(true)}} >
            <MaterialCommunityIcons name="file-document" size={24} color="#88ffd4" />
            <Text style={styles.TextView}>Auditorias Programadas</Text>   
            </TouchableOpacity>   
            <TouchableOpacity style={styles.Container} onPress={()=> {
    setEndPointReport([{endPoint:`/audits/done`, typeRequest:'Get_Audits_Done', rptName:'Auditorias_Realizadas'}])
    setShowAlertReport(true)}}>
            <Entypo name="text-document-inverted" size={24} color="#88d9ff" />
            <Text style={styles.TextView}>Auditorias Realizadas</Text>    
            </TouchableOpacity>
            </>
        )
    }

    export  const Reports_Management_Btn = () => {
       
             const  {setShowAlertReport, setEndPointReport}= useContext(Context);


             return (
                <>
                <TouchableOpacity style={styles.thirdContainer} onPress={()=> {
    setEndPointReport([{endPoint:`/users`, typeRequest:'Get_Users_Detail', rptName:'Reporte_Roles_&_Usuarios'}])
    setShowAlertReport(true)}} >
                <MaterialCommunityIcons name="file-document-multiple" size={24} color="#a888ff" />
                <Text style={styles.TextView}>Reporte / Usuarios / Roles</Text>         
                </TouchableOpacity>
                </>
            )
        }


const styles = StyleSheet.create({
  Container:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#555555',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  SecondContainer:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#1e2128',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  thirdContainer:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#262726',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
      TextView: {
        color: '#e0e0f6',
        fontWeight: 'bold',
        fontSize: 16,
        marginTop:10,
        fontWeight: 'bold'
      }
});