import React, { useState, useContext,Component, useEffect } from 'react';
import { ScrollView,TextInput, Text, Button, Alert, View, StyleSheet, Platform, Pressable } from 'react-native';
import { FlatList } from 'react-native-gesture-handler'
import { FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Context from '../../../../context/Context'
import { AntDesign } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
//For Making Requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
import AwesomeAlert from 'react-native-awesome-alerts';
  

//MQTT Connection:
import Paho from "paho-mqtt";

const Add_Existing_Products =()=>  {

    const [showAlert, setShowAlert]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [response, setResponse]=useState('');
    const [disableSubmit, setDisableSubmit]=useState(false);
    const [disableButton, setDisableButton]=useState(false);
    const [unlockState,setUnlockState]=useState(false);
    const [showAlertLockStateFinished,setShowAlertLockStateFinished]=useState(false);
    const [showAlertError, setShowAlertError]=useState(false);
    //Search on Table:
    const [search, setSearch]=useState('0') 
    //Columns for table:
    const [itemSelected, setItemSelected] = useState([{id:'0', name:'', measure:'', type:'', quantity:'', brand:'', consecutive:''},]);
    const [arrayItems, setArrayItems]= useState([]);
    const {dataNavigation, identificationUser, setUpdateRequest, updateRequest, MQTTObject} = useContext(Context);
    const Navigation = useNavigation();

  //Paho client connection:
  const client = new Paho.Client(
    MQTTObject.MQTT,
    Number(MQTTObject.Port), 
    identificationUser[0].id
    );

    client.connect({
      timeout: 15, 
      onSuccess: ()=>{ 
        client.subscribe("esp32/blocker");
       },
      onFailure: ()=>{ 
        console.log("Failed to connect!");
       },
      userName:MQTTObject.Usr,
      password:MQTTObject.Pwd, 
      useSSL: true, 
      keepAliveInterval: 60, 
      reconnect: false});
    
      const changeValue=(Number)=>{
        try {
    
        const message = new Paho.Message(String(Number));
        message.destinationName="esp32/blocker";
        client.send(message);
        setShowAlertLockStateFinished(true);
      
      setTimeout(()=>{
        setDisableButton(false);  
      }, 30000)
        } catch (error){
          setDisableButton(false);
          setShowAlertError(true);
        }
    
        } 

        const saveLogAccessStore=(typeAccess)=>{
      
          //Create:
          executeRequest('/products/add', {
            "Log_Access_Store": [
              {
                "body": {
                  "_User_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
                  "_Type_Access":Number(typeAccess),
                  "_Type_Request":"Entry_Material"
                
                }
              }
            ]
          }, 'post', 'post').then(
                getData=>{
                  null
                }
              )

          }
    
const requestBasicDataInventory=()=>{
//Get:
 executeRequest('/basic_data_inventory', {
  "Get_Basic_Data_Inventory": [
    {
      "body": {
        "_Type_Request": "Get_Basic_Data_Inventory"
      }
    }
  ]
}, 'get', 'post').then(
      getData=>{
        setArrayItems(getData)
      }
    )

}

  
      useEffect(()=>{
        requestBasicDataInventory();
      },[])



      const sendAddProducts=()=>{
          setDisableSubmit(true);
          setShowAlert(false);
          //Put:
          executeRequest('/products/add', {
            "Add_Products": [
              {
                "body": {
                 "_User_Update_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
                  "_Data_Json": (itemSelected.length>1) ? itemSelected : null
                }
              }
            ]
          }, 'post', 'put').then(
                getData=>{
                  setResponse(getData[0].Response);
                  setUpdateRequest(!updateRequest);
                  setShowAlertFinished(true);
                  setDisableSubmit(false);
                }
              )
        }
      






      const showDataItems=()=>{

    
        if (arrayItems.length>0) {
        if (search!=='0') {
        return (
          
          <View style={styles.TableStyles}>
            <ScrollView horizontal={true}>
        <FlatList 
          data={arrayItems.filter((e)=>(e.itemName.toLowerCase().includes((search).toLowerCase()))).slice(0,10)}
          renderItem={item} 
          ListHeaderComponent={()=>{
          
          return(
          <View style={styles.ViewRows}>
          <View style={styles.Row}>
            <Text style={styles.TextHeader}>#</Text>
          </View>
          <View style={styles.Row}>
            <Text style={styles.TextHeader}>Item</Text>
          </View>
          <View style={styles.Row}>
            <Text style={styles.TextHeader}>Check</Text>
          </View>
        </View>
        
        )}}
          />  
     </ScrollView>
        </View>
       
        )
        } else {
          return (
            <View style={styles.TableStyles}>
            <ScrollView horizontal={true}>
            
          <FlatList 
            data={arrayItems.slice(0,10)}
            renderItem={item} 
            ListHeaderComponent={()=>{
            
            return(
            <View style={styles.ViewRows}>
            <View style={styles.Row}>
              <Text style={styles.TextHeader}>#</Text>
            </View>
            <View style={styles.Row}>
              <Text style={styles.TextHeader}>Item</Text>
            </View>
            <View style={styles.Row}>
              <Text style={styles.TextHeader}>Check</Text>
            </View>
          </View>
          
          )}}
            />  
      
         
          </ScrollView>
          </View>
          )
        }
      }  else { null }
    
      }




/************StartTableRows*****************/
const item= ({item})=>{

  return (

    <Pressable  onPress={()=>{ if (itemSelected.find(e=>e.id===item.hashID)) {
      setItemSelected(itemSelected.filter(e=>e.id!==item.hashID)) 
    } else {
     setItemSelected((e)=>[...e, {id:item.hashID, name:item.itemName, measure:item.measure, type:item.itemType ,quantity:'', brand:item.brand, consecutive:item.consecutive}])
    }
    }}
  style={{backgroundColor:(itemSelected.map(e=>e.id).indexOf(item.hashID)!==-1)? '#0d0e0f' : '#0e1115' 
            ,  flexDirection:'row',
            alignItems:'center',}}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.id}</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.itemName}</Text>
      </View>
      <View style={styles.Row}>
        {(itemSelected.map(e=>e.id).indexOf(item.hashID)!==-1)? <Feather name="check-circle" size={18} color="white" style={styles.Text}/>  : <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={18} color="grey" style={styles.Text}/> }
      </View>
    </Pressable>

  )
  
}


const updateValueQuantity=(id, quant)=>{
  if (isNaN(Number(quant))===true || quant=== null || quant === '' || quant ===undefined || quant == ' ') { null }
  else {
    const newValueReplace=itemSelected;
    newValueReplace.map(e=>((e.id)===id) ? (e.quantity=quant) : null  );
    setItemSelected(newValueReplace);

    }
};

/************EndTableRows*****************/
/************StartTable2Rows*****************/
const item_Selected= ({item})=>{


  return (
    <View style={styles.ViewRows}>
      <View style={styles.Row}>
        <Text style={styles.TextRows}>{item.name}</Text>
      </View>
      <View style={styles.Row}>
       <TextInput style={styles.QuantitySelected} placeholder='?' onChangeText={(e)=>{
      updateValueQuantity(item.id, e);
 }}/> 
      </View>
      <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.measure}</Text>
       </View>
       
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.type}</Text>
       </View>

       <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.brand}</Text>
       </View>

       <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.consecutive}</Text>
       </View>


      </View>
  )

}
/************EndTable2Rows*****************/
    return (

<View style={styles.MainContainer}>
<ScrollView>
   
<View>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Agregar Items Existentes</Text>
</View>
         
<View style={styles.spaceBottom}>

{/* Start Fields And List */}

    <Text style={styles.TextHigh}>Especifica el nombre del item que deseas agregar al inventario:</Text>
    <View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder='Búscar item específico' style={styles.SearchInput}
     onChangeText={(e) => {
    (e==='')?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    {showDataItems()}

   {/* End Fields And FlatList */}

{(itemSelected[1]) &&
   <View>
        <Text style={styles.TextHigh}>Lista de Items seleccionados:</Text>
        
       
        <View style={styles.TableStyles}> 
        <ScrollView horizontal={true}>          
        
        <FlatList 
                        data={ 
                        itemSelected.filter(e=>e.id!=='0')
                             }
                        renderItem={item_Selected} 
                        ListHeaderComponent={()=>{
                        
                        return(
                        <View style={styles.ViewRows}>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Item</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cantidad</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>U. Medida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Tipo</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Marca</Text>
                        </View>
                          <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Consecutivo</Text>
                        </View>

                       </View>
                      
                      )}}
                        /> 
                         </ScrollView> 
        </View>
       
        </View>
}

<TouchableOpacity style={(disableButton===false) ?  styles.ButtonUnlockStyle : styles.ButtonUnlockDisableStyle } onPress={()=> {
              setDisableButton(true);
              if (disableButton===false) {
              setUnlockState(true)
              changeValue(0);
              saveLogAccessStore(0);
                }
             }}>
            <FontAwesome5 style={styles.iconButtons}  name="unlock-alt" size={18} color="#f38080" />
            <Text style={styles.TextStylesFinished}>Desbloquear Bodega</Text>
              </TouchableOpacity>


              <TouchableOpacity style={(disableButton===false) ?  styles.ButtonLockStyle : styles.ButtonLockDisableStyle } onPress={()=> {
              setDisableButton(true);
              if (disableButton===false) {
              setUnlockState(false)
              changeValue(1);
              saveLogAccessStore(1);
                }
             }}>
            <FontAwesome5 style={styles.iconButtons}  name="unlock-alt" size={18} color="#80f389" />
            <Text style={styles.TextStylesFinished}>Bloquear Bodega</Text>
              </TouchableOpacity>

{ (disableSubmit===true) ?  
       <TouchableOpacity style={styles.ButtonDisabled }>
      <MaterialCommunityIcons name="rocket-launch" size={20} color="#80cdf3" />
        <Text style={styles.TextStylesFinished}>Procesando</Text>
        </TouchableOpacity>
       
      :     
            <TouchableOpacity style={styles.ButtonFinished}
             onPress={()=>{setShowAlert(true)}}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="#80cdf3" />
              <Text style={styles.TextStylesFinished}>Agregar Items</Text>
              </TouchableOpacity>
      }


                </View>

            </View>

            <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            sendAddProducts();
            
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.navigate('Products_Created');
          }}
        />
<AwesomeAlert
          show={showAlertLockStateFinished}
          showProgress={false}
          title={(unlockState===true) ? "Desbloqueo Realizado" : "Bloqueo Realizado" }
          message={(unlockState===true) ? 
            "Se desbloqueó exitosamente el acceso a bodega. Por favor no olvides cerrar nuevamente la puerta cuando hayas terminado el proceso de entrega."
          : 
            "Se activó exitosamente el bloqueo magnético de la bodega, por favor verifica que la puerta se encuentre bien ajustada, gracias."
          }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertLockStateFinished(false);
          }}
        />
<AwesomeAlert
          show={showAlertError}
          showProgress={false}
          title="Error Inesperado"
          message="Se ha presentado un error inesperado al intentar establecer comunicación con el servidor, por favor reintenta la petición nuevamente."
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#ffb2b2'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertError(false);
          }}
        />
   
      </ScrollView>
      </View>
    );
  }

const styles = StyleSheet.create({
  MainContainer:{
    backgroundColor:'#0d0d0d',
    justifyContent:'center',
    flex:1,
    flexDirection:'row'
  },

  formContainer: {
    padding: 20,
    textAlign:'center',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center'
  
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10
  },

  DatesInput: {
    alignItems:'center',
    justifyContent:'center',
    borderRadius:30,
    width:'35%',
    height:60,
  },
  DropDownSelection: {
    marginTop:1,
  },
  TittleScreen:{
    color:'#74ddf7',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewRoot:{
     backgroundColor:'#111111',
     height:'100%'
  },
  ViewTittleScreen:{
    backgroundColor:'#25292a',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:40
  },
  ButtonCenterFinished: {
    backgroundColor:'#4d83a5',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  Row:{
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center'
  },
  

  ContainerSearch: {
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'center',
  },
  SearchInput: {
    backgroundColor:'#767a7a',
    width:'70%',
    textAlign:'left',
    borderRadius:10,
    marginTop:40,
    paddingLeft:10,
    marginLeft:30,
    height:25
  },
  IconSearch:{
    width:'10%',
    marginTop:40,
    marginLeft:20
  },
  TextHigh: {
    textAlign:'left',
    color:'#EDF0F5',
    paddingTop:'5%',
    paddingLeft:'5%',
    paddingRight:'5%'
  },
  PropertiesView:{
    backgroundColor:'#111111',
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:5
  },

  Text:{
    width:120,
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
  },
  TextHeader:{
    color:'#B4DCEC',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TableStyles: {
    flexDirection:'row',
    textAlign:'center',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    borderRadius:10
  },
  TextInput:{
    width:60,
    backgroundColor:'grey',
    borderRadius:7,
    color:'#f1f1f1',
    textAlign:'center',
    borderRightWidth:1,
    borderRightColor:'#393939',
    alignContent:'center',
    marginLeft:30,
    marginRight:30
  },
  LeftSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    color:'#f1f1f1'
  },
  CenterSelect:{
    width:'18%',
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    marginTop:10,
    color:'#f1f1f1'
  },
  ButtonUnlockStyle:{
    borderRadius:10,
    marginTop:10,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#4b4646'
  },
  ButtonLockStyle:{
    borderRadius:10,
    marginTop:10,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#464b48'
  },
  ButtonUnlockDisableStyle:{
    borderRadius:10,
    marginTop:10,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#bfbfbf'
  },
  ButtonLockDisableStyle:{
    borderRadius:10,
    marginTop:10,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#bfbfbf'
  },
  RightSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    color:'#f1f1f1'
  },

  LeftTitleSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  CenterTitleSelect:{
    width:'18%',
    textAlign:'center',
    borderRadius:10,
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  RightTitleSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },

  TextRows:{
    color:'#f1f1f1',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  QuantitySelected:{
    width:120,
    textAlign:'center',
    backgroundColor:'#585c62',
    borderRadius:10,
    height:35,
    color:'#f1f1f1'
  },
  inputStyleProceduresExecution:  {
    width:'100%',
    height:150,
    borderRadius:10,
    padding:10,
    marginTop:10,
    textAlign:'justify',
    textAlignVertical:'top',
    backgroundColor:'#dbd9d9'
  },
  inputStyle:{
      width:'100%',
      height:50,
      borderRadius:10,
      padding:10,
      marginTop:10,
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#dbd9d9'
  },
  ButtonFinished: {
    backgroundColor:'#2a2d30',
    borderRadius:10,
    marginTop:10,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#bccdd4',
    borderRadius:10,
    marginTop:10,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center'
  },
  spaceBottom: {
    marginBottom:10
  }
});

export default Add_Existing_Products
