import React, {useState, useContext, useEffect} from 'react';
import { ScrollView, Text, TouchableOpacity,View, StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { FlatList } from 'react-native-gesture-handler'
import AwesomeAlert from 'react-native-awesome-alerts';
import { MaterialIcons } from '@expo/vector-icons';
import Context from '../../../context/Context'
//For making requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
  

const Team =()=>  {

const  {dataNavigationTeams, setUpdateRequest,updateRequest, dataSession, identificationUser}= useContext(Context);
const Navigation = useNavigation();
const [arrayTeams, setArrayTeams]= useState([]);
const [showAlertDeleteTeam, setShowAlertDeleteTeam]=useState(false);
const [disableSubmit, setDisableSubmit]=useState(false);
const [showAlertFinished, setShowAlertFinished]=useState(false);
const [response, setResponse]=useState('');

 
;


const sendRequest=()=>{
      //Get:
      executeRequest('/teams', {
        "Get_Teams": [
          {
            "body": {
              "_Type_Request": "Get_Team_Detail",
              "_Team_ID": ((dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
              dataNavigationTeams[0] !== undefined) ? dataNavigationTeams[0].teamChosen : null,
              "_User_ID":((dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b") && identificationUser.length>0) ? identificationUser[0]["id"] : null
            }
          }
        ]
      }, 'get', 'post').then(
            getData=>{  
              setArrayTeams(getData)
            }
        )
  } 
  

  useEffect(()=>{
      sendRequest();
  },[])

  useEffect(()=>{
    sendRequest();
},[updateRequest])
  


const deleteTeam=()=>{
        setDisableSubmit(true);
        setShowAlertDeleteTeam(false);
        //Delete:
        executeRequest('/teams', {
          "Delete_Team": [
            {
              "body": {
                "_Team_ID": (dataNavigationTeams[0] !== undefined) ? dataNavigationTeams[0].teamChosen : null
              }
            }
          ]
        }, 'post', 'delete').then(
              getData=>{
                setResponse(getData[0].Response);
                setShowAlertFinished(true);
                setDisableSubmit(false);
          }
      )

}


  const listArrayTeam= ({item})=>{


    return (
      <View style={styles.ViewRows}>
        <View style={styles.Row}>
          <Text style={styles.TextRows}>{item.fullName}</Text>
        </View>
        <View style={styles.Row}>
        <Text style={styles.TextRows}>{item.identificationCode}</Text>
        </View>
        <View style={styles.Row}>
        <Text style={styles.TextRows}>{item.rol}</Text>
        </View>
        <View style={styles.Row}>
        <Text style={styles.TextRows}>{item.state}</Text>
         </View>
        </View>
    )
  
  }

  const showData=()=>{
    if (arrayTeams.length>0){
    return (
    <FlatList 
    data={arrayTeams}
    renderItem={listArrayTeam} 
    ListHeaderComponent={()=>{
        return(
          <View style={styles.ViewRows}>
                <View style={styles.Row}>
                  <Text style={styles.TextHeader}>Nombre</Text>
                </View>
                <View style={styles.Row}>
                  <Text style={styles.TextHeader}>Identificación</Text>
                </View>
                <View style={styles.Row}>
                  <Text style={styles.TextHeader}>Rol</Text>
                </View>
                <View style={styles.Row}>
                  <Text style={styles.TextHeader}>Estado</Text>
                </View>
          </View>
              )}}
                />  
      );
    } else { null }
  }


    return (
<View style={styles.RootContainer}>
<ScrollView>

    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Equipo de Trabajo</Text>
    </View>
    {(arrayTeams.length>0) ?
    <>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Nombre Equipo:</Text> 
    <Text style={styles.TextScreenRight}>{arrayTeams[0].name}</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Descripción:</Text> 
    <Text style={styles.TextScreenRight}>{arrayTeams[0].description}</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Fecha Creación:</Text> 
    <Text style={styles.TextScreenRight}>{arrayTeams[0].dateCreated}</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Proyectos Asociados:</Text> 
    <Text style={styles.TextScreenRight}>{arrayTeams[0].nameProjects}</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Clientes Asociados:</Text> 
    <Text style={styles.TextScreenRight}>{arrayTeams[0].clientsName}</Text> 
    </View>
  
    </> :
    <>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Nombre Equipo:</Text> 
    <Text style={styles.TextScreenRight}>___________________</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Descripción:</Text> 
    <Text style={styles.TextScreenRight}>___________________</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Fecha Creación:</Text> 
    <Text style={styles.TextScreenRight}>___________________</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Proyectos Asociados:</Text> 
    <Text style={styles.TextScreenRight}>___________________</Text> 
    </View>
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Clientes Asociados:</Text> 
    <Text style={styles.TextScreenRight}>___________________</Text> 
    </View>
    </>
    }
    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Integrantes</Text>
    </View>
    <View style={styles.SectionLine}></View>

    <ScrollView horizontal={true}>          
        <View style={styles.TableStyles}> 
    {showData()}
      </View>
      </ScrollView>
{ (dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
<>
    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Administración</Text>
    </View>
    <View style={styles.MainContainer}> 
        <View style={styles.ButtonCenterAdmin}>
        <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=> Navigation.navigate('Update_Team')}>
        <MaterialIcons name="update" size={20} color="white" />
          <Text style={styles.TextStylesAdmin}>Actualizar</Text>
          </TouchableOpacity>
        </View> 
        <View style={styles.ButtonRightAdmin} >
        <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=> setShowAlertDeleteTeam(true)}>
        <AntDesign name="delete" size={20} color="white" />
          <Text style={styles.TextStylesAdmin}>Eliminar</Text>
          </TouchableOpacity>
        </View>
    </View>
    </>
   }




<AwesomeAlert style={styles.AlertContainer}
                show={showAlertDeleteTeam}
                showProgress={false}
                title="Eliminar Equipo"
                message="¿Estas seguro de que deseas eliminar este Equipo?, presiona [Eliminar] para confirmar los cambios."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                titleStyle={{color:'#8DDBFF'}}
                messageStyle={{color:'#f9f9f9'}}
                contentContainerStyle={{backgroundColor:'#272727'}}
                confirmButtonColor='#809280'
                cancelButtonColor='#746E6E'
                onCancelPressed={() => {
                  setShowAlertDeleteTeam(false)
                }}
                onConfirmPressed={() => {
                  deleteTeam()
                }}
              />

<AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            setUpdateRequest(!updateRequest);
            Navigation.goBack();
          }}
        />

</ScrollView>
</View>
    );
  }

const styles = StyleSheet.create({
  RootContainer: {
    backgroundColor:'#0d0d0d',
    justifyContent:'center',
    flex:1,
    flexDirection:'row'
  },
  TextScreenLeft: {
    color: '#DDE7F0',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'left',
    width:'40%',
    paddingTop:5,
    marginLeft:10
  },
  TextScreenRight: {
    color: '#f1f1f1',
    fontSize: 14,
    textAlign:'left',
    width:'54%',
    paddingTop:5
  },
  ButtonCenterAdmin: {
    textAlign:'center',
    width:'48%',
    paddingTop:15,
    marginLeft:5,
  },
  ButtonRightAdmin: {
    textAlign:'left',
    width:'48%',
    paddingTop:15,
    marginLeft:5,
  },
  ButtonStylesUpdate: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#2a2f35',
    marginBottom:10
  },
  ButtonStylesDelete: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#352a2a',
    marginBottom:10
  },
  TextStylesAdmin: {
    fontSize: 14,
    color: '#f1f1f1',
  },
  MainContainer: {
    flex:1,
     width:'90%',
     flexWrap:'wrap',
     flexDirection: 'row',
  },
  TittleScreen:{
    color:'#B4DCEC',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    width:'95%',
    backgroundColor:'#0a0a0a',
    marginTop:10,
    borderRadius:10
  },
  SectionLine:{
    width: '100%',
    borderBottomWidth :1,
    borderBottomColor: '#3d3d3d',
  },
  TableStyles: {
    backgroundColor:'#181818',
    marginTop:10
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:5
  },
  Row:{
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  TextRows:{
    color:'#f1f1f1',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TextHeader:{
    color:'#B4DCEC',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
});

export default Team
