import React, { useState, useEffect, useContext } from 'react';
import { ScrollView,TextInput, Text, FlatList, View, StyleSheet, Pressable } from 'react-native';
import * as yup from 'yup';
import { Formik } from 'formik';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import Context from '../../../../context/Context';
import AwesomeAlert from 'react-native-awesome-alerts';
import { AntDesign } from '@expo/vector-icons';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
  

const Create_Initial_Delivery_Agreement =()=>  {

    const Navigation = useNavigation();
    const {dataNavigation, identificationUser, setTypeSignature, setUpdateRequest, updateRequest} = useContext(Context);
    //Array Data:
    const [arrayBasicParameters, setArrayBasicParameters]=useState([]);
    // Selected list:
    const [conditionSelected, setConditionSelected]= useState([{id:'0', description:''}]);
    //const [measureSelected, setMeasureSelected]= useState([{id:'0', description:''}]);
    const [humidityLevelSelected, setHumidityLevelSelected]= useState([{id:'0', name:''}]);
    const [lightLevelSelected, setLightLevelSelected]= useState([{id:'0', name:''}]);
    const [energyTypeSelected, setEnergyTypeSelected]= useState([{id:'0', name:''}]);
    const [horaryTypeSelected, setHoraryTypeSelected]= useState([{id:'0', name:''}]);
    //Alerts:
    const [showAlert, setShowAlert]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [disableSubmit, setDisableSubmit]=useState(false);
    // Response:
    const [response, setResponse]=useState('');
    const [unevennessBit,setUnevennessBit]=useState(0);
    const [indoorsBit,setIndoorsBit]=useState(0);
    const [undulationsBit, setUndulationsBit]=useState(0);
    const [levelingBit, setLevelingBit]=useState(0);

  //Start Data Liquidity Table//
  const [dataLiquidity, setDataLiquidity] = useState([]);
  const [newItem, setNewItem] = useState({
    id: '',
    description: '',
    qQuotized: '',
    measureID: '',
    measure: ''
  });

  const generateId = () => {
    // Encuentra el máximo ID en el arreglo dataLiquidity
    const maxId = dataLiquidity.reduce((max, item) => {
    // Convierte el ID a un número y compara con el máximo actual
    const itemId = parseInt(item.id);
    return itemId > max ? itemId : max;
  }, 0);

  // Incrementa el máximo ID encontrado en 1 para obtener un nuevo ID único
  return (maxId + 1).toString();
};

  const handleAddItem = () => {
    if (newItem.qQuotized==='' || newItem.qExecuted==='') { null } else {
      const id = generateId();
      const newItemWithId = { ...newItem, id };
      setDataLiquidity([...dataLiquidity, newItemWithId]);
      setNewItem({ id: '',
      description: '',
      qQuotized: '',
      measureID: '',
      measure: '' });
    }
  };

  const handleDeleteItem = (id) => {
    setDataLiquidity(dataLiquidity.filter((item) => item.id !== id));
  };
   //End Data Liquidity Table//


  const sendRequestBasicParameters=()=>{
        //Get:
        executeRequest('/basic_parameters', {
          "Get_Basic_Parameters": [
            {
              "body": {
                "_Type_Request": "Get_Basic_Parameters"
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayBasicParameters(getData)
              }
          )
      }

    useEffect(()=>{
      sendRequestBasicParameters();
    },[])

const sendRequestCreateIDA=(paymentOrder, quotationCode, unevennessQuantity, unevennessObservation, humidityObservation, currentStateFloor, finalEndedDescription, lightingObservation, energyObservation, wallRoofObservation, quantityDays, quantityHours, IQQ_Description, proceduresExecution, generalObservations, proyectSupervisorName,identificationSupervisor, identificationContractor, Consecutive, Contract_Number)=>{
            setDisableSubmit(true);
            setShowAlert(false);
            //Create:
            executeRequest('/ida', {
              "Create_IDA": [
                  {
                      "body": {
                          "_Payment_Order_Service": (paymentOrder.length>0) ? paymentOrder : null,
                          "_QuotationCode": (quotationCode.length>0) ? quotationCode : null,
                          "_Conditions_Service_ID": (conditionSelected.length>0) ? conditionSelected : null,
                          "_Initial_Quantity_Quotized": null,
                          "_Measure_ID": null,
                          "_Unevenness_Quantity": (unevennessQuantity.length>0) ? unevennessQuantity : null,
                          "_Unevenness_Observations": (unevennessObservation.length>0) ? unevennessObservation : null,
                          "_Humidity_Level_ID": humidityLevelSelected[0].id,
                          "_Humidity_Observations": (humidityObservation.length>0) ? humidityObservation : null,
                          "_Actual_State_Floor_Description": (currentStateFloor.length>0) ? currentStateFloor : null,
                          "_Final_Ended_Floor_Description": (finalEndedDescription.length>0) ? finalEndedDescription : null,
                          "_Lighting_ID": lightLevelSelected[0].id,
                          "_Lighting_Observation": (lightingObservation.length>0) ? lightingObservation : null,
                          "_Energy_ID": energyTypeSelected[0].id,
                          "_Energy_Observation": (energyObservation.length>0) ? energyObservation : null,
                          "_WallAndRoof_Observations": (wallRoofObservation.length>0) ? wallRoofObservation : null,
                          "_Horary_ID": horaryTypeSelected[0].id,
                          "_Quantity_Days": (quantityDays.length>0) ? quantityDays : null,
                          "_Quantity_Hours": (quantityHours.length>0) ? quantityHours : null,
                          "_IQQ_Description": (IQQ_Description.length>0) ? IQQ_Description : null,
                          "_Procedures_Execution": (proceduresExecution.length>0) ? proceduresExecution : null,
                          "_General_Observations": (generalObservations.length>0) ? generalObservations : null,
                          "_Signature_Entity_Supervisor": null,
                          "_Project_Supervisor_Name": (proyectSupervisorName.length>0) ? proyectSupervisorName : null,
                          "_Identification_Entity_Supervisor": (identificationSupervisor.length>0) ? identificationSupervisor : null,
                          "_Signature_Contractor": null,
                          "_Contractor_Identification": (identificationContractor.length>0) ? identificationContractor : null,
                          "_User_Creator_ID": (identificationUser[0] !== undefined) ? identificationUser[0]["id"] : null,
                          "_Client_ID": (dataNavigation[0] !== undefined ) ?  dataNavigation[0].clientChosen : null,
                          "_Project_ID":(dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
                          "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null,
                          "_Consecutive": (Consecutive.length>0) ? Consecutive : null,
                          "_Contract_Number": (Contract_Number.length>0) ? Contract_Number : null,
                          "_Indoor": indoorsBit,
                          "_Unevenness": unevennessBit,
                          "_Undulation": undulationsBit,
                          "_Leveling": levelingBit,
                          "_Data_Json": (dataLiquidity !== undefined) ? dataLiquidity : null
                      }
                  } 
              ]
          }, 'post', 'post').then(
                        getData=>{
                          setDisableSubmit(false);
                          setResponse(getData[0].Response);
                          setUpdateRequest(!updateRequest)
                          setShowAlertFinished(true);   
                  }
            ) 
      
    }


    const showConditions= ()=>{
    
      if (arrayBasicParameters.length>0) {
      
          return (
        <View style={styles.TableStyles}>
     {arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Conditions_Service".toLowerCase()))).map((x, index)=>(
        <Pressable  key={index} onPress={()=>{(conditionSelected.find(e=>e.id===x.id)) ? setConditionSelected(conditionSelected.filter(e=>e.id!==x.id)) 
         : setConditionSelected((e)=>[...e, {id:x.id, description:x.description}])
        }}
      style={{backgroundColor:(conditionSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
                flexDirection:'row',
                alignItems:'center',
                textAlign:'center',
                justifyContent:'center',
                borderRadius:10,
                width:'100%',
                padding:10,
                marginTop:5
                }}>
          <View style={styles.Row}>
            <Text style={styles.Text}>{x.description}</Text>
          </View>
        </Pressable>
        ))
      }
        </View>
          );
        } else { null }

    }

 const showMeasures=()=>{
  if (arrayBasicParameters.length>0) {
  
      return (
    <View style={styles.TableStyles}>

 {arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Measures".toLowerCase()))).map((x, index)=>(
    <Pressable  key={index} onPress={()=>{
      setNewItem({ ...newItem, measureID:x.id, measure:x.name })
    }}
  style={{backgroundColor: (newItem.measureID) ? (newItem.measureID.indexOf(x.id)!==-1) ? '#3489c8' : '#1e1f20' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            width:'100%',
            padding:10,
            marginTop:5
            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{x.description}</Text>
      </View>
    </Pressable>
    ))
  }
    </View>
      );
    } else { null }

}

const indoors=()=>{
  return (
<View style={styles.TableStyles}>
<Pressable onPress={()=>{setIndoorsBit(1)}}
style={{backgroundColor:(indoorsBit===1)? '#3489c8' : '#1e1f20',
        flexDirection:'row',
        alignItems:'center',
        textAlign:'center',
        justifyContent:'center',
        borderRadius:10,
        width:'100%',
        padding:10,
        marginTop:5
        }}>
  <View style={styles.Row}>
    <Text style={styles.Text}>Si</Text>
  </View>
</Pressable>
<Pressable onPress={()=>{setIndoorsBit(0)}}
style={{backgroundColor:(indoorsBit===0)? '#3489c8' : '#1e1f20',
        flexDirection:'row',
        alignItems:'center',
        textAlign:'center',
        justifyContent:'center',
        borderRadius:10,
        width:'100%',
        padding:10,
        marginTop:5
        }}>
  <View style={styles.Row}>
    <Text style={styles.Text}>No</Text>
  </View>
</Pressable>
</View>
  );
}

const unevenness=()=>{
      return (
    <View style={styles.TableStyles}>
    <Pressable onPress={()=>{setUnevennessBit(1)}}
  style={{backgroundColor:(unevennessBit===1)? '#3489c8' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            width:'100%',
            padding:10,
            marginTop:5
            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>Si</Text>
      </View>
    </Pressable>
    <Pressable onPress={()=>{setUnevennessBit(0)}}
  style={{backgroundColor:(unevennessBit===0)? '#3489c8' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            width:'100%',
            padding:10,
            marginTop:5
            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>No</Text>
      </View>
    </Pressable>
    </View>
      );
}

const undulationsFn=()=>{
  return (
<View style={styles.TableStyles}>
<Pressable onPress={()=>{setUndulationsBit(1)}}
style={{backgroundColor:(undulationsBit===1)? '#3489c8' : '#1e1f20',
        flexDirection:'row',
        alignItems:'center',
        textAlign:'center',
        justifyContent:'center',
        borderRadius:10,
        width:'100%',
        padding:10,
        marginTop:5
        }}>
  <View style={styles.Row}>
    <Text style={styles.Text}>Si</Text>
  </View>
</Pressable>
<Pressable onPress={()=>{setUndulationsBit(0)}}
style={{backgroundColor:(undulationsBit===0)? '#3489c8' : '#1e1f20',
        flexDirection:'row',
        alignItems:'center',
        textAlign:'center',
        justifyContent:'center',
        borderRadius:10,
        width:'100%',
        padding:10,
        marginTop:5
        }}>
  <View style={styles.Row}>
    <Text style={styles.Text}>No</Text>
  </View>
</Pressable>
</View>
  );
}


const levelingFn=()=>{
  return (
<View style={styles.TableStyles}>
<Pressable onPress={()=>{setLevelingBit(1)}}
style={{backgroundColor:(levelingBit===1)? '#3489c8' : '#1e1f20',
        flexDirection:'row',
        alignItems:'center',
        textAlign:'center',
        justifyContent:'center',
        borderRadius:10,
        width:'100%',
        padding:10,
        marginTop:5
        }}>
  <View style={styles.Row}>
    <Text style={styles.Text}>Si</Text>
  </View>
</Pressable>
<Pressable onPress={()=>{setLevelingBit(0)}}
style={{backgroundColor:(levelingBit===0)? '#3489c8' : '#1e1f20',
        flexDirection:'row',
        alignItems:'center',
        textAlign:'center',
        justifyContent:'center',
        borderRadius:10,
        width:'100%',
        padding:10,
        marginTop:5
        }}>
  <View style={styles.Row}>
    <Text style={styles.Text}>No</Text>
  </View>
</Pressable>
</View>
  );
}




const showHumidityLevel=()=>{
    
  if (arrayBasicParameters.length>0) {
  
      return (
    <View style={styles.TableStyles}>
 {arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Humidity_Level".toLowerCase()))).map((x, index)=>(
    <Pressable  key={index} onPress={()=>{(humidityLevelSelected.find(e=>e.id===x.id)) ? setHumidityLevelSelected(humidityLevelSelected.filter(e=>e.id!==x.id)) 
     : setHumidityLevelSelected([{id:x.id, name:x.name}])
    }}
  style={{backgroundColor:(humidityLevelSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            width:'100%',
            padding:10,
            marginTop:5
            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{x.name}</Text>
      </View>
    </Pressable>
    ))
  }
    </View>
      );
    } else { null }

}

const showLightLevel=()=>{
  if (arrayBasicParameters.length>0) {
  
    return (
  <View style={styles.TableStyles}>
{arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Lighting_ID".toLowerCase()))).map((x, index)=>(
  <Pressable  key={index} onPress={()=>{(lightLevelSelected.find(e=>e.id===x.id)) ? setLightLevelSelected(lightLevelSelected.filter(e=>e.id!==x.id)) 
   : setLightLevelSelected([{id:x.id, name:x.name}])
  }}
style={{backgroundColor:(lightLevelSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
          flexDirection:'row',
          alignItems:'center',
          textAlign:'center',
          justifyContent:'center',
          borderRadius:10,
          width:'100%',
          padding:10,
          marginTop:5
          }}>
    <View style={styles.Row}>
      <Text style={styles.Text}>{x.name}</Text>
    </View>
  </Pressable>
  ))
}
  </View>
    );
  } else { null }
}

const showEnergyType=()=>{
  if (arrayBasicParameters.length>0) {
  
    return (
  <View style={styles.TableStyles}>
{arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Energy_ID".toLowerCase()))).map((x, index)=>(
  <Pressable  key={index} onPress={()=>{(energyTypeSelected.find(e=>e.id===x.id)) ? setEnergyTypeSelected(energyTypeSelected.filter(e=>e.id!==x.id)) 
   : setEnergyTypeSelected([{id:x.id, name:x.name}])
  }}
style={{backgroundColor:(energyTypeSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
          flexDirection:'row',
          alignItems:'center',
          textAlign:'center',
          justifyContent:'center',
          borderRadius:10,
          width:'100%',
          padding:10,
          marginTop:5
          }}>
    <View style={styles.Row}>
      <Text style={styles.Text}>{x.name}</Text>
    </View>
  </Pressable>
  ))
}
  </View>
    );
  } else { null }
}

const showHoraryType=()=>{
  if (arrayBasicParameters.length>0) {
  
    return (
  <View style={styles.TableStyles}>
{arrayBasicParameters.filter((y)=>(y.type.toLowerCase().includes("Horary_Type".toLowerCase()))).map((x, index)=>(
  <Pressable  key={index} onPress={()=>{(horaryTypeSelected.find(e=>e.id===x.id)) ? setHoraryTypeSelected(horaryTypeSelected.filter(e=>e.id!==x.id)) 
   : setHoraryTypeSelected([{id:x.id, name:x.name}])
  }}
style={{backgroundColor:(horaryTypeSelected.map(e=>e.id).indexOf(x.id)!==-1)? '#3489c8' : '#1e1f20',
          flexDirection:'row',
          alignItems:'center',
          textAlign:'center',
          justifyContent:'center',
          borderRadius:10,
          width:'100%',
          padding:10,
          marginTop:5
          }}>
    <View style={styles.Row}>
      <Text style={styles.Text}>{x.name}</Text>
    </View>
  </Pressable>
  ))
}
  </View>
    );
  } else { null }
}

    return (

    <ScrollView style={styles.formContainer}>

<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>ACTA DE INICIO DE OBRA</Text>
</View>
      <Formik
        initialValues={{
          paymentOrder: '',
          quotationCode: '',
          unevennessQuantity: '',
          unevennessObservation: '',
          humidityObservation: '',
          currentStateFloor: '',
          finalEndedDescription: '',
          lightingObservation: '',
          energyObservation: '',
          wallRoofObservation: '',
          quantityDays: '',
          quantityHours: '',
          IQQ_Description:'',
          proceduresExecution: '',
          generalObservations: '',
          proyectSupervisorName:'',
          identificationSupervisor:'',
          identificationContractor:'',
          Consecutive:'',
          Contract_Number:''
        }}
        onSubmit={values => {
          const paymentOrder=JSON.stringify(values["paymentOrder"]);
          const quotationCode=JSON.stringify(values["quotationCode"]);
          const unevennessQuantity=JSON.stringify(values["unevennessQuantity"]);
          const unevennessObservation=JSON.stringify(values["unevennessObservation"]);
          const humidityObservation=JSON.stringify(values["humidityObservation"]);
          const currentStateFloor=JSON.stringify(values["currentStateFloor"]);
          const finalEndedDescription=JSON.stringify(values["finalEndedDescription"]);
          const lightingObservation=JSON.stringify(values["lightingObservation"]);
          const energyObservation=JSON.stringify(values["energyObservation"]);
          const wallRoofObservation=JSON.stringify(values["wallRoofObservation"]);
          const quantityDays=JSON.stringify(values["quantityDays"]);
          const quantityHours=JSON.stringify(values["quantityHours"]);
          const IQQ_Description=JSON.stringify(values["IQQ_Description"]);
          const proceduresExecution=JSON.stringify(values["proceduresExecution"]);
          const generalObservations=JSON.stringify(values["generalObservations"]);
          const proyectSupervisorName=JSON.stringify(values["proyectSupervisorName"]);
          const identificationSupervisor=JSON.stringify(values["identificationSupervisor"]);
          const identificationContractor=JSON.stringify(values["identificationContractor"]);
          const Consecutive=JSON.stringify(values["Consecutive"]);
          const Contract_Number=JSON.stringify(values["Contract_Number"]);

          sendRequestCreateIDA(JSON.parse(paymentOrder), JSON.parse(quotationCode), JSON.parse(unevennessQuantity), 
          JSON.parse(unevennessObservation), JSON.parse(humidityObservation), JSON.parse(currentStateFloor), JSON.parse(finalEndedDescription), 
          JSON.parse(lightingObservation), JSON.parse(energyObservation), JSON.parse(wallRoofObservation), JSON.parse(quantityDays), JSON.parse(quantityHours),
          JSON.parse(IQQ_Description),JSON.parse(proceduresExecution), JSON.parse(generalObservations), JSON.parse(proyectSupervisorName),JSON.parse(identificationSupervisor),
          JSON.parse(identificationContractor), JSON.parse(Consecutive), JSON.parse(Contract_Number));
        }}
        validationSchema={yup.object().shape({
            paymentOrder: yup
            .string()  
            .min(1, 'La Orden de Compra debe contener como minimo 1 caracter')
            .max(20, 'La Orden de Compra debe contener como máximo 20 caracteres'),
            quotationCode: yup
            .string()  
            .min(1, 'El código de cotización debe tener como minimo 1 caracter')
            .max(20, 'El código de cotización debe tener como máximo 20 caracteres'),
            unevennessQuantity: yup
            .number('Este campo únicamente acepta valores numéricos')
            .max(100, 'La cantidad máxima de desniveles no puede superar 100'),
            unevennessObservation: yup
            .string()
            .min(5, 'El valor minimo permitido para almacenar una observación son 5 caracteres')
            .max(300, 'El valor máximo permitido para almacenar una observación son 300 caracteres'),
            humidityObservation: yup
            .string()
            .min(5, 'El valor minimo permitido para almacenar una observación son 5 caracteres')
            .max(300, 'El valor máximo permitido para almacenar una observación son 300 caracteres'),
            currentStateFloor: yup
            .string()
            .min(5, 'El valor minimo permitido para almacenar una observación son 5 caracteres')
            .max(300, 'El valor máximo permitido para almacenar una observación son 300 caracteres')
            .required('Este campo es completamente obligatorio'),
            finalEndedDescription: yup
            .string()
            .min(5, 'El valor minimo permitido para almacenar una observación son 5 caracteres')
            .max(300, 'El valor máximo permitido para almacenar una observación son 300 caracteres')
            .required('Este campo es completamente obligatorio'),
            lightingObservation: yup
            .string()
            .min(5, 'El valor minimo permitido para almacenar una observación son 5 caracteres')
            .max(300, 'El valor máximo permitido para almacenar una observación son 300 caracteres'),
            energyObservation: yup
            .string()
            .min(5, 'El valor minimo permitido para almacenar una observación son 5 caracteres')
            .max(300, 'El valor máximo permitido para almacenar una observación son 300 caracteres'),
            wallRoofObservation: yup
            .string()
            .min(5, 'El valor minimo permitido para almacenar una observación son 5 caracteres')
            .max(300, 'El valor máximo permitido para almacenar una observación son 300 caracteres'),
            quantityDays: yup
            .number('Este campo únicamente acepta valores numéricos')
            .max(365, 'El número maximo permitido para almacenar en este campo es 365 dias')
            .required('Este campo es completamente obligatorio'),
            quantityHours: yup
            .number('Este campo únicamente acepta valores numéricos')
            .max(23, 'El número maximo permitido para almacenar en este campo es 23 horas'),
            IQQ_Description: yup
            .string()  
            .min(4, 'La descripción para cantidad inicial cotizada debe tener como minimo 4 caracteres')
            .max(300, 'La descripción para cantidad inicial cotizada debe tener como máximo 300 caracteres'),
            proceduresExecution: yup
            .string()
            .min(5, 'El valor minimo permitido para almacenar una observación son 5 caracteres')
            .max(300, 'El valor máximo permitido para almacenar una observación son 300 caracteres')
            .required('Este campo es completamente obligatorio'),
            generalObservations: yup
            .string()
            .min(5, 'El valor minimo permitido para almacenar una observación son 5 caracteres')
            .max(300, 'El valor máximo permitido para almacenar una observación son 300 caracteres'),
            proyectSupervisorName: yup
            .string()
            .min(3, 'El valor minimo permitido para el campo Nombre Supervisor es de 3 caracteres')
            .max(30, 'El valor máximo permitido para el campo Nombre Supervisor es de 30 caracteres')
            .required('Este campo es completamente obligatorio'),
            identificationSupervisor: yup
            .number('Este campo únicamente acepta valores numéricos')
            .min(100000, 'El número minimo permitido para almacenar en este campo es 100000')
            .max(999999999999, 'El número maximo permitido para almacenar en este campo es 1000000000')
            .required('Este campo es completamente obligatorio'),
            identificationContractor: yup
            .number('Este campo únicamente acepta valores numéricos')
            .min(100000, 'El número minimo permitido para almacenar en este campo es 100000')
            .max(999999999999, 'El número maximo permitido para almacenar en este campo es 1000000000')
            .required('Este campo es completamente obligatorio'),
            Consecutive: yup
            .string()  
            .min(1, 'El Nombre de Áreas debe tener como minimo 1 caracter')
            .max(50, 'El Nombre de Áreas debe tener como máximo 50 caracteres'),
            Contract_Number: yup
            .string()  
            .min(1, 'El número de contrato debe tener como minimo 1 caracter')
            .max(50, 'El número de contrato debe tener como máximo 50 caracteres'),
            })}
       >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
<View style={styles.formContainer}>
          
<View style={styles.formGroupDates}>
<View style={styles.containerLeft}>
<Text style={styles.FormStylesText}>Orden de Compra:</Text> 
</View>
<View  style={styles.dataInputs}>
             <TextInput 
             placeholder="00000000-1"
             value={values.paymentOrder}
             onChangeText={handleChange('paymentOrder')}
             onBlur={() => setFieldTouched('paymentOrder')}
             style={styles.inputForm}
             />
            {touched.paymentOrder && errors.paymentOrder &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.paymentOrder}</Text>
            }
            
</View>
</View>

<View style={styles.formGroupDates}>
<View style={styles.containerLeft}>
<Text style={styles.FormStylesText}>Nombre de Áreas:</Text> 
</View>
<View  style={styles.dataInputs}>
             <TextInput 
             placeholder="00000000-1"
             value={values.Consecutive}
             onChangeText={handleChange('Consecutive')}
             onBlur={() => setFieldTouched('Consecutive')}
             style={styles.inputForm}
             />
            {touched.Consecutive && errors.Consecutive &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Consecutive}</Text>
            }
            
</View>
</View>

<View style={styles.formGroupDates}>
<View style={styles.containerLeft}>
<Text style={styles.FormStylesText}># Contrato:</Text> 
</View>
<View  style={styles.dataInputs}>
             <TextInput 
             placeholder="00000000-1"
             value={values.Contract_Number}
             onChangeText={handleChange('Contract_Number')}
             onBlur={() => setFieldTouched('Contract_Number')}
             style={styles.inputForm}
             />
            {touched.Contract_Number && errors.Contract_Number &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Contract_Number}</Text>
            }
            
</View>
</View>

<View style={styles.formGroupDates}>
<View style={styles.containerLeft}>
<Text style={styles.FormStylesText}>Código Cotización:</Text> 
</View>
<View  style={styles.dataInputs}>
             <TextInput 
             placeholder="000000"
             value={values.quotationCode}
             onChangeText={handleChange('quotationCode')}
             onBlur={() => setFieldTouched('quotationCode')}
             style={styles.inputForm}
             />
            {touched.quotationCode && errors.quotationCode &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.quotationCode}</Text>
            }
</View>
</View>
     

<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>¿El proyecto será ejecutado bajo techo?:</Text>
</View>
{indoors()}

<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>Escoge las condiciones de servicio:</Text>
</View>

{showConditions()}

<Text style={styles.liquidityTable}>Cantidad Inicial Cotizada</Text>
<View style={styles.inputContainer}>
        <TextInput
          style={styles.input}
          placeholder="Descripción"
          placeholderTextColor="#ccc"
          value={newItem.description}
          onChangeText={(text) => setNewItem({ ...newItem, description: text })}
        />
        <TextInput
          style={styles.input}
          placeholder="Cant. Inicial Cotizada"
          placeholderTextColor="#ccc"
          keyboardType="numeric"
          value={newItem.qQuotized}
          onChangeText={(text) => setNewItem({ ...newItem, qQuotized: text })}
        />
        <View style={styles.ListSelectedContainer}>
      <Text style={styles.RightTitleSelect}>Selecciona la unidad de medida:</Text>
      </View>
      {showMeasures()}
        <TouchableOpacity style={styles.addButton} onPress={handleAddItem}>
          <Text style={styles.buttonText}>+</Text>
        </TouchableOpacity>
      </View>
<ScrollView horizontal={true}>
      <FlatList
        data={dataLiquidity}
        renderItem={({ item }) => (
          <View style={styles.itemContainer}>
            <Text style={styles.itemText}>{`Item: ${item.id}`}</Text>
            <Text style={styles.itemText}>{`Descripción: ${item.description}`}</Text>
            <Text style={styles.itemText}>{`Cant. Inicial Cotizada: ${''.concat(item.qQuotized, ' ', item.measure)} `}</Text>
            <TouchableOpacity style={styles.deleteButton} onPress={() => handleDeleteItem(item.id)}>
              <AntDesign name="closecircle" size={20} color="#FF7777" />
            </TouchableOpacity>
          </View>
        )}
        keyExtractor={(item) => item.id}
      />
</ScrollView>

<Text style={styles.ProceduresExecution}>Observación Cantidad Inicial Cotizada:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {8}
              value={values.IQQ_Description}
              style={styles.inputStyle}
              onChangeText={handleChange('IQQ_Description')}
              onBlur={() => setFieldTouched('IQQ_Description')}
              placeholder=" "
            />
            {touched.IQQ_Description && errors.IQQ_Description &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.IQQ_Description}</Text>
            } 

<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>¿La losa presenta pendiente?:</Text>
</View>
{unevenness()}

<View style={styles.formGroupDates}>
<View style={styles.containerLeft}>
<Text style={styles.FormStylesText}>Cantidad desniveles:</Text> 
</View>
<View  style={styles.dataInputs}>
             <TextInput 
             placeholder="0"
             value={values.unevennessQuantity}
             onChangeText={handleChange('unevennessQuantity')}
             onBlur={() => setFieldTouched('unevennessQuantity')}
             style={styles.inputForm}
             />
</View>
{touched.unevennessQuantity && errors.unevennessQuantity &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.unevennessQuantity}</Text>
            }
</View>

<Text style={styles.ProceduresExecution}>Observación desniveles:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {8}
              value={values.unevennessObservation}
              style={styles.inputStyle}
              onChangeText={handleChange('unevennessObservation')}
              onBlur={() => setFieldTouched('unevennessObservation')}
              placeholder=" "
            />
            {touched.unevennessObservation && errors.unevennessObservation &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.unevennessObservation}</Text>
            }

<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>¿El sustrato presenta ondulaciones?:</Text>
</View>
{undulationsFn()}

<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>¿El sustrato esta nivelado?:</Text>
</View>
{levelingFn()}

<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>Especifica nivel de humedad:</Text>
</View>

{showHumidityLevel()}

<Text style={styles.ProceduresExecution}>Observación de humedad:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {8}
              value={values.humidityObservation}
              style={styles.inputStyle}
              onChangeText={handleChange('humidityObservation')}
              onBlur={() => setFieldTouched('humidityObservation')}
              placeholder=" "
            />
            {touched.humidityObservation && errors.humidityObservation &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.humidityObservation}</Text>
            }

<Text style={styles.ProceduresExecution}>Descripción estado actual del sustrato:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {8}
              value={values.currentStateFloor}
              style={styles.inputStyle}
              onChangeText={handleChange('currentStateFloor')}
              onBlur={() => setFieldTouched('currentStateFloor')}
              placeholder=" "
            />
            {touched.currentStateFloor && errors.currentStateFloor &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.currentStateFloor}</Text>
            }
<Text style={styles.ProceduresExecution}>Descripción terminado final del sustrato:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {8}
              value={values.finalEndedDescription}
              style={styles.inputStyle}
              onChangeText={handleChange('finalEndedDescription')}
              onBlur={() => setFieldTouched('finalEndedDescription')}
              placeholder=" "
            />
            {touched.finalEndedDescription && errors.finalEndedDescription &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.finalEndedDescription}</Text>
            }

<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>Indica el nivel de iluminación del área:</Text>
</View>

{showLightLevel()}           

<Text style={styles.ProceduresExecution}>Observación iluminación:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {8}
              value={values.lightingObservation}
              style={styles.inputStyle}
              onChangeText={handleChange('lightingObservation')}
              onBlur={() => setFieldTouched('lightingObservation')}
              placeholder=" "
            />
            {touched.lightingObservation && errors.lightingObservation &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.lightingObservation}</Text>
            }

<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>Selecciona el tipo de energía disponible:</Text>
</View>

{showEnergyType()}

<Text style={styles.ProceduresExecution}>Observación energia:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {8}
              value={values.energyObservation}
              style={styles.inputStyle}
              onChangeText={handleChange('energyObservation')}
              onBlur={() => setFieldTouched('energyObservation')}
              placeholder=" "
            />
            {touched.energyObservation && errors.energyObservation &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.energyObservation}</Text>
            }   


<Text style={styles.ProceduresExecution}>Observación paredes y techos:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {8}
              value={values.wallRoofObservation}
              style={styles.inputStyle}
              onChangeText={handleChange('wallRoofObservation')}
              onBlur={() => setFieldTouched('wallRoofObservation')}
              placeholder=" "
            />
            {touched.wallRoofObservation && errors.wallRoofObservation &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.wallRoofObservation}</Text>
            }                      
         
<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>Horario acordado para la ejecución del proyecto:</Text>
</View>

{showHoraryType()}

<View style={styles.formGroupDates}>
<View style={styles.containerLeft}>
<Text style={styles.FormStylesText}>Cantidad de dias:</Text> 
</View>
<View  style={styles.dataInputs}>
             <TextInput 
             placeholder="0"
             value={values.quantityDays}
             onChangeText={handleChange('quantityDays')}
             onBlur={() => setFieldTouched('quantityDays')}
             style={styles.inputForm}
             />
</View>
{touched.quantityDays && errors.quantityDays &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.quantityDays}</Text>
            }
</View>

<View style={styles.formGroupDates}>
<View style={styles.containerLeft}>
<Text style={styles.FormStylesText}>Cantidad de horas:</Text> 
</View>
<View  style={styles.dataInputs}>
             <TextInput 
             placeholder="0"
             value={values.quantityHours}
             onChangeText={handleChange('quantityHours')}
             onBlur={() => setFieldTouched('quantityHours')}
             style={styles.inputForm}
             />
</View>
{touched.quantityHours && errors.quantityHours &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.quantityHours}</Text>
            }
</View>

<Text style={styles.ProceduresExecution}>Procedimientos a ejecutar:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {10}
              value={values.proceduresExecution}
              style={styles.inputStyleProceduresExecution}
              onChangeText={handleChange('proceduresExecution')}
              onBlur={() => setFieldTouched('proceduresExecution')}
              placeholder=" "
            />
            {touched.proceduresExecution && errors.proceduresExecution &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.proceduresExecution}</Text>
            }
        
<Text style={styles.ProceduresExecution}>Observaciones Generales:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {10}
              value={values.generalObservations}
              style={styles.inputStyleProceduresExecution}
              onChangeText={handleChange('generalObservations')}
              onBlur={() => setFieldTouched('generalObservations')}
              placeholder=" "
            />
            {touched.generalObservations && errors.generalObservations &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.generalObservations}</Text>
            }

               <View style={styles.ButtonCenterTeam}>
            <TouchableOpacity style={styles.ButtonCenterTeam} onPress={()=>{
              setTypeSignature([{typeDoc:'IDA-', typeSign:'-ES'}]);
              Navigation.navigate('Create_Signatures');
              }}>
            <FontAwesome name="group" size={24} color="white" />
              <Text style={styles.TextStylesFinished}>Firma Supervisor Proyecto</Text>
              </TouchableOpacity>
            </View> 

            <TextInput style={styles.Signature} placeholder="Nombre Supervisor"
            value={values.proyectSupervisorName}
             onChangeText={handleChange('proyectSupervisorName')}
             onBlur={() => setFieldTouched('proyectSupervisorName')}
             />
            {touched.proyectSupervisorName && errors.proyectSupervisorName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.proyectSupervisorName}</Text>
            }


            <TextInput style={styles.Signature} placeholder="Número identificación Supervisor"
              value={values.identificationSupervisor} 
             onChangeText={handleChange('identificationSupervisor')}
             onBlur={() => setFieldTouched('identificationSupervisor')}
             />
            {touched.identificationSupervisor && errors.identificationSupervisor &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.identificationSupervisor}</Text>
            }



            <View style={styles.ButtonCreateProject}>
            <TouchableOpacity style={styles.ButtonCreateProject} onPress={()=>{
              setTypeSignature([{typeDoc:'IDA-', typeSign:'-CI'}]);
              Navigation.navigate('Create_Signatures');
          }}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
              <Text style={styles.TextStylesFinished}>Firma Representante ConstruEpoxicos</Text>
              </TouchableOpacity>
            </View> 
            <TextInput style={styles.Signature} placeholder="Número identificación Representante"
            value={values.identificationContractor}
            onChangeText={handleChange('identificationContractor')}
            onBlur={() => setFieldTouched('identificationContractor')}
            />
           {touched.identificationContractor && errors.identificationContractor &&
             <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.identificationContractor}</Text>
           }

  { (disableSubmit===true) ?  
      <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished  }>
      <Ionicons name="create" size={20} color="black" />
        <Text style={styles.TextStylesSave}>Procesando</Text>
        </TouchableOpacity>
      :  
            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished  }
            disabled={!isValid} onPress={()=>{setShowAlert(true)}}>
            <Ionicons name="create" size={20} color="black" />
              <Text style={styles.TextStylesSave}>Guardar Acta</Text>
              </TouchableOpacity>
          }

<View style={styles.ViewSpace}></View>

<AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />


          </View>
        )}
      </Formik>

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            if (dataNavigation[2] !== undefined) {
              Navigation.navigate('SubProject');
            } else {
              Navigation.navigate('Project');
            }
          }}
        />

    </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
    backgroundColor:'#111111',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10,
    backgroundColor:'#c0c0c0',
  },
  formGroupDates: {
    flex:1,
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  containerLeft: {
    textAlign:'left',
    justifyContent:'center',
    width:'50%'
  },
  containerLeftMeasure: {
    textAlign:'left',
    justifyContent:'center',
    width:'48%',
    verticalAlign:'bottom',
    paddingTop:18
  },
  dataInputs: {
    alignItems:'center',
    marginTop:10,
    justifyContent:'center',
    borderRadius:10,
    width:'50%',
    backgroundColor:'white'
  },
  DropDownSelection: {
    marginTop:10,
    marginBottom:10,
    backgroundColor:'white',
    borderRadius:15,
    padding:10,
  },
  MeasureSelection: {
    margin:5,
    marginBottom:10,
    backgroundColor:'white',
    borderRadius:10,
    width:'48%'
  },
  TittleScreen:{
    color:'#D0F3FF',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#1C1E23',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10
  },
  ButtonCreateProject: {
    backgroundColor:'#4d83a5',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonCenterTeam: {
    backgroundColor:'#373737',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  TextStylesSave: {
    color:'#000000',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewSpace: {
    marginTop:10,
    width:'100%'
  },
 FormStylesText:{
   color:'#f1f1f1',
   marginTop:12
 },
  inputStyle:  {
      width:'100%',
      height:80,
      borderRadius:10,
      padding:10,
      marginTop:10,
      textAlign:'justify',
      textAlignVertical:'top',
      backgroundColor:'#dbd9d9'
    },
    inputStyleProceduresExecution:  {
      width:'100%',
      height:150,
      borderRadius:10,
      padding:10,
      marginTop:10,
      textAlign:'justify',
      textAlignVertical:'top',
      backgroundColor:'#dbd9d9'
    },
    ProceduresExecution:{
      marginTop:10,
      color:'#f9f9f9',
      width:'100%'
    },
    Signature:{
      marginTop:10,
      marginLeft:20,
      marginRight:20,
      backgroundColor:'white',
      borderRadius:10,
      height:40,
      paddingLeft:10,
      textAlign:'center'
    },
    ContainerSearch: {
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    },
    SearchInput: {
      backgroundColor:'#767a7a',
      width:'85%',
      textAlign:'left',
      borderRadius:10,
      marginTop:40,
      paddingLeft:10
    },
    IconSearch:{
      width:'10%',
      marginTop:40,
      marginLeft:10
    },
    TableStyles: {
      textAlign:'center',
      alignItems:'center',
      justifyContent:'center',
      width:'100%'
    },
    ListSelectedContainer: {
      width:'100%',
      marginTop:5
    },
 
  LeftSelect:{
    width:'10%',
    textAlign:'left',
    marginTop:10,
    color:'#f1f1f1',
   
  },
  RightSelect:{
    textAlign:'left',
    color:'#f3f3f3',
    borderRadius:10,
    backgroundColor:'#232325',
    padding:10
  },

  RightTitleSelect:{
    textAlign:'left',
    marginTop:10,
    marginBottom:10,
    fontSize:14,
    fontWeight:'bold',
    color:'#bdecda'
  },
  Row:{
    textAlign:'left',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
  },
  
  Text:{
    color:'#f1f1f1',
    fontSize:12
  },
  TextStyleLoading: {
    color:'#fa93b4',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#bccdd4',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonFinished: {
    backgroundColor:'#8edbff',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  inputForm:{
    padding:5
  },
  inputContainer: {
    marginBottom: 10,
  },
  input: {
    marginBottom: 5,
    paddingHorizontal: 10,
    paddingVertical: 8,
    backgroundColor: '#333',
    borderRadius: 5,
    color: '#fff',
  },
  addButton: {
    backgroundColor: '#CDF3CD',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    marginTop:10,
    width: 30,
    height: 30,
  },
  buttonText: {
    color: '#121212',
    fontSize: 20,
  },
  itemContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    backgroundColor: '#333',
    paddingVertical: 8,
    paddingHorizontal: 50,
    borderRadius: 5,
    flex: 1,
    margin: 1,
  },
  itemText: {
    color: '#fff',
    marginBottom: 5,
  },
  deleteButton: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    width: 30,
    height: 30,
  },
  liquidityTable:{
    color:'#E8F7F7',
    fontWeight:'bold',
    fontSize:16,
    textAlign:'center',
    padding:20
  }
});

export default Create_Initial_Delivery_Agreement

