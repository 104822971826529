import React, {useState, useContext, useEffect} from 'react'
import { StyleSheet,Text, View, TextInput, ScrollView, Button } from 'react-native'
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import SvgIconLogo from '../../svg_components/SvgIconLogo';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { AntDesign } from '@expo/vector-icons';
import Context from '../../../context/Context';
//For making requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
  

const Projects_Created = () => {

  const Navigation = useNavigation();

    //Search on Table:
    const [search, setSearch]=useState('0');
    const  {setDataNavigation, dataNavigation, updateRequest, dataSession, identificationUser}= useContext(Context);
    const [arrayProjects, setArrayProjects]= useState([]);

     
    ;
    

    const Projects_Created=(e, index)=>{
      
        return ( 
          <TouchableOpacity style={styles.Container} onPress={()=> {
            if (dataNavigation[1]===undefined) {
            setDataNavigation((i)=>[...i, {projectChosen:e.id, groupQuotation:''}])
            } else {
              setDataNavigation([dataNavigation[0],{projectChosen:e.id, groupQuotation:''}])
            }
            Navigation.navigate('Project')

       }} key={index}> 
          <Text style={styles.TextScreenLeft}>Nombre:</Text> 
          <Text style={styles.TextScreenRightBold}>{e.nameProject}</Text>
          <Text style={styles.TextScreenLeft}>Fecha Creación:</Text> 
          <Text style={styles.TextScreenRight}>{e.dateCreation}</Text>
          <Text style={styles.TextScreenLeft}>Dirección:</Text> 
          <Text style={styles.TextScreenRight}>{e.address}</Text>
          <Text style={styles.TextScreenLeft}>Ubicación:</Text> 
          <Text style={styles.TextScreenRight}>{e.location}</Text>
          <Text style={styles.TextScreenLeft}>Ciudad:</Text> 
          <Text style={styles.TextScreenRight}>{e.city}</Text>
          <Text style={styles.TextScreenLeft}>Estado:</Text> 
          <Text style={styles.TextScreenRightBold}>{e.state}</Text>
          
          </TouchableOpacity>
        )
       
    }

const sendRequest=()=>{
    if (dataNavigation.length>0) {
    //Get:
    executeRequest('/psp-data', {
      "Get_Projects": [
          {
              "body": {
                  "_Type_Request": "List_Projects_Information",
                  "_Client_ID": dataNavigation[0].clientChosen,
                  "_Project_ID": null,
                  "_SubProject_ID": null,
                  "_Group_Quotation":null,
                  "_Group_Quotation_SubProject":null,
                  "_User_ID":((dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b")  && identificationUser.length>0) ? identificationUser[0]["id"] : null
              }
          }
      ]
    }, 'get', 'post').then(
          getData=>{
            setArrayProjects(getData)
          }
        )

        } else { null }
      }
      
      useEffect(()=>{
          sendRequest();
      },[updateRequest])


      useEffect(()=>{
        sendRequest();
    },[])

      const showData=()=>{
        if (arrayProjects !== null && (arrayProjects.length)>0) {
        return (
            <>
            {
            (search!=='0') 
            &&
            arrayProjects.filter((e)=>(e.nameProject.toLowerCase().includes((search).toLowerCase()))).map((e, index)=>(
              Projects_Created(e, index)
              )) 
            }
            {
            (search==='0' || search==='' || search=== null || search=== undefined) &&
            arrayProjects.map((e, index)=>(
              Projects_Created(e, index)
            ))
             }
            </>
          )
        } else { null }
      }


    
    return (
      
      <View style={styles.RootContainer}>
      <View style={styles.MainContainer}>
      <Text style={styles.TittleScreen}>{(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b")  ?  "Proyectos Asignados" :
    "Proyectos Creados"
    }</Text>
    <Text style={styles.TextHigh}>Especifica el nombre del proyecto para realizar una búsqueda personalizada:</Text>
    <View style={styles.ContainerSearch}>

    {/* <Button title='Press me for Reset' onPress={()=>{setDataNavigation(dataNavigation[0]); console.log(dataNavigation)}}/> */}

    <TextInput title='Search' placeholder='Nombre del Proyecto' style={styles.SearchInput}
     onChangeText={(e) => { 
    (e==='' || e=== null || e=== undefined)?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    <ScrollView style={styles.Scroller} >
        {showData()}
        </ScrollView>

      </View>
      </View>
     
    )
  }

  const styles = StyleSheet.create({
    Scroller:{
      backgroundColor:'#111111'
    },
    RootContainer: {
      flex:1,
      width:'100%',
      padding:10,
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#111111'
    },
    MainContainer: {
      flex:1
    },
    Container:{
      borderRadius:15,
      flexGrow: 1,
      marginTop:20,
      marginBottom:20,
      marginLeft:5,
      marginRight:5,
      backgroundColor:'#151515',
      elevation: 10,
      shadowColor: '#ffffff',
      paddingLeft:25,
      paddingRight:25,
      paddingTop:25,  
      paddingBottom:25, 
      // padding:25,
      justifyContent:'space-between',
      flexWrap:'wrap',
      flexDirection: 'row',
      alignItems:'flex-start'
    },
    TextScreenLeft: {
      color: '#989898',
      fontWeight: 'bold',
      fontSize: 14,
      width:'35%',

    },
    TextScreenRight: {
      color: '#dbede9',
      fontSize: 14,
      width:'60%',
    },
    TextScreenRightBold:{
      color: '#D9EDFF',
      fontSize: 14,
      fontWeight:'bold',
      width:'60%'
    },
    TextView: {
      color: '#2f2f2f',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop:10
    },
    InProgress: {
      color:'#04620f',

    },
    Finished: {
      color:'#343434',
    },
    ContainerSearch: {
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    },
    SearchInput: {
      backgroundColor:'#767a7a',
      width:'75%',
      height:25,
      textAlign:'left',
      borderRadius:10,
      marginTop:40,
      paddingLeft:10,
      marginLeft:30
    },
    IconSearch:{
      width:'10%',
      marginTop:40,
      marginLeft:20
    },
    TextHigh: {
      textAlign:'left',
      color:'#EDF0F5',
      paddingTop:'5%',
      paddingLeft:'5%',
      paddingRight:'5%',
      marginBottom:5
    },
    TittleScreen:{
      color:'#61AAE3',
      margin:10,
      fontSize:22,
      fontWeight:'bold',
      textAlign:'center'
    },
  });  

export default Projects_Created
