import React, { useState, useContext, useEffect } from 'react';
import { ScrollView,TextInput, Text, View, StyleSheet, Pressable } from 'react-native';
import { FlatList } from 'react-native-gesture-handler'
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Context from '../../../../context/Context';
import { AntDesign } from '@expo/vector-icons';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
import AwesomeAlert from 'react-native-awesome-alerts';
import { storeData, getData } from '../../../functional_global_components/LocalStorageManager';

const Create_Entry_Sheet_PSP =()=>  {

    const [showAlert, setShowAlert]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [response, setResponse]=useState('');
    const [disableSubmit, setDisableSubmit]=useState(false);
    //Search on Table:
    const [search, setSearch]=useState('0');
    const [itemSelected, setItemSelected] = useState([{id:'0', name:'', measure:'', unitValue:'', type:'',quantity:'',totalValue:'', brand:'',consecutive:''},]);
    const {dataNavigation, identificationUser, setUpdateRequest, updateRequest } = useContext(Context);
    const [arrayItems, setArrayItems]= useState([]);
    const Navigation = useNavigation();



/*Formating Values to Country: */
function formatCurrency (locales, currency, fractionDigits, number) {

  var formatted = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fractionDigits
  }).format(number);
  return formatted;

}

  useEffect(()=>{
    const saveLocalData = async () => {

        try {
          if (itemSelected[1] !== undefined) {
            await storeData((dataNavigation[2] !== undefined) ? "C_EntrySheet_".concat(dataNavigation[2].subProjectChosen) : "C_EntrySheet_".concat(dataNavigation[1].projectChosen), itemSelected) 
          } else null;
        } catch (err) {
          console.log(err);
        }
    }
    saveLocalData();
 }, [itemSelected])

  const requestBasicDataInventory=()=>{
    //Get:
    executeRequest('/entry-sheet', {
          "Get_Basic_Data_Inventory_for_Entry_Sheet": [
            {
              "body": {
                "_Type_Request": "Get_Basic_Data_Inventory_for_Entry_Sheet",
                "_Project_ID":(dataNavigation[1] !== undefined) ?  dataNavigation[1].projectChosen : null,
                "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayItems(getData);
              }
          )
      }

  useEffect(()=>{
    requestBasicDataInventory();
    // Recover and Set Data Local Storage or AsyncStorage:
    const fetchData = async () => {
      const data = await getData((dataNavigation[2] !== undefined) ? "C_EntrySheet_".concat(dataNavigation[2].subProjectChosen) : "C_EntrySheet_".concat(dataNavigation[1].projectChosen));
      if (data.length > 0 && data[1] !== undefined) {
        setItemSelected(data);
      } else {console.log('No existen datos para recuperar')};
    };
    fetchData();
  },[])
  
  const sendRequest=()=>{
    setDisableSubmit(true);
    setShowAlert(false);
    //Create:
    executeRequest('/entry-sheet', {
          "Create_Entry_Sheet": [
            {
              "body": {
                "_Final_Value_Project": null,
                "_User_Creator_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
                "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null,
                "_Project_ID":(dataNavigation[1] !== undefined) ?  dataNavigation[1].projectChosen : null,
                "_Client_ID": (dataNavigation[0] !== undefined) ? dataNavigation[0].clientChosen : null,
                "_Data_Json": (itemSelected.length>0) ? itemSelected : null
              }
            }
          ]
        }, 'post', 'post').then(
              getData=>{
                setResponse(getData[0].Response);
                setUpdateRequest(!updateRequest);
                setShowAlertFinished(true);
                setDisableSubmit(false);
            }
        )
    }
   

  const showDataItems=()=>{

    if (arrayItems.length>0) {
    return (
      <ScrollView horizontal={true}>
      <View style={styles.TableStyles}>
      <FlatList 
      data={
        (search!=='0') ? 
        arrayItems.filter((e)=>(e.itemName.toLowerCase().includes((search).toLowerCase())))
        :
        arrayItems
      }
      renderItem={item} 
      updateCellsBatchingPeriod={1}
      removeClippedSubviews={true}
      ListHeaderComponent={()=>{
      
      return(
      <View style={styles.ViewRows}>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}><AntDesign name="checkcircleo" size={18} color="#96CCFE" /></Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Item</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Marca</Text>
      </View>
    </View>
    
    )}}
      /> 
    </View>
    </ScrollView>
        )
      }  else { null }
  }


  const updateValueQuantity = (id, quant) => {
    if (isNaN(Number(quant)) || quant === null || quant.trim() === '') {
        // Update itemSelected with New Quantity:
        const updatedItems = itemSelected.map(item =>
          item.id === id
            ? { ...item, quantity: '', totalValue: '' }
            : item
        );
        // Update State With new Data:
        setItemSelected(updatedItems);
    } else {
      // Update itemSelected with new Data:
      const updatedItems = itemSelected.map(item =>
        item.id === id
          ? { ...item, quantity: quant, totalValue: quant * item.unitValue }
          : item
      );
      // Update State With New Data:
      setItemSelected(updatedItems);
    }
  };


/************StartTableRows*****************/
const item= ({item, index})=>{

  return (

    <Pressable  onPress={()=>{ (itemSelected.find(e=>e.id===item.hashID)) ?
      setItemSelected(itemSelected.filter(e=>e.id!==item.hashID)) 
      :
     setItemSelected((e)=>[...e, {id:item.hashID, name:item.itemName, measure:item.measure, unitValue:item.unitValue, type:item.itemType ,quantity:'',totalValue:'', brand:item.brand, consecutive: item.consecutive}])
    }}
  style={{backgroundColor:(itemSelected.map(e=>e.id).indexOf(item.hashID)!==-1)? '#161b20' : '#0d1013' 
            ,  flexDirection:'row',
            alignItems:'center',}}>
      <View style={styles.Row} >
        <Text style={styles.Text}>{index + 1}</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.itemName}</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.brand}</Text>
      </View>
    </Pressable>

  )
  
}
/************EndTableRows*****************/
/************StartTable2Rows*****************/
const item_Selected= ({item})=>{

  // Find the match item on array:
  const findItem = itemSelected.find((x) => x.id === item.id);
  const quantity = findItem?.quantity?.toString() || '';

  // Update Quantity on State:
  const handleQuantityChange = (newQuantity) => {
    updateValueQuantity(item.id, newQuantity);
  };

  return (
    <View style={styles.ViewRows}>
      <View style={styles.Row}>
        <Text style={styles.TextRows}>{item.name}</Text>
      </View>
      <View style={styles.Row}>
      <TextInput
          style={styles.QuantitySelected}
          value={quantity}
          placeholder='?'
          keyboardType='numeric'
          onChangeText={handleQuantityChange}
        />
      </View>
      <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.measure}</Text>
       </View>
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{formatCurrency("es-CO", "COP", 2, item.unitValue)}</Text>
       </View>
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{item.type}</Text>
       </View>
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{formatCurrency("es-CO", "COP", 2, item.totalValue)}</Text>
       </View>
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{
          item.brand
      }</Text>
       </View>
       <View style={styles.Row}>
      <Text style={styles.TextRows}>{
          item.consecutive
      }</Text>
       </View>
      </View>
  )

}
/************EndTable2Rows*****************/

    return (
<ScrollView style={styles.ViewRoot}>
   
  
<View style={styles.formContainer}>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Crear Hoja de Ingreso</Text>
</View>
     
<View  style={styles.DropDownSelection}>

    <Text style={styles.TextHigh}>En el siguiente apartado puedes realizar una búsqueda personalizada teniendo presente los items que deseas agregar a la  Hoja de Ingreso, ten presente que unicamente aparecerán los items relacionados en la Hoja de Salida inicialmente creada:</Text>
    <View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder='Buscar producto específico' style={styles.SearchInput}
     onChangeText={(e) => {
    (e==='')?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    {showDataItems()}


{(itemSelected[1]) &&
   <>
        <Text style={styles.TextHigh}>Lista de Items seleccionados:</Text>
        
        <ScrollView horizontal={true}>          
        <View style={styles.TableStyles}> 
        
        <FlatList 
                        data={ 
                        itemSelected.filter(e=>e.id!=='0')
                             }
                        renderItem={item_Selected} 
                        ListHeaderComponent={()=>{
                        
                        return(
                        <View style={styles.ViewRows}>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Item</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cantidad</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>U. Medida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Valor Unit.</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Tipo</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Valor Total</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Marca</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Consecutivo</Text>
                        </View>
                      </View>
                      
                      )}}
                        />  
        </View>
        </ScrollView>
        </>
}

{ (disableSubmit===true) ?  
       <TouchableOpacity style={styles.ButtonFinished}>
       <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
         <Text style={styles.TextStylesFinished}>Procesando</Text>
         </TouchableOpacity>
      :
          
            <TouchableOpacity style={styles.ButtonFinished} onPress={()=>{setShowAlert(true)}}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
              <Text style={styles.TextStylesFinished}>Crear</Text>
              </TouchableOpacity>
           
        }

          </View>

          <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se llevará a cabo la creación de la nueva Hoja de Ingreso, este proceso agregará automáticamente los items especificados al inventario, adicionalmente los valores asociados a costos de implementación serán calculados nuevamente."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            sendRequest();
            
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />


     </View>
   
      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            if (dataNavigation[2] !== undefined) {
              Navigation.navigate('SubProject');
            } else {
              Navigation.navigate('Project');
            }
          }}
        />

      </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10
  },
  FormStylesText:{
    color:'#f1f1f1'
  },
  formGroupDates: {

    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  inputStyle:{
    paddingLeft:10,
    textAlign:'center'
  },
  AutoSumValue: {
    alignItems:'center',
    marginTop:10,
    justifyContent:'center',
    borderRadius:10,
    width:'50%',
    padding:5,
    backgroundColor:'#323232'
  },
  AutoSum:{
    color:'#8efcd1'
  },
  DatesInput: {
    alignItems:'center',
    marginTop:10,
    justifyContent:'center',
    borderRadius:10,
    width:'50%',
    padding:5,
    backgroundColor:'#a9a9a9'
  },
  DatesPicked: {
    textAlign:'left',
    justifyContent:'center',
    width:'40%',
    marginTop:10,
  },
  DropDownSelection: {
    marginTop:10
  },
  TittleScreen:{
    color:'#77C6E1',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewRoot:{
     backgroundColor:'#111111',
     width:'100%',
     height:'100%'
  },
  ViewTittleScreen:{
    backgroundColor:'#404040',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:40
  },
  ButtonCenterFinished: {
    backgroundColor:'#4d83a5',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  Row:{
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  
  ContainerSearch: {
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'center',
  },
  SearchInput: {
    backgroundColor:'#767a7a',
    width:'75%',
    height:35,
    textAlign:'left',
    borderRadius:10,
    marginTop:10,
    paddingLeft:10,
    marginLeft:20
  },
  IconSearch:{
    width:'10%',
    marginTop:10,
    marginLeft:10
  },
  TextHigh: {
    textAlign:'left',
    color:'#d0dff3',
    paddingTop:5,
    paddingLeft:'5%',
    paddingRight:'5%',
    marginBottom:5
  },
  PropertiesView:{
    backgroundColor:'#111111',
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    marginTop:5
  },
  
  Text:{
    width:120,
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
  },
  TextHeader:{
    color:'#96CCFE',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TextRows:{
    color:'#f1f1f1',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TableStyles: {
    backgroundColor:'#181818',
  },
  TextInput:{
    width:60,
    backgroundColor:'grey',
    borderRadius:7,
    color:'#f1f1f1',
    textAlign:'center',
    borderRightWidth:1,
    borderRightColor:'#393939',
    alignContent:'center',
    marginLeft:30,
    marginRight:30
  },
  ListSelectedContainer: {
    flex:1,
    flexDirection:'row',
    flexWrap:'wrap',
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
    marginTop:10
  },
  LeftSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    color:'#f1f1f1'
  },
  CenterSelect:{
    width:'18%',
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    marginTop:10,
    color:'#f1f1f1'
  },
  RightSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    color:'#f1f1f1'
  },

  LeftTitleSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  CenterTitleSelect:{
    width:'18%',
    textAlign:'center',
    borderRadius:10,
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  RightTitleSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  QuantitySelected:{
    width:120,
    textAlign:'center',
    backgroundColor:'#3a3f4a',
    borderRadius:10,
    height:35,
    color:'#f1f1f1'
  },
  ButtonFinished: {
    backgroundColor:'#1d2732',
    borderRadius:10,
    marginTop:20,
    paddingTop:15,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#737373',
    borderRadius:10,
    marginTop:20,
    paddingTop:15,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStyleLoading: {
    color:'#fa93b4',
    fontSize:16,
    paddingTop:5,
    fontWeight:'bold',
    textAlign:'center'
  },
});

export default Create_Entry_Sheet_PSP
