import React from 'react';
import {StyleSheet,Text,TouchableOpacity} from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Fontisto } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';

export const Products_Created_Btn = () => {
    const Navigation = useNavigation();

    return (
        <>
    <TouchableOpacity style={styles.Container} onPress={()=>{Navigation.navigate('Products_Created')}}> 
      <MaterialIcons name='inventory' size={30} color='#b4fff3' /> 
      <Text style={styles.TextView}>Items Creados</Text>
    </TouchableOpacity>
        </>
        )
    }

export  const Create_New_Product_Btn = () => {
    const Navigation = useNavigation();

        return (
            <>
            <TouchableOpacity style={styles.ContainerNewProject} onPress={()=> Navigation.navigate('Create_New_Product')} >
            <Ionicons name="create" size={30} color="#dab4ff" />
            <Text style={styles.TextView}>Crear Nuevo Item</Text>
            </TouchableOpacity>
            </>
            )
        }

export  const Add_Existing_Product_Btn = () => {
          const Navigation = useNavigation();
      
              return (
                  <>
                  <TouchableOpacity style={styles.ContainerClients} onPress={()=> Navigation.navigate('Add_Existing_Products')} >
                  <MaterialIcons name="add-business" size={30} color="#b9ffcd" />
                  <Text style={styles.TextViewLight}>Agregar Items Existentes</Text>
                  </TouchableOpacity>
                  </>
                  )
              }

export  const Deliveries_Btn = () => {
    const Navigation = useNavigation();
        
         return (
            <>
            <TouchableOpacity style={styles.ContainerTeams} onPress={()=> Navigation.navigate('Deliveries')} >
            <AntDesign name="dropbox" size={30} color="#ffe0b4" />
            <Text style={styles.TextView}>Entregas</Text>
            </TouchableOpacity>
            </>
        )
    }

export  const Auditory_Btn = () => {
    const Navigation = useNavigation();
        
         return (
            <>
        <TouchableOpacity style={styles.ContainerClients} onPress={()=> Navigation.navigate('Main_Audit')} >
        <MaterialCommunityIcons name="store-check" size={30} color="#f99696" />
        <Text style={styles.TextViewLight}>Auditoría</Text>
        </TouchableOpacity>
            </>
        )
    }

const styles = StyleSheet.create({
  Container:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#555555',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerNewProject:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#262726',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerTeams:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#555555',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerClients:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#1e2128',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  TextView: {
    color: '#e0e0f6',
    fontWeight: 'bold',
    fontSize: 16,
    marginTop:10,
    fontWeight: 'bold'
  },
  TextViewLight: {
    color: '#e0e0f6',
    fontWeight: 'bold',
    fontSize: 16,
    marginTop:10,
    fontWeight: 'bold'
  },
  ContainerRequest:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#0E0E0E',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
});