import React from 'react'
import { StyleSheet,View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { StatusBar } from 'react-native';
import { Ionicons } from '@expo/vector-icons';


const Clients = () => {
  
  const Navigation = useNavigation();

  return (
    <View style={styles.Root}>
    <View style={styles.RootContainer}>
    <View style={styles.MainContainer}>
    <Text style={styles.TitleScreen}>Administrar Clientes</Text>
    <TouchableOpacity style={styles.Container} onPress={()=> Navigation.navigate('Clients_Created')}> 
    <MaterialCommunityIcons name="office-building-cog" size={30} color="#abffe2" />
      <Text style={styles.TextView}>Clientes Creados</Text>
    </TouchableOpacity>
    <TouchableOpacity style={styles.ContainerNewProject} onPress={()=> Navigation.navigate('Create_Client')} >
  
    <Ionicons name="ios-create" size={30} color="#abf4ff" />
    <Text style={styles.TextView}>Nuevo Cliente</Text>
    </TouchableOpacity>
    </View>
    <StatusBar barStyle="light-content" backgroundColor="black" />
    
    </View>
    </View>
  )
}

const styles = StyleSheet.create({
  Root:{
    backgroundColor:'black',
    flex:1,
  },
  RootContainer: {
    flex:1,
    width:'100%',
    padding:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#111111',
    borderTopLeftRadius:30,
    borderTopRightRadius:30
  },
  MainContainer: {
    flex:1,
     width:'70%',
  },
  Container:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#555555',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerNewProject:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#252937',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerTeams:{
    alignItems:'center',
    justifyContent:'center',
    borderRadius:10,
    width:'100%',
    height:100,
    marginTop:20,
    elevation: 20,
    backgroundColor:'#aaaaa8',
    shadowColor: '#aaaaa8',
  },
  ContainerRequest:{
    alignItems:'center',
    justifyContent:'center',
    borderRadius:10,
    width:'100%',
    height:100,
    marginTop:80,
    elevation: 20,
    backgroundColor:'#4aa9a9',
    shadowColor: '#4aa9a9',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 14
  },
  TextView: {
    color: '#e0e0f6',
    fontWeight: 'bold',
    fontSize: 16,
    marginTop:10,
    fontWeight: 'bold'
  },
  TitleScreen:{
    color:'#ABD6FF',
    margin:25,
    fontSize:20,
    fontWeight:'bold',
    textAlign:'center'
  },
});


export default Clients
