import React, {useState, useContext, useEffect} from 'react'
import { StyleSheet,Text, View, TextInput, ScrollView, Button } from 'react-native'
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import Context from '../../../context/Context';
//For making requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
  

const Deliveries = () => {

  const Navigation = useNavigation();

    //Search on Table:
    const [search, setSearch]=useState('0');
    const  {setDataNavigationRequest, updateRequest}= useContext(Context);
    const [arrayRequests, setArrayRequests]= useState([]);

   
    

    const Requests_Created=(e, index)=>{
      
        return ( 
          <TouchableOpacity style={styles.Container} onPress={()=> {
            setDataNavigationRequest([{requestChosen:e.id}])
            Navigation.navigate('Delivery_Detail');
       }} key={index}> 
          
          <Text style={styles.titleRequest}>{e.requestTitle}</Text>
          <Text style={styles.TextScreenLeft}>Solicitante:</Text> 
          <Text style={styles.TextScreenRight}>{e.fullName}</Text>
          <Text style={styles.TextScreenLeft}>Identificación:</Text> 
          <Text style={styles.TextScreenRight}>{e.identificationCode}</Text>
          <Text style={styles.TextScreenLeft}>Rol:</Text> 
          <Text style={styles.TextScreenRightHighlight}>{e.rol}</Text>
          <Text style={styles.TextScreenLeft}>Fecha Solicitud:</Text> 
          <Text style={styles.TextScreenRight}>{e.dateRequest}</Text>
          <Text style={styles.TextScreenLeft}>{(e.nameSubProject !== null) ? "SubProyecto:"  : "Proyecto:" }</Text> 
          <Text style={styles.TextScreenRightSubProject}>{(e.nameSubProject !== null) ? e.nameSubProject  : e.nameProject }</Text>
          <Text style={styles.TextScreenLeft}>Cliente:</Text> 
          <Text style={styles.TextScreenRight}>{e.clientName}</Text>
          <Text style={styles.TextScreenLeft}>NIT:</Text> 
          <Text style={styles.TextScreenRight}>{e.NIT}</Text>
          <Text style={styles.TextScreenLeft}>Estado:</Text> 
          <Text style={styles.TextScreenRightHighlight}>{e.state}</Text>

          </TouchableOpacity>

        )
       
    }


    const sendRequest=()=>{
      //Get:
      executeRequest('/requests', {
        "Get_Requests": [
          {
            "body": {
              "_Type_Request": "Get_All_Requests_For_Delivering",
              "_Request_ID": null,
              "_User_ID": null
            }
          }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayRequests(getData)
            }
          )
      }

      useEffect(()=>{
        sendRequest();
    },[]);

    useEffect(()=>{
      sendRequest();
  },[updateRequest]);
    
    const showData=()=>{
      if (arrayRequests !== null && (arrayRequests.length)>0) {
      return (
          <>
          {
          (search!=='0') 
          &&
          arrayRequests.filter((e)=>(e.fullName.toLowerCase().includes((search).toLowerCase()))).map((e, index)=>(
            Requests_Created(e, index)
            )) 
          }
          { 
          (search!=='0') 
          &&
          arrayRequests.filter((e)=>(e.identificationCode.toLowerCase().includes((search).toLowerCase()))).map((e, index)=>(
            Requests_Created(e, index)
            )) 
          }
          {
          (search==='0' || search==='' || search=== null || search=== undefined) &&
          arrayRequests.map((e, index)=>(
            Requests_Created(e, index)
          ))
           }
          </>
        )
      } else { null }
    }



    
    return (
      
      <View style={styles.RootContainer}>
      <View style={styles.MainContainer}>
      <Text style={styles.TittleScreen}>Entregas Pendientes</Text>
    <Text style={styles.TextHigh}>Especifica el nombre del solicitante o la cédula para realizar una búsqueda personalizada:</Text>
    <View style={styles.ContainerSearch}>


    <TextInput title='Search' placeholder='Nombre o cédula del solicitante' style={styles.SearchInput}
     onChangeText={(e) => { 
    (e==='' || e=== null || e=== undefined)?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>

    <ScrollView style={styles.Scroller} >
        {showData()}
        </ScrollView>

      </View>
      </View>
     
    )
  }

  const styles = StyleSheet.create({
    Scroller:{
      backgroundColor:'#111111'
    },
    RootContainer: {
      flex:1,
      width:'100%',
      padding:10,
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#111111'
    },
    MainContainer: {
      flex:1
    },
    Container:{
      borderRadius:15,
      flexGrow: 1,
      marginTop:5,
      marginBottom:10,
      marginLeft:5,
      marginRight:5,
      backgroundColor:'#151515',
      elevation: 5,
      shadowColor: '#ffffff',
      paddingLeft:25,
      paddingRight:25,
      paddingTop:25,
      paddingBottom:25,    
      // padding:25,
      justifyContent:'space-between',
      flexWrap:'wrap',
      flexDirection: 'row',
      alignItems:'flex-start'
    },
    TextScreenLeft: {
      color: '#78baeb',
      fontWeight: 'bold',
      fontSize: 14,
      width:'35%',

    },
    TextScreenRight: {
      color: '#dbede9',
      fontSize: 14,
      width:'60%',

    },
    TextView: {
      color: '#2f2f2f',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop:10
    },
    InProgress: {
      color:'#04620f',

    },
    Finished: {
      color:'#343434',
    },
    ContainerSearch: {
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    },
    SearchInput: {
      backgroundColor:'#767a7a',
      width:'75%',
      height:25,
      textAlign:'left',
      borderRadius:10,
      marginTop:40,
      paddingLeft:10,
      marginLeft:30
    },
    IconSearch:{
      width:'10%',
      marginTop:40,
      marginLeft:20
    },
    TextHigh: {
      textAlign:'left',
      color:'#EDF0F5',
      paddingTop:'5%',
      paddingLeft:'5%',
      paddingRight:'5%',
      marginBottom:5
    },
    TittleScreen:{
      color:'#61AAE3',
      margin:10,
      fontSize:22,
      fontWeight:'bold',
      textAlign:'center'
    },
    titleRequest:{
      color:'#adfffa',
      fontWeight:'bold',
      textAlign:'center',
      justifyContent:'center',
      alignItems:'center',
      margin:10,
      width:'100%'
    },
    TextScreenRightSubProject: {
      color: '#ff6868',
      fontSize: 14,
      width:'60%',
    },
    TextScreenRightHighlight: {
      color: '#b2ff8b',
      fontSize: 14,
      width:'60%'

    },
  });  

export default Deliveries
