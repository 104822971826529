import React, {useContext} from 'react'
import { StyleSheet,View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import { StatusBar } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import Context from '../context/Context';

const Admin = () => {
  
  const Navigation = useNavigation();
  const {setDisconnectSession, dataSession}= useContext(Context);
  if (dataSession["user"] != undefined && dataSession["IdentificationCode"] != undefined) {
  return (
    <View style={styles.Root}>
    <View style={styles.RootContainer}>
    <Text style={styles.TitleScreen}>Gestión de Usuarios</Text>
    <View style={styles.MainContainer}>
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b" ||
     dataSession["role"]==='69fb4a85-f453-11ed-b65a-0697dd88345b') &&
    <TouchableOpacity style={styles.Container} onPress={()=> Navigation.navigate('Users_Created')}> 
    <Entypo name="users" size={24} color="#baf3ff" />
      <Text style={styles.TextView}>Usuarios Creados</Text>
    </TouchableOpacity>
  }
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") &&
    <TouchableOpacity style={styles.ContainerNewProject} onPress={()=> Navigation.navigate('Create_New_User')} >
    <Entypo name="add-user" size={24} color="#c3ffba" />
    <Text style={styles.TextView}>Crear Nuevo Usuario</Text>
    </TouchableOpacity> 
    }
    </View>
    <StatusBar barStyle="light-content" backgroundColor="black" />
    
    </View>
    </View>
  );
} else {
    setDisconnectSession(true)
    }
}

const styles = StyleSheet.create({
  Root:{
    backgroundColor:'black',
    flex:1,
  },
  RootContainer: {
    flex:1,
    width:'100%',
    padding:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#111111'
  },
  MainContainer: {
    flex:1,
     width:'70%',
  },
  Container:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#555555',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerNewProject:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#252937',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  ContainerTeams:{
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    height:80,
    marginTop:10,
    backgroundColor:'#555555',
    borderTopLeftRadius:  30,
    borderBottomRightRadius:  30
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 14
  },
 
  TitleScreen:{
    margin:20,
    textAlign:'center',
    fontWeight:'bold',
    fontSize:20,
    color:'#a3fbd5'
  },

  TextView: {
    color: '#e0e0f6',
    fontWeight: 'bold',
    fontSize: 16,
    marginTop:10,
    fontWeight: 'bold'
  },
});


export default Admin