import React, { useState,Component, useEffect, useContext } from 'react';
import { ScrollView,TextInput, Text, Button, Alert, View, StyleSheet, Platform, Pressable } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
//For making requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
import AwesomeAlert from 'react-native-awesome-alerts';
import { SafeAreaView,FlatList } from 'react-native-gesture-handler'
import { Feather } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import Context from '../../../context/Context'


const Create_Team =()=>  {

    const {setUpdateRequest,updateRequest}=useContext(Context);
    const Navigation = useNavigation();
    const [showAlert, setShowAlert]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [response, setResponse]=useState('');
    const [disableSubmit, setDisableSubmit]=useState(false);

     //Search on Table:
     const [search, setSearch]=useState('0');
     const [usersSelected, setUsersSelected] = useState([{id:'0', fullName:''}]);
     const [arrayUsers, setArrayUsers]=useState([]);
     const [allowSelectAll, setAllowSelectAll]=useState(false);

      
     
     

const sendRequest=(TeamName,TeamDescription)=>{
        setDisableSubmit(true);
        setShowAlert(false);
        //Create:
        executeRequest('/teams', {
          "Create_Team": [
            {
              "body": {
                "_TeamName": TeamName,
                "_TeamDescription": TeamDescription,
                "_Users":usersSelected
              }
            }
          ]
        }, 'post', 'post').then(
              getData=>{
                setResponse(getData[0].Response);
                setUpdateRequest(!updateRequest);
                setShowAlertFinished(true);
                setDisableSubmit(false);
              }
            )        
  
      }


  const sendRequestUsers=()=>{
        //Get:
        executeRequest('/users', {
          "Get_Users": [
            {
              "body": {
                "_Type_Request": "Get_Basic_Data_Users_Created",
                "_IdentificationCode":null,
                "_User_ID": null
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayUsers(getData)
              }
            )
        }

        useEffect(()=>{
          sendRequestUsers();
        },[])
      

/************StartTableRows*****************/

const item= ({item})=>{
    
  return (
    <Pressable  onPress={()=>{(usersSelected.find(e=>e.id===item.id)) ? setUsersSelected(usersSelected.filter(e=>e.id!==item.id)) 
     : setUsersSelected((e)=>[...e, {id:item.id, fullName:item.fullName}])
    }}
  style={{backgroundColor:(usersSelected.map(e=>e.id).indexOf(item.id)!==-1)? '#333334' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.fullName}</Text>
      </View>
      <View style={styles.Row}>
        {(usersSelected.map(e=>e.id).indexOf(item.id)!==-1)? <Feather name="check-circle" size={18} color="white" style={styles.Text}/>  : <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={18} color="grey" style={styles.Text}/> }
      </View>
    </Pressable>

  )
  
}
/************EndTableRows*****************/

 
    return (
<ScrollView style={styles.ViewRoot}>
   
      <Formik
        initialValues={{
          TeamName: '',
          Description: ''
        }}
        onSubmit={values => {
          const TeamName=JSON.stringify(values["TeamName"]);
          const TeamDescription=JSON.stringify(values["Description"]);
          sendRequest(JSON.parse(TeamName), JSON.parse(TeamDescription));
        }}
        validationSchema={yup.object().shape({
            TeamName: yup
            .string() 
            .min(3, 'El nombre del equipo debe tener como minimo 3 caracteres')
            .max(30, 'El nombre del equipo no puede exceder 30 caracteres')
            .required('Por favor especifique un nombre para el equipo'),
            Description: yup
            .string()
            .min(4, 'La descripción del equipo debe tener minimo 4 caracteres')  
            .max(150, 'La descripción del equipo no puede superar los 150 caracteres'),
        })}
       >

        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <View style={styles.formContainer}>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Crear Equipo</Text>
</View>
            <TextInput
              value={values.TeamName}
              style={styles.inputStyle}
              onChangeText={handleChange('TeamName')}
              onBlur={() => setFieldTouched('TeamName')}
              placeholder="Nombre del Equipo"
            />
            {touched.TeamName && errors.TeamName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.TeamName}</Text>
            }
            <TextInput
              value={values.Description}
              style={styles.inputStyleDescription}
              onChangeText={handleChange('Description')}
              onBlur={() => setFieldTouched('Description')}
              placeholder="Descripción"
              multiline = {true}
              numberOfLines = {5}
            />

            {touched.Description && errors.Description &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Description}</Text>
            }
            

    <View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder='Buscar usuario específico' style={styles.SearchInput}
     onChangeText={(e) => {
    (e==='')?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    <AntDesign name="select1" size={24} color="#90e8ff" style={styles.IconSearch} onPress={()=>{
      setAllowSelectAll(!allowSelectAll);
      (allowSelectAll===true) ?
      arrayUsers.map(x=>setUsersSelected((e)=>[...e, {id:x.id, fullName:x.fullName}]))
      : 
      setUsersSelected([{id:'0', fullName:''}])
      }}/>
    </View>

{ (arrayUsers.length>0) ?
    <ScrollView horizontal={true}>
    <View style={styles.TableStyles}>
    <FlatList 
      data={
        (search==='0') ? 
        arrayUsers.slice(0,10)
        :
        arrayUsers.filter((e)=>(e.fullName.toLowerCase().includes((search).toLowerCase())))
      }
      renderItem={item} 
      
      />  
    </View>
    </ScrollView>
    : null
  }
<View style={styles.ListSelectedContainer}>
<Text style={styles.RightTitleSelect}>Usuarios Seleccionados</Text>
</View>

{

 usersSelected.filter(e=>e.id!=='0').map(e=>{
  return(
  <View key={e.id} style={styles.ListSelectedContainer}>
  <Text style={styles.RightSelect}>{e.fullName}</Text>
  </View>
       )
    })

 }

<View style={styles.spaceButton}>
      { (disableSubmit===true) ?  
       <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished  } disabled={!isValid}>
          <MaterialCommunityIcons name="rocket-launch" size={20} color="#6abe99" />
            <Text style={styles.TextStylesFinished}>Procesando</Text>
            </TouchableOpacity>
      :

            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished  } disabled={!isValid} onPress={()=>{
          setShowAlert(true); 
              }}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="#6abe99" />
              <Text style={styles.TextStylesFinished}>Crear</Text>
              </TouchableOpacity>
         
        }
</View>
            <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
            
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />

  </View>

        )}

      </Formik>

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
          }}
        />
   
      </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10
  },
  formGroupDates: {
    flex:1,
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  DatesInput: {
    alignItems:'center',
    justifyContent:'center',
    borderRadius:30,
    width:'35%',
    height:60,
  },
  DropDownSelection: {
    marginTop:10,
    backgroundColor:'#505050',
    padding:5,
    borderRadius:10,
  },
  TittleScreen:{
    color:'#B5EAFF',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewRoot:{
     backgroundColor:'#111111',
     width:'100%',
     height:'100%'
  },
  ViewTittleScreen:{
    backgroundColor:'black',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:40
  },
  ButtonCenterFinished: {
    backgroundColor:'#151e42',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonFinished: {
    backgroundColor:'#1F1F20',
    borderRadius:10,
    marginTop:10,
    paddingTop:15,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#bccdd4',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  TableStyles: {
    backgroundColor:'#181818',
    width:'100%',
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  inputStyle:  {
    width:'100%',
    height:40,
    borderRadius:10,
    padding:10,
    marginTop:10,
    textAlign:'justify',
    textAlignVertical:'center',
    backgroundColor:'#9f9f9f'
  },
  inputStyleDescription:{
    width:'100%',
    height:80,
    borderRadius:10,
    padding:10,
    marginTop:10,
    textAlign:'justify',
    textAlignVertical:'top',
    backgroundColor:'#9f9f9f'
  },
  TextStyleLoading: {
    color:'#fa93b4',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ContainerSearch: {
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'center',
  },
  SearchInput: {
    backgroundColor:'#767a7a',
    width:'70%',
    height:25,
    textAlign:'left',
    borderRadius:10,
    marginTop:40,
    paddingLeft:10
  },
  IconSearch:{
    width:'10%',
    marginTop:40,
    marginLeft:15
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
  },
  Row:{
    textAlign:'left',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
  },
  
  Text:{
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    width:200
  },
  TextHeader:{
    color:'#B4DCEC',
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
   ListSelectedContainer: {
    width:'100%',
    height:50,
    verticalAlign:'middle'
  },

  LeftSelect:{
    width:'10%',
    textAlign:'left',
    marginTop:10,
    color:'#f1f1f1',
   
  },
  RightSelect:{
    textAlign:'center',
    textAlignVertical:'center',
    verticalAlign:'middle',
    color:'#f3f3f3',
    borderRadius:10,
    padding:10,
    backgroundColor:'#232325',
    fontWeight:'bold'
  },

  RightTitleSelect:{
    textAlign:'center',
    marginTop:10,
    marginBottom:10,
    fontSize:18,
    fontWeight:'bold',
    color:'#B5EAFF'
  },
  spaceButton: {
    marginTop:25
  }

});

export default Create_Team
