import { StyleSheet,View, Text, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StatusBar } from 'react-native';
import { useContext, useEffect } from 'react';
//Activity Indicator de momento esto no sirve:
// import { ScrollView } from 'react-native-gesture-handler';
import Context from '../context/Context';
/*Import components UI:*/
import {Products_Created_Btn, Create_New_Product_Btn, Deliveries_Btn, Auditory_Btn, Add_Existing_Product_Btn} from './components_ui/Components_Inventory'
import {returnScrts} from './functional_global_components/Validate_Session';

const Inventory = () => {

  const  {activityIndicator,setActivityIndicator, setDisconnectSession, dataSession, setMQTTObject}= useContext(Context);

  useEffect(()=>{
    //MQTT:  
    returnScrts().then(scrts=>setMQTTObject({MQTT:scrts.Host_MQTT, Port:scrts.Port_MQTT, Usr: scrts.User_MQTT, Pwd:scrts.pwrd_MQTT}));
  },[]) 
  
  //Redirect to Products Created ussing Activity Indicator:
  useEffect( () => {
    (activityIndicator===true)
    ?
    (
      setTimeout(() => {
      Navigation.navigate('Products_Created')
    }, 1)
    )
    :
    null
  }, [activityIndicator])
 
  const Navigation = useNavigation();

  if (dataSession["user"] != undefined && dataSession["IdentificationCode"] != undefined) {
  return (
    <ScrollView style={styles.Root}>
    <View style={styles.RootContainer}>
    <View style={styles.MainContainer}>
    <Text style={styles.TitleScreen}>{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b")
     ? "Gestión de Inventarios" : "Consultar Inventario"}</Text>
      {/* Allow access to Gerencia, Administrativo, Bodega And Auditor: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b"
    || dataSession["role"]==="69fb4a85-f453-11ed-b65a-0697dd88345b") &&
    <Products_Created_Btn/>
    }
     {/* Allow access to Gerencia y Bodega: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b") &&
    <>
    <Create_New_Product_Btn/>
    <Add_Existing_Product_Btn/>
    </>
    }
    {/* Allow access to Gerencia, Administrativo y Bodega: */}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b") &&
    <Deliveries_Btn/>
    }
    
    {/* Allow access to Gerencia and Auditor:*/}
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4a85-f453-11ed-b65a-0697dd88345b") &&
    <>
    <Text style={styles.TitleScreen}>Programar & Consultar Auditorias</Text>
    <Auditory_Btn/>
    </>
    
    }
    </View>
    <StatusBar barStyle="light-content" backgroundColor="black" />
    </View>

    </ScrollView>
  )
  } else {
  setDisconnectSession(true)
  }

}

const styles = StyleSheet.create({
  Root:{
    backgroundColor:'#111111',
    flex:1,
  },
  RootContainer: {
    flex:1,
    width:'100%',
    padding:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#111111',
    borderTopLeftRadius:30,
    borderTopRightRadius:30
  },
  MainContainer: {
    flex:1,
     width:'80%',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 14
  },
  TitleScreen:{
    color:'#c1fffe',
    margin:20,
    fontSize:20,
    fontWeight:'bold',
    textAlign:'center'
  },
});


export default Inventory
