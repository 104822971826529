import React, {useContext, useEffect, useState} from 'react';
import { TextInput, Button,Text, View, StyleSheet, Dimensions, TouchableOpacity, Linking} from 'react-native';
import * as yup from 'yup' /*Forms validator */
import { Formik } from 'formik' /*Forms Handler */
import { StatusBar } from 'expo-status-bar';
import Svg, {Path} from 'react-native-svg';
import { useNavigation } from '@react-navigation/native';
import awsconfig from '../aws-exports';
import {Amplify, Auth} from 'aws-amplify';
import { LinearGradient } from 'expo-linear-gradient';
import Context from '../context/Context';
import {axiosGetScrts ,executeRequest} from "../views/functional_global_components/API_Requests";

Amplify.configure(awsconfig);  

const {width, height}= Dimensions.get('window');   


const Login =()=>{

const [errorResponse, setErrorResponse] = useState('');
const  {setDataSession, sessionStatus, setSessionStatus, setIdentificationUser, setEmailConfirmation, setArrayClients, setMQTTObject}= useContext(Context);
const Navigation = useNavigation();
const [disableSubmit, setDisableSubmit]=useState(false);


useEffect(()=>{
if (sessionStatus===true) {
setTimeout(()=>{
  setDataSession({});
  setMQTTObject({});
  setSessionStatus(false);
}
, 3000)
}
},[])

// useEffect(()=>{
//   (sessionStatus===false) ? 
//   setTimeout(()=>setDataSession({}), 3000)
//   : null
// },[sessionStatus])

const requestUserData=(dataSession)=>{
  const jsonValue = JSON.stringify(dataSession);
  const jsonParsedValue = JSON.parse(jsonValue);
  executeRequest('/users', {
    "Get_Users": [
      {
        "body": {
          "_Type_Request": "Get_Specific_User_Data",
          "_IdentificationCode": jsonParsedValue.IdentificationCode,
          "_User_ID": null
          }
        }
      ]
    }, 'get', 'post').then(
      getData=>{
        setIdentificationUser(getData)
      }
    )
  }

  const sendRequestClients=()=>{
    executeRequest('/psp-data', {
      "Get_Projects": [
        {
          "body": {
            "_Type_Request": "List_Client_Information",
            "_Client_ID": null,
            "_Project_ID": null,
            "_SubProject_ID": null,
            "_Group_Quotation": null,
            "_Group_Quotation_SubProject":null,
            "_User_ID": null
          }
        }
      ]
    }, 'get', 'post').then(
        getData=>{
          setArrayClients(getData)
        }
      )
    }
     


async function signIn(Username, Pass) {
  setDisableSubmit(true);
  const signInOptions = {
    username: Username,
    password: Pass,
    options: {
      timeout: 60000
    }
  };
  try {
      const user = await Auth.signIn(signInOptions);
      if (user.signInUserSession.accessToken.jwtToken !== undefined && user.signInUserSession.accessToken.jwtToken !== null &&
         user.signInUserSession.accessToken.jwtToken.length>0) {
        const dataSession={"user":user.attributes["email"], "role":user.attributes["custom:role"], "state":user.attributes["custom:state"], "IdentificationCode":user.attributes["custom:userIdentification"]};
        const jsonValue = JSON.stringify(dataSession);
        setDataSession(JSON.parse(jsonValue));
        setErrorResponse('');
        axiosGetScrts(user.attributes["email"], user.keyPrefix, user.attributes["custom:state"], user.attributes.sub)
        .then(result=>{
          if (result===true) {
          Navigation.navigate('MyTabsNavigator');
          requestUserData(dataSession);
          sendRequestClients();
          setDisableSubmit(false);
                } else {
                  setErrorResponse('Se ha detectado inestabilidad en tu conexión de red, por favor intenta realizar el proceso de inicio de sesión nuevamente.');
                  setDisableSubmit(false);
                }
            }
          )
        
        } else {
        setDisableSubmit(false);
        setErrorResponse('Error al intentar efectuar el login de usuario, las credenciales proporcionadas son invalidas.');
        }
    }
     catch (error) {
      setErrorResponse('Se ha presentado un error a nivel de red, por favor valida la estabilidad de tu conexión a internet.');
        setDisableSubmit(false);
      if (Object.values(error)[0]==='UserNotConfirmedException') {
        Navigation.navigate('Confirm_Account');
      } else if (Object.values(error)[0]==='NetworkError') {
        setErrorResponse('Se ha detectado inestabilidad en tu conexión de red, por favor revisa la estabilidad de tu conexión a internet. Si el problema persiste comunicate con tu proveedor de internet.');
      } else {
        setErrorResponse('El usuario o la contraseña especificados son incorrectos, por favor ingrese nuevamente las credenciales');
        setDisableSubmit(false);
      }

  }

}


    function SvgTop(props){
      return(
        <Svg width={width} height="100%" {...props}>
    <Path fill="#111" d="M0 0h1920v1080H0z" />
    <Path
      fill="#183740"
      d="m0 308 53.3-7.8c53.4-7.9 160-23.5 266.7-25.9 106.7-2.3 213.3 8.7 320 4.9 106.7-3.9 213.3-22.5 320-17.5 106.7 5 213.3 33.6 320 34.8 106.7 1.2 213.3-25.2 320-22.8 106.7 2.3 213.3 33.3 266.7 48.8L1920 338V0H0Z"
    />
    <Path
      fill="#0f484d"
      d="m0 163 53.3 4.7c53.4 4.6 160 14 266.7 24.3 106.7 10.3 213.3 21.7 320 33.8C746.7 238 853.3 251 960 246c106.7-5 213.3-28 320-35.3 106.7-7.4 213.3 1 320 12.1 106.7 11.2 213.3 25.2 266.7 32.2l53.3 7V0H0Z"
    />
    <Path
      fill="#0e5a55"
      d="m0 156 53.3-4c53.4-4 160-12 266.7-18.2 106.7-6.1 213.3-10.5 320-5.1 106.7 5.3 213.3 20.3 320 25 106.7 4.6 213.3-1 320 1.8 106.7 2.8 213.3 14.2 320 10.2 106.7-4 213.3-23.4 266.7-33l53.3-9.7V0H0Z"
    />
    <Path
      fill="#236b55"
      d="m0 85 53.3 5.5c53.4 5.5 160 16.5 266.7 14.7 106.7-1.9 213.3-16.5 320-27.4C746.7 67 853.3 60 960 60.5c106.7.5 213.3 8.5 320 12.8 106.7 4.4 213.3 5 320 11.7 106.7 6.7 213.3 19.3 266.7 25.7l53.3 6.3V0H0Z"
    />
  </Svg>
      )
    }

    return (
      <View style={styles.mainContainer}>
      <View style={styles.containerSvg}>
      <SvgTop width="100%" height="100%"/> 
      </View>
        <View style={styles.container}>
        {/* <SvgLogo/> */}

       <View style={styles.ContainerTittleLogin}>
        <Text style={styles.FirstTitle}>Check</Text>
        <Text style={styles.SecondTitle}>Out</Text>
        <Text style={styles.FirstTitleBottom}>Business</Text>
        </View>

           
      <View style={styles.ContainerLogin}>
        

      <Formik /*Initial values to evaluate:*/
        initialValues={{ 
          email: '', 
          password: '' 
        }}
        onSubmit={values => {
          const email=JSON.stringify(values["email"]);
          const password=JSON.stringify(values["password"]);
          setEmailConfirmation(JSON.parse(email));
          signIn(JSON.parse(email), JSON.parse(password));

          }
        }  /*Values for send with JSON Format, this happen when handleSubmit catch the information sent*/
        validationSchema={yup.object().shape({ /*form validations with yup object with shapes:*/
          email: yup
            .string()
            .email('El campo E-mail debe ser un correo valido')
            .required('El campo E-mail es requerido'),
          password: yup
            .string()
            .min(4, 'La contraseña debe tener como minimo 4 caracteres')
            .max(20, 'La contraseña no puede exceder los 20 caracteres')
            .required('El campo Password es requerido'),
        })}
       >
      {/* props to handler the errors, events and submiting data: */}
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <View style={styles.ContainerLogin}>   
          <Text style={styles.subTitle}> Inicia sesión en tu cuenta</Text>       
            <TextInput
              value={values.email}
              style={styles.textInput}
              onChangeText={handleChange('email')}
              onBlur={() => setFieldTouched('email')}
              placeholder="E-mail"
            />
            {touched.email && errors.email &&
              <Text style={{ fontSize: 12, color: '#fa7251' }}>{errors.email}</Text>
            }
            <TextInput
              value={values.password}
              style={styles.textInput}
              onChangeText={handleChange('password')}
              placeholder="Password"
              onBlur={() => setFieldTouched('password')}
              secureTextEntry={true}
            />
            {touched.password && errors.password &&
              <Text style={{ fontSize: 12, color: '#fa7251' }}>{errors.password}</Text>
            }
            {(errorResponse !='') &&
            <Text style={styles.errorResponse}>{errorResponse}</Text>
            }
            <Text style={styles.forgotPassword} onPress={()=>{Navigation.navigate('Forgot_Password')}}>Olvidé mi contraseña</Text>

          {/* <Button 
              title='Login'
              disabled={!isValid}
              onPress={handleSubmit}
            /> */}
 
 { (disableSubmit===true) ? 
         <TouchableOpacity style={styles.containerButton}>
          <LinearGradient colors={(!isValid===true) ? ['#808080', '#434e64'] : ['#0b3a24', '#27bf78'] }
          start={{x:0, y:0}}
          end={{x:1, y:1}}
          style={styles.button}  
          >
          <Text style={styles.text}>Procesando</Text>
        </LinearGradient>
             </TouchableOpacity>
             :
             <TouchableOpacity disabled={!isValid} onPress={handleSubmit} style={styles.containerButton}>
          <LinearGradient colors={(!isValid===true) ? ['#808080', '#434e64'] : ['#0b3a24', '#27bf78'] }
          start={{x:0, y:0}}
          end={{x:1, y:1}}
          style={styles.button}  
          >
          <Text style={styles.text}>Login</Text>
        </LinearGradient>
             </TouchableOpacity>
}
{/* <TouchableOpacity style={styles.containerButton}>
          <LinearGradient colors={(!isValid===true) ? ['#808080', '#435B64'] : ['#0B2F3A', '#2791BF'] }
          start={{x:0, y:0}}
          end={{x:1, y:1}}
          style={styles.offlineButton}  
          >
          <Text style={styles.offlineText}>Funciones Offline</Text>
        </LinearGradient>
             </TouchableOpacity> */}

            <Text style={styles.dontHaveAccount} onPress={()=>{setErrorResponse('Por favor contacta el área de administración de ConstruEpoxicos para realizar la creación del nuevo usuario. Gracias')}}>Aun no tengo una cuenta</Text>
            {(errorResponse==='') ? 
            <Text style={styles.termsConditions} onPress={()=>{ Linking.openURL('https://check-out-business.com/Terms_Conditions_Privacy_Policy_&_Cookies_COB.html')}}>Al utilizar esta aplicación aceptas nuestros terminos y condiciones de uso, junto a las políticas de privacidad y uso de cookies</Text>
            : null}
          </View>
        )}
      </Formik>
      
      </View>
      
      </View>
               <StatusBar style="auto" />
      </View>
    );
  


}

export default Login;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor:'black',
    height:'100%'
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop:80

  },
  containerSvg: {
    width:'100%',
    height:'100%',
    backgroundColor:'black',
    // justifyContent:'flex-start',
    // alignItems:'center'
    position: 'absolute',
     top: 0,
     left: 0,
     right: 0,
     bottom: 0
  },

  MainContainerTittle:{
    width:"50%"
  },
  ContainerTittleLogin:{
   flexDirection:'row',
   flexWrap:'wrap',
   textAlign:'center',
   alignItems:'center',
   marginTop:50,
   justifyContent:'center'
  },
   FirstTitle: {
    fontSize:60, 
    color:'#74d9f9',
    fontWeight:'bold',
    textAlign:'center'
   },
   SecondTitle: {
    fontSize:60, 
    color:'#33d7ff',
    paddingLeft:20,
    textAlign:'left',
    fontWeight:'bold'
   },


   FirstTitleBottom: {
    fontSize:60, 
    color:'#74f9c1',
    fontWeight:'bold',
    textAlign:'center',
    width:'100%'
   },
   ContainerLogin:{
    marginTop:35,
    justifyContent:'center',
    alignItems:'center',
    width:'100%'
   },

   subTitle: {
    fontSize:20,
    color:'gray',
   },
   forgotPassword: {
    fontSize:14,
    color:'gray',
    paddingTop:15
   },
   errorResponse: {
    fontSize:10,
    color:'#ff9494',
    marginTop:10,
    width:'80%',
    textAlign:'center'
   }
   ,
   offlineFunctions:{
    textAlign:'center',
    fontSize:12,
    color:'#AEF4FF',
   },
   dontHaveAccount: {
    textAlign:'center',
    fontSize:10,
    color:'#f1f1f1',
    marginTop:20,
   },
   termsConditions: {
    textAlign:'center',
    fontSize:10,
    color:'gray',
    margin:10,
    padding:10,
    alignContent:'center',
    justifyContent:'center',
    alignItems:'center'
   },
   textInput: {
    padding: 10,
    paddingStart:30,
    width:'80%',
    marginTop:10,
    borderRadius:30,
    height:50,
    fontSize:12,
    backgroundColor:'#f1f1f1',
   },
   containerButton:{
   
    alignItems:'center',
    justifyContent:'center',
    width:'60%',
    height:50,
    marginTop:25
  },
  button: {
    width:'80%',
    height:40,
    borderRadius:25,
    alignItems:'center',
    justifyContent:'center',
 },
 offlineButton:{
    width:'50%',
    height:30,
    borderRadius:25,
    alignItems:'center',
    justifyContent:'center',
 },
 offlineText: {
  fontSize:10,
  color:'#f1f1f1'
 },
   text: {
    fontSize:14,
    color:'#f1f1f1'
   },
});
