import React, { useState, useEffect, useContext } from 'react';
import { ScrollView,TextInput, Text, Button, Alert, View, StyleSheet, Pressable, TouchableOpacity } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import { AntDesign } from '@expo/vector-icons'; 
import { useNavigation } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';
import Context from '../../../context/Context'
import AwesomeAlert from 'react-native-awesome-alerts';
//AWS for creating user:
import awsconfig from '../../../../src/aws-exports';
import {Amplify, Auth} from 'aws-amplify';
//For making requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
//Save Photo on Cloud:
import * as ImagePicker from 'expo-image-picker';
import { RNS3 } from 'react-native-aws3';

const Create_New_User =()=>  {

  const Navigation = useNavigation();
  const {identificationUser} = useContext(Context);
  //Array Data:
  const [arrayRoles, setArrayRoles]=useState([]);
  const [roleSelect, setRoleSelect]= useState([{id:'0'}]);
   //Alerts:
   const [showAlert, setShowAlert]=useState(false);
   const [showAlertError, setShowAlertError]=useState(false);
   const [showAlertFinished, setShowAlertFinished]=useState(false);
   const [response, setResponse]=useState('');
   const [disableSubmit, setDisableSubmit]=useState(false);
   
   Amplify.configure(awsconfig); 

    
   

   //Save Picture on Cloud:
   const [file, setFile]=useState({uri:"",name:"",type: ""});
   const [faceRecognitionCaptured, setFaceRecognitionCaptured]=useState(false);
   //Name Image:
   const [identification, setIdentification]=useState('');
   

//Upload image for recognition process:

async function UploadForRekognition() {
    try {
      await RNS3.put(file, options)
        .then(response => {
            console.log("El registro facial se almacenó exitosamente en la nube");
        })
        .catch(error => {
            console.log("No fue posible cargar la imagen al contenedor existente el la nube");
        });
    } catch (err) {
        console.log("No fue posible cargar la imagen al contenedor existente el la nube");
    }
  
  }


 
//Capture Image For Uppload:
const facialRegistration = async () => {
  // Request camera permissions:
  const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
  // Response if request permission is denied:
  if (permissionResult.granted === false) {
    setResponse("Es neceario que autorices el uso de la camara para efectuar el registro facial");
    setShowAlertError(true);
    return;
  }
  // If permission is granted then launch Camera for making a picture:
  const result = await ImagePicker.launchCameraAsync({quality:0.1});
  // If process is not canceled then save image uri on variable:
  if (!result.canceled) {
    const ImageUri=result.assets[0].uri;
    setFile({uri:ImageUri, name:  roleSelect[0].id + '-' + identification + ".jpeg",type: "image/jpeg"});
    setFaceRecognitionCaptured(true);
  }

}


  


const requestBasicDataRoles=()=>{
     //Get:
 executeRequest('/roles', {
  "Get_Roles": [
    {
      "body": {
        "_Type_Request": "Get_Basic_Data_Roles"
      }
    }
  ]
}, 'get', 'post').then(
      getData=>{
        setArrayRoles(getData)
      }
    ) 
     
    }

  useEffect(()=>{
    requestBasicDataRoles();
  },[])


//auto-confirm Account:
const autoConfirmAccount=()=>{
    //Get:
executeRequest('/auto-Confirm-Account', {
 "autoConfirm-Account": [
   {
     "body": {
       "_confirmType": "email"
     }
   }
 ]
}, 'post', 'post').then(
     getData=>{
      setDisableSubmit(false);
      null
     }
   ) 
    
   }

 useEffect(()=>{
   requestBasicDataRoles();
 },[])

  //SignUp:
async function signUp(email, pass) {
 
  try {
     await Auth.signUp({
      username:email,
      password:pass,
      attributes: {
        'custom:userIdentification':identification,
        'custom:role':(roleSelect[0] !== undefined) ? roleSelect[0].id : null,
        'custom:state':'859de7ee-edb6-11ed-b65a-0697dd88345b',
        email:email
      }
    });
    setResponse('Usuario creado exitosamente, por favor indicale al usuario iniciar sesión con las credenciales de acceso creadas anteriormente.');
    setShowAlertFinished(true);
    autoConfirmAccount();
  } catch (error) {
    console.log(error)
    /*Execution delete for Rolling Back Data Base Register in case of Error*/
    setResponse('Se presentó un error inesperado al momento de registrar el usuario, por favor intente realizar el proceso mas tarde.', error);
    setShowAlertError(true);
    setDisableSubmit(false);
  } 
}



const sendRequestNewUser=(firstName, secondName, firstLastName, secondLastName, phonenumber, email, pass)=>{
 setDisableSubmit(true);
 setShowAlert(false);
 //Create:
 executeRequest('/users', {
  "Create_User": [
    {
      "body": {
        "_Username": email,
        "_PhoneNumber": phonenumber,
        "_First_LastName": firstLastName,
        "_Second_LastName": secondLastName,
        "_First_Name": firstName,
        "_Second_Name": secondName,
        "_IdentificationCode": identification,
        "_User_Creator_ID": (identificationUser[0] !== undefined) ? identificationUser[0]["id"] : null,
        "_Role_ID": (roleSelect[0] !== undefined) ? roleSelect[0].id : null
      }
    }
  ]
}, 'post', 'post').then(
      getData=>{
        
        if (getData !== undefined) {
          if (getData[0].Response === '409') {
            setResponse('El correo electrónico proporcionado o la identificación del usuario ya fueron registrados con anterioridad.');
            setShowAlertError(true);
            setDisableSubmit(false);
          }
          else if (getData[0].Response === '200') {
            signUp(email, pass);
          }
                }
             }
        )

    }



    const showRoles= ()=>{
    
      if (arrayRoles.length>0) {
      
          return (
        <View style={styles.TableStyles}>
     {arrayRoles.map((x, index)=>(
        <Pressable  key={index} onPress={()=>{(roleSelect.find(e=>e.id===x.hashID)) ? setRoleSelect(roleSelect.filter(e=>e.id!==x.hashID)) 
         : setRoleSelect([{id:x.hashID}])
        }}
      style={{backgroundColor:(roleSelect.map(e=>e.id).indexOf(x.hashID)!==-1)? '#3489c8' : '#1e1f20',
                flexDirection:'row',
                alignItems:'center',
                textAlign:'center',
                justifyContent:'center',
                borderRadius:10,
                width:'85%',
                padding:10,
                marginTop:5
                }}>
          <View style={styles.Row}>
            <Text style={styles.Text}>{x.name}</Text>
          </View>
        </Pressable>
        ))
      }
        </View>
          );
        } else { null }

    }


    return (

    <ScrollView style={styles.formContainer}>


<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Crear Nuevo Usuario</Text>
</View>
      <Formik
        initialValues={{
          firstName: '',
          secondName: '',
          firstLastName: '',
          secondLastName: '',
          phonenumber: '',
          email: '',
          pass: ''
        }}
        onSubmit={values => {
          if (!isNaN(identification) || (identification.length<=999999999999 && identification.length>=100000)) {
            // if (faceRecognitionCaptured===true) {
          const firstName=JSON.stringify(values["firstName"]);
          const secondName=JSON.stringify(values["secondName"]);
          const firstLastName=JSON.stringify(values["firstLastName"]);
          const secondLastName=JSON.stringify(values["secondLastName"]);
          const phonenumber=JSON.stringify(values["phonenumber"]);
          const email=JSON.stringify(values["email"]);
          const pass=JSON.stringify(values["pass"]);
          sendRequestNewUser(JSON.parse(firstName), JSON.parse(secondName), JSON.parse(firstLastName), JSON.parse(secondLastName), JSON.parse(phonenumber), JSON.parse(email), JSON.parse(pass));
          // } else {
          //   setResponse('Es necesario que tomes el registro facial del nuevo colaborador para finalizar el proceso de creación');
          //   setShowAlertError(true);
          // }  
        } else {
            setResponse('Por favor asegurate de que el campo # Identificación sea numérico, adicionalmente ten presente que el valor no puede superar los 12 caracteres, o ser inferior a 6 caracteres.');
            setShowAlertError(true);
          }
        }}
        validationSchema={yup.object().shape({
            firstName: yup
            .string()
            .min(2, 'El nombre debe tener como minimo 2 caracteres')
            .max(20, 'El nombre no puede exceder los 20 caracteres')
            .required('Por favor especifique el primer nombre del colaborador'),
            secondName: yup
            .string()
            .min(2, 'El nombre debe tener como minimo 2 caracteres')
            .max(20, 'El nombre no puede exceder los 20 caracteres'),
            firstLastName: yup
            .string()
            .min(2, 'El apellido debe tener como minimo 2 caracteres')
            .max(30, 'El apellido no puede exceder los 30 caracteres')
            .required('Por favor especifique el primer apellido del colaborador'),
            secondLastName: yup
            .string()
            .min(2, 'El apellido debe tener como minimo 2 caracteres')
            .max(30, 'El apellido no puede exceder los 30 caracteres')
            .required('Por favor especifique el segundo apellido del colaborador'),
            phonenumber: yup
            .number('Este campo únicamente acepta valores numéricos')
            .min(100000, 'La cantidad minima de caracteres para este campo es de 6')
            .max(9999999999, 'El número maximo permitido para almacenar en este campo es de 10 caracteres')
            .required('Este campo es completamente obligatorio'),
            email: yup
            .string()
            .email('Correo electrónico invalido')
            .min(5, 'El número minimo de caracteres permitidos para este campo es de 5')
            .max(50, 'El email no puede exceder los 50 caracteres')
            .required('Por favor especifique un email valido'),
            pass: yup
            .string()
            .min(8, 'La contraseña debe tener al menos 8 caracteres')
            .max(15, 'La contraseña no puede exceder los 15 caracteres')
            .required('Por favor agregue una contraseña'),
            
        })}
       >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <View style={styles.formContainer}>
    <Text style={styles.TittleScreenPersonalData}>Datos Personales</Text>       
          <View style={styles.formGroupDates}> 

            <TextInput
              value={values.firstName}
              style={styles.inputStyle}
              onChangeText={handleChange('firstName')}
              onBlur={() => setFieldTouched('firstName')}
              placeholder="Primer nombre"
            />
            {touched.firstName && errors.firstName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.firstName}</Text>
            }
              <TextInput
              value={values.secondName}
              style={styles.inputStyle}
              onChangeText={handleChange('secondName')}
              onBlur={() => setFieldTouched('secondName')}
              placeholder="Segundo nombre"
            />
            {touched.secondName && errors.secondName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.secondName}</Text>
            }
                <TextInput
              value={values.firstLastName}
              style={styles.inputStyle}
              onChangeText={handleChange('firstLastName')}
              onBlur={() => setFieldTouched('firstLastName')}
              placeholder="Primer apellido"
            />
            {touched.firstLastName && errors.firstLastName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.firstLastName}</Text>
            }
                <TextInput
              value={values.secondLastName}
              style={styles.inputStyle}
              onChangeText={handleChange('secondLastName')}
              onBlur={() => setFieldTouched('secondLastName')}
              placeholder="Segundo apellido"
            />
            {touched.secondLastName && errors.secondLastName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.secondLastName}</Text>
            }
            <TextInput
              value={identification}
              style={styles.inputStyle}
              onChangeText={e=>setIdentification(e)}
              // onBlur={() => setFieldTouched('identification')}
              placeholder="# Identificación"
            />
            
            {/* {touched.identification && errors.identification &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.identification}</Text>
            } */}
              <TextInput
              value={values.phonenumber}
              style={styles.inputStyle}
              onChangeText={handleChange('phonenumber')}
              placeholder="Número de contacto"
              onBlur={() => setFieldTouched('phonenumber')}
            />
            {touched.phonenumber && errors.phonenumber &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.phonenumber}</Text>
            }

 <View style={styles.centerTitle}>
<Text style={styles.TittleScreenPersonalData}>Asignación de Roles</Text>    
</View>
{showRoles()}


      <View style={styles.containerAuth}>
        <Text style={styles.TittleScreen}>Autenticación</Text>   
            <TextInput
              value={values.email}
              style={styles.inputStyleLong}
              onChangeText={handleChange('email')}
              onBlur={() => setFieldTouched('email')}
              placeholder="Correo electrónico"
            />
            {touched.email && errors.email &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.email}</Text>
            }

                    <TextInput
              value={values.pass}
              style={styles.inputStyleLong}
              onChangeText={handleChange('pass')}
              onBlur={() => setFieldTouched('pass')}
              placeholder="Contraseña"
            />
            {touched.pass && errors.pass &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.pass}</Text>
            }
  </View>
            </View>
               
            {/* <TouchableOpacity style={styles.ButtonCenterTeam} onPress={()=>facialRegistration()}>
            
            <AntDesign name="idcard" size={24} color="#ccfff6" /> 
              <Text style={styles.TextStylesFinished}>Registro Facial</Text>
          
              </TouchableOpacity>
             */}
{ (disableSubmit===true) ?  
        <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished}>
        <MaterialIcons name="supervised-user-circle" size={20} color="white" />
          <Text style={styles.TextStylesFinished}>Procesando</Text>
          </TouchableOpacity>
      :  
            
            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished}
            disabled={!isValid} onPress={()=>{setShowAlert(true)}}>
            <MaterialIcons name="supervised-user-circle" size={20} color="white" />
              <Text style={styles.TextStylesFinished}>Crear Usuario</Text>
              </TouchableOpacity>

}
            
<View style={styles.ViewSpace}></View>

<AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
            
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />



          </View>
        )}
      </Formik>

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.navigate('Users_Created');
          }}
        />

<AwesomeAlert
          show={showAlertError}
          showProgress={false}
          title="Error"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#ffb2b2'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertError(false);
          }}
        />


    </ScrollView>

    );
      
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 10,
    backgroundColor:'#111111'
  },

  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10,
    backgroundColor:'#c0c0c0',
  },
  formGroupDates: {
    alignItems:'center'
  },
  
  DatesPicked: {
    textAlign:'left',
    justifyContent:'center',
    width:'65%',
    height:60,
  },
  DatesInput: {
    alignItems:'center',
    justifyContent:'center',
    borderRadius:30,
    width:'35%',
    height:60,
  },
  DropDownSelection: {
    marginTop:10
  },
  TittleScreen:{
    color:'#99f5bc',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  TittleScreenPersonalData:{
    color:'#99f5bc',
    margin:15,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#383838',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10
  },
  ButtonCreateProject: {
    backgroundColor:'#a54d4d',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonCenterTeam: {
    backgroundColor:'#282b32',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#ffffff',
    margin:10,
    fontSize:16,
    textAlign:'center'
  },
  ViewSpace: {
    marginTop:10,
    width:'100%'
  },
 FormStylesText:{
   color:'#f1f1f1'
 },
 inputStyle: {
  width:'85%',
  height:50,
  borderRadius:5,
  padding:10,
  marginTop:5,
  alignItems:'center',
  justifyContent:'center',
  color:'#1f1f1f',
  backgroundColor:'#8f9298'
},
 
inputStyleLong: {
  width:'100%',
  height:50,
  borderRadius:5,
  padding:10,
  marginTop:10,
  alignItems:'center',
  justifyContent:'center',
  color:'#e1efff',
  backgroundColor:'#8f9298'
},
containerAuth:{
  marginTop:10,
  width:'100%'
},
ListSelectedContainer: {
  width:'100%',
  marginTop:5
},
RightTitleSelect:{
  textAlign:'left',
  marginTop:10,
  marginBottom:10,
  fontSize:14,
  fontWeight:'bold',
  color:'#bdecda'
},
TableStyles: {
  textAlign:'center',
  alignItems:'center',
  justifyContent:'center',
  width:'100%'
},
Row:{
  textAlign:'left',
  alignItems:'center',
  justifyContent:'center',
  textAlign:'center',
},

Text:{
  color:'#f1f1f1',
  fontSize:12
},
centerTitle:{
  alignItems:'center',
  textAlign:'center',
  width:'100%'
},
ButtonDisabled: {
  backgroundColor:'#bccdd4',
  borderRadius:10,
  marginTop:20,
  alignItems:'center',
  justifyContent:'center'
},
ButtonFinished: {
  backgroundColor:'#a54d4d',
  borderRadius:10,
  marginTop:20,
  alignItems:'center',
  justifyContent:'center'
},
TextHigh: {
  textAlign:'left',
  color:'#EDF0F5',
  paddingTop:'5%',
  paddingLeft:'5%',
  paddingRight:'5%'
},
});

export default Create_New_User
