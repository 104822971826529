import React, {useState, useContext, useEffect} from 'react';
import { ScrollView, Text, TouchableOpacity, View, StyleSheet, TextInput, Platform } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
//Download files:
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { MaterialIcons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import * as yup from 'yup';
import { Formik } from 'formik';
import Context from '../../../context/Context';
import { Buffer } from 'buffer'; 
//For making requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
import { deleteLocalData } from '../../functional_global_components/LocalStorageManager';
 
const Project =()=>  {



const  {setDataNavigation, dataNavigation,  updateRequest, arrayProject, setArrayProject, setUpdateRequest, idNovelty, setIdNovelty,
  dataSession, identificationUser}= useContext(Context);

//Array SubProject List Information:
const [arraySubProject, setArraySubProject]=useState([]);
//Array Novelties Project:
const [arrayNovelties, setArrayNovelties]=useState([]);

//Booleans for active new requests after getting principal information:

const [createSubProject, setCreateSubProject]=useState(false);
const [showOptionsNovelty, setShowOptionsNovelty]=useState(false);

const [showAlert, setShowAlert]=useState(false);
//Show Alert for Finishing Project:
const [showAlertFinishProject, setShowAlertFinishProject]=useState(false);
const [showAlertStateProcess, setShowAlertStateProcess]=useState(false);
const [showAlertFinished, setShowAlertFinished]=useState(false);
const [showAlertUpload, setShowAlertUpload]=useState(false);
const [alertDeleteFinished, setAlertDeleteFinished]=useState(false);
const [response, setResponse]=useState('');
const [disableSubmit, setDisableSubmit]=useState(false);
const [lockUnlockQuotation, setLockUnlockQuotation]=useState('1'); /*Default Locked*/
const [alertUpdateDisabled, setAlertUpdateDisabled]=useState(false);
//Deleting Novelty:
const [alertDeletingNovelty, setAlertDeletingNovelty]=useState(false);
//Deleting PSP Quotation:
const [alertDeletePSPQuotation, setAlertDeletePSPQuotation]=useState(false);
//Deleting PSP Entry Sheet:
const [alertDeletePSPEntrySheet, setAlertDeletePSPEntrySheet]=useState(false);
//Deleting IDA:
const [alertDeleteIDA, setAlertDeleteIDA]=useState(false);
//Deleting EDA:
const [alertDeleteEDA, setAlertDeleteEDA]=useState(false);
//Deleting PSP:
const [showAlertDeletePSP, setShowAlertDeletePSP]=useState(false);
const [showAlertPSPDeleted, setShowAlertPSPDeleted]=useState(false);
//Blocking Download:
const [blockDownload, setBlockDownload]=useState(false);
//Image Base 64 Download S3Bucket:
const [s3ImgBase64, setS3ImgBase64]=useState(null);
const [typeDocument,setTypeDocument]=useState(null);
//Upload Files from WebApp:
const [selectedFile, setSelectedFile] = useState(null);


const checkPermissions = async () => {
    try {
      const result = await PermissionsAndroid.check(
        PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE
      );

      if (!result) {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
          {
            title:
              'Permiso Lectura de Almacenamiento',
            message: 'Esta aplicación requiere permisos de lectura de almacenamiento externo para lograr cargar y respaldar tus archivos en la nube',
            buttonNeutral: 'Preguntar mas tarde',
            buttonNegative: 'Cancelar',
            buttonPositive: 'Confirmar',
          }
        );
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          return true;
        } else {
          Alert.alert('Esta aplicación requiere permisos para acceder al almacenamiento externo para lograr cargar y respaldar tus archivos en la nube');
          return false;
        }
      } else {
        return true;
      }
    } catch (err) {
      console.log('No fue posible habilitar los permisos requeridos por la aplicación.');
      return false;
    }
  };

const updateNameDocumentation=(documentType, nameFile)=>{
        const Update_Upload_Docs = 
        (documentType==='IDA') ?
        {
          "Update_Upload_Docs": [
          {
            "body": {
              "_Project_ID": dataNavigation[1].projectChosen,
              "_SubProject_ID": null,
              "_IDA_File_Uploaded_Name": nameFile,
              "_EDA_File_Uploaded_Name": null
              }
            }
          ]
        }
      : (documentType==='EDA') ?
        {     
      "Update_Upload_Docs": [
            {
              "body": {
                "_Project_ID": dataNavigation[1].projectChosen,
                "_SubProject_ID": null,
                "_IDA_File_Uploaded_Name": null,
                "_EDA_File_Uploaded_Name": nameFile
                }
              }
            ]
          }
      : null;
      //Put:
      executeRequest('/uploadDocumentation', Update_Upload_Docs, 'post', 'put').then(
              getData=>{
                setUpdateRequest(!updateRequest)
              }
            )

}


//Sending docs to S3 Bucket React JS:
  const uploadFile=(fileBase64, nameFile, documentType)=>{
          //Put:
          executeRequest('/uploadFilesS3', {
            "Upload_File": [
              {
                "body": {
                  "_file": fileBase64,
                  "_filename": nameFile
                }
              }
            ]
          }, 'post', 'put').then(
            getData=>{
              setResponse(getData[0].Response);
              setShowAlertUpload(true);
              updateNameDocumentation(documentType, nameFile);
            }
        )
    }

  async function selectFile(documentType) {
    let result;
    switch (Platform.OS) {
      case 'web':
        result= true;
        break;
      case 'android':
        const OSversion=Platform.constants['Release'];
        result= (OSversion>=13) ?  true : await checkPermissions();
        break;
      case 'ios':
        result= true;
        break;
      default:
        result= false;
    };
    try {
     
      if (result===true) {
        setBlockDownload(true);
        const result = await DocumentPicker.getDocumentAsync({
          copyToCacheDirectory: true,
          type: 'application/pdf',
          base64: true
        });
        if (Platform.OS==='android' || Platform.OS==='ios') {
          const fileBase64 = await FileSystem.readAsStringAsync(result.assets[0].uri, { encoding: FileSystem.EncodingType.Base64 });
          const nameFile = documentType + '-' + dataNavigation[1].projectChosen + '.pdf'
          uploadFile(fileBase64, nameFile, documentType);
        } else if (Platform.OS==='web') {
          const nameFile = documentType + '-' + dataNavigation[1].projectChosen + '.pdf';
          const file = result.assets[0].uri;
          uploadFile(file.substring(28), nameFile, documentType);
        } 
      } else {
          setBlockDownload(false);
          console.log("Se ha presentado un error inesperado al momento de cargar el archivo.");
        }
    } catch (err) {
      console.log("Se presentó un error inesperado al seleccionar el archivo de origen.", err)
      setBlockDownload(false);
      return false;
    }
  }

  /*Taking a picture: */
  //Capture Image For Uppload:
const makePicture = async (documentType) => {
  setBlockDownload(true);
  // Request camera permissions:
  const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
  // Response if request permission is denied:
  if (permissionResult.granted === false) {
    setResponse("Es neceario que autorices el uso de la camara para tomar registro del documento que pretendes subir a la nube");
    setShowAlertFinished(true);
    return;
  }
  // If permission is granted then launch Camera for making a picture:
  const result = await ImagePicker.launchCameraAsync({quality:0.7, base64:true});
  // If process is not canceled then save image uri on variable:
  if (!result.canceled) {
    const ImageUri=(Platform.OS==='android' || Platform.OS==='ios') ? result.assets[0].uri : result.assets[0].file;
    const nameFile = documentType + '-' + dataNavigation[1].projectChosen + '.jpeg'
    const fileBase64 = await FileSystem.readAsStringAsync(ImageUri, { encoding: FileSystem.EncodingType.Base64 });
    (Platform.OS==='android' || Platform.OS==='ios') ?
    uploadFile(fileBase64, nameFile, documentType)
    : null
  } else {
    setBlockDownload(false);
  }
} 
//End UploadFiles

/*Start Downloading: */
const download = async (documentType) => {
//setTypeDocument(documentType);
setBlockDownload(true);
if (documentType==='IDA') {
executeRequest('/s3Object', {
  "Download_File": [ 
    {
      "body": {
        "_object_Key": arrayProject[0].idaID 
      }
    }
  ]
}, 'get', 'post').then(
    getData=>{
      let fileName='Acta_Inicio_de_Obra_Proyecto'.concat((arrayProject[0].idaID).substring((arrayProject[0].idaID).indexOf('.'),(arrayProject[0].idaID).length));
      if (Platform.OS==='web') {
        const link = document.createElement("a");
        link.href = getData[0].Response;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
           
        document.body.removeChild(link);
      }  else if (Platform.OS==='android' || Platform.OS==='ios'){
        FileSystem.downloadAsync(
          getData[0].Response,
          FileSystem.documentDirectory + fileName
          )
          .then(({ uri }) => {
              share(uri) 
          })
          .catch(error => {
            console.error('Se ha presentado un error inesperado al momento de realizar la ejecución de la descarga');
          });
      }
      setBlockDownload(false);
    } 
)
} else if (documentType==='EDA'){
      executeRequest('/s3Object', {
        "Download_File": [
          {
            "body": {
              "_object_Key": arrayProject[0].edaID
            }
          }
        ]
      }, 'get', 'post').then(
          getData=>{
            let fileName='Acta_Entrega_Final_Proyecto'.concat((arrayProject[0].edaID).substring((arrayProject[0].edaID).indexOf('.'),(arrayProject[0].edaID).length));
            if (Platform.OS==='web') {
              const link = document.createElement("a");
              link.href = getData[0].Response;
              link.setAttribute('download', fileName);
              link.target= "_blank"; 
              link.click();
            }  else if (Platform.OS==='android' || Platform.OS==='ios'){
              FileSystem.downloadAsync(
                getData[0].Response,
                FileSystem.documentDirectory + fileName
                )
                .then(({ uri }) => {
                    share(uri)
                })
                .catch(error => {
                  console.error('Se ha presentado un error inesperado al momento de realizar la ejecución de la descarga');
                });
            }
            setBlockDownload(false);
          }
      )
      }
  }

const share = async (url) => {
    setBlockDownload(false);
    try {
      await Sharing.shareAsync(url);
    } catch (error) {
      alert(error.message);
    }
  }
/*End Downloading*/

const Navigation = useNavigation();

const sendRequestProject=()=>{
  if (dataNavigation.length>0) {
    //Get:
    executeRequest('/psp-data', {
      "Get_Projects": [
          {
              "body": {
                  "_Type_Request": "Project_Information_Detail",
                  "_Client_ID": dataNavigation[0].clientChosen,
                  "_Project_ID": dataNavigation[1].projectChosen,
                  "_SubProject_ID": null,
                  "_Group_Quotation":null,
                  "_Group_Quotation_SubProject":null,
                  "_User_ID":(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" && identificationUser.length>0) ? identificationUser[0]["id"] : null
              }
          }
      ]
    }, 'get', 'post').then(
          getData=>{
            setArrayProject(getData);
            setBlockDownload(false);
          }
        )
    } else { null }
  }


  useEffect(()=>{
      sendRequestProject();
      sendRequestSubProject();
      sendRequestNovelties();
      requestLockUnlockQuotation();
  },[updateRequest])

  const sendRequestSubProject=()=>{
    if (dataNavigation.length>0) {
    //Get:
    executeRequest('/psp-data', {
      "Get_Projects": [
          {
              "body": {
                  "_Type_Request": "List_SubProjects_Information",
                  "_Client_ID": dataNavigation[0].clientChosen,
                  "_Project_ID": dataNavigation[1].projectChosen,
                  "_SubProject_ID": null,
                  "_Group_Quotation":null,
                  "_Group_Quotation_SubProject":null,
                  "_User_ID":(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" && identificationUser.length>0) ? identificationUser[0]["id"] : null
              }
          }
      ]
    }, 'get', 'post').then(
          getData=>{
            setArraySubProject(getData);
          }
        )
      } else { null }
    }


const sendRequestNovelties=()=>{

      if (dataNavigation.length>0) {
      //Get:
      executeRequest('/psp-data', {
        "Get_Projects": [
            {
                "body": {
                    "_Type_Request": "List_Novelties_Project",
                    "_Client_ID": dataNavigation[0].clientChosen,
                    "_Project_ID": dataNavigation[1].projectChosen,
                    "_SubProject_ID": null,
                    "_Group_Quotation":null,
                    "_Group_Quotation_SubProject":null,
                    "_User_ID":(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" && identificationUser.length>0) ? identificationUser[0]["id"] : null
                }
            }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayNovelties(getData);
            }
          )
        } else { null }
      }

const requestLockUnlockQuotation=()=>{

        if (dataNavigation.length>0) {
        //Get:
        executeRequest('/quotations', {
          "Unlock_PSP_Quotation": [
            {
              "body": {
                "_Project_ID": (dataNavigation[1] !== undefined) ?  dataNavigation[1].projectChosen : null,
                "_SubProject_ID": null
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setLockUnlockQuotation(getData[0].Response)
              }
            )
          } else { null }
        }


const requestCreateSubProject=(SubProjectName)=>{
          setDisableSubmit(true);
          setShowAlert(false);
          //Create:
          executeRequest('/subprojects', {
            "Create_SubProject": [
              {
                "body": {
                  "_Name_SubProject":(SubProjectName.length>0) ? SubProjectName : null,
                  "_Client_ID": (dataNavigation[0] !== undefined) ? dataNavigation[0].clientChosen : null,
                  "_Project_ID": (dataNavigation[1] !== undefined) ?  dataNavigation[1].projectChosen : null
                }
              }
            ]
          }, 'post', 'post').then(
                getData=>{
                  setResponse(getData[0].Response);
                  setUpdateRequest(!updateRequest);
                  setShowAlertFinished(true);
                  setDisableSubmit(false);
                }
              )

        }
    

const finishProject=()=>{
    
        setDisableSubmit(true);
        setShowAlertFinishProject(false); 
        //Put:
        executeRequest('/projects', {
            "Finish_PSP": [
              {
                "body": {
                  "_Type_Request": "Finish_Project",
                  "_Project_ID": dataNavigation[1].projectChosen,
                  "_SubProject_ID": null,
                  "_User_Update_ID": identificationUser[0]["id"]
                }
              }
            ]
          }, 'post', 'put').then(
                getData=>{
                  setResponse(getData[0].Response);
                  setUpdateRequest(!updateRequest);
                  setShowAlertStateProcess(true);
                  setDisableSubmit(false);
                }
              )
        }


const deleteNovelty=()=>{
        setDisableSubmit(true);
        setAlertDeletingNovelty(false);
        //Delete:
        executeRequest('/novelties', {
          "Delete_Novelty": [
            {
              "body": {
                "_Novelty_ID": idNovelty
              }
            }
          ]
        }, 'post', 'delete').then(
              getData=>{
                setResponse(getData[0].Response);
                setAlertDeleteFinished(true);
                setUpdateRequest(!updateRequest);
                setDisableSubmit(false);
              }
            )  
  }

const  deletePSPQuotation=()=>{
      setDisableSubmit(true);
      setAlertDeletePSPQuotation(false);
      //Delete:
      executeRequest('/quotations', {
        "Delete_PSP_Quotation": [
          {
            "body": {
              "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
              "_SubProject_ID": null,
              "_Group_Quotation": (dataNavigation[1] !== undefined) ? dataNavigation[1].groupQuotation : null
            }
          }
        ]
      }, 'post', 'delete').then(
            getData=>{
              setResponse(getData[0].Response);
              setAlertDeleteFinished(true);
              setUpdateRequest(!updateRequest);
              setDisableSubmit(false);
            }
          );
      //Clean LocalData Create & Update Exit Sheet:
      deleteLocalData('C_ExitSheet_'.concat((dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null));
      deleteLocalData('timeStamp_C_ExitSheet_'.concat((dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null));
      deleteLocalData('U_ExitSheet_'.concat((dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null));    
      deleteLocalData('timeStamp_U_ExitSheet_'.concat((dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null)); 
  }

  const  deletePSPEntrySheet=()=>{
    setDisableSubmit(true);
    setAlertDeletePSPEntrySheet(false);
    //Delete:
    executeRequest('/entry-sheet', {
      "Delete_PSP_Entry_Sheet": [
        {
          "body": {
            "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
            "_SubProject_ID": null,
            "_Group_Quotation": (dataNavigation[1] !== undefined) ? dataNavigation[1].groupQuotation : null
          }
        }
      ]
    }, 'post', 'delete').then(
          getData=>{
            setResponse(getData[0].Response);
            setAlertDeleteFinished(true);
            setUpdateRequest(!updateRequest);
            setDisableSubmit(false);
          }
        );
      //Clean LocalData Create & Update Exit Sheet:
      deleteLocalData('C_EntrySheet_'.concat((dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null));
      deleteLocalData('timeStamp_C_EntrySheet_'.concat((dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null));
      deleteLocalData('U_EntrySheet_'.concat((dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null));  
      deleteLocalData('timeStamp_U_EntrySheet_'.concat((dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null));            
}
  

const  deleteIDA=()=>{
    setDisableSubmit(true); 
    setAlertDeleteIDA(false);
    //Delete:
    executeRequest('/ida', {
      "Delete_IDA": [
        {
          "body": {
            "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
            "_SubProject_ID": null
          }
        }
      ]
    }, 'post', 'delete').then(
          getData=>{
            setResponse(getData[0].Response);
            setAlertDeleteFinished(true);
            setUpdateRequest(!updateRequest);
            setDisableSubmit(false);
          }
        )

  }

const  deleteEDA=()=>{
    setDisableSubmit(true);
    setAlertDeleteEDA(false);
    executeRequest('/eda', {
            "Delete_EDA": [
              {
                "body": {
                  "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
                  "_SubProject_ID": null
                }
              }
            ]
          }, 'post', 'delete').then(
                getData=>{
                  setResponse(getData[0].Response);
                  setAlertDeleteFinished(true);
                  setUpdateRequest(!updateRequest);
                  setDisableSubmit(false);
            }
        )
  }

const  deletePSP=()=>{ 
    setDisableSubmit(true);
    setShowAlertDeletePSP(false);
    executeRequest('/projects', {
            "Delete_PSP": [
              {
                "body": {
                  "_Project_ID": (dataNavigation[1] !== undefined) ? dataNavigation[1].projectChosen : null,
                  "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null
                }
              }
            ]
          }, 'post', 'delete').then(
                getData=>{
                  setResponse(getData[0].Response);
                  setShowAlertPSPDeleted(true);
                  setDisableSubmit(false);
            }
        )


  }

const idaPhysical =()=>{
    if (arrayProject.length>0) {
     if (arrayProject[0].idaID!==null) {
      return (
        <>
        {((dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") && (Platform.OS==='android' || Platform.OS==='ios')) ?
  <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
        makePicture('IDA');
        }}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> 
  }
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonCenterAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
        selectFile('IDA');
        }}>
     <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
        </TouchableOpacity>
      </View> :
       <View style={styles.ButtonCenterAdmin}>
       <TouchableOpacity style={styles.ButtonStylesUnabled}>
       <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
         </TouchableOpacity>
       </View>
  }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={(blockDownload===false) ? styles.ButtonStylesDelete : styles.ButtonStylesBlockDownload}  onPress={()=>{
      (blockDownload===false) ?
      download('IDA')
      : null
      }}>
      <AntDesign name="clouddownload" size={30} color="#bdffc7" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <AntDesign name="clouddownload" size={30} color="#bdffc7" />
        </TouchableOpacity>
      </View> 
            }
            </>
        );
    } else {
      return (<>
             {((dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") && (Platform.OS==='android' || Platform.OS==='ios')) ?
  <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
        makePicture('IDA');
        }}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> 
  }
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonCenterAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
        selectFile('IDA');
        }}>
     <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
        </TouchableOpacity>
      </View> :
       <View style={styles.ButtonCenterAdmin}>
       <TouchableOpacity style={styles.ButtonStylesUnabled}>
       <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
         </TouchableOpacity>
       </View>
  }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={(blockDownload===false) ? styles.ButtonStylesDelete : styles.ButtonStylesBlockDownload}  onPress={()=>{
      (blockDownload===false) ?
      download('IDA')
      : null
      }}>
      <AntDesign name="clouddownload" size={30} color="#e9e9e9" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <AntDesign name="clouddownload" size={30} color="#e9e9e9" />
        </TouchableOpacity>
      </View> 
            }
      </>)
    }
  }
}


const idaDigitalized =()=>{
  if (arrayProject.length>0) {
  if (arrayProject[0].id_IDA_Digitalized!==null) {
      return (
    <>
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" ||
     dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b") &&
    <View style={styles.ButtonLeftAdmin}>
        <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{   
          setDataNavigation([dataNavigation[0],dataNavigation[1]]);
          Navigation.navigate('Initial_Delivery_Agreement');
          }}>
        <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
          <Text style={styles.TextStylesAdmin}>Consultar</Text>
          </TouchableOpacity>
        </View>
    }
        {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
        <View style={styles.ButtonCenterAdmin}>
        <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
          setDataNavigation([dataNavigation[0],dataNavigation[1]]);
          Navigation.navigate('Update_Initial_Delivery_Agreement')
          }}>
        <MaterialIcons name="update" size={15} color="white" />
          <Text style={styles.TextStylesAdmin}>Actualizar</Text>
          </TouchableOpacity>
        </View>
        :
        <View style={styles.ButtonCenterAdmin}>
        <TouchableOpacity style={styles.ButtonStylesUnabled}>
        <MaterialIcons name="update" size={15} color="#7e7d7d" />
          <Text style={styles.TextStylesDisabled}>Actualizar</Text>
          </TouchableOpacity>
        </View>
        } 

        {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
        <View style={styles.ButtonRightAdmin} >
        <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=>{
          setDataNavigation([dataNavigation[0],dataNavigation[1]]);
          setAlertDeleteIDA(true);
          }}>
        <AntDesign name="delete" size={15} color="white" />
          <Text style={styles.TextStylesAdmin}>Eliminar</Text>
          </TouchableOpacity>
        </View> :
        <View style={styles.ButtonRightAdmin} >
        <TouchableOpacity style={styles.ButtonStylesUnabled}>
        <AntDesign name="delete" size={15} color="#7e7d7d" />
          <Text style={styles.TextStylesDisabled}>Eliminar</Text>
          </TouchableOpacity>
        </View> 
        }
        </>)
      } else { return (       
          <>
          {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
          <View style={styles.ButtonCreateDocumentation}>
              <TouchableOpacity style={styles.ButtonDocumentsDeliveryInitial} onPress={()=>{
                setDataNavigation([dataNavigation[0],dataNavigation[1]]);
                Navigation.navigate('Create_Initial_Delivery_Agreement');
                }}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
                <Text style={styles.TextStylesAdmin}>Crear Acta</Text>
                </TouchableOpacity>
              </View> :
                <View style={styles.ButtonCreateDocumentation}>
                <TouchableOpacity style={styles.ButtonDocumentsDeliveryInitial}>
                <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
                  <Text style={styles.TextStylesAdmin}>Crear Acta</Text>
                  </TouchableOpacity>
                </View>
              } 
              </>
              )
    }
  } else { 

    return (
      <View style={styles.ButtonCreateDocumentation}>
              <TouchableOpacity style={styles.ButtonDocumentsDeliveryInitial}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
                <Text style={styles.TextStylesAdmin}>______</Text>
                </TouchableOpacity>
              </View>
       )
   }
}

const edaPhysical=()=>{
  if (arrayProject.length>0) {
     if (arrayProject[0].edaID!==null) {
      return (
        <>
        {((dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") && (Platform.OS==='android' || Platform.OS==='ios')) ?
  <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
        makePicture('EDA')
        }}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> 
  }
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonCenterAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
        selectFile('EDA');
        }}>
     <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
        </TouchableOpacity>
      </View> :
       <View style={styles.ButtonCenterAdmin}>
       <TouchableOpacity style={styles.ButtonStylesUnabled}>
       <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
         </TouchableOpacity>
       </View>
  }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={(blockDownload===false) ? styles.ButtonStylesDelete : styles.ButtonStylesBlockDownload}  onPress={()=>{
      (blockDownload===false) ?
      download('EDA')
      : null
      }}>
      <AntDesign name="clouddownload" size={30} color="#bdffc7" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <AntDesign name="clouddownload" size={30} color="#bdffc7" />
        </TouchableOpacity>
      </View> 
            }
            </>
        );
    } else {
      return (<>
             {((dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") && (Platform.OS==='android' || Platform.OS==='ios')) ?
  <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
        makePicture('EDA');
        }}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <Ionicons name="camera" size={30} color="#d3d7dc" />
        </TouchableOpacity>
      </View> 
  }
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonCenterAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
        selectFile('EDA');
        }}>
     <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
        </TouchableOpacity>
      </View> :
       <View style={styles.ButtonCenterAdmin}>
       <TouchableOpacity style={styles.ButtonStylesUnabled}>
       <MaterialCommunityIcons name="file-pdf-box" size={30} color="#ff8b8b" />
         </TouchableOpacity>
       </View>
  }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={(blockDownload===false) ? styles.ButtonStylesDelete : styles.ButtonStylesBlockDownload}  onPress={()=>{
      (blockDownload===false) ?
      download('EDA')
      : null
      }}>
      <AntDesign name="clouddownload" size={30} color="#e9e9e9" />
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <AntDesign name="clouddownload" size={30} color="#e9e9e9" />
        </TouchableOpacity>
      </View> 
            }
      </>)
    }
  }
}

const edaDigitalized =()=>{
  if (arrayProject.length>0) {
  if (arrayProject[0].id_EDA_Digitalized!==null) {
      return (
  <>
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
  <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
        setDataNavigation([dataNavigation[0],dataNavigation[1]]);
        Navigation.navigate('End_Delivery_Agreement')
        }}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
        <Text style={styles.TextStylesAdmin}>Consultar</Text>
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
        <Text style={styles.TextStylesDisabled}>Consultar</Text>
        </TouchableOpacity>
      </View> 
  }
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonCenterAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
        setDataNavigation([dataNavigation[0],dataNavigation[1]]);
        Navigation.navigate('Update_End_Delivery_Agreement')}}>
      <MaterialIcons name="update" size={15} color="white" />
        <Text style={styles.TextStylesAdmin}>Actualizar</Text>
        </TouchableOpacity>
      </View> :
       <View style={styles.ButtonCenterAdmin}>
       <TouchableOpacity style={styles.ButtonStylesUnabled}>
       <MaterialIcons name="update" size={15} color="white" />
         <Text style={styles.TextStylesDisabled}>Actualizar</Text>
         </TouchableOpacity>
       </View>
  }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=>{
        setDataNavigation([dataNavigation[0],dataNavigation[1]]);
        setAlertDeleteEDA(true);
        }}>
      <AntDesign name="delete" size={15} color="white" />
        <Text style={styles.TextStylesAdmin}>Eliminar</Text>
        </TouchableOpacity>
      </View> :
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <AntDesign name="delete" size={15} color="#7e7d7d" />
        <Text style={styles.TextStylesDisabled}>Eliminar</Text>
        </TouchableOpacity>
      </View> 
      }
      </>
      )
    } else { return (      
  <>
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" && arrayProject[0].id_IDA_Digitalized!==null) ?
  <View style={styles.ButtonCreateDocumentation}>
      <TouchableOpacity style={styles.ButtonDocumentsDelivery} onPress={()=> {
        setDataNavigation([dataNavigation[0],dataNavigation[1]]);
        Navigation.navigate('Create_End_Delivery_Agreement')}}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
        <Text style={styles.TextStylesAdmin}>Crear Acta</Text>
        </TouchableOpacity>
      </View> : 
        <View style={styles.ButtonCreateDocumentation}>
        <TouchableOpacity style={styles.ButtonDocumentsDelivery}>
        <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
          <Text style={styles.TextStylesAdmin}>Crear Acta</Text>
          </TouchableOpacity>
        </View>
          } 
      </>
      )
    }
  } else { return (
    
      <View style={styles.ButtonCreateDocumentation}>
              <TouchableOpacity style={styles.ButtonDocumentsDeliveryInitial}>
              <MaterialCommunityIcons name="rocket-launch" size={15} color="#D9FFFC" />
                <Text style={styles.TextStylesAdmin}>______</Text>
                </TouchableOpacity>
              </View>
       
         )
     }
  
}

const fpqValidation =()=>{
  if (arrayProject.length>0) {
    if (arrayProject[0].fpqID!==null) {
      return (
  <>
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" 
  || dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b") ?
  <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
        setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen, groupQuotation:arrayProject[0].groupQuotation}]);
        Navigation.navigate('Project_SubProject_Quotation');
        }}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
        <Text style={styles.TextStylesAdmin}>Consultar</Text>
        </TouchableOpacity>
      </View> : 
      <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="#7e7d7d" />
        <Text style={styles.TextStylesDisabled}>Consultar</Text>
        </TouchableOpacity>
      </View> 
    }

      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonCenterAdmin}>
      {(lockUnlockQuotation==='0')  ?
      <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{ 
        setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen, groupQuotation:arrayProject[0].groupQuotation}]);
        Navigation.navigate('Update_Project_SubProject_Quotation');}}>
      <MaterialIcons name="update" size={15} color="white" />
        <Text style={styles.TextStylesAdmin}>Actualizar</Text>
        </TouchableOpacity>
        :
        <TouchableOpacity style={styles.ButtonStylesUnabled} onPress={()=>{ setResponse("No puedes actualizar una hoja de salida si la misma ya tiene solicitudes asociadas."); setAlertUpdateDisabled(true);}}>
        <MaterialIcons name="update" size={15} color="#7e7d7d" />
          <Text style={styles.TextStylesDisabled}>Actualizar</Text>
          </TouchableOpacity>
        }
      </View> 
      :
      <View style={styles.ButtonCenterAdmin}>
        <TouchableOpacity style={styles.ButtonStylesUnabled}>
        <MaterialIcons name="update" size={15} color="#7e7d7d" />
          <Text style={styles.TextStylesDisabled}>Actualizar</Text>
          </TouchableOpacity>
      </View> 
      }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=>{
        setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen, groupQuotation:arrayProject[0].groupQuotation}]);
        if (arrayProject[0].entrySheetID!==null) {
          setResponse("Solo podrás eliminar la Hoja de Salida si primero eliminas la Hoja de Ingreso."); setAlertUpdateDisabled(true)
        } else {
          setAlertDeletePSPQuotation(true);
        }
        }}>
      <AntDesign name="delete" size={15} color="white" />
        <Text style={styles.TextStylesAdmin}>Eliminar</Text>
        </TouchableOpacity>
      </View>
      : 
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <AntDesign name="delete" size={15} color="#7e7d7d" />
        <Text style={styles.TextStylesDisabled}>Eliminar</Text>
        </TouchableOpacity>
      </View>
      }
    </> 
   )
  } else { return (  
  <>
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
  <View style={styles.ButtonCreateDocumentation}>
      <TouchableOpacity style={styles.ButtonStylesCreateDocumentation} onPress={()=>{
        setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen, groupQuotation:arrayProject[0].groupQuotation}]);
        Navigation.navigate('Create_New_Project_SubProject_Quotation')
        }}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="#FF8989" />
        <Text style={styles.TextStylesAdmin}>Crear</Text>
        </TouchableOpacity>
      </View> :
       <View style={styles.ButtonCreateDocumentation}>
       <TouchableOpacity style={styles.ButtonStylesCreateDocumentation}>
       <MaterialCommunityIcons name="rocket-launch" size={15} color="#FF8989" />
         <Text style={styles.TextStylesAdmin}>Inaccesible</Text>
         </TouchableOpacity>
       </View> 
  }
  </>  
   )
  }
} else { return (
    
  <View style={styles.ButtonCreateDocumentation}>
          <TouchableOpacity style={styles.ButtonDocumentsDeliveryInitial}>
          <MaterialCommunityIcons name="rocket-launch" size={15} color="#FF8989" />
            <Text style={styles.TextStylesAdmin}>______</Text>
            </TouchableOpacity>
          </View>
   
       )
    
    }

}

/*Entry Sheet: */

const entrySheet =()=>{
  if (arrayProject.length>0) {
    if (arrayProject[0].entrySheetID!==null) {
      return (
  <>
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" 
  || dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b") ?
  <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesRequest} onPress={()=>{
        setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen, groupQuotation:arrayProject[0].groupQuotation}]);
        Navigation.navigate('Request_Entry_Sheet_PSP');
        }}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
        <Text style={styles.TextStylesAdmin}>Consultar</Text>
        </TouchableOpacity>
      </View> : 
      <View style={styles.ButtonLeftAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="#7e7d7d" />
        <Text style={styles.TextStylesDisabled}>Consultar</Text>
        </TouchableOpacity>
      </View> 
    }

      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonCenterAdmin}>
      <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{ 
        setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen, groupQuotation:arrayProject[0].groupQuotation}]);
        Navigation.navigate('Update_Entry_Sheet_PSP');}}>
      <MaterialIcons name="update" size={15} color="white" />
        <Text style={styles.TextStylesAdmin}>Actualizar</Text>
        </TouchableOpacity>
      </View> 
      :
      <View style={styles.ButtonCenterAdmin}>
        <TouchableOpacity style={styles.ButtonStylesUnabled}>
        <MaterialIcons name="update" size={15} color="#7e7d7d" />
          <Text style={styles.TextStylesDisabled}>Actualizar</Text>
          </TouchableOpacity>
      </View> 
      }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=>{
        setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen, groupQuotation:arrayProject[0].groupQuotation}]);
        setAlertDeletePSPEntrySheet(true)
        }}>
      <AntDesign name="delete" size={15} color="white" />
        <Text style={styles.TextStylesAdmin}>Eliminar</Text>
        </TouchableOpacity>
      </View>
      : 
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesUnabled}>
      <AntDesign name="delete" size={15} color="#7e7d7d" />
        <Text style={styles.TextStylesDisabled}>Eliminar</Text>
        </TouchableOpacity>
      </View>
      }
    </> 
   )
  } else { return (  
  <>
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") ?
  (arrayProject[0].fpqID!==null) ? 
  <View style={styles.ButtonCreateDocumentation}>
      <TouchableOpacity style={styles.ButtonStylesCreateDocumentation} onPress={()=>{
        setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen, groupQuotation:arrayProject[0].groupQuotation}]);
        Navigation.navigate('Create_Entry_Sheet_PSP')
        }}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="#C9FAC8" />
        <Text style={styles.TextStylesAdmin}>Crear</Text>
        </TouchableOpacity>
      </View> : 
      <View style={styles.ButtonCreateDocumentation}>
      <TouchableOpacity style={styles.ButtonStylesCreateDocumentation} onPress={()=>{
         setResponse("Solo podrás crear una Hoja de Ingresos cuando hayas diligenciado una Hoja de Salida."); setAlertUpdateDisabled(true)
        }}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="#C9FAC8" />
        <Text style={styles.TextStylesAdmin}>Crear</Text>
        </TouchableOpacity>
      </View>
      :
      <View style={styles.ButtonCreateDocumentation}>
      <TouchableOpacity style={styles.ButtonStylesCreateDocumentation}>
      <MaterialCommunityIcons name="rocket-launch" size={15} color="#C9FAC8" />
        <Text style={styles.TextStylesAdmin}>Inaccesible</Text>
        </TouchableOpacity>
      </View>
  }
  </>  
   )
  }
} else { return (
    
  <View style={styles.ButtonCreateDocumentation}>
          <TouchableOpacity style={styles.ButtonDocumentsDeliveryInitial}>
          <MaterialCommunityIcons name="rocket-launch" size={15} color="#C9FAC8" />
            <Text style={styles.TextStylesAdmin}>______</Text>
            </TouchableOpacity>
          </View>
   
       )
    
    }

}



const showNovelties=()=>{
if (arrayNovelties.length>0) {
  
  return (
    <View  style={styles.NoveltyContainer} >
    <View style={styles.MainContainer}>
    <View style={styles.TitleDateNovelty} >
    <Text style={styles.TextTitleNovelty}>Fecha</Text>
    </View>
    <View style={styles.TitleUserNovelty} >
    <Text style={styles.TextTitleNovelty}>Usuario</Text>
    </View>
    <View style={styles.TitleDescriptionNovelty} >
    <Text style={styles.TextTitleNovelty}>Novedad</Text>
    </View>
    </View>
    
  {arrayNovelties.map((e, index)=>(
<View key={index}>

<TouchableOpacity style={styles.MainContainer} onPress={()=>{ setIdNovelty(e.id); setShowOptionsNovelty(!showOptionsNovelty)}}> 
<View style={styles.DateNovelty} >
      <Text style={styles.TextStylesAdmin}>{e.dateNovelty}</Text>
    </View>
    <View style={styles.UserNovelty} >
    <Text style={styles.TextStylesAdmin}>{e.fullName}</Text>
    </View>
    <View style={styles.DescriptionNovelty} >
    <Text style={styles.TextStylesAdmin}>{e.description}</Text>
    </View>
</TouchableOpacity>


{(idNovelty===(e.id) && showOptionsNovelty===true) &&
<View style={styles.MainContainer}>
  <TouchableOpacity style={styles.ViewUpdateNovelty} onPress={()=>{
    
    setShowOptionsNovelty(false);
    Navigation.navigate('Update_Novelty_Project');
    }}>
  <Text style={styles.TittleCreateNewSubProject}>Actualizar</Text>
  </TouchableOpacity>
  <TouchableOpacity style={styles.ViewDeleteNovelty} onPress={()=>{setAlertDeletingNovelty(true)}}>
  <Text style={styles.TittleCreateNewSubProject}>Eliminar</Text>
  </TouchableOpacity>
</View>
          }
      </View>
    ))}
    </View>
    )
  } else { null }
}

useEffect(()=>{
  showNovelties();
},[idNovelty])


const showSubProjects=()=>{

  if (arraySubProject.length>0) {

    return (
  <>
  {arraySubProject.map((e)=>(
  <TouchableOpacity style={styles.MainContainerSubProjects} onPress={()=>{
    if (dataNavigation[2]===undefined) {
  setDataNavigation((i)=>[...i, {subProjectChosen:e.id, groupQuotationSubProject:e.groupQuotation}]);
    } else {
     setDataNavigation([dataNavigation[0], dataNavigation[1], {subProjectChosen:e.id, groupQuotationSubProject:e.groupQuotation}]);
   }
   Navigation.navigate('SubProject')
}}  
  key={e.id}> 
    <Text style={styles.TextScreenLeftSubProjects}>Nombre:</Text> 
    <Text style={styles.TextScreenRightSubProjects}>{e.nameSubProject}</Text> 
    <Text style={styles.TextScreenLeftSubProjects}>Fecha Creación:</Text> 
    <Text style={styles.TextScreenRightSubProjects}>{e.dateCreated}</Text> 
    <Text style={styles.TextScreenLeftSubProjects}>Estado:</Text> 
    <Text style={styles.TextScreenRightSubProjects}>{e.processState}</Text> 
    <Text style={styles.TextScreenLeftSubProjects}>Fecha Finalización:</Text> 
    <Text style={styles.TextScreenRightSubProjects}>{e.dateFinishedSubProject}</Text> 
    </TouchableOpacity>
       ))
      }
    </>
    )
  } else { null }
}

const showProjectData=()=>{
if (arrayProject.length>0) {
  return (
  <ScrollView style={styles.BackgroundScrollView}>
<View style={styles.RootContainer}>
    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Datos Básicos del Proyecto</Text>
    </View>
    {arrayProject.map((e)=>(
    
    <View style={styles.MainContainer} key={e.id}> 
    <Text style={styles.TextScreenLeft}>Nombre:</Text> 
    <Text style={styles.TextScreenRight} >{e.nameProject}</Text> 
    <Text style={styles.TextScreenLeft}>Descripción:</Text> 
    <Text style={styles.TextScreenRight}>{e.projectDescription}</Text> 
   

    <Text style={styles.TextScreenLeft}>Dirección:</Text> 
    <Text style={styles.TextScreenRight}>{e.address}</Text> 
 
  
    <Text style={styles.TextScreenLeft}>Ciudad:</Text> 
    <Text style={styles.TextScreenRight}>{e.city}</Text> 
 

    <Text style={styles.TextScreenLeft}>Ubicación:</Text> 
    <Text style={styles.TextScreenRight}>{e.location}</Text> 
  

    <Text style={styles.TextScreenLeft}>Cliente:</Text> 
    <Text style={styles.TextScreenRight}>{e.clientName}</Text> 
  

    <Text style={styles.TextScreenLeft}>NIT:</Text> 
    <Text style={styles.TextScreenRight}>{e.NIT}</Text> 
  
    <Text style={styles.TextScreenLeft}>Estado:</Text> 
    <Text style={styles.TextScreenRight}>{e.processState}</Text> 

    <Text style={styles.TextScreenLeft}>Fecha Inicio:</Text> 
    <Text style={styles.TextScreenRight}>{e.initDate}</Text> 
 
 
    <Text style={styles.TextScreenLeft}>Fecha Finalización:</Text> 
    <Text style={styles.TextScreenRight}>{e.endDate}</Text> 

    <Text style={styles.TextScreenLeft}>Equipo Asociado:</Text> 
    <Text style={styles.TextScreenRight}>{e.teamName}</Text> 

    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
    <>
    <Text style={styles.TextScreenLeft}>$ Valor Inicial Hoja de Salida:</Text> 
    <Text style={styles.TextScreenRight}>$ {e.ipq}</Text> 
  
    <Text style={styles.TextScreenLeft}>$ Valor Final Hoja de Salida:</Text> 
    <Text style={styles.TextScreenRight}>$ {e.fpq}</Text>
    <Text style={styles.TextScreenLeft}>$ Valor Total Hoja de Ingreso:</Text> 
    <Text style={styles.TextScreenRight}>$ {e.entrySheetValue}</Text> 
    <Text style={styles.TextScreenLeft}>$ V.T Costos Implementación:</Text> 
    <Text style={styles.TextScreenRight}>$ { e.cost_Implementation}</Text> 
    <Text style={styles.TextScreenLeft}>$ V.T Proyecto:</Text> 
    <Text style={styles.TextScreenRight}>$ {e.finalValueProject}</Text> 
    </>
}
    </View>
    
    ))}


    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Documentación Proyecto</Text>
    </View>
    <View style={styles.MainContainerDocumentation}> 
    <View style={styles.MainContainerIDA}>
    <Text style={styles.TextScreenDocumentation}>{(arrayProject[0].idaID===null && dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Inicio de Obra - Nube" : (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Inicio de Obra - Nube" : null}</Text>   
    {idaPhysical()}
    <Text style={styles.TextScreenDocumentation}>{(arrayProject[0].id_IDA_Digitalized===null && dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Inicio de Obra - Digital" : (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Inicio de Obra - Digital" : null}</Text>   
    {idaDigitalized()}
    </View>
    <View style={styles.MainContainerEDA}>
    <Text style={styles.TextScreenDocumentation}>{(arrayProject[0].edaID===null && dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Entrega Final - Nube" : (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Entrega Final - Nube" : null}</Text>
    {edaPhysical()}
    <Text style={styles.TextScreenDocumentation}>{(arrayProject[0].id_EDA_Digitalized===null && dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Entrega Final - Digital" : (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Acta Entrega Final - Digital" : null}</Text>
    {edaDigitalized()}
    </View>
    <View style={styles.MainContainerPSPQuotation}>
    <Text style={styles.TextScreenDocumentation}>{(arrayProject[0].fpqID===null && dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Hoja de Salida Proyecto" : (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Hoja de Salida Proyecto" : null}</Text>
    {fpqValidation()}
    <Text style={styles.TextScreenDocumentation}>{(arrayProject[0].fpqID===null && dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Hoja de Ingreso Proyecto" : (dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b") ? "Hoja de Ingreso Proyecto" : null}</Text>
    {entrySheet()}
    </View>
    </View>
 

    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Novedades Proyecto</Text>
    </View>

    {showNovelties()}

    <TouchableOpacity style={styles.ViewCreateNovelty} onPress={()=>{
      setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen}]);
      Navigation.navigate('Create_Novelty_Project');
      }}>
    <Text style={styles.TittleCreateNewSubProject}>Crear Novedad</Text>
    </TouchableOpacity>

    <View style={styles.Line_Space}></View>
    <View style={styles.ViewTittleScreenSubProjects}>
    <Text style={styles.TittleScreenSP}>SubProyectos</Text>
    </View>
    {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
    <TouchableOpacity style={styles.ViewCreateNewSubProjects} onPress={()=>{setCreateSubProject(!createSubProject);}}>
    <Text style={styles.TittleCreateNewSubProject}>Crear SubProyecto</Text>
    </TouchableOpacity>
    }
    {(createSubProject===true) ? 
    <>
      <Formik
        initialValues={{
          SubProjectName: ''
        }}
        onSubmit={values => {
          const SubProjectName=JSON.stringify(values["SubProjectName"]);
          requestCreateSubProject(JSON.parse(SubProjectName));
        }}
        validationSchema={yup.object().shape({
          SubProjectName: yup
            .string()  
            .max(40, 'El nombre del subproyecto no puede exceder 40 caracteres')
            .required('Por favor especifique un nombre para el subproyecto')
        })}
        >
      {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <View style={styles.formContainer}>
            <TextInput
              value={values.SubProjectName}
              style={styles.inputStyle}
              onChangeText={handleChange('SubProjectName')}
              onBlur={() => setFieldTouched('SubProjectName')}
              placeholder="Nombre del SubProyecto"
            />
            {touched.SubProjectName && errors.SubProjectName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.SubProjectName}</Text>
            }
     { (disableSubmit===true) ?  
       <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished}>
       <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
         <Text style={styles.TextStylesFinished}>Procesando</Text>
         </TouchableOpacity>
      : 
            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished}
            disabled={!isValid} onPress={()=>{setShowAlert(true)}}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
              <Text style={styles.TextStylesFinished}>Crear SubProyecto</Text>
              </TouchableOpacity>
      }
            <View style={styles.ViewSpace}></View>

            <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirmar cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
            
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />

          </View>
        )}
      </Formik>

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            if (dataNavigation[2] !== undefined) {
              Navigation.navigate('SubProject');
            } else {
              Navigation.navigate('Project');
            }
            
          }}
        />
    </>
    : null}
    
    <View style={styles.Line_Space}></View>
    {showSubProjects()}

{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b") &&
<>
    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Administración de Proyecto</Text>
    </View>
    <View style={styles.MainContainer}> 

  { (disableSubmit===true) ?  
       <View style={styles.ButtonAdminProject}>
        <TouchableOpacity style={styles.ButtonStylesFinished}>
        <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
          <Text style={styles.TextStylesAdmin}>Procesando</Text>
          </TouchableOpacity>
        </View> 
      : 
        <View style={styles.ButtonAdminProject}>
        <TouchableOpacity style={styles.ButtonStylesFinished} onPress={()=>{
          setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen}]);
          setShowAlertFinishProject(true);
          }}>
        <MaterialCommunityIcons name="rocket-launch" size={15} color="white" />
          <Text style={styles.TextStylesAdmin}>Finalizar</Text>
          </TouchableOpacity>
        </View> 
    }
        <View style={styles.ButtonAdminProject}>
        <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=> {
          setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen}]);
          Navigation.navigate('Update_Project');}}>
        <MaterialIcons name="update" size={15} color="white" />
          <Text style={styles.TextStylesAdmin}>Actualizar</Text>
          </TouchableOpacity>
        </View> 
        <View style={styles.ButtonAdminProject} >
        <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=>{
          setDataNavigation([dataNavigation[0],{projectChosen:dataNavigation[1].projectChosen}]);
          setShowAlertDeletePSP(true);}}>
        <AntDesign name="delete" size={15} color="white" />
          <Text style={styles.TextStylesAdmin}>Eliminar</Text>
          </TouchableOpacity>
        </View>
    </View>
    </>
}
</View>


<AwesomeAlert
          show={showAlertFinishProject}
          showProgress={false}
          title="Confirmar cambios"
          message="¿Estas seguro de que deseas finalizar el Proyecto?. Si finalizas el Proyecto no será posible que tu equipo de trabajo realice mas solicitudes de insumos y herramientas. Presiona el boton [Continuar] para terminar el proceso."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlertFinishProject(false); 
          }}
          onConfirmPressed={() => {
            finishProject()
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />
 <AwesomeAlert
          show={showAlertStateProcess}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertStateProcess(false);
          }}
        />

<AwesomeAlert
          show={alertUpdateDisabled}
          showProgress={false}
          title="Advertencia"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#ffb2b2'}}
          messageStyle={{color:'#e2e2e2'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setAlertUpdateDisabled(false);
          }}
        />
  
  <AwesomeAlert
          show={alertDeletingNovelty}
          showProgress={false}
          title="Confirmar cambios"
          message="Se eliminará la novedad seleccionada, presiona [Continuar] para confirmar los cambios."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setAlertDeletingNovelty(false)
          }}
          onConfirmPressed={() => {
            deleteNovelty();
            
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />


<AwesomeAlert style={styles.AlertContainer}
                show={alertDeletePSPQuotation}
                showProgress={false}
                title="Eliminar Hoja de Salida Proyecto"
                message="¿Estas seguro de que deseas eliminar la Hoja de Salida asociada al Proyecto?, si lo haces tambien se eliminarán todas las solicitudes que aun no se hayan entregado por parte del área de bodega."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                titleStyle={{color:'#8DDBFF'}}
                messageStyle={{color:'#f9f9f9'}}
                contentContainerStyle={{backgroundColor:'#272727'}}
                confirmButtonColor='#809280'
                cancelButtonColor='#746E6E'
      
                onCancelPressed={() => {
                  setAlertDeletePSPQuotation(false)
                }}
                onConfirmPressed={() => {
                  deletePSPQuotation()
                }}
              />

<AwesomeAlert style={styles.AlertContainer}
                show={alertDeletePSPEntrySheet}
                showProgress={false}
                title="Eliminar Hoja de Entrada Proyecto"
                message="¿Estas seguro de que deseas eliminar la Hoja de Entrada asociada al Proyecto?."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                titleStyle={{color:'#8DDBFF'}}
                messageStyle={{color:'#f9f9f9'}}
                contentContainerStyle={{backgroundColor:'#272727'}}
                confirmButtonColor='#809280'
                cancelButtonColor='#746E6E'
      
                onCancelPressed={() => {
                  setAlertDeletePSPEntrySheet(false)
                }}
                onConfirmPressed={() => {
                  deletePSPEntrySheet()
                }}
              />


 <AwesomeAlert
          show={alertDeleteFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setAlertDeleteFinished(false);
          }}
        />      


        
<AwesomeAlert style={styles.AlertContainer}
                show={alertDeleteIDA}
                showProgress={false}
                title="Eliminar Acta de Inicio de Obra"
                message="¿Estas seguro de que deseas eliminar el Acta de Inicio de Obra asociada al Proyecto?, presiona [Continuar] para confirmar los cambios."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                cancelButtonColor='#6c8ead'
                confirmButtonColor="#DD6B55"
      
                onCancelPressed={() => {
                  setAlertDeleteIDA(false)
                }}
                onConfirmPressed={() => {
                  deleteIDA()
                }}
              />


<AwesomeAlert style={styles.AlertContainer}
                show={alertDeleteEDA}
                showProgress={false}
                title="Eliminar Acta de Entrega Final"
                message="¿Estas seguro de que deseas eliminar el Acta de Entrega Final asociada al Proyecto?, presiona [Continuar] para confirmar los cambios."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                cancelButtonColor='#6c8ead'
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertDeleteEDA(false)
                }}
                onConfirmPressed={() => {
                  deleteEDA()
                }}
              />               
<AwesomeAlert style={styles.AlertContainer}
                show={showAlertDeletePSP}
                showProgress={false}
                title="Eliminar Proyecto"
                message="¿Estas seguro de que deseas eliminar el Proyecto?. Antes de realizar este proceso asegurate de haber eliminado toda la documentación asociada, si continuas las solicitudes pendientes por entrega que se hayan creado y las novedades asociadas al Proyecto tambien serán eliminadas."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                cancelButtonColor='#6c8ead'
                confirmButtonColor="#DD6B55"
      
                onCancelPressed={() => {
                  setShowAlertDeletePSP(false)
                }}
                onConfirmPressed={() => {
                  deletePSP()
                }}
              />
<AwesomeAlert
          show={showAlertPSPDeleted}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertPSPDeleted(false);
            setUpdateRequest(!updateRequest);
            Navigation.navigate('Search_Projects_By_NIT');
           
          }}
        />
<AwesomeAlert
          show={showAlertUpload}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertUpload(false)
          }}
        />
</ScrollView>
    ) } else {
      return (
<ScrollView style={styles.BackgroundScrollView}>
<View style={styles.RootContainer}>
    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Datos Básicos del Proyecto</Text>
    </View>
   
    <View style={styles.MainContainer}> 
    <Text style={styles.TextScreenLeft}>Nombre:</Text> 
    <Text style={styles.TextScreenRight}>______________________</Text> 
    <Text style={styles.TextScreenLeft}>Descripción:</Text> 
    <Text style={styles.TextScreenRight}>______________________</Text> 
   

    <Text style={styles.TextScreenLeft}>Dirección:</Text> 
    <Text style={styles.TextScreenRight}>______________________</Text> 
 
  
    <Text style={styles.TextScreenLeft}>Ciudad:</Text> 
    <Text style={styles.TextScreenRight}>______________________</Text> 
 

    <Text style={styles.TextScreenLeft}>Ubicación:</Text> 
    <Text style={styles.TextScreenRight}>______________________</Text> 
  

    <Text style={styles.TextScreenLeft}>Cliente:</Text> 
    <Text style={styles.TextScreenRight}>______________________</Text> 
  

    <Text style={styles.TextScreenLeft}>NIT:</Text> 
    <Text style={styles.TextScreenRight}>______________________</Text> 
  
    <Text style={styles.TextScreenLeft}>Estado:</Text> 
    <Text style={styles.TextScreenRight}>______________________</Text> 

    <Text style={styles.TextScreenLeft}>Fecha Inicio:</Text> 
    <Text style={styles.TextScreenRight}>_____________</Text> 
 
 
    <Text style={styles.TextScreenLeft}>Fecha Finalización:</Text> 
    <Text style={styles.TextScreenRight}>_____________</Text> 

    </View>
  

   

    <View style={styles.ViewTittleScreen}>
    <Text style={styles.TittleScreen}>Novedades Proyecto</Text>
    </View>

    <View style={styles.MainContainer}>
    <View style={styles.TitleDateNovelty} >
    <Text style={styles.TextTitleNovelty}>Fecha</Text>
    </View>
    <View style={styles.TitleUserNovelty} >
    <Text style={styles.TextTitleNovelty}>Usuario</Text>
    </View>
    <View style={styles.TitleDescriptionNovelty} >
    <Text style={styles.TextTitleNovelty}>Novedad</Text>
    </View>
    </View>

</View>
</ScrollView>
      )
    }
}


    return (
      <>
      {showProjectData()}
      </>
    );
  }

const styles = StyleSheet.create({
  TextScreenLeft: {
    color: '#DDE7F0',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'left',
    width:'40%',
    paddingTop:5
  },
  TextScreenRight: {
    color: '#f1f1f1',
    fontSize: 14,
    textAlign:'left',
    width:'54%',
    paddingTop:5,
    paddingLeft:15
  },
  TextScreenLeftSubProjects:{
    color: '#a9acc2',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'left',
    width:'40%',
    paddingTop:5
  },
  TextScreenRightSubProjects:{
    color: '#c2d5d3',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'left',
    width:'60%',
    paddingTop:5
  },
  TextScreenDocumentation: {
    color: '#DDE7F0',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'center',
    width:'100%',
    marginTop:10
  },
  TextScreenDocumentationSP:{
    color: '#f1f1f1',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign:'center',
    width:'100%',
    marginTop:10,
    padding:5,
    backgroundColor:'#1f1f1f',
    borderRadius:5
  },
  MainContainerDocumentation:{
    width:'95%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#1d1c1c',
    padding:5,
    borderRadius:10,
    marginTop:5,
    padding:10
  },
  MainContainerIDA:{
    textAlign:'center',
    alignContent:'center',
    justifyContent: 'center',
    alignItems:'center',
    width:'100%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#111112',
    marginTop:5,
    paddingBottom:15,
    borderRadius:10
  },
  MainContainerEDA:{
    textAlign:'center',
    alignContent:'center',
    justifyContent: 'center',
    alignItems:'center',
    width:'100%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#151515',
    marginTop:5,
    paddingBottom:15,
    borderRadius:10
  },
  MainContainerPSPQuotation:{
    textAlign:'center',
    alignContent:'center',
    justifyContent: 'center',
    alignItems:'center',
    width:'100%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#151515',
    marginTop:5,
    paddingBottom:15,
    borderRadius:10
  },
  MainContainerDocumentationSP:{
    flex:1,
    width:'95%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#c6ced3',
    padding:5,
    borderRadius:10,
    marginTop:5,
    marginBottom:10,
    padding:10
  },
  IconScreenRight: {
    textAlign:'left',
    width:'30%',
    paddingTop:10
  },
  ButtonCreateDocumentation:{
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
    fontWeight: 'bold',
    textAlign:'center',
    width:'100%',
    paddingTop:10
  },
ButtonLeftAdmin: {
    fontWeight: 'bold',
    textAlign:'left',
    width:'30%',
    paddingTop:15,
    marginLeft:5
  },
  ButtonCenterAdmin: {
    textAlign:'center',
    width:'30%',
    paddingTop:15,
    marginLeft:10,
  },
  ButtonRightAdmin: {
    textAlign:'left',
    width:'30%',
    paddingTop:15,
    marginLeft:10,
  },
  TitleDateNovelty: {
    textAlign:'center',
    alignItems:'center',
    width:'25%',
    paddingTop:15,
    paddingBottom:5,
    borderBottomColor: '#A6B9C4', 
    borderBottomWidth: .5,
  },
  TitleUserNovelty: {
    textAlign:'center',
    alignItems:'center',
    width:'25%',
    paddingTop:15,
    paddingBottom:5,
    borderBottomColor: '#A6B9C4', 
    borderBottomWidth: .5,
  },
  TitleDescriptionNovelty: {
    textAlign:'center',
    alignItems:'center',
    width:'50%',
    paddingTop:15,
    paddingBottom:5,
    borderBottomColor: '#A6B9C4', 
    borderBottomWidth: .5,
  },
  DateNovelty: { 
    textAlign:'center',
    alignItems:'center',
    width:'25%',
    paddingLeft:5,
    paddingRight:5,
  },
  UserNovelty: {
    textAlign:'center',
    alignItems:'center',
    width:'25%',
    paddingLeft:5,
    paddingRight:5,
  },
  DescriptionNovelty: {
    textAlign:'justify',
    alignItems:'center',
    width:'50%',
    paddingLeft:5,
    paddingRight:5,
  },
  TextTitleNovelty: {
    fontSize: 14,
    color: '#AFEEEE',
  },
  ButtonAdminProject: {
    fontWeight: 'bold',
    textAlign:'center',
    width:'100%',
    paddingLeft:20,
    paddingRight:20,
    paddingTop:10
  },
  
  ButtonStylesFinished: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#6b8161',
  },

  ButtonStylesUpdate: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#3d3d3d',
  },

  ButtonStylesUnabled: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#e8e8e8',
  },


  ButtonStylesDelete: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#3d3d3d',
  },

  ButtonStylesBlockDownload: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#8a8a8a',
  },
  
  ButtonStylesRequest:{
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#3d3d3d',
  },
  ButtonDocumentsDelivery:{
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor:'#3d3d3d',
    width:'33%',
    paddingTop:5,
    paddingBottom:5,
  },
  ButtonDocumentsDeliveryInitial:{
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor:'#3d3d3d',
    width:'33%',
    paddingTop:5,
    paddingBottom:5,
  },
  ButtonStylesCreateDocumentation:{
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor:'#3d3d3d',
    width:'33%',
    paddingTop:5,
    paddingBottom:5,
  },
  TextStylesAdmin: {
    fontSize: 12,
    color: '#f1f1f1',
    textAlign:'justify'
  },
  TextStylesDisabled: {
    fontSize: 14,
    color: '#7e7d7d',
  },
  RootContainer: {
    flex:1,
    width:'100%',
    alignItems:'center',
    textAlign:'left',
    justifyContent:'center',
    backgroundColor:'#151515',
  },
  MainContainer: {
     width:'90%',
     flexWrap:'wrap',
     flexDirection: 'row',
     marginBottom:5
  },
  NoveltyContainer: {
    width:'100%',
    textAlign:'center',
    alignItems:'center',
    alignContent:'center'
  },
  MainContainerSubProjects: {
    flex:1,
    width:'95%',
    flexWrap:'wrap',
    flexDirection: 'row',
    backgroundColor:'#27272a',
    padding:20,
    marginTop:5,
    borderRadius:10
  },
  BackgroundScrollView: {
    backgroundColor:'#151515'
  },
  TittleScreen:{
    color:'#CEEFFF',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  TittleScreenSP:{
    color:'#CEEFFF',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  TittleCreateNewSubProject:{
    color:'#cee7ff',
    margin:10,
    fontSize:14,
    textAlign:'center'
  },
  IconCreateNewSubProject:{
    color:'#3babff',
    fontSize:24,
    textAlign:'center',
    marginBottom:10
  }
  ,
  ViewTittleScreen:{
    width:'95%',
    backgroundColor:'#0a0a0a',
    marginTop:10,
    borderRadius:10
  },
  ViewTittleScreenSubProjects:{
    width:'95%',
    backgroundColor:'#0a0a0a',
    marginTop:10,
    borderRadius:10,
    marginBottom:10
  }, 
  ViewCreateNewSubProjects:{
    width:'50%',
    backgroundColor:'#333238',
    marginTop:5,
    borderRadius:10,
    marginBottom:10,
  },
  ViewCreateNovelty:{
    width:'45%',
    backgroundColor:'#2d2d2d',
    borderRadius:10,
    margin:15,
  },
  ViewUpdateNovelty:{
    width:'45%',
    backgroundColor:'#80909d',
    marginTop:5,
    marginLeft:5,
    marginRight:5,
    borderRadius:10,
    marginBottom:10,
  },
  ViewDeleteNovelty:{
    width:'45%',
    backgroundColor:'#c07979',
    marginTop:5,
    marginLeft:5,
    marginRight:5,
    borderRadius:10,
    marginBottom:10,
  },
  Line_Space:{
    width:'100%',
    borderBottomColor: '#f9f9f9', 
    borderBottomWidth: .5,
  },
  formContainer: {
    padding: 20,
    backgroundColor:'#2c2c2c',
    width:'95%',
    borderRadius:15,
    marginBottom:10,
  },
  inputStyle:
  {
    width:'100%',
    height:50,
    borderRadius:10,
    padding:10,
    marginTop:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#dbd9d9'
  },
  ButtonCreateProject: {
    backgroundColor:'#54526f',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ButtonFinished: {
    backgroundColor:'#5e5272',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#bccdd4',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStyleLoading: {
    color:'#fa93b4',
    fontSize:16,
    paddingTop:5,
    fontWeight:'bold',
    textAlign:'center'
  },
});

export default Project
