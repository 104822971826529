import React, { useState, useEffect, useContext } from 'react';
import { ScrollView,TextInput, Text, FlatList, View, StyleSheet } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import Context from '../../../../context/Context'
import AwesomeAlert from 'react-native-awesome-alerts';
import { AntDesign } from '@expo/vector-icons';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
  

const Update_End_Delivery_Agreement =()=>  {

  const Navigation = useNavigation();
  const {dataNavigation, identificationUser, setTypeSignature, setUpdateRequest, updateRequest, arrayProject, dataSession} = useContext(Context);
  const [arrayBasicDataIDA,setArrayBasicDataIDA]=useState([]);
  const [arrayBasicDataEDA,setArrayBasicDataEDA]=useState([]);
  //Alerts and response
  const [showAlert, setShowAlert]=useState(false);
  const [showAlertFinished, setShowAlertFinished]=useState(false);
  const [response, setResponse]=useState('');
  const [disableSubmit, setDisableSubmit]=useState(false);
  const [arrayEDA, setArrayEDA]=useState([]);

     //Start Data Liquidity Table//
  const [dataLiquidity, setDataLiquidity] = useState([]);
  const [newItem, setNewItem] = useState({
    id: '',
    description: '',
    qQuotized: '',
    qExecuted: '',
  });

  const handleDeleteItem = (id) => {
    setDataLiquidity(dataLiquidity.filter((item) => item.id !== id));
  };
   //End Data Liquidity Table//
    

  const getBasicDataIDA=() => {
    if (dataNavigation.length>0) {
      //Get:
      executeRequest('/basic_data_ida', {
        "Get_Basic_Data_IDA": [
            {
                "body": {
                    "_Type_Request": "Get_Basic_Data_IDA_Project",
                    "_Client_ID": dataNavigation[0].clientChosen,
                    "_Project_ID": dataNavigation[1].projectChosen,
                    "_SubProject_ID": null
                }
            }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayBasicDataIDA(getData)
            }
          )

    } else { null }

  }
  
  const getBasicDataIDASubProject=() => {
    if (dataNavigation.length>0) {
      //Get:
      executeRequest('/basic_data_ida', {
        "Get_Basic_Data_IDA": [
            {
                "body": {
                    "_Type_Request": "Get_Basic_Data_IDA_SubProject",
                    "_Client_ID": dataNavigation[0].clientChosen,
                    "_Project_ID": dataNavigation[1].projectChosen,
                    "_SubProject_ID": dataNavigation[2].subProjectChosen 
                }
            }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayBasicDataIDA(getData)
            }
          )
    } else { null }
  }

  
  const getBasicDataEDA=() => {
    if (dataNavigation.length>0) {
      //Get:
      executeRequest('/basic_data_eda', {
        "Get_Liquidity_Table_Items": [
            {
                "body": {
                    "_Project_ID": dataNavigation[1].projectChosen,
                    "_SubProject_ID": null
                }
            }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayBasicDataEDA(getData);
              setDataLiquidity(getData);
            }
          )

    } else { null }

  }
  
  const getBasicDataEDASubProject=() => {
    if (dataNavigation.length>0) {
      //Get:
      executeRequest('/basic_data_eda', {
        "Get_Liquidity_Table_Items": [
            {
                "body": {
                    "_Project_ID": dataNavigation[1].projectChosen,
                    "_SubProject_ID": dataNavigation[2].subProjectChosen 
                }
            }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayBasicDataEDA(getData);
              setDataLiquidity(getData);
            }
          )

    } else { null }

  }
  
  useEffect(()=>{
          if (dataNavigation[2] !== undefined) {
            getBasicDataIDASubProject();
            getBasicDataEDASubProject();
        } else {
            getBasicDataIDA();
            getBasicDataEDA();
          }
        }
   ,[])

  const sendRequestUpdateEDA=(Name_Supervisor, Observations, Supervisor_Identification, Contractor_Identification)=>{
      setDisableSubmit(true);
      setShowAlert(false);
      //Put:
      executeRequest('/eda', {
        "Update_EDA": [
          {
            "body": {
              "_Name_Entity_Supervisor": (Name_Supervisor.length>0) ? Name_Supervisor : null,
              "_End_Quantity_Executed": null,
              "_Observations": (Observations.length>0) ? Observations : null,
              "_Signature_Entity_Supervisor": null,
              "_Identification_Entity_Supervisor": (Supervisor_Identification.length>0) ? Supervisor_Identification : null,
              "_Signature_Contractor": null,
              "_Contractor_Identification": (Contractor_Identification.length>0) ? Contractor_Identification : null,
              "_Project_ID": (dataNavigation.length>0) ? dataNavigation[1].projectChosen : null,
              "_SubProject_ID": (dataNavigation[2] !== undefined) ? dataNavigation[2].subProjectChosen : null,
              "_Client_ID": (dataNavigation.length>0) ? dataNavigation[0].clientChosen : null,
              "_User_Update_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
              "_Data_Json": (dataLiquidity !== undefined) ? dataLiquidity : null
            }
          }
        ]
      }, 'post', 'put').then(
            getData=>{
              setResponse(getData[0].Response);
              setUpdateRequest(!updateRequest);
              setShowAlertFinished(true);
              setDisableSubmit(false);
            }
          )

  }


  const sendRequestProject=()=>{
    if (dataNavigation.length>0) {
      //Get:
      executeRequest('/psp-data', {
        "Get_Projects": [
            {
                "body": {
                    "_Type_Request": "End_Delivery_Agreement",
                    "_Client_ID": dataNavigation[0].clientChosen,
                    "_Project_ID": dataNavigation[1].projectChosen,
                    "_SubProject_ID": null,
                    "_Group_Quotation":null,
                    "_Group_Quotation_SubProject":null,
                    "_User_ID":(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" && identificationUser.length>0) ? identificationUser[0]["id"] : null
                }
            }
        ]
      }, 'get', 'post').then(
            getData=>{
              setArrayEDA(getData)
            }
          )

      } else { null }
    }


    const sendRequestSubProject=()=>{
          if (dataNavigation.length>0) {
            //Get:
            executeRequest('/psp-data', {
              "Get_Projects": [
                  {
                      "body": {
                          "_Type_Request": "End_Delivery_Agreement_SubProject",
                          "_Client_ID": dataNavigation[0].clientChosen,
                          "_Project_ID": dataNavigation[1].projectChosen,
                          "_SubProject_ID": dataNavigation[2].subProjectChosen,
                          "_Group_Quotation":null,
                          "_Group_Quotation_SubProject":null,
                          "_User_ID":(dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b" && identificationUser.length>0) ? identificationUser[0]["id"] : null
                      }
                  }
              ]
            }, 'get', 'post').then(
                  getData=>{
                    setArrayEDA(getData)
                  }
                )
            } else { null }
      }


      useEffect(()=>{ 
        if (dataNavigation[2]===undefined) {
        sendRequestProject() 
        } else { 
        sendRequestSubProject()
      }},[])


      
  // Modify Values Required State React:
const modifyValuesState=(id, newValue)=>{
  // Create New Instance about original variable:
  const newDataLiquidity = [...dataLiquidity];
  // Search Index with Id passed:
  const index = newDataLiquidity.findIndex(item => item.id === id);
  // If Object is found, then Update it:
  if (index !== -1) {
    newDataLiquidity[index] = { ...newDataLiquidity[index], qExecuted: newValue };
    setDataLiquidity(newDataLiquidity);
  } else {
    console.log("The id does not exists...");
  }
}


    return (

    <ScrollView style={styles.formContainer}>

<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>ACTA FINAL DE ENTREGA</Text>
</View>
      <Formik
        initialValues={{
          Name_Supervisor: '',
          Observations: '',
          Supervisor_Identification: '',
          Contractor_Identification: ''
        }}
        onSubmit={values => {
          
          const Name_Supervisor=JSON.stringify(values["Name_Supervisor"]);
          const Observations=JSON.stringify(values["Observations"]);
          const Supervisor_Identification=JSON.stringify(values["Supervisor_Identification"]);
          const Contractor_Identification=JSON.stringify(values["Contractor_Identification"]);
          sendRequestUpdateEDA(JSON.parse(Name_Supervisor), JSON.parse(Observations),JSON.parse(Supervisor_Identification), JSON.parse(Contractor_Identification));

        }}
        validationSchema={yup.object().shape({
            Name_Supervisor: yup
            .string()
            .min(3, 'El nombre debe contener como minimo 4 caracteres')
            .max(30, 'El nombre debe contener máximo 30 caracteres'),
            Observations: yup
            .string() 
            .max(500, 'La descripción del proyecto no puede superar los 500 caracteres'),
            Supervisor_Identification: yup
            .number('Este campo únicamente acepta valores numéricos')
            .min(100000, 'El número minimo permitido para almacenar en este campo es 100000')
            .max(999999999999, 'El número maximo permitido para almacenar en este campo es 1000000000'),
            Contractor_Identification: yup
            .number('Este campo únicamente acepta valores numéricos')
            .min(100000, 'El número minimo permitido para almacenar en este campo es 100000')
            .max(999999999999, 'El número maximo permitido para almacenar en este campo es 1000000000'),
        })}
       >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
<View style={styles.formContainer}>

<View style={styles.FormGroup}>
<View style={styles.ContainerForm}>
<Text style={styles.FormStylesText}>Cliente:</Text> 
</View>
<View  style={styles.RightText}>
             <Text style={styles.styleTextRight}>{(arrayBasicDataIDA.length>0) ? arrayBasicDataIDA[0].clientName : null}</Text>
          
</View>
</View>

<View style={styles.FormGroup}>
<View style={styles.ContainerForm}>
<Text style={styles.FormStylesText}>Orden de Compra:</Text> 
</View>
<View  style={styles.RightText}>
             <Text style={styles.styleTextRight}>{(arrayBasicDataIDA.length>0) ? arrayBasicDataIDA[0].paymentOrderService : null}</Text>
          
</View>
</View>

<View style={styles.FormGroup}>
<View style={styles.ContainerForm}>
<Text style={styles.FormStylesText}>Código Cotización:</Text> 
</View>
<View  style={styles.RightText}>
             <Text style={styles.styleTextRight}>{(arrayBasicDataIDA.length>0) ? arrayBasicDataIDA[0].quotationCode : null}</Text>
          
</View>
</View>

<View style={styles.FormGroup}>
<View style={styles.ContainerForm}>
<Text style={styles.FormStylesText}>Nombre de Áreas:</Text> 
</View>
<View  style={styles.RightText}>
             <Text style={styles.styleTextRight}>{(arrayBasicDataIDA.length>0) ? arrayBasicDataIDA[0].consecutive : null}</Text>
          
</View>
</View>
<View style={styles.FormGroup}>
<View style={styles.ContainerForm}>
<Text style={styles.FormStylesText}>Número de Contrato:</Text> 
</View>
<View  style={styles.RightText}>
             <Text style={styles.styleTextRight}>{(arrayBasicDataIDA.length>0) ? arrayBasicDataIDA[0].contractNumber : null}</Text>
          
</View>
</View>

<View style={styles.FormGroup}>
<View style={styles.ContainerForm}>
<Text style={styles.FormStylesText}>Fecha inicio {(dataNavigation[2] !== undefined) ? "SubProyecto" : "Proyecto"}:</Text> 
</View>
<View  style={styles.RightText}>
             <Text style={styles.styleTextRight}>{(arrayProject.length>0) ? arrayProject[0].initDate : null}</Text>
          
</View>
</View>

<View style={styles.FormGroup}>
<View style={styles.ContainerForm}>
<Text style={styles.FormStylesText}>Fecha finalización:</Text> 
</View>
<View  style={styles.RightText}>
             <Text style={styles.styleTextRight}>{(arrayProject.length>0) ? arrayProject[0].endDate : null}</Text>
          
</View>
</View>


<Text style={styles.liquidityTable}>Datos Finales Tabla de Liquidación</Text>

<ScrollView horizontal={true}>
      <FlatList
        data={dataLiquidity}
        renderItem={({ item }) => (
          <View style={styles.itemContainer}>
            <Text style={styles.itemText}>{`Item: ${item.id}`}</Text>
            <Text style={styles.itemText}>{`Descripción: ${item.description}`}</Text>
            <Text style={styles.itemText}>{`Cant. Inicial Cotizada: ${''.concat(item.qQuotized, ' ', item.measure)} `}</Text>
            <View style={styles.rowContainer}>
            <Text  style={styles.itemText}>Cant. Final Ejecutada: </Text>
            <TextInput style={styles.itemTextInput} placeholder={'?'} value={''.concat(item.qExecuted ? item.qExecuted : '')} placeholderTextColor="#ccc" onChangeText={(c) => (modifyValuesState(item.id, c))}/>
            <Text  style={styles.itemText}>{item.measure ? ''.concat(' ', item.measure) : null}</Text>
            </View>
            <TouchableOpacity style={styles.deleteButton} onPress={() => handleDeleteItem(item.id)}>
              <AntDesign name="closecircle" size={20} color="#FF7777" />
            </TouchableOpacity>
          </View>
        )}
        keyExtractor={(item) => item.id}
      />
</ScrollView>

<Text style={styles.ProceduresExecution}>Observaciones Generales:</Text> 
<TextInput    multiline = {true}
              numberOfLines = {10}
              value={values.Observations}
              style={styles.inputStyleProceduresExecution}
              onChangeText={handleChange('Observations')}
              onBlur={() => setFieldTouched('Observations')}
              placeholder={(arrayEDA.length>0) ? "".concat(arrayEDA[0].observations) : ""} 
            />
            {touched.Observations && errors.Observations &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Observations}</Text>
            }
        
               <View style={styles.ButtonCenterTeam}>
            <TouchableOpacity style={styles.ButtonCenterTeam} onPress={()=>{
              setTypeSignature([{typeDoc:'EDA-', typeSign:'-ES'}]);
              Navigation.navigate('Create_Signatures');
              }}>
            <FontAwesome name="group" size={24} color="white" />
              <Text style={styles.TextStylesFinished}>Firma Supervisor Proyecto</Text>
              </TouchableOpacity>
            </View> 




            <TextInput style={styles.Signature} placeholder={(arrayEDA.length>0) ? "".concat(arrayEDA[0].projectSupervisorName) : ""} 
            value={values.Name_Supervisor}
            onChangeText={handleChange('Name_Supervisor')}
            onBlur={() => setFieldTouched('Name_Supervisor')}
            />
             {touched.Name_Supervisor && errors.Name_Supervisor &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Name_Supervisor}</Text>
            }
        
            <TextInput style={styles.Signature} placeholder={(arrayEDA.length>0) ? "".concat(arrayEDA[0].identificationEntitySupervisor) : ""}   
            value={values.Supervisor_Identification}
            onChangeText={handleChange('Supervisor_Identification')}
            onBlur={() => setFieldTouched('Supervisor_Identification')}
            />
           {touched.Supervisor_Identification && errors.Supervisor_Identification &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Supervisor_Identification}</Text>
            }
        



            <View style={styles.ButtonCreateProject}>
            <TouchableOpacity style={styles.ButtonCreateProject} onPress={()=> {
              setTypeSignature([{typeDoc:'EDA-', typeSign:'-CI'}]);
              Navigation.navigate('Create_Signatures');
              }}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="white" />
              <Text style={styles.TextStylesFinished}>Firma Representante ConstruEpoxicos</Text>
              </TouchableOpacity>
            </View> 
            {touched.Observations && errors.Observations &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Observations}</Text>
            }
        


            <TextInput style={styles.Signature} placeholder={(arrayEDA.length>0) ? "".concat(arrayEDA[0].contractorIdentification) : ""}  
            value={values.Contractor_Identification}
            onChangeText={handleChange('Contractor_Identification')}
            onBlur={() => setFieldTouched('Contractor_Identification')}
            />
            {touched.Contractor_Identification && errors.Contractor_Identification &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Contractor_Identification}</Text>
            }


{ (disableSubmit===true) ?  
        <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished}>
        <Ionicons name="create" size={20} color="black" />
          <Text style={styles.TextStylesSave}>Procesando</Text>
          </TouchableOpacity>
      :  
        
            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonFinished}
            disabled={!isValid} onPress={()=>{setShowAlert(true)}}>
            <Ionicons name="create" size={20} color="black" />
              <Text style={styles.TextStylesSave}>Actualizar</Text>
              </TouchableOpacity>
         
            }

<View style={styles.ViewSpace}></View>

<AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
            
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />


          </View>
        )}
      </Formik>

    <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            if (dataNavigation[2] !== undefined) {
              Navigation.navigate('SubProject');
            } else {
              Navigation.navigate('Project');
            }
            
          }}
        />

    </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
    backgroundColor:'#111111',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10,
    backgroundColor:'#c0c0c0',
  },
  FormGroup: {
    flex:1,
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  ContainerForm: {
    textAlign:'left',
    justifyContent:'center',
    width:'50%'
  },
  ContainerFormMeasure: {
    textAlign:'left',
    justifyContent:'center',
    width:'48%',
    verticalAlign:'bottom',
    paddingTop:18
  },
  RightTextInput: {
    alignItems:'center',
    justifyContent:'center',
    borderRadius:10,
    marginTop:12,
    width:'50%',
    backgroundColor:'white'
  },
  RightText: {
    alignItems:'center',
    justifyContent:'center',
    width:'50%',
    marginTop:12,
  },
  styleTextRight:{
    color:'#78e0ff'
  },
  DropDownSelection: {
    marginTop:10,
    marginBottom:10,
    backgroundColor:'white',
    borderRadius:15,
    padding:10,
  },
  MeasureSelection: {
    margin:5,
    marginBottom:10,
    backgroundColor:'white',
    borderRadius:10,
    width:'48%'
  },
  TittleScreen:{
    color:'#fff',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#616161',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10
  },
  ButtonCreateProject: {
    backgroundColor:'#4d83a5',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonFinished: {
    backgroundColor:'#8edbff',
    borderRadius:10,
    marginTop:10,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#bccdd4',
    borderRadius:10,
    marginTop:20,
    marginLeft:60,
    marginRight:60,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonCenterTeam: {
    backgroundColor:'#373737',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  TextStylesSave: {
    color:'#000000',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewSpace: {
    marginTop:10,
    width:'100%'
  },
 FormStylesText:{
   color:'#f1f1f1',
   marginTop:12
 },
  inputStyle:  {
      width:'100%',
      height:80,
      borderRadius:10,
      padding:10,
      marginTop:10,
      textAlign:'justify',
      textAlignVertical:'top',
      backgroundColor:'#dbd9d9'
    },
    inputStyleProceduresExecution:  {
      width:'100%',
      height:150,
      borderRadius:10,
      padding:10,
      marginTop:10,
      textAlign:'justify',
      textAlignVertical:'top',
      backgroundColor:'#dbd9d9'
    },
    ProceduresExecution:{
      marginTop:10,
      color:'#f9f9f9',
      width:'100%'
    },
    Signature:{
      marginTop:10,
      marginLeft:20,
      marginRight:20,
      backgroundColor:'white',
      borderRadius:10,
      height:40,
      paddingLeft:10,
      textAlign:'center'
    },
    TextStyleLoading: {
      color:'#fa93b4',
      fontSize:16,
      paddingTop:5,
      fontWeight:'bold',
      textAlign:'center'
    },
    inputContainer: {
      marginBottom: 10,
    },
    input: {
      marginBottom: 5,
      paddingHorizontal: 10,
      paddingVertical: 8,
      backgroundColor: '#333',
      borderRadius: 5,
      color: '#fff',
    },
    addButton: {
      backgroundColor: '#CDF3CD',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 15,
      width: 30,
      height: 30,
    },
    buttonText: {
      color: '#121212',
      fontSize: 20,
    },
    itemContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      backgroundColor: '#333',
      paddingHorizontal: 50,
      paddingVertical: 8,
      borderRadius: 5,
      flex: 1,
      margin: 1,
    },
    itemText: {
      color: '#fff',
      marginBottom: 5,
    },
    deleteButton: {
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 15,
      width: 30,
      height: 30,
    },
    liquidityTable:{
      color:'#E8F7F7',
      fontWeight:'bold',
      fontSize:16,
      textAlign:'center',
      padding:20
    },
    rowContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },

    itemTextInput: {
      textAlign: 'center',
      color: '#fff',
      backgroundColor:'#838891',
      width:60,
      height:25,
      borderRadius:10
    },
});

export default Update_End_Delivery_Agreement

