import React, { useState,Component, useEffect, useContext } from 'react';
import { ScrollView,TextInput, Text, Button, Alert, View, StyleSheet, Platform, Pressable } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import { DatePickerModal, es, registerTranslation } from 'react-native-paper-dates'
registerTranslation('es', es);
import { AntDesign } from '@expo/vector-icons'; 
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { FlatList } from 'react-native-gesture-handler'
import AwesomeAlert from 'react-native-awesome-alerts';
import Context from '../../../context/Context'

//For making requests:
import {executeRequest} from "../../functional_global_components/API_Requests";
  

const Create_New_Project =()=>  {

  const Navigation = useNavigation();
  const {dataNavigation, identificationUser, updateRequest} = useContext(Context);



    const [Init_Date, setInit_Date]= useState('');
    const [End_Date, setEnd_Date]= useState('');
    const [openModalStartDate, setOpenModalStartDate]=useState(false);
    const [openModalEndDate, setOpenModalEndDate]=useState(false);

  const onDismissSingle = () => {
    setOpenModalStartDate(false);
    setOpenModalEndDate(false);
  }

  const onConfirmStartDate = (params) => {
      setOpenModalStartDate(false);
      const date = params.date;
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      setInit_Date(formattedDate);
    }

    const onConfirmEndDate = (params) => {
      setOpenModalEndDate(false); 
      const date = params.date;
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      setEnd_Date(formattedDate);
    }

    //Alerts:
    const [showAlert, setShowAlert]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [showAlertError, setShowAlertError]=useState(false);
    const [response, setResponse]=useState('');
    const [disableSubmit, setDisableSubmit]=useState(false);

    // Selected list City:
    const [citySelected, setCitySelected]= useState([]);
    const [clientSelected, setClientSelected]= useState([]);
    const [teamSelected, setTeamSelected]= useState([]);
    const [search, setSearch]=useState('0') 
    const [searchClients, setSearchClients]=useState('0') 
    const [searchTeams, setSearchTeams]=useState('0') 

    const  [arrayCities,setArrayCities]= useState([]);
    const  [arrayClients,setArrayClients]= useState([]);
    const [arrayTeams, setArrayTeams]= useState([]);

    
   
    

     const sendRequestCities=()=>{
        //Get:
        executeRequest('/cities', {
          "Get_Cities": [
            {
              "body": {
                "_Type_Request": "Get_Cities"
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayCities(getData)
              }
            )

      }

      const sendRequestClients=()=>{
        //Get:
        executeRequest('/clients', {
          "Get_Clients": [
            {
              "body": {
                "_Type_Request": "Get_Clients",
                "_Client_ID":null
              }
            }
          ]
        }, 'get', 'post').then(
              getData=>{
                setArrayClients(getData)
              }
            )

        }

  
      const sendRequestTeams=()=>{
      //Get:
      executeRequest('/teams', {
        "Get_Teams": [
          {
            "body": {
              "_Type_Request": "Get_Teams_Available",
              "_Team_ID": null,
              "_User_ID": null
            }
          }
        ]
      }, 'get', 'post').then(
                  getData=>{
                    setArrayTeams(getData)
               }
            )
        }

        useEffect(()=>{
          sendRequestCities();
          sendRequestClients();
          sendRequestTeams();
        },[])

        useEffect(()=>{
          sendRequestClients();
          sendRequestTeams();
        },[updateRequest])
        

        const sendRequestCreateProject=(ProjectName, Description, Address, Location)=>{
    
          setDisableSubmit(true);
          setShowAlert(false);
            //Create:
            executeRequest('/projects', {
              "Create_Project": [
                {
                  "body": {
                    "_ProjectName": (ProjectName.length>0) ? ProjectName : null,
                    "_ProjectDescription": (Description.length>0) ? Description : null,
                    "_Address":(Address.length>0) ? Address : null,
                    "_Location": (Location.length>0) ? Location : null,
                    "_City_ID":(citySelected.length>0) ? citySelected[0].id : null,
                    "_Client_ID":(clientSelected.length>0) ? clientSelected[0].id : null,
                    "_InitDate":(Init_Date.length>0) ?  Init_Date : null,
                    "_EndDate":(End_Date.length>0) ?  End_Date : null,
                    "_Team_ID":(teamSelected.length>0) ?  teamSelected[0].id : null,
                    "_User_Creator_ID":(identificationUser.length>0) ? identificationUser[0]["id"] : null
  
                  }
                }
              ]
            }, 'post', 'post').then(
                  getData=>{
                    setResponse(getData[0].Response)
                    setShowAlertFinished(true);
                    setDisableSubmit(false);
                  }
              )
          }

/************StartTableRows*****************/

const itemCities= ({item})=>{
  
  return (
    <Pressable  onPress={()=>{ if (citySelected.find(e=>e.id===item.id)) {
      setCitySelected(citySelected.filter(e=>e.id!==item.id));
      setSearch('0');
    } else { 
      setCitySelected([{id:item.id, name:item.name}]);
      
      setSearch('0');
    }
    }}
  style={{backgroundColor:(citySelected.map(e=>e.id).indexOf(item.id)!==-1)? '#333334' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            margin:5,

            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.name}</Text>
      </View>
    </Pressable>

  )
  
}
/************EndTableRows*****************/

/************StartTableRows*****************/

const itemClients= ({item})=>{
    
  return (
    <Pressable  onPress={()=>{ if (clientSelected.find(e=>e.id===item.id)) {
      setClientSelected(clientSelected.filter(e=>e.id!==item.id));
      setSearchClients('0');
    } else { 
      setClientSelected([{id:item.id, name:item.name}]);
      setSearchClients('0');
    }
    }}
  style={{backgroundColor:(clientSelected.map(e=>e.id).indexOf(item.id)!==-1)? '#333334' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            margin:5,

            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.name}</Text>
      </View>
    </Pressable>

  )
  
}
/************EndTableRows*****************/

/************StartTableRows*****************/

const itemTeams= ({item})=>{
    
  return (
    <Pressable  onPress={()=>{ if (teamSelected.find(e=>e.id===item.id)) {
      setTeamSelected(teamSelected.filter(e=>e.id!==item.id));
      setSearchTeams('0');
    } else { 
      setTeamSelected([{id:item.id, name:item.name}]);
      setSearchTeams('0');
    }
    }}
  style={{backgroundColor:(teamSelected.map(e=>e.id).indexOf(item.id)!==-1)? '#333334' : '#1e1f20',
            flexDirection:'row',
            alignItems:'center',
            textAlign:'center',
            justifyContent:'center',
            borderRadius:10,
            margin:5,

            }}>
      <View style={styles.Row}>
        <Text style={styles.Text}>{item.name}</Text>
      </View>
    </Pressable>

  )
  
}
/************EndTableRows*****************/


const showCities=()=>{
  if (arrayCities.length>0) {
  return (
    <ScrollView horizontal={true}>
    <View style={styles.TableStyles}>
      
    <FlatList 
      data={
        (search!=='0') &&
        arrayCities.filter((e)=>(e.name.toLowerCase().includes((search).toLowerCase()))).slice(0,4)
      }
      renderItem={itemCities} 
      
      /> 
      
    </View>
      </ScrollView>
    )
  } else { null }
}

const showClients=()=>{
  if (arrayClients.length>0) {
  return (
    <ScrollView horizontal={true}>
    <View style={styles.TableStyles}>
      
    <FlatList 
      data={
        (searchClients!=='0') ?
        arrayClients.filter((e)=>(e.name.toLowerCase().includes((searchClients).toLowerCase()))).slice(0,6)
        :
        arrayClients.slice(0,6)
      }
      renderItem={itemClients} 
      
      /> 
      
    </View>
      </ScrollView>
    )
  } else { null }
}

const showTeams=()=>{
  if (arrayTeams.length>0) {
  return (
    <ScrollView horizontal={true}>
    <View style={styles.TableStyles}>
      
    <FlatList 
      data={
        (searchTeams!=='0') ?
        arrayTeams.filter((e)=>(e.name.toLowerCase().includes((searchTeams).toLowerCase()))).slice(0,6)
        :
        arrayTeams.slice(0,6)
      }
      renderItem={itemTeams} 
      
      /> 
      
    </View>
      </ScrollView>
    )
  } else { null }
}

    return (

    <ScrollView style={styles.formContainer}>

<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Crear Nuevo Proyecto</Text>
</View>
      <Formik
        initialValues={{
          ProjectName: '',
          Description: '',
          Address: '',
          Location: ''
        }}
        onSubmit={values => { 
          if (Init_Date.length>0 && citySelected.length>0 && clientSelected.length>0 && teamSelected.length>0 ) { 
          const ProjectName=JSON.stringify(values["ProjectName"]);
          const Description=JSON.stringify(values["Description"]);
          const Address=JSON.stringify(values["Address"]);
          const Location=JSON.stringify(values["Location"]);
          sendRequestCreateProject(JSON.parse(ProjectName), JSON.parse(Description), JSON.parse(Address), JSON.parse(Location));
          } else {
            setResponse('Por favor asegurate de asignar Ciudad, Cliente, Fecha Inicio y un Equipo al nuevo Proyecto.');
            //console.log("fecha inicio: ", Init_Date, "ciudad: ", citySelected, "cliente: ",clientSelected ,  "equipo: ", teamSelected)
            setShowAlertError(true);
          }
        }}
        validationSchema={yup.object().shape({
            ProjectName: yup
            .string()  
            .min(4, 'El nombre del proyecto debe tener como minimo 4 caracteres')
            .max(40, 'El nombre del proyecto no puede exceder 40 caracteres')
            .required('Por favor especifique un nombre para el proyecto'),
            Description: yup
            .string()  
            .max(100, 'La descripción del proyecto no puede superar los 100 caracteres'),
            Address: yup
            .string()
            .min(4, 'La dirección debe contener como mínimo 4 caracteres')
            .max(40, 'La dirección no puede contener mas de 40 caracteres')
            .required('Por favor especifique una dirección'),
            Location: yup
            .string()
            .min(3, 'La ubicación debe tener mínimo 3 caracteres')
            .max(30, 'La ubicación debe tener máximo 30 caracteres')
        })}
       >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
          <View style={styles.formContainer}>
            <TextInput
              value={values.ProjectName}
              style={styles.inputStyle}
              onChangeText={handleChange('ProjectName')}
              onBlur={() => setFieldTouched('ProjectName')}
              placeholder="Nombre del Proyecto"
            />
            {touched.ProjectName && errors.ProjectName &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.ProjectName}</Text>
            }
            <TextInput
              value={values.Description}
              style={styles.inputStyleDescription}
              onChangeText={handleChange('Description')}
              onBlur={() => setFieldTouched('Description')}
              placeholder="Descripción"
              multiline = {true}
              numberOfLines = {4}
            />
            {touched.Description && errors.Description &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Description}</Text>
            }
              <TextInput
              value={values.Address}
              style={styles.inputStyle}
              onChangeText={handleChange('Address')}
              placeholder="Dirección"
              onBlur={() => setFieldTouched('Address')}
            />
            {touched.Address && errors.Address &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Address}</Text>
            }
             <TextInput
              value={values.Location}
              style={styles.inputStyle}
              onChangeText={handleChange('Location')}
              placeholder="Ubicación  (Barrio,Casa, Piso, Apartamento)"
              onBlur={() => setFieldTouched('Location')}
            />
            {touched.Location && errors.Location &&
              <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.Location}</Text>
            }


<View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder={(citySelected.length>0) ?  citySelected[0].name : 'Especifica una ciudad'} style={(citySelected.length>0) ? 
    styles.SearchInputSelected  : styles.SearchInput}
     onChangeText={(e) => {
    (e==='')?setSearch('0'):setSearch(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>


    {showCities()}


<View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder={(clientSelected.length>0) ?  clientSelected[0].name : 'Selecciona un cliente'} style={(clientSelected.length>0) ? 
    styles.SearchInputSelected  : styles.SearchInput}
     onChangeText={(e) => {
    (e==='')?setSearchClients('0'):setSearchClients(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>
     

    {showClients()}

               <View style={styles.ButtonCenterTeam}>
            <TouchableOpacity style={styles.ButtonCenterTeam} onPress={()=> Navigation.navigate('Create_Client')}>
            <MaterialCommunityIcons name="office-building-cog" size={24} color="#f9f9f9" />
              <Text style={styles.TextStylesFinished}>Crear Nuevo Cliente</Text>
              </TouchableOpacity>
            </View> 



{/*Implement Date Picker Init_Date: */}
<View style={styles.formGroupDates}>
<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText}  title='Select_Date' onPress={() => setOpenModalStartDate(true)}>Fecha Inicio  <AntDesign name="calendar" size={24} color="#f1f1f1" /></Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={Init_Date}   
             style={styles.inputStyle}
             editable={false}
             selectTextOnFocus={false}
             placeholder="mm/dd/aaaa"
             />
</View>
</View>
{/*Implement Date Picker End_Date: */}
<View style={styles.formGroupDates}>
<View style={styles.DatesPicked}>
<Text style={styles.FormStylesText} title='Select_Date' onPress={() =>  setOpenModalEndDate(true)}>Fecha Finalización  <AntDesign name="calendar" size={24} color="#f1f1f1" /></Text> 
</View>
<View  style={styles.DatesInput}>
             <TextInput value={End_Date} 
             style={styles.inputStyle}
             editable={false}
             selectTextOnFocus={false}
             placeholder="mm/dd/aaaa"
             />
</View>
</View>


<View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder={(teamSelected.length>0) ?  teamSelected[0].name : 'Selecciona un equipo'} style={(teamSelected.length>0) ? 
    styles.SearchInputSelected  : styles.SearchInput}
     onChangeText={(e) => {
    (e==='')?setSearchTeams('0'):setSearchTeams(e) 
      } 
    }
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>


    {showTeams()}


               <View style={styles.ButtonCenterTeam}>
            <TouchableOpacity style={styles.ButtonCenterTeam} onPress={()=> Navigation.navigate('Create_Team')}>
            <FontAwesome name="group" size={24} color="white" />
              <Text style={styles.TextStylesFinished}>Crear Nuevo Equipo de Trabajo</Text>
              </TouchableOpacity>
            </View> 

 { (disableSubmit===true) ?  
        <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonCreateProject}>
        <MaterialCommunityIcons name="rocket-launch" size={20} color="#60a970" />
          <Text style={styles.TextStylesFinished}>Procesando</Text>
          </TouchableOpacity>
      :
          
            <TouchableOpacity style={(!isValid===true) ? styles.ButtonDisabled : styles.ButtonCreateProject} disabled={!isValid} onPress={()=>{setShowAlert(true);}}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="#60a970" />
              <Text style={styles.TextStylesFinished}>Crear Proyecto</Text>
              </TouchableOpacity>
           
 }


<View style={styles.ViewSpace}></View>
        
<AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Confirma cambios"
          message="Se efectuará la ejecución del proceso con los datos especificados, si estas seguro presiona el botón continuar."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancelar"
          confirmText="Si, continuar"
          onCancelPressed={() => {
          setShowAlert(false); 
          }}
          onConfirmPressed={() => {
            handleSubmit();
          }}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          confirmButtonColor='#809280'
          cancelButtonColor='#746E6E'
        />    
          </View>
        )}
      </Formik>

      <AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.navigate('StackProjects');
          }}
        />

<AwesomeAlert
          show={showAlertError}
          showProgress={false}
          title="Verifica los Datos"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#ffb2b2'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertError(false);
          }}
        />


      {/*Show DateTimePicker if varible Show is true: */}

      {/* {show && (
              <DateTimePicker
              testID='dateTimePicker'
              value={date}
              mode={mode}
              is24Hour={false}
              display='default'
              onChange={onChange}
              />
            )
      } */}

<DatePickerModal
          locale="es"
          mode="single"
          visible={openModalStartDate}
          onDismiss={onDismissSingle}
          date={Init_Date}
          onConfirm={onConfirmStartDate}
        />
<DatePickerModal
          locale="es"
          mode="single"
          visible={openModalEndDate}
          onDismiss={onDismissSingle}
          date={End_Date}
          onConfirm={onConfirmEndDate}
        />
    </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
    backgroundColor:'#111111',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10,
    backgroundColor:'#c0c0c0',
  },
  formGroupDates: {
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  DatesPicked: {
    textAlign:'left',
    justifyContent:'center',
    width:'65%',
    height:60,
  },
  DatesInput: {
    alignItems:'center',
    justifyContent:'center',
    borderRadius:30,
    width:'35%',
    height:60,
  },
  DropDownSelection: {
    marginTop:10,
    backgroundColor:'#505050',
    padding:5,
    borderRadius:10,
  },
  TittleScreen:{
    color:'#99f5bc',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#383838',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10
  },
  ButtonCreateProject: {
    backgroundColor:'#282828',
    borderRadius:10,
    marginTop:20,
    paddingTop:15,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDisabled: {
    backgroundColor:'#929292',
    borderRadius:10,
    marginTop:20,
    paddingTop:15,
    marginLeft:40,
    marginRight:40,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonCenterTeam: {
    backgroundColor:'#24262c',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewSpace: {
    marginTop:10,
    width:'100%'
  },
 FormStylesText:{
   color:'#f1f1f1'
 },

 inputStyle : {
  width:'100%',
  height:50,
  borderRadius:10,
  padding:10,
  marginTop:10,
  alignItems:'center',
  justifyContent:'center',
  backgroundColor:'#767a7a',
  color:'#303030'
  },
  inputStyleDescription: {
    width:'100%',
    height:80,
    borderRadius:10,
    padding:10,
    marginTop:10,
    textAlignVertical:'top',
    backgroundColor:'#767a7a'
    },
    ContainerSearch: {
      marginTop:5,
      flexDirection:'row',
      flexWrap:'wrap',
      alignItems:'center',
    },
    SearchInput: {
      backgroundColor:'#767a7a',
      width:'80%',
      height:35,
      textAlign:'left',
      borderRadius:10,
      marginTop:5,
      paddingLeft:10
    },
    SearchInputSelected: {
      backgroundColor:'#767a7a',
      width:'80%',
      height:35,
      textAlign:'left',
      borderRadius:10,
      marginTop:5,
      paddingLeft:10
    },
    IconSearch:{
      width:'10%',
      marginLeft:20
    },
    TableStyles: {
      backgroundColor:'#181818',
      borderRadius:10
    },
    Row:{
      textAlign:'left',
      alignItems:'center',
      justifyContent:'center',
      textAlign:'center'
    },
    
    Text:{
      padding:8,
      color:'#f1f1f1',
      textAlign:'center',
      justifyContent:'center',
      alignItems:'center',
      width:180
    },
    TextStyleLoading: {
      color:'#fa93b4',
      margin:10,
      fontSize:16,
      fontWeight:'bold',
      textAlign:'center'
    },
});

export default Create_New_Project
