import React, { useState, useContext, useEffect } from 'react';
import { ScrollView,TextInput, Text, View, StyleSheet, TouchableOpacity, Pressable, Button } from 'react-native';
import { FlatList } from 'react-native-gesture-handler'
import Context from '../../../../context/Context';
import { Fontisto } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
//Formulary validations:
import * as yup from 'yup'
import { Formik } from 'formik'
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
//MQTT Connection:
import Paho from "paho-mqtt";

const Delivery_Detail =()=>  {

  
    const  {dataNavigationRequest, identificationUser, setUpdateRequest, updateRequest, dataSession, MQTTObject}= useContext(Context);
    const [itemSelected, setItemSelected] = useState([{id:'0'},]);
    const [arrayRequests, setArrayRequests]=useState([]);
    const [showAlertChecked, setShowAlertChecked]=useState(false);
    const [showAlertError, setShowAlertError]=useState(false);
    
    const [showAlert, setShowAlert]=useState(false);
    const [response, setResponse]=useState('');
    const [disableSubmit, setDisableSubmit]=useState(false);
    const [showAlertFinished, setShowAlertFinished]=useState(false);
    const [showAlertDeliveryFinished, setShowAlertDeliveryFinished]=useState(false);
    const [unlockState,setUnlockState]=useState(false);
    const [disableButton, setDisableButton]=useState(false);
    const [showAlertLockStateFinished,setShowAlertLockStateFinished]=useState(false);


    const Navigation = useNavigation();
    
 //Paho client connection:
 const client = new Paho.Client(
  MQTTObject.MQTT,
  Number(MQTTObject.Port), 
  identificationUser[0].id
);

client.connect({
  timeout: 15, 
  onSuccess: ()=>{ 
    client.subscribe("esp32/blocker");
   },
  onFailure: ()=>{ 
    console.log("Failed to connect!");
   },
  userName:MQTTObject.Usr,
  password:MQTTObject.Pwd, 
  useSSL: true, 
  keepAliveInterval: 60, 
  reconnect: false});

    const changeValue=(Number)=>{
      try {
  
      const message = new Paho.Message(String(Number));
      message.destinationName="esp32/blocker";
      client.send(message);
      setShowAlertLockStateFinished(true);
    
    setTimeout(()=>{
      setDisableButton(false);  
    }, 30000)
      } catch (error){
        setDisableButton(false);
        setShowAlertError(true);
      }
  
      }

 
 
const sendRequest=()=>{  
//Get:
executeRequest('/requests', {
  "Get_Requests": [
    {
      "body": {
        "_Type_Request": "Get_Requests_Detailed",
        "_Request_ID": dataNavigationRequest[0].requestChosen,
        "_User_ID": null
      }
    }
  ]
}, 'get', 'post').then(
      getData=>{
        setArrayRequests(getData)
      }
    ) 
}

      useEffect(()=>{
        sendRequest();
    },[]);
  

  const saveLogAccessStore=(typeAccess)=>{
  //Create:
 executeRequest('/access_store', {
  "Log_Access_Store": [
    {
      "body": {
        "_User_ID": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
        "_Type_Access":Number(typeAccess),
        "_Type_Request":"Finishing_Delivery"
      
      }
    }
  ]
}, 'post', 'post').then(
      getData=>{
        null
      }
    )
}

const Finishing_Delivery=(observation)=>{  
  setDisableSubmit(true);
  setShowAlert(false);
  //Create:
 executeRequest('/deliveries', {
  "Finishing_Delivery": [
    {
      "body": {
        "_ID_Responsable_Delivery": (identificationUser.length>0) ? identificationUser[0]["id"] : null,
        "_Observation":(observation.length>0) ? observation : null,
        "_Request_ID": (dataNavigationRequest.length>0) ? dataNavigationRequest[0].requestChosen : null
      }
    }
  ]
}, 'post', 'post').then(
      getData=>{
        setResponse(getData[0].Response);
        setUpdateRequest(!updateRequest);
        setShowAlertDeliveryFinished(true);
        setDisableSubmit(false);
      }
    )
         
  }
 
const listArrayItems= ({item})=>{


        return (
          <Pressable  onPress={()=>{ if (itemSelected.find(e=>e.id===item.id)) {
            setItemSelected(itemSelected.filter(e=>e.id!==item.id)) 
          } else {
           setItemSelected((e)=>[...e, {id:item.id}])
          }
          }}
        style={{backgroundColor:(itemSelected.map(e=>e.id).indexOf(item.id)!==-1)? '#0a0a11' : '#1f2226' 
                  ,  flexDirection:'row',
                  borderBottomWidth:0.2,
                  borderColor:'#5d5d6c',
                  alignItems:'center',}}>
  {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b") && 
             <View style={styles.Row}>
        {(itemSelected.map(e=>e.id).indexOf(item.id)!==-1)? <Feather name="check-circle" size={18} color="white" style={styles.Text}/>  : <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={18} color="grey" style={styles.Text}/> }
        </View>
  }
            <View style={styles.Row}>
              <Text style={styles.TextRows}>{item.itemName}</Text>
            </View>
            <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.quantity}</Text>
            </View>
            <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.measure}</Text>
            </View>
            <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.itemType}</Text>
             </View>
             <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.existsOnStore}</Text>
             </View>
             <View style={styles.Row}>
            <Text style={styles.TextRows}>{item.quantityExisting}</Text>
             </View>
            </Pressable>

        )
      
      }

if (dataNavigationRequest !== null && arrayRequests.length>0) {
    return (
<View style={styles.MainContainer}>    
<ScrollView>

<View style={styles.formContainer}>
<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>
{arrayRequests[0].requestTitle}
</Text>
</View>
    
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Nombre solicitante:</Text> 
        </View>
        <View  style={styles.TextRight} >
                     <Text style={styles.textRightStyle}>{arrayRequests[0].fullName}</Text>
        </View>
        </View>
        
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Rol asociado:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].rol}</Text>
        </View>
</View>
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Fecha solicitud:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].dateRequest}</Text>
        </View>
</View>
<View style={styles.formGroup}>
<Text style={styles.titleJustification}>Justificación:</Text> 
</View>
<View style={styles.formGroup}>
  <Text style={styles.justification}>{arrayRequests[0].justification}</Text>
  </View>  
  <View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Proyecto Asociado:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyleSubProject}>{arrayRequests[0].nameProject}</Text>
        </View>
</View>
{(arrayRequests[0].nameSubProject !== null) ? 
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>SubProyecto Solicitud:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyleSubProject}>{arrayRequests[0].nameSubProject}</Text>
        </View>
</View>
: null}
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Cliente:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].clientName}</Text>
        </View>
</View>
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>NIT:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].NIT}</Text>
        </View>
</View>
<View style={styles.formGroup}>
        <View style={styles.TextLeft}>
        <Text style={styles.FormStylesText}>Estado solicitud:</Text> 
        </View>
        <View  style={styles.TextRight}>
        <Text style={styles.textRightStyle}>{arrayRequests[0].state}</Text>
        </View>
</View>

</View>
              
        <View style={styles.TableStyles}> 
        <ScrollView horizontal={true} nestedScrollEnabled={true}>    
        <FlatList 
            data={arrayRequests}
            renderItem={listArrayItems} 
            ListHeaderComponent={()=>{
                return(
                  <View style={styles.ViewRows}>
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b") && 
                    <View style={styles.Row}>
                          <Text style={styles.TextHeader}>¿Entregado?</Text>
                        </View>
            }
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Item</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cantidad Requerida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>U. Medida</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Tipo</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>¿Existe en Bodega?</Text>
                        </View>
                        <View style={styles.Row}>
                          <Text style={styles.TextHeader}>Cant. Existente</Text>
                        </View>
                  </View>
                      )}}
                        />  
</ScrollView>
        </View>
        


{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b") && 
        <Formik
        initialValues={{
          observation: ''
        }}
        onSubmit={values => {
          const observation=JSON.stringify(values["observation"]);
          Finishing_Delivery(JSON.parse(observation));
        }}
        validationSchema={yup.object().shape({
            observation: yup
            .string()
            .max(300, 'La cantidad máxima de caracteres para justificar la solicitud es de 300')
        })}
       >

        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (

<View style={styles.formContainer}>

          <TextInput
          multiline = {true}
          numberOfLines = {10}
          value={values.observation}
          style={styles.inputStyleProceduresExecution}
          onChangeText={handleChange('observation')}
          onBlur={() => setFieldTouched('observation')}
          placeholder="Observación"
          />
  {touched.observation && errors.observation &&
  <Text style={{ fontSize: 12, color: '#FF0D10' }}>{errors.observation}</Text>
  }






{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b") && 
<View style={styles.alignButtons}>
<TouchableOpacity style={(disableButton===false) ?  styles.ButtonUnlockStyle : styles.ButtonUnlockDisableStyle } onPress={()=> {
              setDisableButton(true);
              if (disableButton===false) {
              setUnlockState(true);
              changeValue(0);
              saveLogAccessStore(0);
                }
             }}>
            <FontAwesome5 style={styles.iconButtons}  name="unlock-alt" size={18} color="#2c2929" />
            <Text style={styles.TextStylesFinished}>Desbloquear Bodega</Text>
              </TouchableOpacity>


              <TouchableOpacity style={(disableButton===false) ?  styles.ButtonLockStyle : styles.ButtonLockDisableStyle } onPress={()=> {
              setDisableButton(true);
              if (disableButton===false) {
              setUnlockState(false);
              changeValue(1);
              saveLogAccessStore(1);
                }
             }}>
            <FontAwesome5 style={styles.iconButtons}  name="unlock-alt" size={18} color="#2c2929" />
            <Text style={styles.TextStylesFinished}>Bloquear Bodega</Text>
              </TouchableOpacity>

  { (disableSubmit===true) ?  
      <View style={styles.ButtonRightAdmin} >
      <TouchableOpacity style={styles.ButtonStylesDelete}>
      <FontAwesome name="send-o" size={20} color="#494949" />
         <Text style={styles.TextStylesAdmin}>Procesando</Text>
         </TouchableOpacity>
 </View>
      :     
         <View style={styles.ButtonRightAdmin} >
       <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=>{
        (itemSelected.filter(e=>(e.id!=='0')).length === arrayRequests.length) ?
        setShowAlert(true)
        : 
        setShowAlertChecked(true);
       }}>
       <FontAwesome name="send-o" size={20} color="#494949" />
          <Text style={styles.TextStylesAdmin}>Finalizar Entrega</Text>
          </TouchableOpacity>
  </View>
}

  </View>
}


<AwesomeAlert
show={showAlert}
showProgress={false}
title="Confirmación Finalización Entrega"
message="Estas a punto de finalizar el proceso de entrega. Si estas completamente seguro presiona el botón continuar."
closeOnTouchOutside={false}
closeOnHardwareBackPress={false}
showCancelButton={true}
showConfirmButton={true}
cancelText="No, cancelar"
confirmText="Si, continuar"
onCancelPressed={() => {
setShowAlert(false); 
}}
onConfirmPressed={() => {
  handleSubmit();
  
}}
titleStyle={{color:'#8DDBFF'}}
messageStyle={{color:'#f9f9f9'}}
contentContainerStyle={{backgroundColor:'#272727'}}
confirmButtonColor='#809280'
cancelButtonColor='#746E6E'
style={{zIndex:1, flex:1}}
/>

</View>

)}

</Formik>
}


<AwesomeAlert
          show={showAlertChecked}
          showProgress={false}
          title="Verifica la Entrega"
          message="Aun no se han terminado de marcar como entregados los Items asociados a la solicitud, por favor asegurate de haber marcado como entregados todos los items antes de finalizar la entrega."
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertChecked(false);
          }}
        />

<AwesomeAlert
          show={showAlertDeliveryFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.navigate('Deliveries');
          }}
        />


<AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title={(unlockState===true) ? "Desbloqueo Realizado" : "Bloqueo Realizado" }
          message={(unlockState===true) ? 
            "Se desbloqueó exitosamente el acceso a bodega. Por favor no olvides cerrar nuevamente la puerta cuando hayas terminado el proceso de entrega."
          : 
            "Se activó exitosamente el bloqueo magnético de la bodega, por favor verifica que la puerta se encuentre bien ajustada, gracias."
          }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
          }}
        />

<AwesomeAlert
          show={showAlertLockStateFinished}
          showProgress={false}
          title={(unlockState===true) ? "Desbloqueo Realizado" : "Bloqueo Realizado" }
          message={(unlockState===true) ? 
            "Se desbloqueó exitosamente el acceso a bodega. Por favor no olvides cerrar nuevamente la puerta cuando hayas terminado el proceso de entrega."
          : 
            "Se activó exitosamente el bloqueo magnético de la bodega, por favor verifica que la puerta se encuentre bien ajustada, gracias."
          }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertLockStateFinished(false);
          }}
        />
<AwesomeAlert
          show={showAlertError}
          showProgress={false}
          title="Error Inesperado"
          message="Se ha presentado un error inesperado al intentar establecer comunicación con el servidor, por favor reintenta la petición nuevamente."
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#ffb2b2'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertError(false);
          }}
        />

 </ScrollView>
        </View>
     

    ); } else { 

      return (
        <View style={styles.MainContainer}>
        <ScrollView>
           
        <View style={styles.formContainer}>
        <View style={styles.ViewTittleScreen}>
        <Text style={styles.TittleScreen}>
        ___________________
        </Text>
        </View>
            </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Nombre solicitante:</Text> 
                </View>
                <View  style={styles.TextRight} >
                             <Text style={styles.textRightStyle}>___________________</Text>
                </View>
                </View>
                
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Rol asociado:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Fecha solicitud:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Justificación:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Proyecto:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Cliente:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>NIT:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        <View style={styles.formGroup}>
                <View style={styles.TextLeft}>
                <Text style={styles.FormStylesText}>Estado solicitud:</Text> 
                </View>
                <View  style={styles.TextRight}>
                <Text style={styles.textRightStyle}>___________________</Text>
                </View>
        </View>
        
              </ScrollView>
          </View>
            );
     } 
  }

const styles = StyleSheet.create({
  MainContainer:{
    backgroundColor:'#0d0d0d',
    justifyContent:'center',
    flex:1,
    flexDirection:'row'
  },
  formContainer: {
    padding: 10
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10
  },
  FormStylesText:{
    color:'#f1f1f1'
  },
  formGroup: {
    justifyContent:'center',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'center',
    width:'90%'
  },
  TextRight: {
    alignItems:'flex-end',
    justifyContent:'flex-end',
    width:'50%',
    color:'#f9f9f9'
  },
  TextLeft: {
    textAlign:'left',
    justifyContent:'center',
    width:'40%'
  },
  DropDownSelection: {
    marginTop:10
  },
  TittleScreen:{
    color:'#a8eec9',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewRoot:{
     backgroundColor:'#111111',
     width:'100%',

  },
  ViewTittleScreen:{
    backgroundColor:'#181818',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:40
  },
  ButtonCenterFinished: {
    backgroundColor:'#4d83a5',
    marginLeft:40,
    marginRight:40,
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:12,
    fontWeight:'bold',
    textAlign:'center'
  },
  ButtonUnlockStyle:{
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#4F4242',
    width:'48%'
  },
  ButtonLockStyle:{
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#424F44',
    marginLeft:5,
    width:'48%'
  },
  ButtonUnlockDisableStyle:{
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#bfbfbf',
    width:'48%'
  },
  ButtonLockDisableStyle:{
    marginTop:20,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginLeft:5,
    backgroundColor:'#bfbfbf',
    width:'48%'
  },
  Row:{
    textAlign:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  
  ContainerSearch: {
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'center',
  },
  SearchInput: {
    backgroundColor:'#767a7a',
    width:'70%',
    height:25,
    textAlign:'left',
    borderRadius:10,
    marginTop:40,
    paddingLeft:10,
    marginLeft:30
  },
  IconSearch:{
    width:'10%',
    marginTop:40,
    marginLeft:20
  },
  TextHigh: {
    textAlign:'left',
    color:'#EDF0F5',
    paddingTop:'5%',
    paddingLeft:'5%',
    paddingRight:'5%',
    marginBottom:5
  },
  PropertiesView:{
    backgroundColor:'#111111',
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:5
  },
  
  Text:{
    width:120,
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
  },
  TextHeader:{
    color:'#B4DCEC',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TextRows:{
    color:'#f1f1f1',
    width:120,
    padding:5,
    textAlign:'center',
    fontWeight:'bold',
  },
  TableStyles: {
    backgroundColor:'#181818',
    textAlign:'center',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    borderRadius:10,
    flexDirection:'row'
  },
  TextInput:{
    width:60,
    backgroundColor:'grey',
    borderRadius:7,
    color:'#f1f1f1',
    textAlign:'center',
    borderRightWidth:1,
    borderRightColor:'#393939',
    alignContent:'center',
    marginLeft:30,
    marginRight:30
  },
  ListSelectedContainer: {
    flex:1,
    flexDirection:'row',
    flexWrap:'wrap',
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
    marginTop:10
  },
  LeftSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    color:'#f1f1f1'
  },
  CenterSelect:{
    width:'18%',
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    marginTop:10,
    color:'#f1f1f1'
  },
  RightSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    color:'#f1f1f1'
  },

  LeftTitleSelect:{
    width:'40%',
    textAlign:'left',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  CenterTitleSelect:{
    width:'18%',
    textAlign:'center',
    borderRadius:10,
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  RightTitleSelect:{
    width:'35%',
    textAlign:'center',
    marginTop:10,
    fontWeight:'bold',
    color:'#f1f1f1'
  },
  QuantitySelected:{
    width:120,
    textAlign:'center',
    backgroundColor:'#5e6b80',
    borderRadius:10,
    height:35,
    color:'#f1f1f1'
  },
  finalValues:{
    marginTop:20
  },
  textRightStyle:{
    color:'#89cff8'
  },
  titleJustification:{
    color:'#a9d0ff',
    marginTop:10
  },
  justification:{
    color:'#eaf4ff',
    margin:10
  },
    ButtonLeftAdmin: {
    textAlign:'center',
    width:'30%',
    paddingTop:15,
    
  },
  ButtonCenterAdmin: {
    textAlign:'center',
    width:'45%',
    paddingTop:15
  },
  ButtonRightAdmin: {
    textAlign:'left',
    width:'45%',
    paddingTop:15,
    marginLeft:5,
  },
  ButtonStylesApproval: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#a9a9a9',
  },
  ButtonStylesUpdate: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#b75454',
    marginBottom:10
  },
  ButtonStylesDelete: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#54b790',
    marginBottom:10
  },
  TextStylesAdmin: {
    fontSize: 14,
    color: '#181818',
    fontWeight:'bold'
  },
  alignButtons:{
    justifyContent:'center',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'center',
    width:'100%'
  },
  inputStyleProceduresExecution:  {
    width:'100%',
    height:100,
    borderRadius:10,
    padding:10,
    marginTop:10,
    textAlign:'justify',
    textAlignVertical:'top',
    backgroundColor:'#afafaf'
  },
  textRightStyleSubProject:{
    color:'#ff6868'
  },
});

export default Delivery_Detail

