import React, { useState,Component, useEffect, useContext } from 'react';
import { ScrollView,TextInput, Text, Button, Alert, View, StyleSheet, Platform } from 'react-native';
import * as yup from 'yup'
import { Formik } from 'formik'
import { AntDesign } from '@expo/vector-icons'; 
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import Context from '../../../../context/Context';
import AwesomeAlert from 'react-native-awesome-alerts';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
  

const Audit_Detail =()=>  {

  const Navigation = useNavigation();
  const  {dataNavigationAuditsSch}= useContext(Context);

    
  //Deleting Audits Scheduled:
  const [alertDeleteAuditSch, setAlertDeleteAuditSch]=useState(false);
  const [disableSubmit, setDisableSubmit]=useState(false);
  const [response, setResponse]=useState('');
  const [showAlertFinished, setShowAlertFinished]=useState(false);

   
  
  

const deleteAudit=()=>{
setDisableSubmit(true);
setAlertDeleteAuditSch(false);
//Delete:
executeRequest('/audits/schedule', {
  "Delete_AuditScheduled": [
    {
      "body": {
        "_Audits_Scheduled_ID": dataNavigationAuditsSch[0].auditSchChosen
      }
    }
  ]
}, 'post', 'delete').then(
      getData=>{
        setResponse(getData[0].Response)
        setShowAlertFinished(true);
        setDisableSubmit(false);
      }
    )
  }



    return (

    <ScrollView style={styles.formContainer}>

<View style={styles.ViewTittleScreen}>
<Text style={styles.TittleScreen}>Detalle Auditoría</Text>
</View>
      
{ (dataNavigationAuditsSch.length>0) ? 
<View style={styles.formContainer}>
         
<View style={styles.GroupItems}>
<Text style={styles.StyleItemLeft}>Nombre Auditoría:</Text>
<Text style={styles.StyleItemRight}>{dataNavigationAuditsSch[0].auditName}</Text>
<Text style={styles.StyleItemLeft}>Fecha Auditoría:</Text>
<Text style={styles.StyleItemRight}>{dataNavigationAuditsSch[0].dateAudit}</Text>
<Text style={styles.StyleItemLeft}>Hora Inicio:</Text>
<Text style={styles.StyleItemRight}>{dataNavigationAuditsSch[0].startHour}</Text>
<Text style={styles.StyleItemLeft}>Hora Finalización:</Text>
<Text style={styles.StyleItemRight}>{dataNavigationAuditsSch[0].endHour}</Text>
<Text style={styles.StyleItemLeft}>Nombre Auditor:</Text>
<Text style={styles.StyleItemRight}>{dataNavigationAuditsSch[0].fullName}</Text>
</View>
 
<Text style={styles.TextStyleObservation}>Observaciones Iniciales:</Text>
<Text style={styles.TextStylesWords}>{dataNavigationAuditsSch[0].initialObservation}</Text>

<TouchableOpacity style={styles.ButtonStart} onPress={()=> Navigation.navigate('Start_Audit')}>
               <View style={styles.Start}>
            <FontAwesome name="group" size={20} color="#ccf0f3" />
              <Text style={styles.TextStylesFinished}>Iniciar Auditoría</Text>
            </View> 
            </TouchableOpacity>
            <TouchableOpacity style={styles.ButtonUpdate} onPress={()=> Navigation.navigate('Update_Audit')}>
            <View style={styles.Update}>
            <MaterialCommunityIcons name="rocket-launch" size={20} color="#bfbfbf" />
              <Text style={styles.TextStylesFinished}>Actualizar Auditoría</Text>
            </View> 
            </TouchableOpacity>
            <TouchableOpacity style={styles.ButtonDelete} onPress={()=>{
                setAlertDeleteAuditSch(true)
            }}>
            <View style={styles.Delete}>
            <AntDesign name="delete" size={20} color="#bdffcd" />
              <Text style={styles.TextStylesFinished}>Eliminar Auditoría</Text>
            </View> 
               </TouchableOpacity>
<View style={styles.ViewSpace}></View>
</View> : null }
     




<AwesomeAlert style={styles.AlertContainer}
                show={alertDeleteAuditSch}
                showProgress={false}
                title="Eliminar Auditoría Programada"
                message="¿Estas seguro de que deseas eliminar la Auditoría Programada?, presiona [Eliminar] para confirmar los cambios."
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                titleStyle={{color:'#8DDBFF'}}
                messageStyle={{color:'#f9f9f9'}}
                contentContainerStyle={{backgroundColor:'#272727'}}
                confirmButtonColor='#809280'
                cancelButtonColor='#746E6E'
                onCancelPressed={() => {
                  setAlertDeleteAuditSch(false)
                }}
                onConfirmPressed={() => {
                  deleteAudit()
                }}
              />

<AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false);
            Navigation.navigate('Main_Audit');
          }}
        />


    </ScrollView>
    );
  }

const styles = StyleSheet.create({
  formContainer: {
    padding: 20,
    backgroundColor:'#111111',
  },
  TextScreen: {
    color: '#3c3c3c',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign:'center',
    paddingTop:10,
    backgroundColor:'#c0c0c0',
  },
  GroupItems: {
    flex:1,
    justifyContent:'space-between',
    flexWrap:'wrap',
    flexDirection: 'row',
    alignItems:'flex-start'
  },
  DatesPicked: {
    textAlign:'left',
    justifyContent:'center',
    width:'65%',
    height:60,
  },
  DatesInput: {
    alignItems:'center',
    justifyContent:'center',
    borderRadius:30,
    width:'35%',
    height:60,
  },
  DropDownSelection: {
    marginTop:10
  },
  TittleScreen:{
    color:'#fff',
    margin:10,
    fontSize:18,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewTittleScreen:{
    backgroundColor:'#616161',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10
  },
  ButtonStart: {
    backgroundColor:'#1f2532',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  Start: {
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonUpdate: {
    backgroundColor:'#4b5a4c',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  Update: {
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  ButtonDelete: {
    backgroundColor:'#604747',
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  Delete: {
    marginTop:10,
    borderRadius:10,
    alignItems:'center',
    justifyContent:'center'
  },
  
  TextStylesFinished: {
    color:'#f1f1f1',
    margin:10,
    fontSize:16,
    fontWeight:'bold',
    textAlign:'center'
  },
  ViewSpace: {
    marginTop:10,
    width:'100%'
  },
 FormStylesText:{
   color:'#f1f1f1'
 },
 TextStylesWords:{
    marginTop:5,
    color:'#f1f1f1',
    width:'100%',
    textAlign:'justify',
    marginBottom:10
  },
 TextStylesInput:{
  backgroundColor:'#bcc0c8',
  width:'100%',
  paddingLeft:10,
  paddingRight:10,
  height:200, 
  textAlignVertical: 'top',
  height:100,
  marginTop:10,
  marginBottom:10,
  paddingTop:10,
  textAlign:'justify'
 },
 InputAuditoryName:{
  width:'100%',
  height:35,
  borderRadius:10,
  padding:10,
  marginTop:10,
  alignItems:'center',
  justifyContent:'center',
  backgroundColor:'#dbd9d9'
 },
 StyleItemLeft:{
    color:'#647e9d',
    width:'50%'
 },
 StyleItemRight:{
    color:'#f1f1f1',
    width:'50%'
 },
 TextStyleObservation:{
    marginTop:10,
    color:'#647e9d'
 }

});

export default Audit_Detail
