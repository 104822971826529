// storage.js
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const days_Debug = 30;

const clearData = async () => {
  (Platform==='web') ?
    localStorage.clear() 
  :
    await AsyncStorage.clear();
}

const getFilteredDate = (dateString, days) => {
    // Get Actual Date:
    const now = new Date();
    const differenceInDays = (now - dateString) / (1000 * 60 * 60 * 24);
    return differenceInDays > days;
};

const getAllKeys = async () => {
  try {
    if (Platform.OS === 'web') {
      // Retrieve all keys from localStorage (web)
      return Object.keys(localStorage);
    } else {
      // Retrieve all keys from AsyncStorage (mobile)
      return await AsyncStorage.getAllKeys();
    }
  } catch (error) {
    console.error('Error retrieving keys:', error);
    return [];
  }
};

export const debugData = async () => {
  //Clear All LocalData:
  //clearData();
  const keys = await getAllKeys();
  const timeStampKeys = keys.filter(key => key.includes('timeStamp'));
  for (const key of timeStampKeys) {
    try {
      const data = await getData(key);
      const dateObject = new Date(data.dateCreated);
      if (data && data.dateCreated && getFilteredDate(dateObject, days_Debug)) {
        const keyObjetive = key.replace('timeStamp_', '');  
        //Delete key Data:
        deleteLocalData(keyObjetive);
        //Delete TimeStamp:
        deleteLocalData(key);
      }
    } catch (err) {
     console.log('No existe información temporal para recuperar.');
    }
     
  }
};

//Delete Local Data:
export const deleteLocalData = async (keyObjetive) => {
  try {
    if (Platform.OS === 'web') {
      const keys = Object.keys(localStorage);
      const keysToDelete = keys.find(key => key.includes(keyObjetive));
      localStorage.removeItem(keysToDelete);
    } else {
      const keys = await AsyncStorage.getAllKeys();
      const keysToDelete = keys.find(key => key.includes(keyObjetive));
      await AsyncStorage.removeItem(keysToDelete);
    }
  } catch (error) {
    console.error('Error deleting entries:', error);
  }
};

function extractData(string) {
  // Regular Expression to get UUID:
  const uuidRegex = /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
  
  // Search UUID on String
  const match = string.match(uuidRegex);
  
  if (match) {
    const uuid = match[0]; // UUID found
    const typeExecution = string.replace(uuid, ''); // rest of chain after delete UUID
    return { uuid, typeExecution };
  } else {
    // return null if there is not UUID found:
    return { uuid: null, typeExecution: text };
  }
};


// Function to save Data Locally:
export const storeData = async (key, value) => {
  try {
    // Extract Data Required:
    const { uuid, typeExecution } = extractData(key);
    // Get LocalData:
    const dateCreated = new Date().toISOString();
    // Create New Object to save locally:
    const _date_UUID = { uuid, dateCreated };
  // Transform to JSON All Data Array before Store:
    const jsonData = JSON.stringify(value);
    const jsonDate_UUID = JSON.stringify(_date_UUID);
    if (Platform.OS === 'web') {
      // Save Data on LocalSatorege in Web Platforms:
      localStorage.setItem(key, jsonData);
      // Save dataExtrated with timestamp Locally:
      localStorage.setItem("timeStamp_".concat(typeExecution, uuid), jsonDate_UUID);
    } else {
      // Save dataExtrated with timestamp Locally:
      await AsyncStorage.setItem("timeStamp_".concat(typeExecution, uuid), jsonDate_UUID);
      // Save on AsyncStorage for Movil React Native App:
      await AsyncStorage.setItem(key, jsonData);
      
    }
  } catch (error) {
    console.error(`Error storing data with key ${key}:`, error);
  }

};

// Recovery Data Function:
export const getData = async (key) => {
  try {
    if (Platform.OS === 'web') {
      // Recovery from LocalStorage:
      const jsonValue = localStorage.getItem(key);
      return jsonValue != null ? JSON.parse(jsonValue) : [];
    } else {
      // Recovery from AsyncStorage on Movile Devices:
      const jsonValue = await AsyncStorage.getItem(key);
      return jsonValue != null ? JSON.parse(jsonValue) : [];
    }
  } catch (error) {
    console.error(`Error retrieving data with key ${key}:`, error);
    return [];
  }
};