import React, { useContext, useState, useEffect } from 'react'
import {StyleSheet,Text, View, TextInput, Pressable, TouchableOpacity, ScrollView} from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import Context from '../../../../context/Context';
import AwesomeAlert from 'react-native-awesome-alerts';
//For making requests:
import {executeRequest} from "../../../functional_global_components/API_Requests";
  


const Products_Created = () => {

  const  {activityIndicator,setActivityIndicator, setDataNavigationInventory, setUpdateRequest,updateRequest, dataSession}= useContext(Context);
  const Navigation = useNavigation();
  const [itemPressed, setItemPressed] = useState([{id:'0'},]);
  const [arrayProducts, setArrayProducts]=useState([]);
  const [search, setSearch]=useState('') 
  const [showAllInventory, setShowAllInventory]=useState(false);
  
  //Deleting from Inventory:
  const [showAlertDeleteProducts, setShowAlertDeleteProducts]=useState(false);
  const [disableSubmit, setDisableSubmit]=useState(false);
  const [response, setResponse]=useState('');
  const [showAlertFinished, setShowAlertFinished]=useState(false);

   
    

  useEffect(() => {
    setActivityIndicator(false);
  }, [activityIndicator]);

  const sendRequest=()=>{
      //Get:
    executeRequest('/inventory', {
          "Get_Products": [
            {
              "body": {
                "_Type_Request": "Get_All_Products",
                "_Inventory_ID": null
              }
            }
          ]
        }, 'get', 'post').then(
                    getData=>{
                      setArrayProducts(getData)
                    }
              )
    }

    useEffect(()=>{
      sendRequest()
  },[])
  
  useEffect(()=>{
    sendRequest()
},[updateRequest])
  

const deleteProducts=()=>{
    setDisableSubmit(true);
    setShowAlertDeleteProducts(false);
    //Delete:
    executeRequest('/products', {
      "Delete_Products": [
        {
          "body": {
            "_Products": itemPressed
          }
        }
      ]
    }, 'post', 'delete').then(
          getData=>{
            setUpdateRequest(!updateRequest);
            setResponse(getData[0].Response);
            setShowAlertFinished(true);
            setDisableSubmit(false);
          }
        )
}

  const item= ({item})=>{
    
    return (
      <Pressable onPress={()=>{(itemPressed.find(e=>e.id===item.hashID)) ? setItemPressed(itemPressed.filter(e=>e.id!==item.hashID)) 
        : setItemPressed((e)=>[...e, {id:item.hashID}])
       }}
     style={{backgroundColor:(itemPressed.map(e=>e.id).indexOf(item.hashID)!==-1)? '#2f3335' : '#232427' 
               ,  flexDirection:'row',
               alignItems:'center',
               borderBottomWidth:0.5,
               borderColor:'#1d1d1d'
               }}>
        <View style={styles.Row}>
        {(itemPressed.map(e=>e.id).indexOf(item.hashID)!==-1)? <Feather name="check-circle" size={18} color="white" style={styles.Text}/>  : <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={18} color="grey" style={styles.Text}/> }
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.itemName}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.quantity}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.measure}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.type}</Text>
          </View>
          <View style={styles.Row}>
          <Text style={styles.Text}>{item.unitValue}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.totalValue}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.entryDate}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.Brand}</Text>
        </View>
        <View style={styles.Row}>
          <Text style={styles.Text}>{item.Consecutive}</Text>
        </View>
      
      </Pressable>

    )
    
  }

  
const showData=()=>{
  if (arrayProducts.length>0) {
  return (
<FlatList 
      data={(showAllInventory===true) ? 
        arrayProducts.filter((e)=>(e.itemName.toLowerCase().includes((search).toLowerCase())))
        :
        arrayProducts.filter((e)=>(e.itemName.toLowerCase().includes((search).toLowerCase()))).slice(0,10)
      }
      renderItem={item} 
      ListHeaderComponent={()=>{return(<View style={styles.ViewRows}
      >
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Check</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Item</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Cantidad</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>U. Medida</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Tipo</Text>
        </View>
        <View style={styles.Row}>
        <Text style={styles.TextHeader}>Valor Unit.</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Valor Total</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Fecha Ingreso</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Marca</Text>
      </View>
      <View style={styles.Row}>
        <Text style={styles.TextHeader}>Consecutivo</Text>
      </View>
     
    </View>)}}
      />
      );
    } else {
      return (
        <View style={styles.ViewRows}
              >
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Check</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Item</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Cantidad</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>U. Medida</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Tipo</Text>
                </View>
                <View style={styles.Row}>
                <Text style={styles.TextHeader}>Valor Unit.</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Valor Total</Text>
              </View>
              <View style={styles.Row}>
                <Text style={styles.TextHeader}>Fecha Ingreso</Text>
              </View>
                <View style={styles.Row}>
              <Text style={styles.TextHeader}>Marca</Text>
            </View>
            <View style={styles.Row}>
              <Text style={styles.TextHeader}>Consecutivo</Text>
            </View>
         
            </View>
              );
    }
  }

  return(

   <View style={styles.MainContainer}>

    <ScrollView>
  
    <Text style={styles.TittleScreen}>Inventario</Text>
    <Text style={styles.TextHigh}>Especifica el nombre del producto que deseas búscar en la base de datos:</Text>
    <View style={styles.ContainerSearch}>
    <TextInput title='Search' placeholder='Buscar producto específico' style={styles.SearchInput}
     onChangeText={(e) => {setSearch(e)}}
    />
    <AntDesign name="search1" size={24} color="white" style={styles.IconSearch}/> 
    </View>
    <ScrollView horizontal={true} nestedScrollEnabled={true}>
    <View style={styles.TableStyles}>
    {showData()}  
    </View>
    </ScrollView>

{(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4d26-f453-11ed-b65a-0697dd88345b") && 
<View>
       {(itemPressed[1]!==undefined && itemPressed[2]===undefined) &&
        <View style={styles.ButtonCenter}>
        <TouchableOpacity style={styles.ButtonStylesUpdate} onPress={()=>{
          setDataNavigationInventory([{itemchosen:itemPressed[1].id}]);
          Navigation.navigate('Update_Product');
          }}>
        <MaterialIcons name="update" size={18} color="white" />
          <Text style={styles.TextStyles}>Actualizar</Text>
          </TouchableOpacity>
        </View> 
        }
        {(itemPressed[1]!==undefined) &&
        <View style={styles.ButtonRight} >
        <TouchableOpacity style={styles.ButtonStylesDelete}  onPress={()=>{ setShowAlertDeleteProducts(true) }}>
        <AntDesign name="delete" size={18} color="white" />
          <Text style={styles.TextStyles}>Eliminar</Text>
          </TouchableOpacity>
        </View>
        }
  </View>
}

        <View style={styles.ButtonLeft}>
        <TouchableOpacity style={styles.ButtonStylesFinished} onPress={()=>{setShowAllInventory(!showAllInventory)}}>
        <MaterialIcons name="inventory" size={18} color="white" />
          <Text style={styles.TextStyles}>Inventario Completo</Text>
          </TouchableOpacity>
        </View> 
      


        <AwesomeAlert style={styles.AlertContainer}
                show={showAlertDeleteProducts}
                showProgress={false}
                title={(itemPressed.length>2) ? "Eliminar Productos" :
                  "Eliminar Producto"
                }
                message={ (itemPressed.length>2) ? 
                  "¿Estas seguro de que deseas eliminar los Productos seleccionados?, presiona [Eliminar] para confirmar los cambios."
                  :
                  "¿Estas seguro de que deseas eliminar este Producto?, presiona [Eliminar] para confirmar los cambios."
                }
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton={true}
                showConfirmButton={true}
                cancelText="No, cancelar"
                confirmText="Si, eliminar"
                titleStyle={{color:'#8DDBFF'}}
                messageStyle={{color:'#f9f9f9'}}
                contentContainerStyle={{backgroundColor:'#272727'}}
                confirmButtonColor='#809280'
                cancelButtonColor='#746E6E'
                onCancelPressed={() => {
                  setShowAlertDeleteProducts(false)
                }}
                onConfirmPressed={() => {
                  deleteProducts()
                }}
              />

<AwesomeAlert
          show={showAlertFinished}
          showProgress={false}
          title="Proceso Terminado"
          message={response}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          titleStyle={{color:'#8DDBFF'}}
          messageStyle={{color:'#f9f9f9'}}
          contentContainerStyle={{backgroundColor:'#272727'}}
          onDismiss={()=>{
            setShowAlertFinished(false)
          }}
        />

       </ScrollView>

     </View>

    

  )

}

const styles=StyleSheet.create({
  MainContainer:{
    backgroundColor:'#0d0d0d',
    justifyContent:'center',
    flex:1,
    flexDirection:'row'
  },

  PropertiesView:{
    backgroundColor:'#111111',
  },
  ViewRows:{
    flexDirection:'row',
    alignItems:'center',
  },
  Row:{
    textAlign:'left',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center'
  },
  
  Text:{
    width:120,
    padding:8,
    color:'#f1f1f1',
    textAlign:'center',
    borderRightWidth:1,
 
    borderRightColor:'#393939',
  },
  TextHeader:{
    color:'#B4DCEC',
    width:120,
    padding:5,
    textAlign:'center',
    borderRightWidth:1,
    fontWeight:'bold',
    borderRightColor:'#393939',
  },
  TableStyles: {
    backgroundColor:'#181818',
  },
  ContainerSearch: {
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'center',
  },
  SearchInput: {
    backgroundColor:'#767a7a',
    width:'70%',
    height:25,
    textAlign:'left',
    borderRadius:10,
    marginTop:40,
    marginBottom:40,
    paddingLeft:10,
    marginLeft:30
  },
  IconSearch:{
    width:'10%',
    marginTop:40,
    marginBottom:40,
    marginLeft:20
  },
  TextHigh: {
    textAlign:'left',
    color:'#EDF0F5',
    paddingTop:'5%',
    paddingLeft:'5%',
    paddingRight:'5%'
  },
  containerButton: {
    alignItems:'center',
    width:'100%',
    alignContent:'center',
    justifyContent:'center',
    textAlign:'center'
  },

  ButtonLeft: {
    fontWeight: 'bold',
    textAlign:'center',
    width:'100%',
    paddingTop:10,
    alignItems:'center',
    marginBottom:10,

  },
  ButtonCenter: {
    fontWeight: 'bold',
    textAlign:'center',
    width:'100%',
    paddingTop:10,
    alignItems:'center',
    marginBottom:10,
  },
  ButtonRight: {
    fontWeight: 'bold',
    textAlign:'center',
    width:'100%',
    paddingTop:10,
    alignItems:'center',
    marginBottom:10,
  },

  ButtonStylesFinished: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#262c37',
    width:180
  },

  ButtonStylesUpdate: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#3a5e40',
    width:180
  },

  ButtonStylesDelete: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 16,
    elevation: 3,
    backgroundColor:'#5e3a3a',
    width:180
  },
  TextStyles: {
    fontSize: 14,
    color: '#f1f1f1',
    
  },
  TittleScreen:{
    color:'#99f5bc',
    marginTop:20,
    fontSize:22,
    fontWeight:'bold',
    textAlign:'center'
  },

});

export default Products_Created