import  React, {useContext , useEffect} from 'react';
import { StyleSheet,View, Text } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'; //Method for navigate through BottomBar located at down part from our application
import { createStackNavigator } from '@react-navigation/stack';
//views screen:
import Login from '../views/Login';
import Forgot_Password from '../views/Forgot_Password';
import Reset_Password from '../views/Reset_Password';
import Confirm_Account from '../views/Confirm_Account';
import Projects from '../views/Projects';
import Inventory from '../views/Inventory';
import Admin from '../views/Admin';
import Reports from '../views/Reports';
import Dashboard from '../views/Dashboard';
import Teams from '../views/Teams';
import Clients from '../views/Clients';

//components_screen:
//projects:
import Search_Projects_By_NIT from '../views/components_screen/projects/Search_Projects_By_NIT';
import Projects_Created from '../views/components_screen/projects/Projects_Created';
import Create_New_Project from '../views/components_screen/projects/Create_New_Project';
import Requests from '../views/components_screen/projects/Requests';
import Project from '../views/components_screen/projects/Project';
import Update_Project from '../views/components_screen/projects/Update_Project';
//Clients:
import Create_Client from '../views/components_screen/projects/Clients/Create_New_Client';
import Clients_Created from '../views/components_screen/projects/Clients/Clients_Created';
import Client from '../views/components_screen/projects/Clients/Client';
import Update_Client from '../views/components_screen/projects/Clients/Update_Client';
//SubProjects:
import SubProject from '../views/components_screen/projects/SubProject';
//Signature:
import Create_Signatures from '../views/components_screen/projects/Signatures/Create_Signatures';
//Initial_Delivery_Agreement:
import Create_Initial_Delivery_Agreement from '../views/components_screen/projects/Initial_Delivery_Agreement/Create_Initial_Delivery_Agreement';
import Update_Initial_Delivery_Agreement from '../views/components_screen/projects/Initial_Delivery_Agreement/Update_Initial_Delivery_Agreement';
import Initial_Delivery_Agreement from '../views/components_screen/projects/Initial_Delivery_Agreement/Request_Initial_Delivery_Agreement';
//End_Delivery_Agreement:
import Create_End_Delivery_Agreement from '../views/components_screen/projects/End_Delivery_Agreement/Create_End_Delivery_Agreement';
import Update_End_Delivery_Agreement from '../views/components_screen/projects/End_Delivery_Agreement/Update_End_Delivery_Agreement';
import End_Delivery_Agreement from '../views/components_screen/projects/End_Delivery_Agreement/Request_End_Delivery_Agreement';
//Project_SubProject_Quotation
import Create_New_Project_SubProject_Quotation from '../views/components_screen/projects/Project_SubProject_Quotation/Create_Project_SubProject_Quotation';
import Update_Project_SubProject_Quotation from '../views/components_screen/projects/Project_SubProject_Quotation/Update_Project_SubProject_Quotation';
import Project_SubProject_Quotation from '../views/components_screen/projects/Project_SubProject_Quotation/Request_Project_SubProject_Quotation';
//Entry_Sheet
import Create_Entry_Sheet_PSP from '../views/components_screen/projects/Entry_Sheet/Create_Entry_Sheet_PSP';
import Request_Entry_Sheet_PSP from '../views/components_screen/projects/Entry_Sheet/Request_Entry_Sheet_PSP';
import Update_Entry_Sheet_PSP from '../views/components_screen/projects/Entry_Sheet/Update_Entry_Sheet_PSP';
//Project_Novelties:
import Create_Novelty_Project from '../views/components_screen/projects/Novelties/Create_New_Novelty';
import Update_Novelty_Project from '../views/components_screen/projects/Novelties/Update_Novelty';
//Teams
import Teams_Created from '../views/components_screen/teams/Teams_Created';
import Create_Team from '../views/components_screen/teams/Create_Team';
import Team from '../views/components_screen/teams/Team';
import Update_Team from '../views/components_screen/teams/Update_Team';
//Requests
import Requests_Created from '../views/components_screen/requests/Requests_Created';
import Create_New_Request from '../views/components_screen/requests/Create_New_Request';
import Update_Request from '../views/components_screen/requests/Update_Request';
import Request from '../views/components_screen/requests/Request';
//inventory:
import Products_Created from '../views/components_screen/inventory/products/Products_Created';
import Create_New_Product from '../views/components_screen/inventory/products/Create_New_Product';
import Update_Product from '../views/components_screen/inventory/products/Update_Product'
import Add_Existing_Products from '../views/components_screen/inventory/products/Add_Existing_Products';
import Deliveries from '../views/components_screen/inventory/Deliveries';
import Delivery_Detail from '../views/components_screen/inventory/deliveries/Delivery_Detail';
//Audits:
import Main_Audit from '../views/components_screen/inventory/Main_Audit';
import Schedule_New_Audit from '../views/components_screen/inventory/audits/Schedule_New_Audit';
import Audits_Scheduled from '../views/components_screen/inventory/audits/Audits_Scheduled';
import Audits_Done from '../views/components_screen/inventory/audits/Audits_Done';
import Audit_Detail from '../views/components_screen/inventory/audits/Audit_Detail';
import Update_Audit from '../views/components_screen/inventory/audits/Update_Audit';
import Start_Audit from '../views/components_screen/inventory/audits/Start_Audit';
//Admin:
import Users_Created from '../views/components_screen/admin/Users_Created';
import Create_New_User from '../views/components_screen/admin/Create_New_User';
//Icons:
import { AntDesign } from '@expo/vector-icons'; 
import { MaterialIcons } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { FontAwesome } from '@expo/vector-icons'; 
import SvgLogo from '../views/svg_components/SvgLogo';
import { StackActions, useNavigation, NavigationActions } from '@react-navigation/native';
//Global Functional Components:
import {verifySession, closeSession} from '../../src/views/functional_global_components/Validate_Session';
//Activity Indicator:
import Activity_Indicator from '../activity_indicators/Activity_Indicator';
//UseContext
import Context from '../context/Context';


const Stack= createStackNavigator();
const Tab= createBottomTabNavigator();

const My_Projects_Stacks = () => {
  const Navigation= useNavigation();
  const {identificationUser} = useContext(Context);
  
  return (
  //Projects:
  <Stack.Navigator initialRouteName="StackProjects" screenOptions={{headerTitleAlign:'center', 
  headerStyle:{backgroundColor:'black'},
  headerTitle:()=>(<SvgLogo/>), headerRight:()=>(<MaterialCommunityIcons name="exit-to-app" size={24} color="#b5b5b5" style={styles.logoutStyle} onPress={()=>{ closeSession(Navigation)  
   }}/>),
  headerBackTitleVisible:false
  }}>
  <Stack.Screen name="StackProjects" component={Projects} options={{
    headerLeft:()=>(<View style={styles.rootContainerUser}>
      <View style={styles.containerLeftIconUser}> 
      <FontAwesome name="user-circle" size={24} color="#96adc1" style={styles.userIcon}/>
      </View>   
      <View style={styles.containerUser}>
      <Text style={styles.userWellcome}> Hola,</Text>
      <Text style={styles.user}> {(identificationUser.length>0) ? identificationUser[0]["nameUser"] : "" }</Text>
        </View>
        </View>),
        headerBackTitleVisible:false
  }}/>
  <Stack.Screen name="Search_Projects_By_NIT" component={Search_Projects_By_NIT} options={{
    headerTitleStyle:{color:'#B4DCEC'},
    headerTintColor:'white',
    headerBackTitleVisible:false
    }}/>
  <Stack.Screen name="Projects_Created" component={Projects_Created} options={{
    headerTitle:()=>(<SvgLogo/>), 
    headerTitleStyle:{color:'#B4DCEC'},
    headerTintColor:'white',
    headerBackTitleVisible:false
    }}/>

      <Stack.Screen name="Project" component={Project} options={{
    headerTitle:()=>(<SvgLogo/>), 
    headerTitleStyle:{color:'#B4DCEC'},
    headerTintColor:'white',
    headerBackTitleVisible:false
    }}/>
      <Stack.Screen name="Update_Project" component={Update_Project} options={{
    headerTitle:()=>(<SvgLogo/>), 
    headerTitleStyle:{color:'#B4DCEC'},
    headerTintColor:'white',
    headerBackTitleVisible:false
    }}/>
  <Stack.Screen name="Create_New_Project" component={Create_New_Project} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Create_Client" component={Create_Client} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Update_Client" component={Update_Client} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="SubProject" component={SubProject} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Create_Initial_Delivery_Agreement" component={Create_Initial_Delivery_Agreement} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Update_Initial_Delivery_Agreement" component={Update_Initial_Delivery_Agreement} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Initial_Delivery_Agreement" component={Initial_Delivery_Agreement} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Create_End_Delivery_Agreement" component={Create_End_Delivery_Agreement} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Update_End_Delivery_Agreement" component={Update_End_Delivery_Agreement} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="End_Delivery_Agreement" component={End_Delivery_Agreement} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Create_Signatures" component={Create_Signatures} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Create_New_Project_SubProject_Quotation" component={Create_New_Project_SubProject_Quotation} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Update_Project_SubProject_Quotation" component={Update_Project_SubProject_Quotation} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Project_SubProject_Quotation" component={Project_SubProject_Quotation} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Create_Entry_Sheet_PSP" component={Create_Entry_Sheet_PSP} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Request_Entry_Sheet_PSP" component={Request_Entry_Sheet_PSP} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Update_Entry_Sheet_PSP" component={Update_Entry_Sheet_PSP} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>

<Stack.Screen name="Create_Novelty_Project" component={Create_Novelty_Project} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Update_Novelty_Project" component={Update_Novelty_Project} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>

  <Stack.Screen name="Teams" component={Teams} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Teams_Created" component={Teams_Created} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Create_Team" component={Create_Team} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Team" component={Team} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Update_Team" component={Update_Team} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Clients" component={Clients} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
<Stack.Screen name="Clients_Created" component={Clients_Created} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
 <Stack.Screen name="Client" component={Client} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
  <Stack.Screen name="Requests" component={Requests} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
}}/>
      <Stack.Screen name="Requests_Created" component={Requests_Created} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
        <Stack.Screen name="Create_New_Request" component={Create_New_Request} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
        <Stack.Screen name="Update_Request" component={Update_Request} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
        <Stack.Screen name="Request" component={Request} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
  </Stack.Navigator>
      );
  }



const My_Inventory_Stacks = () => {
  const Navigation= useNavigation();
  const {setDisconnectSession, identificationUser} = useContext(Context);

  return (
  //Inventory:
  <Stack.Navigator initialRouteName="InventoryStack" screenOptions={{headerTitleAlign:'center', 
  headerStyle:{backgroundColor:'black',},
  headerTitle:()=>(<SvgLogo/>), headerRight:()=>(<MaterialCommunityIcons name="exit-to-app" size={24} color="#b5b5b5"  style={styles.logoutStyle}  onPress={()=>{ closeSession(Navigation)  
  }}/>),
  headerBackTitleVisible:false
  }}>

  <Stack.Screen name="InventoryStack" component={Inventory} options={{
    headerLeft:()=>(<View style={styles.rootContainerUser}>
      <View style={styles.containerLeftIconUser}> 
      <FontAwesome name="user-circle" size={24} color="#96adc1" style={styles.userIcon}/>
      </View>   
      <View style={styles.containerUser}>
      <Text style={styles.userWellcome}> Hola,</Text>
      <Text style={styles.user}> {(identificationUser.length>0) ? identificationUser[0]["nameUser"] : "" }</Text>
        </View>
        </View>),
        headerBackTitleVisible:false
  }}/>
  <Stack.Screen name="Create_New_Product" component={Create_New_Product} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
  <Stack.Screen name="Update_Product" component={Update_Product} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
  <Stack.Screen name="Add_Existing_Products" component={Add_Existing_Products} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
  <Stack.Screen name="Products_Created" component={Products_Created} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
      <Stack.Screen name="Deliveries" component={Deliveries} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
       <Stack.Screen name="Delivery_Detail" component={Delivery_Detail} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/> 

  <Stack.Screen name="Main_Audit" component={Main_Audit} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
    <Stack.Screen name="Schedule_New_Audit" component={Schedule_New_Audit} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
     <Stack.Screen name="Audits_Scheduled" component={Audits_Scheduled} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
<Stack.Screen name="Audits_Done" component={Audits_Done} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
      <Stack.Screen name="Audit_Detail" component={Audit_Detail} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
        <Stack.Screen name="Update_Audit" component={Update_Audit} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
  <Stack.Screen name="Start_Audit" component={Start_Audit} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>

  </Stack.Navigator>

  
  );
}

const My_Reports_Stacks = () => {
  const Navigation= useNavigation();
  const {setDisconnectSession, identificationUser} = useContext(Context);


  return (
  //Inventory:
  <Stack.Navigator initialRouteName="ReportsStack" screenOptions={{headerTitleAlign:'center', 
  headerStyle:{backgroundColor:'black',},
  headerTitle:()=>(<SvgLogo/>), headerRight:()=>(<MaterialCommunityIcons name="exit-to-app" size={24} color="#b5b5b5"  style={styles.logoutStyle}  onPress={()=>{ closeSession(Navigation)  
  }}/>), 
  headerBackTitleVisible:false
  }}>
    <Stack.Screen name="ReportsStack" component={Reports} options={{
    headerLeft:()=>(<View style={styles.rootContainerUser}>
      <View style={styles.containerLeftIconUser}> 
      <FontAwesome name="user-circle" size={24} color="#96adc1" style={styles.userIcon}/>
      </View>   
      <View style={styles.containerUser}>
      <Text style={styles.userWellcome}> Hola,</Text>
      <Text style={styles.user}> {(identificationUser.length>0) ? identificationUser[0]["nameUser"] : "" }</Text>
        </View>
        </View>),
        headerBackTitleVisible:false
  }}/>

  
  </Stack.Navigator>
  );
}

const My_Dashboard_Stacks = () => {
  const Navigation= useNavigation();
  const {setDisconnectSession,  identificationUser} = useContext(Context);

  return (
  //Dashboard:
  <Stack.Navigator initialRouteName="DashBoardStack" screenOptions={{headerTitleAlign:'center', 
  headerStyle:{backgroundColor:'black',},
  headerTitle:()=>(<SvgLogo/>), headerRight:()=>(<MaterialCommunityIcons name="exit-to-app" size={24} color="#b5b5b5"  style={styles.logoutStyle}  onPress={()=>{ closeSession(Navigation)  
  }}/>), 
  headerBackTitleVisible:false
  }}>
  <Stack.Screen name="DashBoardStack" component={Dashboard} options={{
    headerLeft:()=>(<View style={styles.rootContainerUser}>
      <View style={styles.containerLeftIconUser}> 
      <FontAwesome name="user-circle" size={24} color="#96adc1" style={styles.userIcon}/>
      </View>   
      <View style={styles.containerUser}>
      <Text style={styles.userWellcome}> Hola,</Text>
      <Text style={styles.user}> {(identificationUser.length>0) ? identificationUser[0]["nameUser"] : "" }</Text>
        </View>
        </View>),
        headerBackTitleVisible:false
  }} />

  </Stack.Navigator>
  );
}

const My_Admin_Stacks = () => {
  const Navigation= useNavigation();
  const {setDisconnectSession, identificationUser} = useContext(Context);

  return (

  <Stack.Navigator initialRouteName="AdminStack" screenOptions={{headerTitleAlign:'center', 
  headerStyle:{backgroundColor:'black',}, 
  headerTitle:()=>(<SvgLogo/>), headerRight:()=>(<MaterialCommunityIcons name="exit-to-app" size={24} color="#b5b5b5"  style={styles.logoutStyle}  onPress={()=>{ closeSession(Navigation)  
  }}/>), 
  headerBackTitleVisible:false
  }}>
  <Stack.Screen name="AdminStack" component={Admin} options={{
    headerLeft:()=>(<View style={styles.rootContainerUser}>
      <View style={styles.containerLeftIconUser}> 
      <FontAwesome name="user-circle" size={24} color="#96adc1" style={styles.userIcon}/>
      </View>   
      <View style={styles.containerUser}>
      <Text style={styles.userWellcome}> Hola,</Text>
      <Text style={styles.user}> {(identificationUser.length>0) ? identificationUser[0]["nameUser"] : "" }</Text>
        </View>
        </View>),
        headerBackTitleVisible:false
  }}/>
      <Stack.Screen name="Users_Created" component={Users_Created} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>
      <Stack.Screen name="Create_New_User" component={Create_New_User} options={{
   headerTitle:()=>(<SvgLogo/>), 
   headerTitleStyle:{color:'#B4DCEC'},
   headerTintColor:'white',
   headerBackTitleVisible:false
    }}/>

  </Stack.Navigator>
  );
}

const MyTabsNavigator = () => {
  const {dataSession, sessionStatus, setSessionStatus, setUpdateRequest, updateRequest} = useContext(Context);  
  verifySession();
  if (sessionStatus===true) {

  return ( 
      <Tab.Navigator initialRouteName="Projects" screenOptions={{
        tabBarActiveTintColor:'#86f5d9',
        tabBarHideOnKeyboard:false,
        tabBarStyle: { height:'14%', backgroundColor:'black',},
        tabBarItemStyle: { padding:10},
        headerShown:false,
        headerBackTitleVisible:false
      }}>
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb83e1-f453-11ed-b65a-0697dd88345b" || dataSession["role"]==="69fb4dac-f453-11ed-b65a-0697dd88345b"
       || dataSession["role"]==="37ec1a36-f457-11ed-b65a-0697dd88345b") &&
      <Tab.Screen name="Projects" component={My_Projects_Stacks} 
      options={{tabBarLabel:'Proyectos', 
      tabBarIcon:({color, size})=> (<AntDesign name="rocket1" color={color}  size={30} />),
      headerBackTitleVisible:false
      }}/>
      }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" ||
      dataSession["role"]==='37ec1a36-f457-11ed-b65a-0697dd88345b' ||
      dataSession["role"]==='69fb4a85-f453-11ed-b65a-0697dd88345b' ||
      dataSession["role"]==='69fb4d26-f453-11ed-b65a-0697dd88345b') &&
      <Tab.Screen name="Inventory" component={My_Inventory_Stacks} 
      options={{tabBarLabel:'Inventario', 
      tabBarIcon:({color, size})=> (<MaterialCommunityIcons name="store-check" size={35} color={color} />),
      headerBackTitleVisible:false
      }} />
      }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" ||
      dataSession["role"]==='37ec1a36-f457-11ed-b65a-0697dd88345b' ||
      dataSession["role"]==='69fb4a85-f453-11ed-b65a-0697dd88345b' ||
      dataSession["role"]==='69fb4d26-f453-11ed-b65a-0697dd88345b') &&
      <Tab.Screen name="Reports" component={My_Reports_Stacks} 
      options={{tabBarLabel:'Reportes', 
      tabBarIcon:({color, size})=> (<Entypo name="notification" size={30}  color={color} />),
      headerBackTitleVisible:false
      }}/>
      }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" ) &&
      <Tab.Screen name="Dashboard" component={My_Dashboard_Stacks} 
      options={{tabBarLabel:'Dashboard', 
      tabBarIcon:({color, size})=> (<MaterialCommunityIcons name="view-dashboard" size={30} color={color} />),
      headerBackTitleVisible:false,
      }} onPress={()=>setUpdateRequest(!updateRequest)}/>
      }
      {(dataSession["role"]==="69fa5f62-f453-11ed-b65a-0697dd88345b" ||
      dataSession["role"]==='37ec1a36-f457-11ed-b65a-0697dd88345b' ||
      dataSession["role"]==='69fb4a85-f453-11ed-b65a-0697dd88345b') &&
      <Tab.Screen name="Admin" component={My_Admin_Stacks} 
      options={{tabBarLabel:'Admin',   
      tabBarIcon:({color, size})=> (<MaterialIcons name="admin-panel-settings" size={30}  color={color} /> ),
      headerBackTitleVisible:false
    }}/>
      }
      </Tab.Navigator> 
    
        );
      }    else {
        setSessionStatus(false)
      } 
}

const My_Login_Stacks = () => {
  return (
 
  //Projects:
  <Stack.Navigator initialRouteName="StackLogin" screenOptions={{headerShown:false, headerBackTitleVisible:false
  }}>
  <Stack.Screen name="StackLogin" component={Login} />
  <Stack.Screen name="MyTabsNavigator" component={MyTabsNavigator} />
  <Stack.Screen name="Forgot_Password" component={Forgot_Password} />
  <Stack.Screen name="Reset_Password" component={Reset_Password} />
  <Stack.Screen name="Confirm_Account" component={Confirm_Account} />
  </Stack.Navigator>
  );
}


const HomeNavigation = () => {
  const Navigation= useNavigation();
  
  useEffect(()=>{
    setTimeout(()=>{
      closeSession(Navigation) 
    },7200000)
  },[])


  return (
    <>
    <My_Login_Stacks/>
    
    {/* { activityIndicator===true ? (
      <View style={styles.ContainerActivityIndicator} >
      <Activity_Indicator/>
      </View>
      ) : null
    } */}
     
     </>
  );
}

const styles = StyleSheet.create({
ContainerActivityIndicator: {
  position:'absolute',
  left:'40%',
  top:'40%'
}, 
rootContainerUser:{
  flex:1,
  width:'100%',
  flexWrap:'wrap',
  flexDirection: 'row'
},
containerUser:{
  width:'60%'
 },
userWellcome:{
  color:'#f9f9d9'
 },
 user:{
   fontWeight:'bold',
   color:'#b4f1ff'
 },
 userIcon:{
  verticalAlign:'top',
  marginLeft:10
 },
 logoutStyle:{
  marginRight:10
 },
 containerLeftIconUser:{
  width:'40%',
 }

});

export default HomeNavigation
